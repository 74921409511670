import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Link, useHistory, useRouteMatch } from "react-router-dom";

import moment from 'moment';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';

import { Anchor, Box, Button, Grid, Heading, Image, ResponsiveContext, Stack, Text  } from 'grommet';
import { AddCircle, Chat, CircleInformation, Edit } from 'grommet-icons';
import { ImageStamp } from 'grommet-controls';
import RoutedButton from './RoutedButton';

import TagInput from './VevTagFilter';
import PCScene from './PC_Scene';
import EventParams from '../EventItems';
import Scrollbars from 'react-custom-scrollbars';

const SpacesList = (props) => {
    const [ spaces, setSpaces ] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [suggestions, setSuggestions] = useState (EventParams.Tags);
    const {canAdmin} = props;
    
    let { path, url } = useRouteMatch();
    let history = useHistory();

    useEffect(() => {

        return history.listen((location) => {
            console.log(`You changed the page to: ${location.pathname}`);
            //window.location.reload();
        })
    
    },[history])

    const getSpacesListAsync = async () => {
        try {
            const allUsers = await API.graphql(graphqlOperation(queries.listSpaces, { limit: 500 }));
            const foundSpacesList = allUsers.data.listSpaces.items;
        
            setSpaces (foundSpacesList);
            return foundSpacesList;
        } catch (err) {
            console.log ("Unable to get spaces!");
            return null;
        }
    }

    useEffect( () => {
        getSpacesListAsync ();
    }, []);

    
    const onRemoveTag = tag => {
      const removeIndex = selectedTags.indexOf(tag);
      const newTags = [...selectedTags];
      if (removeIndex >= 0) {
        newTags.splice(removeIndex, 1);
      }
      setSelectedTags(newTags);
    };
  
    const onAddTag = tag => setSelectedTags([...selectedTags, tag]);
  
    const onFilterSuggestion = value =>
      setSuggestions(
        EventParams.Tags.filter(
          suggestion => suggestion.toLowerCase().indexOf(value.toLowerCase()) >= 0
        )
      );

    const listSpaceBoxes = spaces.map(space => (
        <Box 
            round="medium"
            elevation="medium"
            key={space.name}
            background="background-contrast"
            animation="slideUp"
            justify="start"
            align="start"
            direction="column"
        >
            <Stack height="small" fill='horizontal' anchor='top-right'>
                <RoutedButton fill plain
                    path={`${url}/${encodeURIComponent(space.id)}`}
                    label=<Box height="small" fill='horizontal' round={{size: "medium", corner: "top"}}
                                background={space.id==='forest' ? { image: "url(/forest.png)" } : { image: "url(/under-construction.jpg)" }} />
                />

            </Stack>

            <Box height="xxsmall" fill='horizontal' overflow='hidden' pad='small'>
                <RoutedButton fill plain
                    path={`${url}/${encodeURIComponent(space.id)}`} >
                    <Text size='large' truncate color='brand'>{space.name}</Text>
                </RoutedButton>
            </Box>

            <Box height="xsmall" overflow='hidden' 
                    pad={{ left: "small", right: "small", bottom:"medium"}}>
                <Text size="small">{space.description}</Text> 
            </Box>
            <Box background="tab"
                round={{ size:"medium", corner:'bottom' }} 
                fill='horizontal' align='center' direction='row'
                justify="between" pad={{ vertical: "xsmall", horizontal: 'small'}} >
                <Chat color='brand' />
                <CircleInformation color='brand' />
            </Box>

        </Box>
        ));


    return (
        <ResponsiveContext.Consumer>
        {responsive => ( 
                <Box
                a11yTitle='Spaces List'
                fill
                tag='footer'
                direction='column'
                align='center'
                justify='between'
                pad={{ left: 'medium', right: 'small', vertical: 'xsmall' }}
                gap='medium'
                {...props}
                >
                    <Box fill='horizontal' align='center' direction='row' gap='small'>
                        <Heading color='brand' level='2'>Spaces</Heading>
                    </Box>
                    <Box fill='horizontal' align='center' direction='row' gap='small'>
                {canAdmin && <TagInput
                            placeholder="FILTER"
                            suggestions={suggestions}
                            value={selectedTags}
                            onRemove={onRemoveTag}
                            onAdd={onAddTag}
                            onChange={({ target: { value } }) => onFilterSuggestion(value)}
                />}
                    </Box>
                    <Box fill overflow='auto'>
                        <Scrollbars>
                            <Grid
                                margin={{ right: "small" }}
                        gap="medium" columns={responsive === 'small' ? '90vw' : "medium"} margin={{ right: "small" }} >
                                    {listSpaceBoxes}
                            </Grid>
                        </Scrollbars>
                    </Box>
                </Box>
        )}
        </ResponsiveContext.Consumer>

    );
}

export default SpacesList;