import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useRouteMatch, useParams } from "react-router-dom";

import EventParams from '../EventItems';
import { Activity, LVL } from '../Activity';

import KeyboardEventHandler from 'react-keyboard-event-handler';
import { ModalLink } from 'react-router-modal-gallery';
import { useFavicon } from 'react-use';
const sceneElementId = "scene-dom-id";

var eventOrigin = 'https://playcanv.as';
var fileOrigin = process.env.PUBLIC_URL;

// wrap in forwardref so we can access the ref object passed from parent
const PCScene = forwardRef((props, ref) => {
    // Grab what we got sent by parent
    const { loading, setLoading,
        userID, userName, isAuth, goToChatThread,  inviteToChat,
        setSessionOverlay, sessionOverlay, prevOverlay,
        threeDaction, threeDmode,
        userColor, skinColor, hairColor, hairStyle,
        setUserAnim, setUserPos, userPos,
        sceneActive, setSceneActive,
        lastSpace, setSpace, setPOI  } = props;
    
        
    const [sceneLoaded, setSceneLoaded] = useState(false);
    const [triggeredHotspot, setTriggeredHotspot] = useState ("");
    
    let { spaceId } = useParams();
    let { url } = useRouteMatch(); 


    // set a callback so parent can access this via the ref
    useImperativeHandle(ref, () => {
        return {
            sendUserDataToEngine: (changedUser) => {
                let newData = JSON.parse (changedUser.pos);
                if (typeof (newData) === 'string') {
                    newData = JSON.parse (newData);     // aws double json bug
                }
                //console.log (changedUser.updatedAt);
                const updateTime = new Date(changedUser.updatedAt);
                //console.log (updateTime);
                const combinedUpdate = { id: changedUser.id,
                    color: changedUser.color,
                    haircolor: changedUser.haircolor,
                    hairstyle: changedUser.hairstyle,
                    skincolor: changedUser.skincolor,
                    
                    name: changedUser.name,
                    anim: changedUser.animation,
                    update: updateTime.getTime (),
                    ...newData };
                const jsonUpdate = JSON.stringify (combinedUpdate);
                controlScene ("updatePlayer", jsonUpdate);
            }
        };
    });

    // Listen to an event on the global system bus
    const moveListener = (data) => {
        setUserPos (JSON.stringify (data));
        //console.log ("Set new user pos", data);
        // won't trigger if unchanged
        setSceneActive (Date.now());
    }

    const clickOnAvatar = (id) => {
        console.log ("Display details for: " + id);
        inviteToChat (userID, id, "friend", userName);
    }

    useEffect( () => {
        if (sessionOverlay === "") {
            console.log ("Overlay cleared - continue renders!");
            setTimeout (() => {
                controlScene ('unpauseEngine', 1);
                controlScene ('closed', triggeredHotspot);
                setTriggeredHotspot ("");
            }, 1100);
        } else {
            console.log ("Overlay up - pause renders!");
            controlScene ('pauseEngine', 1);
        }
    }, [sessionOverlay]);

    useEffect( () => {
        console.log ("Triggering action: " + threeDaction);
        controlScene ('unpauseEngine', 1);
        controlScene ('fireAction', threeDaction);
    }, [threeDaction]);

    useEffect( () => {
        console.log ("Triggering mode: " + threeDmode);
        controlScene ('unpauseEngine', 1);
        controlScene ('mode', threeDmode);
    }, [threeDmode]);

    const loadSceneAsync = async () => {
        window.addEventListener('message', messageHandler)

        // Forest POIs
        // TODO set from Database
        if (spaceId === 'forest') {
            
            
        }
        
        // do this when scene is actually loaded!
        
        setSpace (spaceId);
    }
    
    const unloadScene = () => {
        setSpace ("");   
        window.removeEventListener ('message', messageHandler);

    }

    const messageHandler = async (event) => {
        //var eventOrigin = window.location.href;
        console.log ("Message detected", event);
        if (eventOrigin.startsWith (event.origin) && event.data.source === 'PlayCanvas') {
                var chan = event.data.chan;
                var val = event.data.val;   // nbw converted back to JS!
                
                console.log ("Received message from 3d on: " + chan);
                //console.log (val);            
                
                switch (chan) {
                    case 'PC:Status':
                        if (val === 'UP') {
                            setSceneLoaded (true);
                            setSceneActive (Date.now());
                            goToChatThread (spaceId, false);

                        // set the current mode
                        controlScene ('mode', threeDmode);
                            {const eventData = {
                                space: { id: spaceId, name: "3D Space" },
                                userID: userID,
                                userName: userName,
                                action: 'enterScene',
                              }
                              console.log ("Event Entry: " + JSON.stringify (eventData));
                          Activity.track ('sceneActivity', eventData, false);}
                        }
                        break;
                    case 'updatePlayerPosition':
                        moveListener (val);
                        break;
                    case 'avatarPicked':
                        clickOnAvatar (val);
                        break;
                    case 'enterPOI':
                        {const eventData = {
                            space: { id: spaceId, name: "3D Space" },
                            userID: userID,
                            userName: userName,
                            action: 'enterPOI',
                            poi: val
                        }
                        //console.log ("Event Entry: " + JSON.stringify (eventData));
                        Activity.track ('sceneActivity', eventData, false);}
                          
                        goToChatThread (val, false);
                      
                        break;
                    case 'exitPOI':
                        {const eventData = {
                            space: { id: spaceId, name: "3D Space" },
                            userID: userID,
                            userName: userName,
                            action: 'exitPOI',
                            poi: val
                            }
                        //console.log ("Event Entry: " + JSON.stringify (eventData));
                        Activity.track ('sceneActivity', eventData, false);}
                        break;

                    case 'hotspotPressed':
                        console.log ("Hotspot requested: " + val);
                        const eventData = {
                            space: { id: spaceId, name: "3D Space" },
                            userID: userID,
                            userName: userName,
                            action: 'hotspotPressed',
                            hotspot: val
                          }
                      //console.log ("Event Entry: " + JSON.stringify (eventData));
                      Activity.track ('sceneActivity', eventData, false);
                        // Map hotspot to session ID
                
                        switch (val) {
                            default:
                                setSessionOverlay ("");
                                setTriggeredHotspot ("");
                                break;
                        }
                        break;
                    default:
                        console.log ("Unknown message from 3D engine! " + chan);
                        console.log (val);
                        break;
            }

            
            
        }
        
    }

    useEffect( () => {
        if (sceneLoaded) {
            // first we need to know who we are!
            controlScene ('setMyID', userID);

            // now construct the same JSON as another player
            let newData = JSON.parse (userPos);
            if (typeof (newData) === 'string') {
                newData = JSON.parse (newData);     // aws double json bug
            }
            //console.log (changedUser.updatedAt);
            const updateTime = new Date(sceneActive);
            //console.log (updateTime);
            const combinedUpdate = { id: userID,
                color: userColor,
                haircolor: hairColor,
                hairstyle: hairStyle,
                skincolor: skinColor,
                
                name: userName,
                update: updateTime.getTime (),
                ...newData };
            const jsonUpdate = JSON.stringify (combinedUpdate);
            controlScene ("updatePlayer", jsonUpdate);

        }
    }, [sceneLoaded]);

    useEffect( () => {
        loadSceneAsync ();
 
        return () => {
            unloadScene ();
        };
        
    }, []);

    const controlScene = async (channelName, value)  => {
        //console.log ("Send message to PlayCanvas: " + channelName, value);
        var iframe = document.getElementById(sceneElementId);
        iframe.contentWindow.postMessage ({source: 'vevomo', chan: channelName, val: value}, '*');
    }
    
    // respond to changes from React
    useEffect( () => {
        if (sceneLoaded) {
            // first we need to know who we are!
            controlScene ('setMyID', userID);

            // now construct the same JSON as another player
            let newData = JSON.parse (userPos);
            if (typeof (newData) === 'string') {
                newData = JSON.parse (newData);     // aws double json bug
            }
            //console.log (changedUser.updatedAt);
            const updateTime = new Date(sceneActive);
            //console.log (updateTime);
            const combinedUpdate = { id: userID,
                color: userColor,
                haircolor: hairColor,
                hairstyle: hairStyle,
                skincolor: skinColor,
                
                name: userName,
                update: updateTime.getTime (),
                ...newData };
            const jsonUpdate = JSON.stringify (combinedUpdate);
            controlScene ("updatePlayer", jsonUpdate);

        }
    }, [userColor, skinColor, hairStyle, hairColor, userName]);


    return (
        <iframe title="3D Scene" id={ sceneElementId }
            style={{ width: "100%", height: "99.8%",  zIndex: "-100" }} 
            src={eventOrigin + "/e/p/7eXmpEpA/"}
            />
    )

  } )

  export default PCScene;