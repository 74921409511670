import React from 'react';
import { Footer } from 'grommet';
import EventParams from '../EventItems';

const AppFooter = (props) => (
  <Footer
    a11yTitle='Event footer bar'
    fill='horizontal'
    tag='footer'
    direction='row'
    align='center'
    justify='between'
    background={EventParams.FlipHeaderFooterColors ? 'brand' : 'secondary'}
    pad={{ left: 'medium', right: 'small', vertical: 'xsmall' }}
    elevation='medium'
    style={{ zIndex: '1' }}
    height='xxsmall'
    {...props}
  />
)

export default AppFooter;