import React, { useLayoutEffect, useRef, useState } from 'react'
import 'video.js/dist/video-js.css';
import videojs from 'video.js';

require ('dashjs');
require ('videojs-contrib-dash');

require('videojs-contrib-quality-levels');
 
// The actual plugin function is exported by this module, but it is also
// attached to the `Player.prototype`; so, there is no need to assign it
// to a variable.
require('videojs-http-source-selector');

// sources:
// https://github.com/iweurman/react-hooks-videojs/blob/master/src/VideoPlayer.js
// https://github.com/karan101292/react-video-js-player/blob/master/src/index.js

export default (props) => {
    const [key, setkey] = useState(0);
    //const [token, setToken] = useState('?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kMWhhOTY1ZWxic3Buby5jbG91ZGZyb250Lm5ldC85MWJiZTYxNi0zYWZlLTQ1M2MtYmVhNS05OThmODM0ZjAzY2YvKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTU1NjgwMjcwMzc3MTgyNn19fV19&Key-Pair-Id=APKAJIR2RGJQ2ZQN6FCQ&Signature=L0uo2B1HEM-du-HZZG5AbO2t6Jpn6jHDXurVntvXy3gXzz2ve6l7AgJ71SeX1ITkRR-S-v3-XPThPZ538AqTk-Mf5CfjlL~X16ba4-mymFy6uOH55dt0KDYr5vezeBmTqODoz4xR9IyQJixt0X2~ISqas1TWRf77ES64-HHeN4f9jCbqlstKldnBInTO6U6R5QQ0KoGn1FksusFsdEMSAtwa2LdrXuRFeq2pKEqnKHyoIJk8XbXrMIlDHTVsISLsfxfwWcZUjybgRACT7~gBBDqU5lG2evQaF87tNUe8lQdSQx~yBDh6e-n0oK72QUjql97eHH4I6kp9rN6HZDwk-TTByA0G2ZvS72hs4w8b8g1Dat569ZXmvptOjYLCGBsypfOzfDe6wmCE49b6gBbv1Y7gAq4QitD-ogMxkJGGa7LLvsEzrYPtWnWKgrjqo01KUWVgKgNcSo-0Opxy1XHFrJHEiR5vZGGsgip3INgIaEwqKEV0Vm8Ss~MWclOfGrzTOIPjdQPTBI~McZSa0NChvY1cQvZ~SjyG8XYySyUvafZgptZRpyJ-WiBKETK9C9p60CwXBk0ONJ-rog~RRRX2rL4jsPuK-lJoxdtEs1C0~Uo83y6afsgINitLmwiFMKbvf8voA0LCczAe-9fj~zMJQD9EnjrEnaSBBHR6Y6Ia6II_');
    const [token, setToken] = useState('?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kMWpocnVqenA5cG40LmNsb3VkZnJvbnQubmV0L291dC92MS8qIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNTU2ODAyNzA0MzY1MDE5fX19XX0_&Key-Pair-Id=APKAJIR2RGJQ2ZQN6FCQ&Signature=h4xJCL6crOQjPrs1MrGD2rdFTeO72dYZTfS6huuR9qr5pMSvDaRbOtLVh~y23EZX-Jzz-0dbGqR~ADGvucbpcwFQBVxHbwhl8IijYUm9ykbAL4xjDbPrC7zz4i8TPag4Lt1EbIPuh7yfRDI9LLdg34vqkrs7aFlo5QsgCeHp0fiaWQrBXZgneuCKLD8jViy1yTrthAX2cdgRJZhsYMB2vvNcsC85P5YRdNc3SKM7CvZeQ0yn1KbjSDZfEve6uidkcL-nOWGWs6sgzABQIbYHvnzhxFxP8F1rl-5ATmGOxd15HXjEpacUtmt4JK4vvzntzHN3cZ8derRueQ7wwo3y4tS7Ow5jafpHP2eY9FXe2bJrDivf7ftFk-c6KP~TQ6jsYb26G~kzvunGwzv~VEGd-gjZYpUctqSfqgKQPmnD~66aP8~2dbkG6Qm6Dabry-XtPbD4yrswpsHKL70NMJvNpVEVdzlAXh52zUBBOr9Xvdl6hlhMpDgc6I9zYk6YMEMf-Gu9m9DWNIonIPp~XUOank0BqGACa1D0MNiG~bahltVvQuU6ylnSDLEo86c2eVkS9EJJ0isDYkEBLY8Qs41O7~1yfXrsUhnPE8vcfzjRQSaFsUX-RKLb4FBygun5PeqIC~tY51HHrEYOgIiyaVeQYZfZKK95sYv046TaAeWlRA8_');
    const [tokenFetched, setTokenFetched] = useState(false);
    let videoNode = useRef(null);
    let player = undefined;

    // TODO move to a hook

    // The sources may be a comma separated array
    //console.log (props.src)
    const sourceString = props.src.split (',');
    //console.log (sourceString)
    const sourceURLs = Array.from (sourceString, x => ({src: x + token }) )
    //console.log (sourceURLs)
    
    
    // ?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kMWhhOTY1ZWxic3Buby5jbG91ZGZyb250Lm5ldC85MWJiZTYxNi0zYWZlLTQ1M2MtYmVhNS05OThmODM0ZjAzY2YvKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTU1NjgwMjcwMzc3MTgyNn19fV19&Key-Pair-Id=APKAJIR2RGJQ2ZQN6FCQ&Signature=L0uo2B1HEM-du-HZZG5AbO2t6Jpn6jHDXurVntvXy3gXzz2ve6l7AgJ71SeX1ITkRR-S-v3-XPThPZ538AqTk-Mf5CfjlL~X16ba4-mymFy6uOH55dt0KDYr5vezeBmTqODoz4xR9IyQJixt0X2~ISqas1TWRf77ES64-HHeN4f9jCbqlstKldnBInTO6U6R5QQ0KoGn1FksusFsdEMSAtwa2LdrXuRFeq2pKEqnKHyoIJk8XbXrMIlDHTVsISLsfxfwWcZUjybgRACT7~gBBDqU5lG2evQaF87tNUe8lQdSQx~yBDh6e-n0oK72QUjql97eHH4I6kp9rN6HZDwk-TTByA0G2ZvS72hs4w8b8g1Dat569ZXmvptOjYLCGBsypfOzfDe6wmCE49b6gBbv1Y7gAq4QitD-ogMxkJGGa7LLvsEzrYPtWnWKgrjqo01KUWVgKgNcSo-0Opxy1XHFrJHEiR5vZGGsgip3INgIaEwqKEV0Vm8Ss~MWclOfGrzTOIPjdQPTBI~McZSa0NChvY1cQvZ~SjyG8XYySyUvafZgptZRpyJ-WiBKETK9C9p60CwXBk0ONJ-rog~RRRX2rL4jsPuK-lJoxdtEs1C0~Uo83y6afsgINitLmwiFMKbvf8voA0LCczAe-9fj~zMJQD9EnjrEnaSBBHR6Y6Ia6II_
    // ?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kMWpocnVqenA5cG40LmNsb3VkZnJvbnQubmV0L291dC92MS8qIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNTU2ODAyNzA0MzY1MDE5fX19XX0_&Key-Pair-Id=APKAJIR2RGJQ2ZQN6FCQ&Signature=h4xJCL6crOQjPrs1MrGD2rdFTeO72dYZTfS6huuR9qr5pMSvDaRbOtLVh~y23EZX-Jzz-0dbGqR~ADGvucbpcwFQBVxHbwhl8IijYUm9ykbAL4xjDbPrC7zz4i8TPag4Lt1EbIPuh7yfRDI9LLdg34vqkrs7aFlo5QsgCeHp0fiaWQrBXZgneuCKLD8jViy1yTrthAX2cdgRJZhsYMB2vvNcsC85P5YRdNc3SKM7CvZeQ0yn1KbjSDZfEve6uidkcL-nOWGWs6sgzABQIbYHvnzhxFxP8F1rl-5ATmGOxd15HXjEpacUtmt4JK4vvzntzHN3cZ8derRueQ7wwo3y4tS7Ow5jafpHP2eY9FXe2bJrDivf7ftFk-c6KP~TQ6jsYb26G~kzvunGwzv~VEGd-gjZYpUctqSfqgKQPmnD~66aP8~2dbkG6Qm6Dabry-XtPbD4yrswpsHKL70NMJvNpVEVdzlAXh52zUBBOr9Xvdl6hlhMpDgc6I9zYk6YMEMf-Gu9m9DWNIonIPp~XUOank0BqGACa1D0MNiG~bahltVvQuU6ylnSDLEo86c2eVkS9EJJ0isDYkEBLY8Qs41O7~1yfXrsUhnPE8vcfzjRQSaFsUX-RKLb4FBygun5PeqIC~tY51HHrEYOgIiyaVeQYZfZKK95sYv046TaAeWlRA8_
    //withCredentials: true,
    const options = {
        autoplay: false,
        playbackRates: [0.8, 1.0, 1.2, 1.4],
        controls: props.controls,
        responsive: true,
        controlBar: {
            children: ['playToggle', 'fullscreenToggle', 'volumePanel', 'currentTimeDisplay', 'timeDivider', 'durationDisplay', 'progressControl', 'liveDisplay', 'seekToLive', 'remainingTimeDisplay', 'customControlSpacer', 'playbackRateMenuButton', 'subsCapsButton', 'audioTrackButton', 'settingMenuButton', 'qualitySelector']
        },
        preload : 'auto',
        sources: sourceURLs,

        plugins: {
            httpSourceSelector:
            {
              default: 'high'
            }
        },

        html5: {
            nativeCaptions: false
        },
    }

    //Optional Callback to disable debugging logging
    var dashjsCallback = function (player, mediaPlayer) {
        // Log MediaPlayer messages through video.js
        if (videojs && videojs.log) {
            //mediaPlayer.getDebug().setLogToBrowserConsole(false);
        }
    };

  useLayoutEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    //videojs.Html5DashJS.hook('beforeinitialize', dashjsCallback);
    player = videojs(videoNode.current, options, () => {
        player.httpSourceSelector ();
        player.qualityLevels ();
        
        player.poster(props.poster);
        player.fill (true);

        let currentTime = 0;
        let previousTime = 0;
        let position = 0;

        player.ready(() => {
            props.onReady(player);
            window.player = player;

            // TODO maybe use Vhs not hls
            // https://github.com/videojs/video.js/issues/6643
            if (player.tech({ IWillNotUseThisInPlugins: true }) && player.tech({ IWillNotUseThisInPlugins: true }).hls) {
                console.log ("Correcting hls secure URLs");
                player.tech({ IWillNotUseThisInPlugins: true }).hls.xhr.beforeRequest = (options) => {
                    options.uri = options.uri + token;
        
                    return options;
                }
            } else {
                console.log ("Not an HLS file!");
            }
        });
        player.on('play', () => {
            props.onPlay(player.currentTime());
        });
        player.on('pause', () => {
            props.onPause(player.currentTime());
        });
        player.on('timeupdate', (e) => {
            props.onTimeUpdate(player.currentTime());
            previousTime = currentTime;
            currentTime = player.currentTime();
            if (previousTime < currentTime) {
                position = previousTime;
                previousTime = currentTime;
            }
        });
        player.on('seeking', () => {
            player.off('timeupdate', () => { });
            player.one('seeked', () => { });
            props.onSeeking(player.currentTime());
        });

        player.on('seeked', () => {
            let completeTime = Math.floor(player.currentTime());
            props.onSeeked(position, completeTime);
        });
        player.on('ended', () => {
            props.onEnd();
        });
    });

    return () => {
      if(player) {
        player.dispose()
        setkey(key + 1)
      }
    }
  }, [options.sources.src])

  
  return (
    <div style={{ width: "100%", height: "100%"}}>
    <div data-vjs-player key={`video-${key}`}>
      <video ref={videoNode} className="video-js  vjs-big-play-centered" ></video>
        </div>
    </div>
  )
  
}