import React, { useEffect, useState } from 'react';
import { Box, Button, Heading, Menu, Stack, Text  } from 'grommet';
import { Alarm, CircleInformation, Chat, Edit, Previous } from 'grommet-icons';

import { useParams, useLocation, useHistory, useRouteMatch } from "react-router-dom";

import { API, graphqlOperation, Storage } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import usePrevious from '../utils/use-previous'
import moment from 'moment';
import { toast } from 'react-toastify';

import RoutedButton from './RoutedButton';

import { Activity, LVL } from '../Activity';

import EventParams from '../EventItems';
import S3ImageBox from './S3ImageBox';
import S3ImageAvatar from './S3ImageAvatar';

import Scrollbars from 'react-custom-scrollbars';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const Person = (props) => {
  const [personDetails, setPersonDetails] = useState({});
  const [isFavorite, setIsFavorite] = useState(false);
  const [friends, setFriends] = useState([]);
  const [isFriend, setIsFriend] = useState(0);

  let { personId } = useParams();
  let { url } = useRouteMatch();
  let query = useQuery();
  let history = useHistory();

  const { userID, userName, canAdmin, inviteToChat } = props;

  const downloadPersonMediaLink = async (key) => {
    const storedURL = await Storage.get (key, { level: 'public' });
    console.log (storedURL);
    console.log ("Get file at: " + storedURL);
    window.open (storedURL, "_blank");
  }

  const getPersonDetails = async () => {
    try {
      const foundPerson = await API.graphql(graphqlOperation(queries.getUser, { id: personId } ));
      //console.log (foundPerson);
      if (foundPerson.data.getUser) {
        const currentPerson = foundPerson.data.getUser;
        setPersonDetails (currentPerson);

      }

      // Also who are our friends?
      const myUser = await API.graphql(graphqlOperation(queries.getUser, {id:userID}));
      if (myUser.data.getUser) {
          const storedUserData = myUser.data.getUser;
          if (storedUserData.friends) {
              const newFriendList = JSON.parse(storedUserData.friends);
              setFriends (Array.isArray(newFriendList) ? newFriendList : []); 
              setIsFriend (newFriendList.includes (personId) ? 1 : 0);
          } else {
              setFriends ([]); 
              setIsFriend (0);
          }
      }
    } catch (err) {
      console.log ("Could not get person details");
    }
  };

  const toggleFavorite = async (myID, theirID) => {
    //console.log ("toggle fave:", myID);
    if (isFriend === 1) {
        const newFaves = friends.filter (item => item !== theirID);
        setFriends (newFaves);
        updateFaves (myID, newFaves);
    } else {
        const newFaves = [
            ...friends,
            theirID
        ]
        setFriends (newFaves);
        
        updateFaves (myID, newFaves);
    }
    setIsFriend (isFriend === 1 ? 0 : 1);
  }

  const updateFaves = async (myID, newFaves) => {
      //console.log ("Update faves", myID);
      const input = {
          id: myID,
          friends: JSON.stringify(newFaves)
      };

      //console.log (input);
      try {
          const tweakedUser = await API.graphql(graphqlOperation(mutations.updateUser, { input }));
          //console.log (tweakedUser);

      } catch (err) {
          console.log ("Unable to set User favorites: ", err);
          //console.log (input);
      }
  }

  useEffect( () => {
    //console.log (personActivity);
    //if (!personActivity) || (isFavorite === true && personActivity && (personActivity.isFave === null || personActivity.isFave === true))) {
      const input = { isFave: isFavorite };
      

    //}

  }, [isFavorite]);

  useEffect( () => {
    // did we get to this link by a refresh or similar? Is it active?
  console.log (query);

    getPersonDetails ();
    console.log ("Viewing Person " + personId );
  }, []);
  
return (
  <Box
      a11yTitle={personDetails.name + ' Personal Details'}
      fill
      tag='footer'
      direction='column'
      align='start'
      gap='small'
      pad={{ left: 'medium', right: 'small', vertical: 'xsmall' }}
      {...props}
    >
      <Box fill='horizontal' align='center' direction='row' gap='small'>
          <Previous color='brand' onClick={() => history.goBack()} />
          <Heading level='3' color="brand" >{personDetails.name}</Heading>
      </Box>
      
      <Box fill direction='column' align='center' justify='center' pad='large'>
        <Box  fill='horizontal' direction='row-responsive' align='start' gap='medium'>
          
          <Box direction='column' gap='medium' justify='center' align='center' >
            <S3ImageAvatar size='192px' color={personDetails.color} userID={personDetails.id} />
            <Box direction='row' gap='small'> 
              <Button plain icon=<EventParams.Favorite on={isFriend} color='brand' /> onClick={() => { toggleFavorite (userID, personDetails.id) }} />
                {personDetails.allowInvitations && <Button plain icon=<Chat
                         color='brand'/> onClick={() => {inviteToChat (userID, personDetails.id, personDetails.name, userName);}} />}
              {(canAdmin || userID === personDetails.id) &&
                <RoutedButton fill plain path={`/peopleEditor/${encodeURIComponent(personDetails.id)}`} label=<Edit color='brand'/> /> }  
            </Box>              
          </Box>
          
          <Box direction='column' size='medium' overflow='auto' gap='small' align='start' justify='start'>
            <Box direction='column' gap='xxsmall' align='start' justify='start'>
              <Text size='large' color='brand' >{personDetails.name}</Text>
              <Text size='small' weight='bold' >{personDetails.title}</Text>
              <Text size='small'  weight='bold' >{personDetails.company}</Text>
            </Box>
            <Box direction='column' gap='xsmall' align='start' justify='start' width='medium'>
              {/*<Text size='medium' color='brand' >About me</Text>*/}
              <Text size='small'>{personDetails.description}</Text>
            </Box>
          </Box>
            

        </Box>
      </Box>    
    
    </Box>

)

};

export default Person;