import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import moment from 'moment'
import { Auth } from 'aws-amplify';
import { google, outlook, office365, ics } from 'calendar-link';
import KeenTracking from 'keen-tracking';

import EventParams from './EventItems';

//import { API, graphqlOperation } from 'aws-amplify';
//import * as mutations from '../graphql/mutations';
// import * as queries from '../graphql/queries';
// import * as subscriptions from '../graphql/subscriptions';

// import Analytics from '@aws-amplify/analytics';

// Central logging, activity tracking and analytics

export const LVL = {
    debug: "Debug",
    user: "User",
    netErr: "Network error"

};
// No enums in js, so we'll get the strings we define here

class ActivityTracker {
    constructor () {
        let now = moment();
        console.log ("Started tracking activity at " + now.format());

        this.init = false;
        this.initialize ();
        this.eventStaff = false;
    }

    
        

    async initialize (){
            try {
             // https://janhesters.com/tracking-and-email-reminders-in-aws-amplify/
             const authUser = await Auth.currentAuthenticatedUser();
             //console.log (authUser);
            
             // TODO mobile tracking
            this.client = new KeenTracking({
                projectId: EventParams.keenProject,
                writeKey: EventParams.keenWriteKey,
                protocol: 'https',
                host: 'metrics.vevomo.com'
            });
             //
             // 
             
             // https://github.com/keen/keen-tracking.js/blob/master/docs/auto-tracking.md
             this.client.initAutoTracking({

                // record on page load
                recordPageViews: true,
                // OR
                // record on leaving the page - this ways you will get the time spent on this page
                recordPageViewsOnExit: true,
              
                recordScrollState: false, // see how far people scrolled
              
                recordClicks: false, // record clicks on A links
                recordClicksPositionPointer: false, // record pointer position for clicks
                
                // FORMS
                recordFormSubmits: true,
                ignoreDisabledFormFields: false,
                ignoreFormFieldTypes: ['password'],
              
                // GDPR related options
                collectIpAddress: true, // default
                collectUuid: true, // default
              
                // share UUID cookies across subdomains
                shareUuidAcrossDomains: false, // default
              
                // catchError: myCustomErrorHandler
              
                //Track HTML elements views
                recordElementViews: false // see if an element was seen
              
              });
            
            
             this.init = true;
 
           } catch (error) {
             console.log("Error starting analytics: ", error);
           } 


    }

    setEventStaff (eventStaff) {
        this.eventStaff = eventStaff;
    };

    // throttle at the other end!
    async track (collection, data, debug) {
        if (this.init && this.eventStaff === false) {
            // Data should have userID
            //console.log (this.client);
            this.client.recordEvent(collection, data);
             //   .then(result => {
             //       //console.log('Success', result);
             //   } )
             //   .catch(err => {
             //       console.log('Tracking system error', err);
             //   });
            if (true || debug) {
                console.log ("Event Entry in " + collection + " collection: " + JSON.stringify (data));
            }
        }
    };

    log (level, message, source, debug, sourceId = 'any') {
        let now = moment();
        let newMsg = level + " activity at " + now.format() + ": " + message + " (triggered by " + source + ")"; 
        
        if (this.init) {
            //Analytics.record ({ name: message, attributes: {source: source, id: sourceId} });
        }

        if (debug) {
            console.log (newMsg);
        }

    }

}

export const createCalendarLinkFromSession = (session, type, url) => {
    console.log (url)
    //console.log ('Create link type: ' + type);
    //console.log (session);
    //console.log (session.startdate);
    const eventStart = (session.startdate === null) ? moment (session.starttime, "hh:mm:ss") :  moment (session.startdate + "T" + session.starttime);
    const eventEnd = (session.enddate === null) ? moment (session.endtime, "hh:mm:ss") : moment (session.enddate + "T" + session.endtime);
    // if dates were blank it should be today
    var eventDuration = moment.duration(eventEnd.diff(eventStart));
    //console.log (eventStart);
    //console.log (eventEnd);
    //console.log (eventDuration.asMinutes())


    const sessionDescription = EventParams.Name + " Session: " + session.description
        + '\n\n' +  url;
    const event = {
        title: session.name,
        description: sessionDescription,
        start: eventStart.toISOString (),
        end: eventEnd.toISOString ()
    }

    let link = "";
    switch (type) {
        case 'google':
            link = google (event);
            break;
        case 'outlook':
            link = outlook (event);
            break;
        case 'office365':
            link = office365 (event);
            break;
        case 'generic':
        default:
            link = ics (event);
            break;
    }
    //console.log (link);
    window.open(link, "_blank")
};

// Javascript fun = let defines this as a solo instance 
// https://k94n.com/es6-modules-single-instance-pattern
export let Activity = new ActivityTracker();
