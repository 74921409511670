import * as React from "react";
import { TextInput as OriginalTextInput } from "grommet";

const TextInput = React.forwardRef(
  ({ onSelect = () => {}, onSuggestionSelect = () => {}, ...rest }, _ref) => {
    const ref = React.useMemo(() => {
      return { current: null };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    React.useEffect(() => {
      return () => {
        if (!ref.current) return;
        ref.current.removeEventListener("select", onSelect);
        ref.current = null;
      };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return (
      <OriginalTextInput
        onSelect={onSuggestionSelect}
        {...rest}
        ref={input => {
          if (_ref) {
            _ref(input);
          }
          if (input) {
            ref.current = input;
            input.addEventListener("select", onSelect);
          }
        }}
      />
    );
  }
);
export default TextInput;