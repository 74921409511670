import React, { useEffect, useState } from 'react';
import { Anchor, Box, Button, Heading, Image, Menu, ResponsiveContext, Stack, Text  } from 'grommet';
import { Alarm, CircleInformation, CirclePlay, Chat, Edit, Previous } from 'grommet-icons';

import { useParams, useLocation, useHistory, useRouteMatch } from "react-router-dom";

import { API, graphqlOperation, Storage } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import usePrevious from '../utils/use-previous'
import moment from 'moment';
import {v4 as uuid} from 'uuid';

import RoutedButton from './RoutedButton';
import VevVideoPlayer from './VevVideoPlayer';

import { Activity, LVL } from '../Activity';
import { useInterval } from 'react-use';

import EventParams from '../EventItems';
import S3ImageBox from './S3ImageBox';
import S3Image from './S3Image';
import Scrollbars from 'react-custom-scrollbars';
import { useMeasure } from 'react-use';

import BaseInterweave, { InterweaveProps } from 'interweave';
import { UrlMatcher, EmailMatcher, HashtagMatcher } from 'interweave-autolink';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const globalMatchers: Matcher[] = [
  new EmailMatcher('email'),
  new UrlMatcher('url'),
  new HashtagMatcher('hashtag'),
];

function Interweave(props: InterweaveProps) {
  return <BaseInterweave {...props} newWindow={true} matchers={globalMatchers} />;
}

function InterweaveFormat(props: InterweaveProps) {
  return <BaseInterweave {...props} newWindow={true} />;
}

const PartnerDetail = (props) => {
  const [partnerDetails, setPartnerDetails] = useState({});
  const [isFavorite, setIsFavorite] = useState(false);
  const [ haveVisited, setHaveVisited ] = useState(false);
  const [ partnerCount, setPartnerCount] = useState(0);
  const [visitedCount, setVisitedCount] = useState(0);

  const [partnerActivity, setPartnerActivity] = useState(null);
  const [visitID, setVisitID] = useState (null);
  const [visitStart, setVisitStart] = useState (0);

  const [isPlayable, setIsPlayable] = useState (false);
  const [player, setPlayer] = useState (null);
  const [playerActivity, setPlayerActivity] = useState([]);
  
  const [playerPos, setPlayerPos] = useState(0);
  const [playRangeStartPosition, setPlayRangeStartPosition] = useState(0.0);
  const [playRangeEndPosition, setPlayRangeEndPosition] = useState(0.0);
  const [videoAR, setVideoAR] = useState (0);

  const [ref, {top, left, width, height}] = useMeasure();
  const [posX, setPosX] = useState ('24px');
  const [posY, setPosY] = useState ('146px');
  const [posWidth, setPosWidth] = useState ('75vw');
  const [posHeight, setPosHeight] = useState ('42.18vw');

  const [downloadables, setDownloadables] = useState({});

  let { partnerId } = useParams();
  let { url } = useRouteMatch();
  let query = useQuery();
  let history = useHistory();
  
  const [showingVideo, setShowingVideo] = useState (false);
  const prevVideo = usePrevious(showingVideo);

  const { userID, userName, canAdmin, changed, goToChatThread } = props;
  
  const downloadPartnerMediaLink = async (index) => {
    const key = getDownloadableFileKey (index);
    const name = getDownloadableEventName (index);

    const eventData = {
      partner: { id: partnerDetails.id, name: partnerDetails.name },
      userID: userID,
      userName: userName,
      item: name
      
    }
    //console.log ("Event Entry: " + JSON.stringify (eventData));
    Activity.track ('partnerActivity', eventData, false);

    if (key && key.length > 4) {
    const storedURL = await Storage.get (key, { level: 'public' });
    console.log (storedURL);
    console.log ("Get file at: " + storedURL);
    window.open (storedURL, "_blank");
    }
    markPartnerDownloadAction (name);
  }

  const markPartnerDownloadAction = async (name) => {
    if (!partnerDetails || !partnerDetails.id) return;
    const downloadObject = {action: name, downloadMark: moment().unix()};
    
    try {
      if (!partnerActivity) {
        const mediaArray = [ downloadObject ];
        const input = { mediaActions: JSON.stringify (mediaArray), userID: userID, partnerID: partnerDetails.id};
        const updatedPartnerActivity = await API.graphql(graphqlOperation(mutations.createPartnerActivity, {input: input}));
        //console.log (updatedPartnerActivity);
        setPartnerActivity (updatedPartnerActivity.data.createPartnerActivity);
      } else {
        // use the current grab we have, for now
        // TODO refresh
        const prevMedia = partnerActivity.mediaActions ? JSON.parse (partnerActivity.mediaActions) : [];  

        if (prevMedia && Array.isArray(prevMedia)) {
          //console.log ("Merging in previous visits: ", prevVisits)
        } else {
          console.log ("Error with partner media actions - it wasn't an array!", partnerActivity);
        }
        // store the newvalue with old ones (filtered)
        const mediaArray = [downloadObject, ...prevMedia];
        //console.log ("Visits: ", visitArray );
        const input = {id:partnerActivity.id, mediaActions: JSON.stringify (mediaArray)};
        const updatedPartnerActivity = await API.graphql(graphqlOperation(mutations.updatePartnerActivity, {input: input}));
        //console.log (updatedPartnerActivity);
        setPartnerActivity (updatedPartnerActivity.data.updatePartnerActivity);
      }
      
    } catch (err) {
      console.log ("Couldn't record partner activity: " + err.message);
      console.log (err);
    }
  }

  const markPartnerPlaybackAction = async (name) => {
    if (!partnerDetails || !partnerDetails.id) return;
    const playbackObject = {action: name, downloadMark: moment().unix()};
    
    const eventData = {
      partner: { id: partnerDetails.id, name: partnerDetails.name },
      userID: userID,
      userName: userName,
      item: 'video'
      
    }
    //console.log ("Event Entry: " + JSON.stringify (eventData));
    Activity.track ('partnerActivity', eventData, false);

    try {
      if (!partnerActivity) {
        const playbackArray = [ playbackObject ];
        const input = { playbackActions: JSON.stringify (playbackArray), userID: userID, partnerID: partnerDetails.id};
        const updatedPartnerActivity = await API.graphql(graphqlOperation(mutations.createPartnerActivity, {input: input}));
        //console.log (updatedPartnerActivity);
        setPartnerActivity (updatedPartnerActivity.data.createPartnerActivity);
      } else {
        // use the current grab we have, for now
        // TODO refresh
        const prevPlaybacks = partnerActivity.playbackActions ? JSON.parse (partnerActivity.playbackActions) : [];  

        if (prevPlaybacks && Array.isArray(prevPlaybacks)) {
          //console.log ("Merging in previous visits: ", prevVisits)
        } else {
          console.log ("Error with partner playback actions - it wasn't an array!", partnerActivity);
        }
        // store the newvalue with old ones (filtered)
        const playbackArray = [playbackObject, ...prevPlaybacks];
        //console.log ("Visits: ", visitArray );
        const input = {id:partnerActivity.id, playbackActions: JSON.stringify (playbackArray)};
        const updatedPartnerActivity = await API.graphql(graphqlOperation(mutations.updatePartnerActivity, {input: input}));
        //console.log (updatedPartnerActivity);
        setPartnerActivity (updatedPartnerActivity.data.updatePartnerActivity);
      }
      
    } catch (err) {
      console.log ("Couldn't record partner activity: " + err.message);
      console.log (err);
    }
  }

  const getPartnerDetails = async () => {
    try {
      const foundPartner = await API.graphql(graphqlOperation(queries.getPartner, { id: partnerId } ));
      
      if (foundPartner.data.getPartner) {
        const currentPartner = foundPartner.data.getPartner;
        setPartnerDetails (currentPartner);

        console.log ("Viewing partner ", currentPartner);
        if (!currentPartner.hideThread && currentPartner.gotoThread) {
          goToChatThread (currentPartner.thread, true);
        }

        // Can we play this?
        if (currentPartner.video && currentPartner.video.length > 3) {
            setIsPlayable (true);

        } else {
          setIsPlayable (false);
        }

          // get partner activity too, if it exists
        try {
          const activityQuery = await API.graphql(graphqlOperation(queries.listPartnerActivityByUser, { userID:userID, limit: 1000 } ));

          const foundActivity = activityQuery.data.listPartnerActivityByUser.items;
          //console.log (foundActivity);
          if (foundActivity) {
            const partnerMatch = foundActivity.find (act => act.partnerID === partnerId);
            //console.log (partnerMatch);
            setPartnerActivity (partnerMatch);
            // we have to set this to display, but will it cause a loop?
            if (partnerMatch && partnerMatch.isFave !== null) {
              setIsFavorite (partnerMatch.isFave);
            } 
            if (partnerMatch && partnerMatch.visitActions !== null) { 
              setHaveVisited (partnerMatch.visitActions.length > 10);
            }

            // also, total number visited?
            const numVisited = foundActivity.filter (act => act.visitActions && act.visitActions.length > 10).length;
            setVisitedCount (numVisited);
            const allPartners = await API.graphql(graphqlOperation(queries.listPartners, { limit: 1000 } ));
            const foundPartnerList = allPartners.data.listPartners.items;

            const numPartners = foundPartnerList.length; // .filter (pard => !pard.hide)
            setPartnerCount (numPartners);
            
          }
        } catch (err) {
            console.log ("Unable to get Activity: ", err);
        }
      }
    } catch (err) {
      console.log ("Could not get partner details");
    }
  };

  useEffect( () => {
    if (width > 0 && height > 0) {
      let AR = (videoAR > 0) ? videoAR : 1.778;
      let displayAR = width /height;
      if (displayAR < AR) {
        setPosWidth (width.toString() + 'px');
        setPosHeight ((width / AR).toString() + 'px');
      } else {
        setPosHeight (height.toString() + 'px');
        setPosWidth ((height * AR).toString() + 'px');
    }
  }
    console.log ("New Location: " + left + ", " + top + ", " + width + ", " + height);
  }, [top, left, width, height, videoAR]);

  useEffect( () => {
    if (videoAR === 0 && player !== null) {
      const AR = player.videoWidth() / player.videoHeight();
      console.log ("Playing video, aspect ratio: " + AR + ", res: " + player.videoWidth() + " x " + player.videoHeight());
      setVideoAR (AR);
    }

  }, [playerPos]);

  const setFavorite = (isFave) => {
    setIsFavorite (isFave);

      if (partnerDetails && isFavorite) {
        const eventData = {
          partner: { id: partnerDetails.id, name: partnerDetails.name },
          userID: userID,
          userName: userName,
          item: 'favorite'
    
        }
        //console.log ("Event Entry: " + JSON.stringify (eventData));
        Activity.track ('partnerActivity', eventData, false);
      }
  };

  useEffect( () => {
    //console.log (partnerActivity);
    //if (!partnerActivity) || (isFavorite === true && partnerActivity && (partnerActivity.isFave === null || partnerActivity.isFave === true))) {
      const input = { isFave: isFavorite };
      updatePartnerActivity (input);
      // give it a few seconds then mark they visited!
      
    //}

    
  }, [isFavorite]);

  const updatePartnerActivity = async ( actions ) => {
    if (!partnerDetails || !partnerDetails.id) return;
    
    console.log ("Updating partner, ", partnerActivity)
    try {
      if (!partnerActivity) {
        const input = {...actions, userID: userID, partnerID: partnerDetails.id};
        const updatedPartnerActivity = await API.graphql(graphqlOperation(mutations.createPartnerActivity, {input: input}));
        //console.log (updatedPartnerActivity);
        setPartnerActivity (updatedPartnerActivity.data.createPartnerActivity);
      } else {
        const input = {id:partnerActivity.id, ...actions};
        const updatedPartnerActivity = await API.graphql(graphqlOperation(mutations.updatePartnerActivity, {input: input}));
        //console.log (updatedPartnerActivity);
        setPartnerActivity (updatedPartnerActivity.data.updatePartnerActivity);
      }
      
    } catch (err) {
      console.log ("Couldn't record partner activity: " + err.message);
      console.log (err);
    }
  }

  useInterval(
    () => {
        // every xx seconds, mark that they're on this page
        markPartnerVisit (); 
      
    },
    10000
  );

  const markPartnerVisit = async ( ) => {
    if (haveVisited === false) {
      setHaveVisited (true);
      setVisitedCount (visitedCount + 1);
      
      const eventData = {
        partner: { id: partnerDetails.id, name: partnerDetails.name },
        userID: userID,
        userName: userName,
        item: 'visit'

      }
      //console.log ("Event Entry: " + JSON.stringify (eventData));
      Activity.track ('partnerActivity', eventData, false);
    }

    if (!partnerDetails || !partnerDetails.id) return;
    const visitObject = {visit: visitID, visitStart: visitStart, visitMark: moment().unix()};
    
    //console.log ("Updating partner visits, ", partnerActivity)
    try {
      if (!partnerActivity) {
        const visitArray = [ visitObject ];
        const input = { visitActions: JSON.stringify (visitArray), userID: userID, partnerID: partnerDetails.id};
        const updatedPartnerActivity = await API.graphql(graphqlOperation(mutations.createPartnerActivity, {input: input}));
        //console.log (updatedPartnerActivity);
        setPartnerActivity (updatedPartnerActivity.data.createPartnerActivity);
      } else {
        // use the current grab we have, for now
        // TODO refresh
        const prevVisits = partnerActivity.visitActions ? JSON.parse (partnerActivity.visitActions) : [];  

        if (prevVisits && Array.isArray(prevVisits)) {
          //console.log ("Merging in previous visits: ", prevVisits)
        } else {
          console.log ("Error with partner visits - it wasn't an array!", partnerActivity);
        }
        // store the newvalue with old ones (filtered)
        const visitArray = [visitObject, ...prevVisits.filter (vis => vis.visit !== visitID)];
        //console.log ("Visits: ", visitArray );
        const input = {id:partnerActivity.id, visitActions: JSON.stringify (visitArray)};
        const updatedPartnerActivity = await API.graphql(graphqlOperation(mutations.updatePartnerActivity, {input: input}));
        //console.log (updatedPartnerActivity);
        setPartnerActivity (updatedPartnerActivity.data.updatePartnerActivity);
      }
      
    } catch (err) {
      console.log ("Couldn't record partner activity: " + err.message);
      console.log (err);
    }
  }

  useEffect( () => {
    if (partnerDetails) {
      setDownloadables (partnerDetails.downloadables ? JSON.parse (partnerDetails.downloadables) : {});

    }

  }, [partnerDetails]);

  useEffect( () => {
    // did we get to this link by a refresh or similar? Is it active?
  //console.log (query);
  if (query.get("play")) {
    setShowingVideo (true);
  }
    getPartnerDetails ();
    setVisitID (uuid()); // a unique marker for this visit
    setVisitStart (moment().unix());

    console.log ("Viewing Partner " + partnerId );

  }, []);
  
  useEffect( () => {
    if (prevVideo && !showingVideo) {
      // We've killed the player
      setPlayer (null);
    }
    if (showingVideo) {
      markPartnerPlaybackAction (partnerDetails.video);
    }
    
  }, [showingVideo]);

  const onPlayerReady = (newPlayer) => {
    console.log("Player is ready: ", newPlayer);
    setPlayer (newPlayer);
    newPlayer.controlBar.fullscreenToggle.hide();
  }

  const onVideoPlay = (position) => {
    //console.log("Video played at: ", position);
    setPlayerPos (position);

  }

  const onVideoPause = (position) => {
    //console.log("Video paused at: ", duration);
    setPlayerPos (position);
  }

  const onVideoTimeUpdate = (position) => {
    //console.log("Time updated: ", position);
    setPlayerPos (position);
  }

  const onVideoSeeking = (duration) => {
    console.log("Video seeking: ", duration);
  }

  const onVideoSeeked = (from, to) => {
      //console.log(`Video seeked from ${from} to ${to}`);
      setPlayerPos (to);
  }

  const onVideoEnd = () => {
      console.log("Video ended");
  }
  
  const getDownloadableName = (index) => {
    const downloadableData = downloadables [index];
    return downloadableData ? downloadableData.name : null;
  }

  const getDownloadableEventName = (index) => {
    const downloadableData = downloadables [index];
    return downloadableData ? downloadableData.event : "download" + index;
  }

  const getDownloadableFileKey = (index) => {
    const downloadableData = downloadables [index];
    return downloadableData ? downloadableData.file : null;
  }

  // div error   https://github.com/zhulduz/video.js/commit/a7e44b702485275696eb5bbc0499bc092ec47862 
  // https://github.com/videojs/video.js/issues/4935

return (
  <ResponsiveContext.Consumer>
        {responsive => (
  <Box
        a11yTitle={partnerDetails.name + ' Partner Details'}
        fill
        tag='footer'
        direction='column'
        align='start'
        gap='small'
        pad={{ left: 'medium', right: 'small', vertical: 'xsmall' }}
        {...props}
        >
            <Box fill='horizontal' align='center' direction='row' gap='small'>
                <Previous color='brand' onClick={() => history.goBack()} />
                <Heading level='3' color="brand" onClick={() => {setShowingVideo (false);} }>{partnerDetails.name}</Heading>
                {showingVideo && <CircleInformation size='medium' color='brand' onClick={() => {setShowingVideo (false);}} /> }
          {showingVideo && !partnerDetails.hideThread && <Button plain icon=<Chat color='brand'/> onClick={() => 
                {goToChatThread (partnerDetails.thread, true); }} /> }
            </Box>
            {showingVideo && partnerDetails.video && partnerDetails.video.length > 0 ?
        <Box fill ref={ref} >
          <Box elevation="medium" animation="fadeIn" 
                  width={posWidth} height={posHeight} >   
                <VevVideoPlayer
                    controls={true} 
                    src={partnerDetails.video}
                    onReady={onPlayerReady}
                    onPlay={onVideoPlay}
                    onPause={onVideoPause}
                    onTimeUpdate={onVideoTimeUpdate}
                    onSeeking={onVideoSeeking}
                    onSeeked={onVideoSeeked}
                    onEnd={onVideoEnd}
                />
          </Box>
        </Box>
            :
              <Box  fill='horizontal' direction='column' align='start' gap='small'>
          
          <Box fill direction='row-responsive' gap='medium'>
              {responsive !== 'small' &&
                <Box direction='column'
                      width='medium'
                      fill={responsive === 'small' ? 'horizontal' : undefined}
                      overflow='hidden'
                      gap='small' >
                  <S3ImageBox fill='horizontal' height='168px'  round="medium" 
                    positioning='center' src={partnerDetails.pic} />
                  <Box round='medium' fill='horizontal' background='brand' align='center' pad='medium'>
                      <Text size='medium'>You have visited</Text>
                      <Text size='medium'>{`${visitedCount} of ${partnerCount}`}</Text>
                      <Text size='medium'>partners</Text>
                  </Box>              
                </Box>
              }
                
              <Box fill='horizontal' direction='column' overflow='auto' gap='small'>
                    <Stack height="small" anchor='top-right'>
                    <Box round='medium' overflow='hidden' onClick={() => {
                          window.open (partnerDetails.headerlink, "_blank");
                          const eventData = {
                            partner: { id: partnerDetails.id, name: partnerDetails.name },
                            userID: userID,
                            userName: userName,
                            item: 'posterlink'
                          }
                          //console.log ("Event Entry: " + JSON.stringify (eventData));
                          Activity.track ('partnerActivity', eventData, false);
                        }}>
                      <S3Image fill='horizontal' src={partnerDetails.headerpic ? partnerDetails.headerpic : partnerDetails.pic} />
                    </Box>
                      {canAdmin && <Box margin = 'small' pad='xsmall' direction='row' background={{ color: 'white', opacity: 'medium' }} round>
                          <RoutedButton fill plain
                      path={`/partnerEditor/${encodeURIComponent(partnerDetails.id)}`}
                          label=<Edit color='brand'/>
                          />
                      </Box>}
                    </Stack>
                <Box flex='grow' direction='row' gap='medium'> 
                  <Button onClick={() => {setShowingVideo (true);}}     
                    disabled={!isPlayable} label="Play Video" />
                  <Button plain icon=<EventParams.Favorite on={isFavorite ? 1 : 0} color='brand' /> onClick={() => {setFavorite (!isFavorite)}} />
                  {!partnerDetails.hideThread && <Button plain icon=<Chat color='brand'/> onClick={() => 
                    {goToChatThread (partnerDetails.thread, true); }} /> }
                </Box>
                  
                <Box flex='grow' direction='column' gap='xsmall'>
                    <Box flex='grow' direction='row-responsive' gap='small' pad='xxsmall'>
                      {getDownloadableName(1) && getDownloadableName(1).length > 2 &&
                        <Button plain 
                          onClick={() => { downloadPartnerMediaLink (1) }} > 
                          <Box width='small' height='xsmall' align='center' justify='center' elevation='medium' round='medium' background='background-front'>
                            <Text size='small' textAlign='center' color='brand'><Interweave content={getDownloadableName(1)} /></Text>
                          </Box>
                        </Button> }
                      {getDownloadableName(2) && getDownloadableName(2).length > 2 &&
                      <Button plain 
                        onClick={() => { downloadPartnerMediaLink (2) }} > 
                        <Box width='small' height='xsmall' align='center' justify='center' elevation='medium' round='medium' background='background-front'>
                          <Text size='small' textAlign='center' color='brand'><Interweave content={getDownloadableName(2)} /></Text>
                        </Box>
                      </Button> }
                      {getDownloadableName(3) && getDownloadableName(3).length > 2 &&
                      <Button plain 
                        onClick={() => { downloadPartnerMediaLink (3) }} > 
                        <Box width='small' height='xsmall' align='center' justify='center' elevation='medium' round='medium' background='background-front'>
                          <Text size='small' textAlign='center' color='brand'><Interweave content={getDownloadableName(3)} /></Text>
                        </Box>
                      </Button> }
                      {getDownloadableName(4) && getDownloadableName(4).length > 2 &&
                      <Button plain 
                        onClick={() => { downloadPartnerMediaLink (4) }} > 
                        <Box width='small' height='xsmall' align='center' justify='center' elevation='medium' round='medium' background='background-front'>
                          <Text size='small' textAlign='center' color='brand'><Interweave content={getDownloadableName(4)} /></Text>
                        </Box>
                      </Button> }
                      {getDownloadableName(5) && getDownloadableName(5).length > 2 &&
                      <Button plain 
                        onClick={() => { downloadPartnerMediaLink (5) }} > 
                        <Box width='small' height='xsmall' align='center' justify='center' elevation='medium' round='medium' background='background-front'>
                          <Text size='small' textAlign='center' color='brand'><Interweave content={getDownloadableName(5)} /></Text>
                        </Box>
                      </Button> }
                      {getDownloadableName(6) && getDownloadableName(6).length > 2 &&
                      <Button plain 
                        onClick={() => { downloadPartnerMediaLink (6) }} > 
                        <Box width='small' height='xsmall' align='center' justify='center' elevation='medium' round='medium' background='background-front'>
                          <Text size='small' textAlign='center' color='brand'><Interweave content={getDownloadableName(6)} /></Text>
                        </Box>
                      </Button> }

                    </Box>
                    {partnerDetails.more && partnerDetails.more.length > 2 && <Box direction='row' gap='medium'>
                      <Text size='medium' color='brand'>Website</Text>
                      <Button plain onClick={() => {
                          window.open ('https://' + partnerDetails.more, "_blank");
                          const eventData = {
                            partner: { id: partnerDetails.id, name: partnerDetails.name },
                            userID: userID,
                            userName: userName,
                            item: 'link'
                          }
                          //console.log ("Event Entry: " + JSON.stringify (eventData));
                          Activity.track ('partnerActivity', eventData, false);
                        }}
                          label=<Text size='medium' ><InterweaveFormat content={partnerDetails.more}/></Text> />
                    </Box>}
                    {partnerDetails.staff && partnerDetails.staff.length > 2 && <Box direction='row' gap='medium'>
                      <Text size='medium' color='brand'>Contacts</Text>
                      <Text size='medium'><InterweaveFormat content={partnerDetails.staff} /></Text>
                    </Box>}
                    {partnerDetails.phone && partnerDetails.phone.length > 2 && <Box direction='row' gap='medium'>
                      <Text size='medium' color='brand'>Telephone</Text>
                      <Text>{partnerDetails.phone}</Text>
                    </Box>}
                    {partnerDetails.email && partnerDetails.email.length > 2 && <Box direction='row' gap='medium'>
                      <Text size='medium' color='brand'>Email</Text>
                      {partnerDetails.email && partnerDetails.email.length > 6 &&
                        <Anchor href={`mailto:${partnerDetails.email}?subject=Visiting with you at ${EventParams.Name}`}
                            label=<Text size='medium'><InterweaveFormat content={partnerDetails.email}/></Text> />
                    }
                    </Box>}
                  </Box>
                
                <Box flex='grow' fill='horizontal' gap='medium'>
                  <Interweave content={partnerDetails.description} />
                  
                </Box>

                

              </Box>
          </Box>    
              </Box>
          
            }
      
  </Box>
  )}
  </ResponsiveContext.Consumer>

) };

export default PartnerDetail;
