import React, { useEffect, useState } from 'react';

import { Box, Image, Heading, Text, Button, TextInput  } from 'grommet';
import { Scrollbars } from 'react-custom-scrollbars';
import EventParams from '../EventItems';


const AdminAnalytics = (props) => {

    return (
  <Box
    a11yTitle='Analytics'
    fill
    tag='footer'
    direction='column'
    align='center'
    justify='between'
    pad={{ left: 'medium', right: 'small', vertical: 'xsmall' }}

    {...props}
    >
            <Box fill='horizontal' align='start' gap='medium'>
                <Heading color='brand' level='2'>Analytics</Heading>
                <Box overflow='auto' pad='medium' >
                        <Image fill="horizontal"  src='/Dashboard-121420.jpg' />
                </Box>
        </Box>
            
    </Box>
)
};

export default AdminAnalytics;