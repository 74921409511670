import React, { useEffect, useState } from 'react';
import { Box, Button, Heading, Layer, Menu, Stack, Text, ResponsiveContext  } from 'grommet';
import { Alarm, CircleInformation, CirclePlay, Chat, Edit, Previous } from 'grommet-icons';

import { useParams, useLocation, useHistory, useRouteMatch } from "react-router-dom";

import { API, graphqlOperation, Storage } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import usePrevious from '../utils/use-previous'
import moment from 'moment';
import {v4 as uuid} from 'uuid';

import RoutedButton from './RoutedButton';
import VevVideoPlayer from './VevVideoPlayer';
import VideoChat from './VevVideoChat';

import { Activity, LVL, createCalendarLinkFromSession } from '../Activity';
import { useInterval } from 'react-use';

import { Rnd } from "react-rnd";
import { useMeasure } from 'react-use';

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

import EventParams from '../EventItems';
import S3ImageBox from './S3ImageBox';
import BaseInterweave, { InterweaveProps } from 'interweave';
  import { UrlMatcher, EmailMatcher, HashtagMatcher } from 'interweave-autolink';
  
const globalMatchers: Matcher[] = [
  new EmailMatcher('email'),
  new UrlMatcher('url'),
  new HashtagMatcher('hashtag'),
];

function Interweave(props: InterweaveProps) {
  return <BaseInterweave {...props} newWindow={true} matchers={globalMatchers} />;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Session = (props) => {
  const [sessionDetails, setSessionDetails] = useState({});
  const [isFavorite, setIsFavorite] = useState(false);
  const [sessionActivity, setSessionActivity] = useState(null);
  const [visitID, setVisitID] = useState (null);
  const [visitStart, setVisitStart] = useState (0);
 const [playerActivity, setPlayerActivity] = useState([]);

  const [isLive, setIsLive] = useState (false);
  const [isPlayable, setIsPlayable] = useState (false);
  const [isEmpty, setIsEmpty] = useState (false);

  const [mainPlayer, setMainPlayer] = useState (null);
  const [presPlayer, setPresPlayer] = useState (null);
  
  const [numElements, setNumElements] = useState (0);
  const [contentURLs, setContentURLs] = useState([]);
  const [playerPos, setPlayerPos] = useState(0);
  const [videoAR, setVideoAR] = useState (0);

  const [ref, {top, left, width, height}] = useMeasure();
  const [posX, setPosX] = useState ('24');
  const [posY, setPosY] = useState ('146');
  const [posWidth, setPosWidth] = useState ('640');
  const [posHeight, setPosHeight] = useState ('360');

  let { sessionId } = useParams();
  
  let { url } = useRouteMatch();
  let location = window.location.href;
  let query = useQuery();
  let history = useHistory();
  
  const [showingVideo, setShowingVideo] = useState (false);
  const prevVideo = usePrevious(showingVideo);

  const { userID, userName, canAdmin, changed, goToChatThread } = props;

  const downloadSessionMediaLink = async (key) => {
    const storedURL = await Storage.get (key, { level: 'public' });
    console.log (storedURL);
    console.log ("Get file at: " + storedURL);
    window.open (storedURL, "_blank");

    const eventData = {
      session: { id: sessionId, name: sessionDetails ? sessionDetails.name : "session" },
      userID: userID,
      userName: userName,
      item: 'download'
      
    }
    //console.log ("Event Entry: " + JSON.stringify (eventData));
    Activity.track ('sessionActivity', eventData, false);
  }

  useEffect( () => {
    if (width > 0 && height > 0) {
      let AR = (videoAR > 0) ? videoAR : 1.778;
      let displayAR = width /height;
      if (displayAR < AR) {
        setPosWidth (width);
        setPosHeight (width / AR);
      } else {
        setPosHeight (height);
        setPosWidth (height * AR);
    }
  }
    //console.log ("New Location: " + left + ", " + top + ", " + width + ", " + height);
  }, [top, left, width, height, videoAR]);

  const getSessionDetails = async () => {
    try {
      const foundSession = await API.graphql(graphqlOperation(queries.getSession, { id: sessionId } ));
      //console.log (foundSession);
      if (foundSession.data.getSession) {
        const currentSession = foundSession.data.getSession;
        setSessionDetails (currentSession);

        if (!currentSession.hideThread && currentSession.gotoThread) {
          goToChatThread (currentSession.thread, true);
        }

        if (currentSession.name && currentSession.name.length > 0) {
          // Else we don't care!
        const eventData = {
          session: { id: currentSession.id, name: currentSession.name},
          userID: userID,
          userName: userName,
          playing: false
        }
        //console.log ("Event Entry: " + JSON.stringify (eventData));
        Activity.track ('sessionViewing', eventData, false);
        }

          // get session activity too, if it exists
        try {
          const activityQuery = await API.graphql(graphqlOperation(queries.listSessionActivityByUser, { userID:userID, limit: 1000 } ));

          const foundActivity = activityQuery.data.listSessionActivityByUser.items;
          //console.log (foundActivity);
          if (foundActivity) {
            const sessionMatch = foundActivity.find (sess => sess.sessionID === sessionId);
            //console.log ("Found session activity to track: ", sessionMatch);
            setSessionActivity (sessionMatch);
            // we have to set this to display, but will it cause a loop?
            if (sessionMatch && sessionMatch.isFave !== null) {
              setIsFavorite (sessionMatch.isFave);
            } 
            
          }
        } catch (err) {
            console.log ("Unable to get Activity: ", err);
            setSessionActivity (null);
        }
      }
    } catch (err) {
      console.log ("Could not get session details");
      setSessionDetails (null);
      setSessionActivity (null);
    }
  };

  const getSessionDateAndTime = () => {
    if (sessionDetails.live === false) {
      return "Pre-recorded session";
    } else {
      const eventStart = (sessionDetails.startdate === null) ? moment (sessionDetails.starttime, "hh:mm") :  moment (sessionDetails.startdate + "T" + sessionDetails.starttime);
      const eventEnd = (sessionDetails.enddate === null) ? moment (sessionDetails.endtime, "hh:mm") : moment (sessionDetails.enddate + "T" + sessionDetails.endtime);
    // if dates were blank it should be today
      var eventDuration = moment.duration(eventEnd.diff(eventStart));
      var durationString = (sessionDetails.duration) ? " (" + sessionDetails.duration + ")" : " (" + eventDuration.asMinutes() + " minutes)";
      // .locale("en").humanize(true)
      
      return eventStart.format('LT, LL') + durationString;
    }
  }

  const checkIfInProgress = () => {
    const now = moment();
    const sessionTime = (sessionDetails.startdate === null) ? moment (sessionDetails.starttime, "hh:mm:ssZ") :  moment (sessionDetails.startdate + "T" + sessionDetails.starttime);
    sessionTime.subtract (sessionDetails.preroll, 'seconds').format('ss');
    const sessionEnd = (sessionDetails.enddate === null) ? moment (sessionDetails.endtime, "hh:mm:ssZ") :  moment (sessionDetails.enddate + "T" + sessionDetails.endtime);
    const inProgress = now.isBetween (sessionTime, sessionEnd, undefined, '[]');

	  //console.log ("SessionInProgress: " + inProgress.toString());
    return inProgress;
  }

  useEffect( () => {
    // if this became playable while we're in here (or just arrived), do it
    if ((isLive || isEmpty) && isPlayable) {
      console.log ("Session is live - going right to player");
      setShowingVideo (true);
    }
  }, [isPlayable, isLive, isEmpty]);

  useEffect( () => {
    if (videoAR === 0 && mainPlayer !== null) {
      const AR = mainPlayer.videoWidth() / mainPlayer.videoHeight();
      console.log ("Playing video, aspect ratio: " + AR + ", res: " + mainPlayer.videoWidth() + " x " + mainPlayer.videoHeight());
      setVideoAR (AR);
    }

  }, [playerPos]);

  useEffect( () => {
    //console.log ("Updated Session Activity: ", sessionActivity);
  }, [sessionActivity]);
    
  useInterval( () => {
      
        if (isLive) {
          // This means anything live will show as playable due to time...
          setIsPlayable (checkIfInProgress ());
        }

    },
    10000
  );

  useInterval( () => {
    // every xx seconds, mark that they're on this page
    markSessionVisit (); 

    const eventData = {
      session: { id: sessionId, name: sessionDetails ? sessionDetails.name : "session" },
      userID: userID,
      userName: userName,
      playing: showingVideo
    }
    //console.log ("Event Entry: " + JSON.stringify (eventData));
    Activity.track ('sessionViewing', eventData, false);

  },
    60000   // every minute
  );

  const markSessionVisit = async ( ) => {
    if (!sessionDetails || !sessionDetails.id) return;
    const visitObject = {visit: visitID, visitStart: visitStart, visitMark: moment().unix()};
    const visitArray = [ visitObject ];
    
    try {
      if (!sessionActivity) {
        const input = { visitActions: JSON.stringify (visitArray), userID: userID, sessionID: sessionDetails.id};
        const updatedSessionActivity = await API.graphql(graphqlOperation(mutations.createSessionActivity, {input: input}));

        setSessionActivity (updatedSessionActivity.data.createSessionActivity);
      } else {
        // use the current grab we have, for now
        // TODO refresh
        const prevVisits = sessionActivity.visitActions ? JSON.parse (sessionActivity.visitActions) : [];  

        if (prevVisits && Array.isArray(prevVisits)) {
          //console.log ("Merging in previous visits: ", prevVisits)
        } else {
          console.log ("Error with session visits - it wasn't an array!", sessionActivity);
        }
        // Possible bug cause!
        //const visitArray = [visitObject, ...prevVisits.filter (vis => vis.visit !== visitID)];
        //console.log ("Visits: ", visitArray );
        const input = {id:sessionActivity.id, visitActions: JSON.stringify (visitArray)};
        const updatedSessionActivity = await API.graphql(graphqlOperation(mutations.updateSessionActivity, {input: input}));
        console.log ("Marked Session activity: ", updatedSessionActivity);
        setSessionActivity (updatedSessionActivity.data.updateSessionActivity);
      }
      
    } catch (err) {
      console.log ("Couldn't record session activity: " + err.message);
      console.log (err);
    }
  }

  const markSessionPlaybackAction = async (name, position) => {
    if (!sessionDetails || !sessionDetails.id) return;

    //console.log ("Playback action: " + name + ", " + position);
    const playbackObject = {action: name, actionMark: moment().unix(), position: position};
    const playbackArray = [ playbackObject ];
    
    try {
      if (!sessionActivity) {
        const input = { playbackActions: JSON.stringify (playbackArray), userID: userID, sessionID: sessionDetails.id};
        const updatedSessionActivity = await API.graphql(graphqlOperation(mutations.createSessionActivity, {input: input}));
        //console.log (updatedSessionActivity);
        setSessionActivity (updatedSessionActivity.data.createSessionActivity);
      } else {
        // use the current grab we have, for now
        // TODO refresh
        const prevPlaybacks = sessionActivity.playbackActions ? JSON.parse (sessionActivity.playbackActions) : [];  

        if (prevPlaybacks && Array.isArray(prevPlaybacks)) {
          //console.log ("Merging in previous visits: ", prevPlaybacks);
        } else {
          console.log ("Error with session playback actions - it wasn't an array!", sessionActivity);
        }
        // possible bug - just store latest instead
        //const playbackArray = [playbackObject, ...prevPlaybacks];
        //console.log ("Visits: ", visitArray );
        const input = {id:sessionActivity.id, playbackActions: JSON.stringify (playbackArray)};
        const updatedSessionActivity = await API.graphql(graphqlOperation(mutations.updateSessionActivity, {input: input}));
        console.log ("Marked playback action: ", updatedSessionActivity);
        setSessionActivity (updatedSessionActivity.data.updateSessionActivity);
      }
      
    } catch (err) {
      console.log ("Couldn't record session activity: " + err.message);
      console.log (err);
    }
  }


  useEffect( () => {
    if (sessionDetails !== undefined && sessionDetails.id) {
      //console.log (sessionDetails);
    const isLive = sessionDetails.live;
    setIsLive (isLive);
      console.log ("Session: ", sessionDetails);
      setIsEmpty (sessionDetails.description === undefined || sessionDetails.description.length === 0);
    
    // let's see if we need multiple windows or not
    var elements = 0;
    if (sessionDetails.video && sessionDetails.video.length > 4) {
      elements = elements + 1;
    }
    if (sessionDetails.slides && sessionDetails.slides.length > 4) {
      elements = elements + 1;
    }
    if (sessionDetails.interactive && sessionDetails.interactive.length > 4) {
      elements = elements + 1;
    }
    if (sessionDetails.videochat && sessionDetails.videochat.length > 4) {
      elements = elements + 1;
    }
    if (sessionDetails.content && sessionDetails.content.length > 4) {
      elements = elements + 1;

      const contents = JSON.parse (sessionDetails.content);
      if (contents && Array.isArray(contents)) {
        getCarouselImagesFromS3Folder (contents);
      }
    }
    setNumElements (elements);
    // Can/should we play this now?
    if (isLive) {

      // This means anything live will show as playable due to time...
      setIsPlayable (checkIfInProgress ());


    } else {
        setIsPlayable (elements > 0);
      }
    }

  }, [sessionDetails]);

  const getCarouselImagesFromS3Folder = async (images) => {
    let imageURLs = [];
    console.log ("getting image URLs: ", images);

    for (const fileKey of images) {
      try {
        console.log ("File for: " + fileKey);
        const storedURL = await Storage.get (fileKey, { level: 'public' }); // fileKey for direct public folder access
        console.log ("Result: " + storedURL);
        imageURLs.push ({source: storedURL});
      } catch (err) {
        // there will be a message anyway, from browser
        console.log ("Carousel Image error: ", err);
      }
    }
    console.log ('Ready to display: ', imageURLs );
    setContentURLs (imageURLs);
  };

  useEffect( () => {
    // console.log (sessionActivity);
    //if (!sessionActivity) || (isFavorite === true && sessionActivity && (sessionActivity.isFave === null || sessionActivity.isFave === true))) {
      const input = { isFave: isFavorite };
      updateSessionActivity (input);
    //}
    if (changed) {
    changed ();
    }
  }, [isFavorite]);

  const updateSessionActivity = async ( actions ) => {
    if (!sessionDetails || !sessionDetails.id) return;
    console.log ("Updating session, ", actions)
    try {
      if (!sessionActivity) {
        const input = {...actions, userID: userID, sessionID: sessionDetails.id};
        const updatedSessionActivity = await API.graphql(graphqlOperation(mutations.createSessionActivity, {input: input}));
        console.log (updatedSessionActivity);
        setSessionActivity (updatedSessionActivity.data.createSessionActivity);
      } else {
        const input = {id:sessionActivity.id, ...actions};
        const updatedSessionActivity = await API.graphql(graphqlOperation(mutations.updateSessionActivity, {input: input}));
        console.log (updatedSessionActivity);
        setSessionActivity (updatedSessionActivity.data.updateSessionActivity);
      }
      
    } catch (err) {
      console.log ("Couldn't record session activity: " + err.message);
      console.log (err);
    }
  }

  useEffect( () => {
    //setShowingVideo (false);
    //setIsLive (false);
    //setIsPlayable (false);
    //setIsEmpty (false);

    // should be in another component but oh well
    //setNumElements (0);
    //setContentURLs ([]);
    //setPlayerPos (0);
    //setVideoAR (0);

    // did we get to this link by a refresh or similar? Is it active?
    //console.log (query);
    if (query.get("play")) {
      setShowingVideo (true);
    }
    getSessionDetails ();
    setVisitID (uuid()); // a unique marker for this visit
    setVisitStart (moment().unix());

    console.log ("Viewing Session " + sessionId );
  }, []); // sessionId, query


  
  useEffect( () => {
    if (prevVideo && !showingVideo) {
      // We've killed the player
      setMainPlayer (null);
      setPresPlayer (null);
    }
    
    if (showingVideo) {
      markSessionPlaybackAction ("Start", 0);
    } else {
      markSessionPlaybackAction ("Stop", 0);
    }
    
    const eventData = {
      session: { id: sessionId, name: sessionDetails ? sessionDetails.name : "session" },
      userID: userID,
      userName: userName,
      playing: showingVideo
    }
    //console.log ("Event Entry: " + JSON.stringify (eventData));
    Activity.track ('sessionViewing', eventData, false);
    
  }, [showingVideo]);

  const onMainPlayerReady = (newPlayer) => {
    console.log("Player is ready: ", newPlayer);
    
    setMainPlayer (newPlayer);
    setTimeout(() => { newPlayer.play () }, 500);
  }

  const onPresPlayerReady = (newPlayer) => {
    setPresPlayer (newPlayer);
  }

  const onVideoPlay = (position) => {
    //console.log("Video played at: ", position);
    setPlayerPos (position);
    markSessionPlaybackAction ("Play", position.toString());

    if (presPlayer) {
      presPlayer.play();
    } 
  }

  const onVideoPause = (position) => {
    //console.log("Video paused at: ", duration);
    setPlayerPos (position);
    markSessionPlaybackAction ("Pause", position.toString());

    if (presPlayer) {
      presPlayer.pause();
    } 
  }

  const onVideoTimeUpdate = (position) => {
    //console.log("Time updated: ", position);
    setPlayerPos (position);
  }

  const onVideoSeeking = (duration) => {
    console.log("Video seeking: ", duration);
  }

  const onVideoSeeked = (from, to) => {
      //console.log(`Video seeked from ${from} to ${to}`);
      setPlayerPos (to);
      markSessionPlaybackAction ("Seeked", to.toString());
      if (presPlayer) {
        presPlayer.seek ();
      } 
  }

  const onVideoEnd = () => {
      console.log("Video ended");
  }
  
  // div error   https://github.com/zhulduz/video.js/commit/a7e44b702485275696eb5bbc0499bc092ec47862 
  // https://github.com/videojs/video.js/issues/4935

return (
  <ResponsiveContext.Consumer>
    {responsive => (
      <Box
        a11yTitle={sessionDetails.name + ' Session Details'}
        fill
        tag='footer'
        direction='column'
        align='start'
        gap='small'
        pad={{ left: 'medium', right: 'small', vertical: 'xsmall' }}
        background={EventParams.SessionBackdrop.length > 4 ? { image: EventParams.SessionBackdrop } : undefined}
        {...props}
        >
        <Box flex={false} fill='horizontal' align='center' direction='row' gap='small'>
            <Previous color='brand' onClick={() => history.goBack()} />
            <Heading level='3' color="brand" onClick={() => {setShowingVideo (false);} }>{sessionDetails.name}</Heading>
            {showingVideo && !isEmpty && <CircleInformation size='medium' color='brand' onClick={() => {setShowingVideo (false);}} /> }
            {showingVideo && !sessionDetails.hideThread && <Button plain icon=<Chat color='brand'/> onClick={() => 
                  {goToChatThread (sessionDetails.thread, true); }} /> }
            {canAdmin && <RoutedButton plain label=<Edit color='brand'/>
                      path={`/sessionEditor/${encodeURIComponent(sessionDetails.id)}`} /> }
        </Box>
        <Box fill>

        {showingVideo ?
          <Box fill ref={ref} >
            {sessionDetails.videochat && sessionDetails.videochat.length > 2 && sessionDetails.useSessionViewer && 
              <Rnd bounds='parent' default={{ width: posWidth / 2, height: posHeight / 2, x: 0, y: posHeight / 2 }}  >
                <Box fill elevation="medium" animation="fadeIn"  round={{ size: 'medium'}} > 
                  <Box background='brand' fill='horizontal' height='20px' align='center' round={{ size: 'medium', corner: 'top'}} >
                    <Text size='small'>VIDEO CHAT</Text>
                  </Box>
                    <VideoChat userName={userName} chatRoom={sessionDetails.videochat} chatServer={sessionDetails.videochatServer} isModerator={canAdmin ? true : false}/>
                  <Box background='tab' fill='horizontal' height='20px' align='center' round={{ size: 'medium', corner: 'bottom'}} />
                </Box>
              </Rnd> }

            {sessionDetails.videochat && sessionDetails.videochat.length > 2 && !sessionDetails.useSessionViewer && 
              <Box fill elevation="medium" animation="fadeIn" >
                <VideoChat userName={userName} chatRoom={sessionDetails.videochat} chatServer={sessionDetails.videochatServer} isModerator={canAdmin ? true : false}/>
              </Box> }

            {sessionDetails.interactive && sessionDetails.interactive.length > 2 && 
            <Rnd bounds='parent' default={{ width: (posWidth / 2), height: (posHeight / 2), x: (posWidth / 2), y: 0}} 
              >
                <Box fill elevation="medium" animation="fadeIn"  round={{ size: 'medium'}} > 
                  <Box background='brand' fill='horizontal' height='20px' align='center' round={{ size: 'medium', corner: 'top'}} >
                    <Text size='small'>INTERACTIVE</Text>
                  </Box>
                  <iframe title='interactive' src={sessionDetails.interactive} width="100%" height="100%" frameBorder="0"/>
                  <Box background='tab' fill='horizontal' height='20px' align='center' round={{ size: 'medium', corner: 'bottom'}} />
                </Box>
            </Rnd> }
            
            {sessionDetails.video && sessionDetails.video.length > 2 && !sessionDetails.useSessionViewer &&
              <Box elevation="medium" animation="fadeIn" 
                width={posWidth + 'px'} height={posHeight + 'px'} > 
                <VevVideoPlayer
                        controls={true} src={sessionDetails.video}
                        preload="auto" onReady={onMainPlayerReady} onPlay={onVideoPlay}
                        onPause={onVideoPause} onTimeUpdate={onVideoTimeUpdate}
                        onSeeking={onVideoSeeking} onSeeked={onVideoSeeked} onEnd={onVideoEnd}
                />
              </Box>}

            {sessionDetails.video && sessionDetails.video.length > 2 && sessionDetails.useSessionViewer &&
            <Rnd bounds='parent' default={sessionDetails.interactive && sessionDetails.interactive.length > 2 ? 
                { width: (posWidth / 2), height: (posHeight / 2), x: 0, y: 0 }
                : 
                { width: posWidth, height: posHeight, x: 0, y: 0 }}
              lockAspectRatio={videoAR > 0.0 ? videoAR : 1.778} lockAspectRatioExtraHeight={40}
              >
              <Box fill elevation="medium" animation="fadeIn" round={{ size: 'medium'}} > 
                <Box background='brand' fill='horizontal'  height='20px' align='center' round={{ size: 'medium', corner: 'top'}} >
                  <Text size='small'>VIDEO</Text>
                </Box>
                <VevVideoPlayer
                        controls={true} src={sessionDetails.video}
                        preload="auto" onReady={onMainPlayerReady} onPlay={onVideoPlay}
                        onPause={onVideoPause} onTimeUpdate={onVideoTimeUpdate}
                        onSeeking={onVideoSeeking} onSeeked={onVideoSeeked} onEnd={onVideoEnd}
                />
                <Box background='tab' fill='horizontal' height='20px' align='center' round={{ size: 'medium', corner: 'bottom'}} />
            </Box>
            </Rnd> }

            {sessionDetails.content && contentURLs.length > 0 && !sessionDetails.useSessionViewer &&
              <Layer plain modal={false} target={ref.current} position='top-left' margin={{ top: posY, left: posX}} >
                <Box plain width={posWidth + 'px'} height={posHeight + 'px'} round='xsmall' overflow='hidden' elevation="medium" animation="fadeIn" > 
                  <AutoplaySlider fillParent play={true} cancelOnInteraction={true} interval={EventParams.SessionCarouselSpeed} media={contentURLs} />
                </Box>
              </Layer>}

            {sessionDetails.content && contentURLs.length > 0 && sessionDetails.useSessionViewer &&
            <Rnd bounds='parent' default={{ width: (posWidth / 2), height: (posHeight / 2), x: (posWidth / 2), y: (posHeight / 2)}} 
              >
                <Box fill elevation="medium" animation="fadeIn"  round={{ size: 'medium'}} > 
                  <Box background='brand' fill='horizontal' height='20px' align='center' round={{ size: 'medium', corner: 'top'}} >
                    <Text size='small'>CAROUSEL</Text>
                  </Box>
                  <Box plain width={posWidth + 'px'} height={posHeight + 'px'} round='xsmall' overflow='hidden' elevation="medium" animation="fadeIn" > 
                    <AutoplaySlider fillParent play={true} cancelOnInteraction={true} interval={EventParams.SessionCarouselSpeed} media={contentURLs} />
                  </Box>
                  <Box background='tab' fill='horizontal' height='20px' align='center' round={{ size: 'medium', corner: 'bottom'}} />
                </Box>
            </Rnd> }
              
            </Box>
        :
          <Box fill='horizontal' direction='column' align='start' gap='xsmall'>
            <Box direction='column' overflow='auto' gap='small'>
              <Box width="large" direction='column' gap='xsmall'>
                <S3ImageBox height="medium"  round="medium" src={sessionDetails.pic} />
                <Box flex={false} direction='row-responsive' justify='between' pad='xxsmall'>
                  <Box flex={false} direction='row' gap='xsmall'> 
                    <Button plain onClick={() => {setShowingVideo (true);}}     
                      disabled={!isPlayable} icon=<CirclePlay color='brand'/> />
                    <Button plain icon=<EventParams.Favorite on={isFavorite ? 1 : 0} color='brand' /> onClick={() => {setIsFavorite (!isFavorite)}} />
                    <Menu plain disabled={sessionDetails.live !== true}
                      items={[
                          {label: 'Google', onClick: () => {createCalendarLinkFromSession (sessionDetails, 'google', location);}},
                          {label: 'Office365', onClick: () => {createCalendarLinkFromSession (sessionDetails, 'office365', location);}},
                          {label: 'Others (.ics)', onClick: () => {createCalendarLinkFromSession (sessionDetails, 'generic', location);}},

                      ]} >
                      <Alarm color='brand' disabled={sessionDetails.live !== true} /> 
                    </Menu>
                    {!sessionDetails.hideThread && <Button plain icon=<Chat color='brand'/> onClick={() => 
                        {goToChatThread (sessionDetails.thread, true); }} /> }
                </Box>              
                {sessionDetails.live && 
                  <Text size='medium' weight='bold' color='brand'>{getSessionDateAndTime()}</Text>}
                    
              </Box>
              <Box flex={false} direction='column' gap='xsmall'>
                {sessionDetails.collateral &&
                  <Button label={'Download ' + sessionDetails.collateralName} onClick={() => {downloadSessionMediaLink (sessionDetails.collateral)}} />
                }
              </Box>
            </Box>
            
              <Box flex fill='horizontal' gap='medium' direction='column'>
                
                <Text size='small'><Interweave content={sessionDetails.description} /></Text>
                {(sessionDetails.more && sessionDetails.more.length > 0) &&
                    <Button plain color='brand' label="More Details (link)" onClick={() => {
                        window.open (sessionDetails.more, "_blank");
                        const eventData = {
                          session: { id: sessionId, name: sessionDetails.name },
                          userID: userID,
                          userName: userName,
                          item: 'link'
                        }
                        //console.log ("Event Entry: " + JSON.stringify (eventData));
                        Activity.track ('sessionActivity', eventData, false);
                        }} /> }        

                <Interweave content={sessionDetails.presenters} />
              </Box>
            </Box>

            
          </Box>
        }
        </Box>
      </Box>
    )}
    </ResponsiveContext.Consumer>
) };

export default Session;
