import React, { useState, useEffect } from "react";
import { Storage } from 'aws-amplify';

import { Avatar, Box } from "grommet";


const S3ImageAvatar = (props) => {
  const { userID, color, shape } = props;
  const [genURL, setGenURL] = useState("/blank-profile-picture.jpg");

  const getS3signedURL = async () => {
    if (userID && userID.length > 3) {
      const key = 'avatars/' + userID;
      try {
        const storedURL = await Storage.get (key, { 
          level: 'public' });
          
        // this should always be a valid URL, which is not great
        //console.log (imageData);
        var img = new Image();
        img.onload = () => {
          setGenURL (storedURL);
        } 
        img.onerror = () => {
          // generated an unavoidable console error too
          // Just don't set
        }
        img.src = storedURL;
      
      } catch(err) {
        console.log('Avatar access error: ', err)
      }
    }
  };

  useEffect( () => {
    getS3signedURL ();

  }, [userID]);

  if (shape && shape === 'box') {
    return (
      <Box {...props}
      border={{ size: 'medium', color: color }}
      background={{ color: 'brand', image:`url(${genURL})`, 
        position: 'center center' }} />
    )
  } else {
    return (
      <Avatar {...props}
          src={genURL}
          border={{ size: 'medium', color: color }}  />
    )
  }
};

export default S3ImageAvatar;
