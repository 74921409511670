/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCommentInThread = /* GraphQL */ `
  subscription OnCreateCommentInThread($thread: ID!) {
    onCreateCommentInThread(thread: $thread) {
      id
      thread
      inThread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      createdAt
      curation
      reporterID
      userID
      reactions {
        items {
          id
          userID
          commentID
          like
          emoji
          reported
          updatedAt
          owner
        }
        nextToken
      }
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      content
      owner
    }
  }
`;
export const onUpdateCommentInThread = /* GraphQL */ `
  subscription OnUpdateCommentInThread($thread: ID!) {
    onUpdateCommentInThread(thread: $thread) {
      id
      thread
      inThread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      createdAt
      curation
      reporterID
      userID
      reactions {
        items {
          id
          userID
          commentID
          like
          emoji
          reported
          updatedAt
          owner
        }
        nextToken
      }
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      content
      owner
    }
  }
`;
export const onCreateUserInSpace = /* GraphQL */ `
  subscription OnCreateUserInSpace($space: String!) {
    onCreateUserInSpace(space: $space) {
      id
      name
      firstName
      secondName
      emailAddress
      description
      isPresenter
      isEventStaff
      type
      title
      company
      showInList
      blocked
      allowInvitations
      tags
      homePage
      space
      color
      skincolor
      hairstyle
      haircolor
      animation
      avoid3D
      pos
      lastOnline
      updatedAt
      createdAt
      currentThread
      comments {
        items {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        nextToken
      }
      reactions {
        items {
          id
          userID
          commentID
          like
          emoji
          reported
          updatedAt
          owner
        }
        nextToken
      }
      threads {
        items {
          id
          userID
          threadID
          lastRead
          viewedCount
        }
        nextToken
      }
      groups {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      friends
      owner
    }
  }
`;
export const onUpdateUserInSpace = /* GraphQL */ `
  subscription OnUpdateUserInSpace($space: String!) {
    onUpdateUserInSpace(space: $space) {
      id
      name
      firstName
      secondName
      emailAddress
      description
      isPresenter
      isEventStaff
      type
      title
      company
      showInList
      blocked
      allowInvitations
      tags
      homePage
      space
      color
      skincolor
      hairstyle
      haircolor
      animation
      avoid3D
      pos
      lastOnline
      updatedAt
      createdAt
      currentThread
      comments {
        items {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        nextToken
      }
      reactions {
        items {
          id
          userID
          commentID
          like
          emoji
          reported
          updatedAt
          owner
        }
        nextToken
      }
      threads {
        items {
          id
          userID
          threadID
          lastRead
          viewedCount
        }
        nextToken
      }
      groups {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      friends
      owner
    }
  }
`;
export const addedUser = /* GraphQL */ `
  subscription AddedUser {
    addedUser {
      id
      name
      firstName
      secondName
      emailAddress
      description
      isPresenter
      isEventStaff
      type
      title
      company
      showInList
      blocked
      allowInvitations
      tags
      homePage
      space
      color
      skincolor
      hairstyle
      haircolor
      animation
      avoid3D
      pos
      lastOnline
      updatedAt
      createdAt
      currentThread
      comments {
        items {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        nextToken
      }
      reactions {
        items {
          id
          userID
          commentID
          like
          emoji
          reported
          updatedAt
          owner
        }
        nextToken
      }
      threads {
        items {
          id
          userID
          threadID
          lastRead
          viewedCount
        }
        nextToken
      }
      groups {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      friends
      owner
    }
  }
`;
export const updatedUser = /* GraphQL */ `
  subscription UpdatedUser {
    updatedUser {
      id
      name
      firstName
      secondName
      emailAddress
      description
      isPresenter
      isEventStaff
      type
      title
      company
      showInList
      blocked
      allowInvitations
      tags
      homePage
      space
      color
      skincolor
      hairstyle
      haircolor
      animation
      avoid3D
      pos
      lastOnline
      updatedAt
      createdAt
      currentThread
      comments {
        items {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        nextToken
      }
      reactions {
        items {
          id
          userID
          commentID
          like
          emoji
          reported
          updatedAt
          owner
        }
        nextToken
      }
      threads {
        items {
          id
          userID
          threadID
          lastRead
          viewedCount
        }
        nextToken
      }
      groups {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      friends
      owner
    }
  }
`;
export const newNotification = /* GraphQL */ `
  subscription NewNotification($userID: ID!) {
    newNotification(userID: $userID) {
      id
      userID
      originUser
      body
      type
      link
      markedRead
      createdAt
      lastRead
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      owner
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($owner: String) {
    onCreateUser(owner: $owner) {
      id
      name
      firstName
      secondName
      emailAddress
      description
      isPresenter
      isEventStaff
      type
      title
      company
      showInList
      blocked
      allowInvitations
      tags
      homePage
      space
      color
      skincolor
      hairstyle
      haircolor
      animation
      avoid3D
      pos
      lastOnline
      updatedAt
      createdAt
      currentThread
      comments {
        items {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        nextToken
      }
      reactions {
        items {
          id
          userID
          commentID
          like
          emoji
          reported
          updatedAt
          owner
        }
        nextToken
      }
      threads {
        items {
          id
          userID
          threadID
          lastRead
          viewedCount
        }
        nextToken
      }
      groups {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      friends
      owner
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($owner: String) {
    onUpdateUser(owner: $owner) {
      id
      name
      firstName
      secondName
      emailAddress
      description
      isPresenter
      isEventStaff
      type
      title
      company
      showInList
      blocked
      allowInvitations
      tags
      homePage
      space
      color
      skincolor
      hairstyle
      haircolor
      animation
      avoid3D
      pos
      lastOnline
      updatedAt
      createdAt
      currentThread
      comments {
        items {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        nextToken
      }
      reactions {
        items {
          id
          userID
          commentID
          like
          emoji
          reported
          updatedAt
          owner
        }
        nextToken
      }
      threads {
        items {
          id
          userID
          threadID
          lastRead
          viewedCount
        }
        nextToken
      }
      groups {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      friends
      owner
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($owner: String) {
    onDeleteUser(owner: $owner) {
      id
      name
      firstName
      secondName
      emailAddress
      description
      isPresenter
      isEventStaff
      type
      title
      company
      showInList
      blocked
      allowInvitations
      tags
      homePage
      space
      color
      skincolor
      hairstyle
      haircolor
      animation
      avoid3D
      pos
      lastOnline
      updatedAt
      createdAt
      currentThread
      comments {
        items {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        nextToken
      }
      reactions {
        items {
          id
          userID
          commentID
          like
          emoji
          reported
          updatedAt
          owner
        }
        nextToken
      }
      threads {
        items {
          id
          userID
          threadID
          lastRead
          viewedCount
        }
        nextToken
      }
      groups {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      friends
      owner
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification($owner: String) {
    onCreateNotification(owner: $owner) {
      id
      userID
      originUser
      body
      type
      link
      markedRead
      createdAt
      lastRead
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      owner
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification($owner: String) {
    onUpdateNotification(owner: $owner) {
      id
      userID
      originUser
      body
      type
      link
      markedRead
      createdAt
      lastRead
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      owner
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification($owner: String) {
    onDeleteNotification(owner: $owner) {
      id
      userID
      originUser
      body
      type
      link
      markedRead
      createdAt
      lastRead
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      owner
    }
  }
`;
export const onCreateSpace = /* GraphQL */ `
  subscription OnCreateSpace($owner: String) {
    onCreateSpace(owner: $owner) {
      id
      name
      pic
      description
      public
      thread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      pois {
        items {
          id
          name
          pic
          description
          pos
          thread
          space
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const onUpdateSpace = /* GraphQL */ `
  subscription OnUpdateSpace($owner: String) {
    onUpdateSpace(owner: $owner) {
      id
      name
      pic
      description
      public
      thread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      pois {
        items {
          id
          name
          pic
          description
          pos
          thread
          space
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const onDeleteSpace = /* GraphQL */ `
  subscription OnDeleteSpace($owner: String) {
    onDeleteSpace(owner: $owner) {
      id
      name
      pic
      description
      public
      thread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      pois {
        items {
          id
          name
          pic
          description
          pos
          thread
          space
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const onCreateSession = /* GraphQL */ `
  subscription OnCreateSession($owner: String) {
    onCreateSession(owner: $owner) {
      id
      externalID
      name
      pic
      hiddenPic
      shortDescription
      description
      more
      tags
      hide
      live
      isFeatured
      isKeynote
      preroll
      startdate
      starttime
      enddate
      endtime
      duration
      presenters
      presenterIDs
      facilitatorIDs
      permitted
      related
      thread
      threadName
      hideThread
      gotoThread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      useSessionViewer
      video
      interactive
      content
      videochat
      videochatServer
      videoChatAllowNonPresenters
      collateral
      collateralName
      actives {
        items {
          id
          userID
          sessionID
          createdAt
          updatedAt
          isFave
          visitActions
          mediaActions
          playbackActions
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const onUpdateSession = /* GraphQL */ `
  subscription OnUpdateSession($owner: String) {
    onUpdateSession(owner: $owner) {
      id
      externalID
      name
      pic
      hiddenPic
      shortDescription
      description
      more
      tags
      hide
      live
      isFeatured
      isKeynote
      preroll
      startdate
      starttime
      enddate
      endtime
      duration
      presenters
      presenterIDs
      facilitatorIDs
      permitted
      related
      thread
      threadName
      hideThread
      gotoThread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      useSessionViewer
      video
      interactive
      content
      videochat
      videochatServer
      videoChatAllowNonPresenters
      collateral
      collateralName
      actives {
        items {
          id
          userID
          sessionID
          createdAt
          updatedAt
          isFave
          visitActions
          mediaActions
          playbackActions
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const onDeleteSession = /* GraphQL */ `
  subscription OnDeleteSession($owner: String) {
    onDeleteSession(owner: $owner) {
      id
      externalID
      name
      pic
      hiddenPic
      shortDescription
      description
      more
      tags
      hide
      live
      isFeatured
      isKeynote
      preroll
      startdate
      starttime
      enddate
      endtime
      duration
      presenters
      presenterIDs
      facilitatorIDs
      permitted
      related
      thread
      threadName
      hideThread
      gotoThread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      useSessionViewer
      video
      interactive
      content
      videochat
      videochatServer
      videoChatAllowNonPresenters
      collateral
      collateralName
      actives {
        items {
          id
          userID
          sessionID
          createdAt
          updatedAt
          isFave
          visitActions
          mediaActions
          playbackActions
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const onCreateSessionActivity = /* GraphQL */ `
  subscription OnCreateSessionActivity($owner: String) {
    onCreateSessionActivity(owner: $owner) {
      id
      userID
      sessionID
      createdAt
      updatedAt
      isFave
      visitActions
      mediaActions
      playbackActions
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      session {
        id
        externalID
        name
        pic
        hiddenPic
        shortDescription
        description
        more
        tags
        hide
        live
        isFeatured
        isKeynote
        preroll
        startdate
        starttime
        enddate
        endtime
        duration
        presenters
        presenterIDs
        facilitatorIDs
        permitted
        related
        thread
        threadName
        hideThread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        useSessionViewer
        video
        interactive
        content
        videochat
        videochatServer
        videoChatAllowNonPresenters
        collateral
        collateralName
        actives {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const onUpdateSessionActivity = /* GraphQL */ `
  subscription OnUpdateSessionActivity($owner: String) {
    onUpdateSessionActivity(owner: $owner) {
      id
      userID
      sessionID
      createdAt
      updatedAt
      isFave
      visitActions
      mediaActions
      playbackActions
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      session {
        id
        externalID
        name
        pic
        hiddenPic
        shortDescription
        description
        more
        tags
        hide
        live
        isFeatured
        isKeynote
        preroll
        startdate
        starttime
        enddate
        endtime
        duration
        presenters
        presenterIDs
        facilitatorIDs
        permitted
        related
        thread
        threadName
        hideThread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        useSessionViewer
        video
        interactive
        content
        videochat
        videochatServer
        videoChatAllowNonPresenters
        collateral
        collateralName
        actives {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const onDeleteSessionActivity = /* GraphQL */ `
  subscription OnDeleteSessionActivity {
    onDeleteSessionActivity {
      id
      userID
      sessionID
      createdAt
      updatedAt
      isFave
      visitActions
      mediaActions
      playbackActions
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      session {
        id
        externalID
        name
        pic
        hiddenPic
        shortDescription
        description
        more
        tags
        hide
        live
        isFeatured
        isKeynote
        preroll
        startdate
        starttime
        enddate
        endtime
        duration
        presenters
        presenterIDs
        facilitatorIDs
        permitted
        related
        thread
        threadName
        hideThread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        useSessionViewer
        video
        interactive
        content
        videochat
        videochatServer
        videoChatAllowNonPresenters
        collateral
        collateralName
        actives {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const onCreatePoi = /* GraphQL */ `
  subscription OnCreatePoi($owner: String) {
    onCreatePOI(owner: $owner) {
      id
      name
      pic
      description
      pos
      thread
      space
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      inspace {
        id
        name
        pic
        description
        public
        thread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        pois {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const onUpdatePoi = /* GraphQL */ `
  subscription OnUpdatePoi($owner: String) {
    onUpdatePOI(owner: $owner) {
      id
      name
      pic
      description
      pos
      thread
      space
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      inspace {
        id
        name
        pic
        description
        public
        thread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        pois {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const onDeletePoi = /* GraphQL */ `
  subscription OnDeletePoi($owner: String) {
    onDeletePOI(owner: $owner) {
      id
      name
      pic
      description
      pos
      thread
      space
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      inspace {
        id
        name
        pic
        description
        public
        thread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        pois {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const onCreatePartner = /* GraphQL */ `
  subscription OnCreatePartner($owner: String) {
    onCreatePartner(owner: $owner) {
      id
      name
      pic
      headerpic
      headerlink
      shortDescription
      description
      more
      phone
      email
      tags
      hide
      priority
      staff
      related
      threadName
      thread
      hideThread
      gotoThread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      video
      slides
      downloadables
      actives {
        items {
          id
          userID
          partnerID
          createdAt
          updatedAt
          isFave
          mediaActions
          playbackActions
          visitActions
          linkActions
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const onUpdatePartner = /* GraphQL */ `
  subscription OnUpdatePartner($owner: String) {
    onUpdatePartner(owner: $owner) {
      id
      name
      pic
      headerpic
      headerlink
      shortDescription
      description
      more
      phone
      email
      tags
      hide
      priority
      staff
      related
      threadName
      thread
      hideThread
      gotoThread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      video
      slides
      downloadables
      actives {
        items {
          id
          userID
          partnerID
          createdAt
          updatedAt
          isFave
          mediaActions
          playbackActions
          visitActions
          linkActions
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const onDeletePartner = /* GraphQL */ `
  subscription OnDeletePartner($owner: String) {
    onDeletePartner(owner: $owner) {
      id
      name
      pic
      headerpic
      headerlink
      shortDescription
      description
      more
      phone
      email
      tags
      hide
      priority
      staff
      related
      threadName
      thread
      hideThread
      gotoThread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      video
      slides
      downloadables
      actives {
        items {
          id
          userID
          partnerID
          createdAt
          updatedAt
          isFave
          mediaActions
          playbackActions
          visitActions
          linkActions
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const onCreatePartnerActivity = /* GraphQL */ `
  subscription OnCreatePartnerActivity($owner: String) {
    onCreatePartnerActivity(owner: $owner) {
      id
      userID
      partnerID
      createdAt
      updatedAt
      isFave
      mediaActions
      playbackActions
      visitActions
      linkActions
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      partner {
        id
        externalID
        name
        pic
        hiddenPic
        shortDescription
        description
        more
        tags
        hide
        live
        isFeatured
        isKeynote
        preroll
        startdate
        starttime
        enddate
        endtime
        duration
        presenters
        presenterIDs
        facilitatorIDs
        permitted
        related
        thread
        threadName
        hideThread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        useSessionViewer
        video
        interactive
        content
        videochat
        videochatServer
        videoChatAllowNonPresenters
        collateral
        collateralName
        actives {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const onUpdatePartnerActivity = /* GraphQL */ `
  subscription OnUpdatePartnerActivity($owner: String) {
    onUpdatePartnerActivity(owner: $owner) {
      id
      userID
      partnerID
      createdAt
      updatedAt
      isFave
      mediaActions
      playbackActions
      visitActions
      linkActions
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      partner {
        id
        externalID
        name
        pic
        hiddenPic
        shortDescription
        description
        more
        tags
        hide
        live
        isFeatured
        isKeynote
        preroll
        startdate
        starttime
        enddate
        endtime
        duration
        presenters
        presenterIDs
        facilitatorIDs
        permitted
        related
        thread
        threadName
        hideThread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        useSessionViewer
        video
        interactive
        content
        videochat
        videochatServer
        videoChatAllowNonPresenters
        collateral
        collateralName
        actives {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const onDeletePartnerActivity = /* GraphQL */ `
  subscription OnDeletePartnerActivity {
    onDeletePartnerActivity {
      id
      userID
      partnerID
      createdAt
      updatedAt
      isFave
      mediaActions
      playbackActions
      visitActions
      linkActions
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      partner {
        id
        externalID
        name
        pic
        hiddenPic
        shortDescription
        description
        more
        tags
        hide
        live
        isFeatured
        isKeynote
        preroll
        startdate
        starttime
        enddate
        endtime
        duration
        presenters
        presenterIDs
        facilitatorIDs
        permitted
        related
        thread
        threadName
        hideThread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        useSessionViewer
        video
        interactive
        content
        videochat
        videochatServer
        videoChatAllowNonPresenters
        collateral
        collateralName
        actives {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const onCreateGroup = /* GraphQL */ `
  subscription OnCreateGroup($owner: String) {
    onCreateGroup(owner: $owner) {
      id
      name
      pic
      shortDescription
      description
      more
      tags
      hide
      related
      priority
      curation
      isPublic
      validMembers
      downloadables
      thread
      gotoThread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      members {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      admins {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      owner
    }
  }
`;
export const onUpdateGroup = /* GraphQL */ `
  subscription OnUpdateGroup($owner: String) {
    onUpdateGroup(owner: $owner) {
      id
      name
      pic
      shortDescription
      description
      more
      tags
      hide
      related
      priority
      curation
      isPublic
      validMembers
      downloadables
      thread
      gotoThread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      members {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      admins {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      owner
    }
  }
`;
export const onDeleteGroup = /* GraphQL */ `
  subscription OnDeleteGroup($owner: String) {
    onDeleteGroup(owner: $owner) {
      id
      name
      pic
      shortDescription
      description
      more
      tags
      hide
      related
      priority
      curation
      isPublic
      validMembers
      downloadables
      thread
      gotoThread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      members {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      admins {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      owner
    }
  }
`;
export const onCreateGroupMember = /* GraphQL */ `
  subscription OnCreateGroupMember {
    onCreateGroupMember {
      id
      userID
      groupID
      createdAt
      isFave
      approved
      visitActions
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      group {
        id
        name
        pic
        shortDescription
        description
        more
        tags
        hide
        related
        priority
        curation
        isPublic
        validMembers
        downloadables
        thread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        members {
          nextToken
        }
        admins {
          nextToken
        }
        owner
      }
    }
  }
`;
export const onUpdateGroupMember = /* GraphQL */ `
  subscription OnUpdateGroupMember {
    onUpdateGroupMember {
      id
      userID
      groupID
      createdAt
      isFave
      approved
      visitActions
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      group {
        id
        name
        pic
        shortDescription
        description
        more
        tags
        hide
        related
        priority
        curation
        isPublic
        validMembers
        downloadables
        thread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        members {
          nextToken
        }
        admins {
          nextToken
        }
        owner
      }
    }
  }
`;
export const onDeleteGroupMember = /* GraphQL */ `
  subscription OnDeleteGroupMember {
    onDeleteGroupMember {
      id
      userID
      groupID
      createdAt
      isFave
      approved
      visitActions
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      group {
        id
        name
        pic
        shortDescription
        description
        more
        tags
        hide
        related
        priority
        curation
        isPublic
        validMembers
        downloadables
        thread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        members {
          nextToken
        }
        admins {
          nextToken
        }
        owner
      }
    }
  }
`;
export const onCreateThreadMember = /* GraphQL */ `
  subscription OnCreateThreadMember {
    onCreateThreadMember {
      id
      userID
      threadID
      lastRead
      viewedCount
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      thread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
    }
  }
`;
export const onUpdateThreadMember = /* GraphQL */ `
  subscription OnUpdateThreadMember {
    onUpdateThreadMember {
      id
      userID
      threadID
      lastRead
      viewedCount
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      thread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
    }
  }
`;
export const onDeleteThreadMember = /* GraphQL */ `
  subscription OnDeleteThreadMember {
    onDeleteThreadMember {
      id
      userID
      threadID
      lastRead
      viewedCount
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      thread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
    }
  }
`;
export const onCreateThread = /* GraphQL */ `
  subscription OnCreateThread($owner: String) {
    onCreateThread(owner: $owner) {
      id
      name
      pic
      isPrivate
      positiveCuration
      disallowVideo
      type
      updatedAt
      comments {
        items {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        nextToken
      }
      users {
        items {
          id
          userID
          threadID
          lastRead
          viewedCount
        }
        nextToken
      }
      owner
    }
  }
`;
export const onUpdateThread = /* GraphQL */ `
  subscription OnUpdateThread($owner: String) {
    onUpdateThread(owner: $owner) {
      id
      name
      pic
      isPrivate
      positiveCuration
      disallowVideo
      type
      updatedAt
      comments {
        items {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        nextToken
      }
      users {
        items {
          id
          userID
          threadID
          lastRead
          viewedCount
        }
        nextToken
      }
      owner
    }
  }
`;
export const onDeleteThread = /* GraphQL */ `
  subscription OnDeleteThread($owner: String) {
    onDeleteThread(owner: $owner) {
      id
      name
      pic
      isPrivate
      positiveCuration
      disallowVideo
      type
      updatedAt
      comments {
        items {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        nextToken
      }
      users {
        items {
          id
          userID
          threadID
          lastRead
          viewedCount
        }
        nextToken
      }
      owner
    }
  }
`;
export const onCreateReaction = /* GraphQL */ `
  subscription OnCreateReaction($owner: String) {
    onCreateReaction(owner: $owner) {
      id
      userID
      commentID
      like
      emoji
      reported
      updatedAt
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      comment {
        id
        thread
        inThread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        createdAt
        curation
        reporterID
        userID
        reactions {
          nextToken
        }
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        content
        owner
      }
      owner
    }
  }
`;
export const onUpdateReaction = /* GraphQL */ `
  subscription OnUpdateReaction($owner: String) {
    onUpdateReaction(owner: $owner) {
      id
      userID
      commentID
      like
      emoji
      reported
      updatedAt
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      comment {
        id
        thread
        inThread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        createdAt
        curation
        reporterID
        userID
        reactions {
          nextToken
        }
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        content
        owner
      }
      owner
    }
  }
`;
export const onDeleteReaction = /* GraphQL */ `
  subscription OnDeleteReaction($owner: String) {
    onDeleteReaction(owner: $owner) {
      id
      userID
      commentID
      like
      emoji
      reported
      updatedAt
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      comment {
        id
        thread
        inThread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        createdAt
        curation
        reporterID
        userID
        reactions {
          nextToken
        }
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        content
        owner
      }
      owner
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment($owner: String) {
    onCreateComment(owner: $owner) {
      id
      thread
      inThread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      createdAt
      curation
      reporterID
      userID
      reactions {
        items {
          id
          userID
          commentID
          like
          emoji
          reported
          updatedAt
          owner
        }
        nextToken
      }
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      content
      owner
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment($owner: String) {
    onUpdateComment(owner: $owner) {
      id
      thread
      inThread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      createdAt
      curation
      reporterID
      userID
      reactions {
        items {
          id
          userID
          commentID
          like
          emoji
          reported
          updatedAt
          owner
        }
        nextToken
      }
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      content
      owner
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment($owner: String) {
    onDeleteComment(owner: $owner) {
      id
      thread
      inThread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      createdAt
      curation
      reporterID
      userID
      reactions {
        items {
          id
          userID
          commentID
          like
          emoji
          reported
          updatedAt
          owner
        }
        nextToken
      }
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      content
      owner
    }
  }
`;
