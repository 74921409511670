import React, { useState, useEffect } from "react";
import { Storage } from 'aws-amplify';

import { Image } from "grommet";


const S3Image = (props) => {
  const { src } = props;
  const [genURL, setGenURL] = useState("");
  //const [positioning, setPositioning] = useState('center top');

  const getS3signedURL = async () => {
    if (src && src.length > 3) {
      try {
        const storedURL = await Storage.get (src, { level: 'public' });
        setGenURL (storedURL);
      
      } catch (err) {
        // there will be a message anyway, from browser
        console.log (err);
      }
    }
  };

  useEffect( () => {
    getS3signedURL ();

    
  }, [src]);

  return (
    <Image {...props}
        src={genURL}

    />
    
  )
};

export default S3Image;
