import React, { useState, useRef } from 'react';
import { Anchor, Box, Button, Drop, Form, FormField, Heading, Image, ResponsiveContext, Text, TextInput  } from 'grommet';
import { View, Hide, Tooltip } from 'grommet-icons';
import { EmailInput, PasswordInput, Spinning } from 'grommet-controls';

import localstorage from 'local-storage';
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";

import EventParams from '../EventItems';

function emailIsValid (email) {
    //return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test (email);
}

const Welcome = (props) => {
    const { isAuth, setIsAuth, signIn, register, confirmUser, resendCode, requestPasswordReset, submitPasswordChange } = props;
    const [ registering, setRegistering ] = useState (false);
    const [confirming, setConfirming] = useState (localstorage.get ('needToConfirm'));
    const [resetting, setResetting] = useState (false);
    const [nameValue, setNameValue] = useState("");
    const [pwValue, setPWValue] = useState("");

    const [newPWValue, setNewPWValue] = useState("");
    const [forceChange, setForceChange] = useState(false);

    const [reveal, setReveal] = useState(false);
    const [working, setWorking] =useState(false);

    const [userNameErr, setUserNameErr] = useState (false);
    const [pwErr, setPWErr] = useState (false);
    const [nameErr, setNameErr] = useState (false);
    const [emailErr, setEmailErr] = useState (false);
    const [codeErr, setCodeErr] = useState (false);

    const ref = useRef ();
    let history = useHistory ();

    const [firstNameValue, setFirstNameValue] = useState("");
    const [secondNameValue, setSecondNameValue] = useState("");
    const [nickNameValue, setNickNameValue] = useState("");
    const [emailValue, setEmailValue] = useState("");
    const [codeValue, setCodeValue] = useState("");

    const trySignIn = async () => {
        setWorking(true);

        // do we need to confirm or reset password?
        if (resetting) {
            const result = submitPasswordChange (nameValue, codeValue, pwValue);
        }
        else if (confirming && codeValue.length) {
            const strippedCode = codeValue.replace (/\D/g,'');
            if (strippedCode.length !== 6) {
                setCodeErr (true);
            }
            else
                setCodeErr (false);

                const result = await confirmUser (nameValue, strippedCode);
                if (result !== "Confirming!") {
                    // otherwise we'll also sign in

                }
                else
                {
                    if (result === 'Invalid code provided, please request a code again.') {
                        localstorage.set ('needToConfirm');
                        return;
                    }
                }
        }

        const result = await signIn (nameValue, pwValue, newPWValue, { name: nickNameValue, family_name: secondNameValue, given_name: firstNameValue});  
        switch (result.err) {
            case "NEW_PASSWORD_REQUIRED":
                setForceChange (true);
                setNewPWValue ("");
                setFirstNameValue (result.attributes ? result.attributes.given_name : "");
                setSecondNameValue (result.attributes ? result.attributes.family_name : "");
                setNickNameValue (result.attributes ? result.attributes.name : "");
                break;
            case "Signed In":
                // Nothing to do
                setForceChange (false);
                localstorage.remove ('needToConfirm');
                break;
            case "User does not exist.":
                console.log ("Failure signing in - user name not found!");
                break;
            
            case "User is not confirmed.":
            case "User needs to confirm":
            
                console.log ("User needs to confirm");
                setForceChange (false);
                setConfirming (true);
                localstorage.set ('needToConfirm');
                break;

            case "Incorrect username or password.":
                setForceChange (false);
                console.log ("Failure signing in - incorrect password?");
                break;
            default:
                // Display error
                console.log ("Failure signing in: ", result);
                break;
        }

        setWorking (false);
    }

    const forgotPassword = async () => {
        setConfirming (false);
        setResetting (true);
        requestPasswordReset (nameValue);
        localstorage.set ('needToConfirm');
    }

    const requestCode = async () => {
        resendCode (nameValue);
        // should already be confirming or else button wouldn't work
        localstorage.set ('needToConfirm');
    }

    const checkRegister = async () => {
        let errorFound = false;

        // Check the fields before we bother
        if (nameValue.length < 4 || !/^[a-zA-Z0-9.@-]+$/.test(nameValue)) {
            setUserNameErr (true);
            errorFound = true;
        }
        else
            setUserNameErr (false);

        if (pwValue.length < 8) {
            setPWErr (true);
            errorFound = true;
        }
        else
            setPWErr (false);

        if (nickNameValue.length < 4) {
            setNameErr (true);
            errorFound = true;
        } 
        else
            setNameErr (false);
        
        if (emailIsValid (emailValue) === false ) {
            setEmailErr (true);
            errorFound = true;
        }
        else
            setEmailErr (false);

        if (errorFound) return;
        
        setRegistering (false);

        console.log ("registering", nameValue, pwValue, nickNameValue, emailValue);
        const result = setConfirming (await register (nameValue, pwValue, nickNameValue, emailValue));
        if (result) {
            localstorage.set ('needToConfirm');
        }
            
        // probably they will need to confirm
    } 

    // Welcome to {EventParams.Name}
    // window.open(EventParams.OrganizerURL, "_blank")

    return (
  <Box a11yTitle='Welcome'
    fill
    align='center'
    justify='center'
    background={{ color: 'background-back', image: EventParams.SignUpBackdrop }}
    >

    <ResponsiveContext.Consumer>
        {responsive => (
    <Box direction='row-responsive' margin='xlarge'
        align='center' gap='xlarge'>

        <Form>
            <Box background='background-front' elevation='medium'
                round='medium' width='medium' direction='column' align='center' pad='medium' gap='xlarge'>

            {!registering ? <>
                <Text size='xxlarge' color='brand'>Log In</Text>
                
                <FormField label="User Name" name='username'>
                    <Box direction='row' border={{ color:'secondary' }} round='large' width='medium' pad={{ horizontal:'small', vertical: 'xxsmall' }}>
                    <TextInput plain color='brand' placeholder="Enter your registered email"
                        value={nameValue} onChange={event => setNameValue((event.target.value).toLowerCase().trim())} />
                    </Box>
                </FormField>

                <FormField label="Password" name='password'>
                    <Box direction='row' border={{ color:'secondary' }} round='large' width='medium' pad={{ horizontal:'small', vertical: 'xxsmall' }}>
                        <TextInput plain placeholder={resetting ? "Pick a new password" : "Enter your password"}
                            type={reveal ? "text" : "password"}
                            value={pwValue} onChange={event => setPWValue(event.target.value)} />
                        <Button tabIndex='-1' plain icon={reveal ? <View size="medium" /> : <Hide size="medium" />}
                            onClick={() => setReveal(!reveal)} />
                    </Box>
                    {(!confirming && !resetting) &&
                    <Box fill='horizontal' direction='row' justify='end' align='start' pad={{ horizontal: 'small'}}>
                            {/*<Button plain color='brand' onClick={() => forgotPassword() }>Forgot password?</Button>*/}
                            
                    </Box> }              
                </FormField>

                {forceChange && <>
                    <FormField label="New Password" name='newpassword'>
                        <Box direction='row' border={{ color:'secondary' }} round='large' width='medium' pad={{ horizontal:'small', vertical: 'xxsmall' }}>
                            <TextInput plain placeholder="Enter your new password"
                                type={reveal ? "text" : "password"}
                                value={newPWValue} onChange={event => setNewPWValue(event.target.value)} />
                            <Button tabIndex='-1' plain icon={reveal ? <View size="medium" /> : <Hide size="medium" />}
                                onClick={() => setReveal(!reveal)} />
                        </Box> 
                        <Text size='xsmall'>Your new password must have 8 or more characters!</Text>         
                    </FormField>
                    
                    <FormField label="First Name" name='firstname'>
                        <Box direction='row'  background={nameErr ? 'status-warning' : 'white'}
                            border={{ color:'secondary' }} round='large' width='medium' pad={{ horizontal:'small', vertical: 'xxsmall' }}>
                            <TextInput plain color='brand' placeholder="Pat"
                                value={firstNameValue} onChange={event => setFirstNameValue(event.target.value)} />
                        </Box>
                    </FormField>   
                    <FormField label="Last Name" name='lastname'>
                        <Box direction='row'  background={nameErr ? 'status-warning' : 'white'}
                            border={{ color:'secondary' }} round='large' width='medium' pad={{ horizontal:'small', vertical: 'xxsmall' }}>
                            <TextInput plain color='brand' placeholder="Smith"
                                value={secondNameValue} onChange={event => setSecondNameValue(event.target.value)} />
                        </Box>
                    </FormField>   
                    <FormField label="Displayed Name" name='name'>
                        <Box direction='row'  background={nameErr ? 'status-warning' : 'white'}
                            border={{ color:'secondary' }} round='large' width='medium' pad={{ horizontal:'small', vertical: 'xxsmall' }}>
                        <TextInput plain color='brand' placeholder="Dr. Pat Smith"
                            value={nickNameValue} onChange={event => setNickNameValue(event.target.value)} />
                        </Box>
                        <Box fill='horizontal' direction='row' justify='end' align='start' pad={{ horizontal: 'small'}}>
                            <Text size='xsmall'>Other attendees will see this name</Text>
                        </Box> 
                    </FormField>                



                </>}

                {(confirming || resetting) && 
                    <FormField label="Confirmation Code" name='code'>
                        <Box direction='row' border={{ color:'secondary' }}  background={codeErr ? 'status-warning' : 'white'}
                                round='large' width='medium' pad={{ horizontal:'small', vertical: 'xxsmall' }}>
                            <TextInput plain placeholder={emailValue.length ? ("Enter code sent to " + emailValue) : "Enter your confirmation code"}
                                value={codeValue} onChange={event => setCodeValue(event.target.value)} />
                        </Box>
                        <Box fill='horizontal' direction='row' justify='end' align='start' pad={{ horizontal: 'small'}}>
                            
                            <Box direction='column'>
                            <Button plain color='brand' onClick={() => requestCode() } >Request a new code</Button>
                                <Button plain color='brand' onClick={() => {
                                    localstorage.remove ('needToConfirm');
                                    setResetting (false);
                                    setConfirming (false); } }>I only have a password!</Button>
                            </Box>
                        </Box> 
                    </FormField> 
                }

                <Box fill='horizontal' pad='medium'>
                    <Button primary color='brand' size='small' label='LOG IN' onClick={() => trySignIn () } />
                </Box>
                
                {EventParams.AllowSignups ?
                <Box direction='row' gap='xsmall'>
                  <Text size='small'>Don't have an account?</Text> 
                  <Button plain color='brand' onClick={() => {setRegistering (true)} } label="Sign up"/>
                </Box>
                : <>  <Text size='small'>Please contact the organizers to register.</Text>
                    <Anchor href={EventParams.TechsupportLink}>Contact us if you are having trouble signing in.</Anchor> 
                </> }
                
                {working && <Spinning kind="three-bounce" />}           
            </>: <>
                <Text size='xxlarge' color='brand'>Register</Text>
                
                <FormField label="User Name" name='username'>
                    <Box direction='row' background={userNameErr ? 'status-warning' : 'white'}
                        border={{ color:'secondary' }} round='large' width='medium' pad={{ horizontal:'small', vertical: 'xxsmall' }}>
                    <TextInput plain color='brand' placeholder="PatSmith"
                        value={nameValue} onChange={event => setNameValue(event.target.value)} />
                    </Box>
                    <Box fill='horizontal' direction='row' justify='end' align='start' pad={{ horizontal: 'small'}}>
                        <Text size='xsmall'>No spaces, case sensitive</Text>
                    </Box> 
                </FormField>

                <FormField label="Password" name='password'>
                    <Box direction='row' background={pwErr ? 'status-warning' : 'white'}
                        border={{ color:'secondary' }} round='large' width='medium' pad={{ horizontal:'small', vertical: 'xxsmall' }}>
                        <TextInput plain placeholder="Password"
                            type={reveal ? "text" : "password"}
                            value={pwValue} onChange={event => setPWValue(event.target.value)} />
                        <Button tabIndex='-1' plain icon={reveal ? <View size="medium" /> : <Hide size="medium" />}
                            onClick={() => setReveal(!reveal)} />
                    </Box>
                    <Box fill='horizontal' direction='row' justify='end' align='start' pad={{ horizontal: 'small'}}>
                        <Text size='xsmall'>Must be at least 8 characters</Text>
                    </Box>              
                </FormField>

                <FormField label="Email Address" name='email'>
                    <Box direction='row' background={emailErr ? 'status-warning' : 'white'}
                        border={{ color:'secondary' }} round='large' width='medium' pad={{ horizontal:'small', vertical: 'xxsmall' }}>
                    <TextInput plain color='brand' placeholder="patsmith@exampleaddress.com"
                        value={emailValue} onChange={event => setEmailValue(String( event.target.value).toLowerCase() )} />
                    </Box>
                    <Box fill='horizontal' direction='row' justify='end' align='start' pad={{ horizontal: 'small'}}>
                        <Text size='xsmall'>This is where you will receive a confirmation code</Text>
                    </Box> 
                </FormField>

                <FormField label="Name" name='name'>
                    <Box direction='row'  background={nameErr ? 'status-warning' : 'white'}
                        border={{ color:'secondary' }} round='large' width='medium' pad={{ horizontal:'small', vertical: 'xxsmall' }}>
                    <TextInput plain color='brand' placeholder="Pat Smith"
                        value={nickNameValue} onChange={event => setNickNameValue(event.target.value)} />
                    </Box>
                    <Box fill='horizontal' direction='row' justify='end' align='start' pad={{ horizontal: 'small'}}>
                        <Text size='xsmall'>Other attendees will see this name</Text>
                    </Box> 
                </FormField>

                <Box fill='horizontal' pad='medium'>
                    <Button primary color='brand' size='small' label='REGISTER' onClick={() => checkRegister()  } />
                </Box>

                <Text size='xsmall'>Registering here will place your details in a secure database. Only the administrators and event organizers can access any personal details that you do not chose to share.</Text>
                <Text size='xsmall'>By pressing submit you are giving permission for the organizer to use your information solely for the purpose of demonstrating the system.</Text>
                
                <Box direction='row' gap='xsmall'>
                  <Text size='small'>Already have an account?</Text> 
                  <Button plain color='brand' onClick={() => {setRegistering (false)} } label="Log in"/>
                </Box>

            </> }
            </Box>
        </Form>

            <Box width={responsive === 'small' ? '80vw' : '35vw'} 
                animation='fadeIn' direction='column' gap='medium'>
            <Image fill="horizontal" src={EventParams.EventLogoPre} />
        </Box>
        
    </Box>
        )}
    </ResponsiveContext.Consumer>
</Box>
  
)};

export default Welcome;