/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateUserLocation = /* GraphQL */ `
  mutation UpdateUserLocation($input: UpdateUserLocationInput!) {
    updateUserLocation(input: $input) {
      id
      name
      firstName
      secondName
      emailAddress
      description
      isPresenter
      isEventStaff
      type
      title
      company
      showInList
      blocked
      allowInvitations
      tags
      homePage
      space
      color
      skincolor
      hairstyle
      haircolor
      animation
      avoid3D
      pos
      lastOnline
      updatedAt
      createdAt
      currentThread
      comments {
        items {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        nextToken
      }
      reactions {
        items {
          id
          userID
          commentID
          like
          emoji
          reported
          updatedAt
          owner
        }
        nextToken
      }
      threads {
        items {
          id
          userID
          threadID
          lastRead
          viewedCount
        }
        nextToken
      }
      groups {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      friends
      owner
    }
  }
`;
export const updateUserDetails = /* GraphQL */ `
  mutation UpdateUserDetails($input: UpdateUserDetailsInput!) {
    updateUserDetails(input: $input) {
      id
      name
      firstName
      secondName
      emailAddress
      description
      isPresenter
      isEventStaff
      type
      title
      company
      showInList
      blocked
      allowInvitations
      tags
      homePage
      space
      color
      skincolor
      hairstyle
      haircolor
      animation
      avoid3D
      pos
      lastOnline
      updatedAt
      createdAt
      currentThread
      comments {
        items {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        nextToken
      }
      reactions {
        items {
          id
          userID
          commentID
          like
          emoji
          reported
          updatedAt
          owner
        }
        nextToken
      }
      threads {
        items {
          id
          userID
          threadID
          lastRead
          viewedCount
        }
        nextToken
      }
      groups {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      friends
      owner
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      firstName
      secondName
      emailAddress
      description
      isPresenter
      isEventStaff
      type
      title
      company
      showInList
      blocked
      allowInvitations
      tags
      homePage
      space
      color
      skincolor
      hairstyle
      haircolor
      animation
      avoid3D
      pos
      lastOnline
      updatedAt
      createdAt
      currentThread
      comments {
        items {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        nextToken
      }
      reactions {
        items {
          id
          userID
          commentID
          like
          emoji
          reported
          updatedAt
          owner
        }
        nextToken
      }
      threads {
        items {
          id
          userID
          threadID
          lastRead
          viewedCount
        }
        nextToken
      }
      groups {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      friends
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      firstName
      secondName
      emailAddress
      description
      isPresenter
      isEventStaff
      type
      title
      company
      showInList
      blocked
      allowInvitations
      tags
      homePage
      space
      color
      skincolor
      hairstyle
      haircolor
      animation
      avoid3D
      pos
      lastOnline
      updatedAt
      createdAt
      currentThread
      comments {
        items {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        nextToken
      }
      reactions {
        items {
          id
          userID
          commentID
          like
          emoji
          reported
          updatedAt
          owner
        }
        nextToken
      }
      threads {
        items {
          id
          userID
          threadID
          lastRead
          viewedCount
        }
        nextToken
      }
      groups {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      friends
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      firstName
      secondName
      emailAddress
      description
      isPresenter
      isEventStaff
      type
      title
      company
      showInList
      blocked
      allowInvitations
      tags
      homePage
      space
      color
      skincolor
      hairstyle
      haircolor
      animation
      avoid3D
      pos
      lastOnline
      updatedAt
      createdAt
      currentThread
      comments {
        items {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        nextToken
      }
      reactions {
        items {
          id
          userID
          commentID
          like
          emoji
          reported
          updatedAt
          owner
        }
        nextToken
      }
      threads {
        items {
          id
          userID
          threadID
          lastRead
          viewedCount
        }
        nextToken
      }
      groups {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      friends
      owner
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      userID
      originUser
      body
      type
      link
      markedRead
      createdAt
      lastRead
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      owner
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      userID
      originUser
      body
      type
      link
      markedRead
      createdAt
      lastRead
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      owner
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      userID
      originUser
      body
      type
      link
      markedRead
      createdAt
      lastRead
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      owner
    }
  }
`;
export const createSpace = /* GraphQL */ `
  mutation CreateSpace(
    $input: CreateSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    createSpace(input: $input, condition: $condition) {
      id
      name
      pic
      description
      public
      thread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      pois {
        items {
          id
          name
          pic
          description
          pos
          thread
          space
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const updateSpace = /* GraphQL */ `
  mutation UpdateSpace(
    $input: UpdateSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    updateSpace(input: $input, condition: $condition) {
      id
      name
      pic
      description
      public
      thread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      pois {
        items {
          id
          name
          pic
          description
          pos
          thread
          space
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const deleteSpace = /* GraphQL */ `
  mutation DeleteSpace(
    $input: DeleteSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    deleteSpace(input: $input, condition: $condition) {
      id
      name
      pic
      description
      public
      thread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      pois {
        items {
          id
          name
          pic
          description
          pos
          thread
          space
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const createSession = /* GraphQL */ `
  mutation CreateSession(
    $input: CreateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    createSession(input: $input, condition: $condition) {
      id
      externalID
      name
      pic
      hiddenPic
      shortDescription
      description
      more
      tags
      hide
      live
      isFeatured
      isKeynote
      preroll
      startdate
      starttime
      enddate
      endtime
      duration
      presenters
      presenterIDs
      facilitatorIDs
      permitted
      related
      thread
      threadName
      hideThread
      gotoThread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      useSessionViewer
      video
      interactive
      content
      videochat
      videochatServer
      videoChatAllowNonPresenters
      collateral
      collateralName
      actives {
        items {
          id
          userID
          sessionID
          createdAt
          updatedAt
          isFave
          visitActions
          mediaActions
          playbackActions
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $input: UpdateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    updateSession(input: $input, condition: $condition) {
      id
      externalID
      name
      pic
      hiddenPic
      shortDescription
      description
      more
      tags
      hide
      live
      isFeatured
      isKeynote
      preroll
      startdate
      starttime
      enddate
      endtime
      duration
      presenters
      presenterIDs
      facilitatorIDs
      permitted
      related
      thread
      threadName
      hideThread
      gotoThread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      useSessionViewer
      video
      interactive
      content
      videochat
      videochatServer
      videoChatAllowNonPresenters
      collateral
      collateralName
      actives {
        items {
          id
          userID
          sessionID
          createdAt
          updatedAt
          isFave
          visitActions
          mediaActions
          playbackActions
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const deleteSession = /* GraphQL */ `
  mutation DeleteSession(
    $input: DeleteSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    deleteSession(input: $input, condition: $condition) {
      id
      externalID
      name
      pic
      hiddenPic
      shortDescription
      description
      more
      tags
      hide
      live
      isFeatured
      isKeynote
      preroll
      startdate
      starttime
      enddate
      endtime
      duration
      presenters
      presenterIDs
      facilitatorIDs
      permitted
      related
      thread
      threadName
      hideThread
      gotoThread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      useSessionViewer
      video
      interactive
      content
      videochat
      videochatServer
      videoChatAllowNonPresenters
      collateral
      collateralName
      actives {
        items {
          id
          userID
          sessionID
          createdAt
          updatedAt
          isFave
          visitActions
          mediaActions
          playbackActions
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const createSessionActivity = /* GraphQL */ `
  mutation CreateSessionActivity(
    $input: CreateSessionActivityInput!
    $condition: ModelSessionActivityConditionInput
  ) {
    createSessionActivity(input: $input, condition: $condition) {
      id
      userID
      sessionID
      createdAt
      updatedAt
      isFave
      visitActions
      mediaActions
      playbackActions
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      session {
        id
        externalID
        name
        pic
        hiddenPic
        shortDescription
        description
        more
        tags
        hide
        live
        isFeatured
        isKeynote
        preroll
        startdate
        starttime
        enddate
        endtime
        duration
        presenters
        presenterIDs
        facilitatorIDs
        permitted
        related
        thread
        threadName
        hideThread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        useSessionViewer
        video
        interactive
        content
        videochat
        videochatServer
        videoChatAllowNonPresenters
        collateral
        collateralName
        actives {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const updateSessionActivity = /* GraphQL */ `
  mutation UpdateSessionActivity(
    $input: UpdateSessionActivityInput!
    $condition: ModelSessionActivityConditionInput
  ) {
    updateSessionActivity(input: $input, condition: $condition) {
      id
      userID
      sessionID
      createdAt
      updatedAt
      isFave
      visitActions
      mediaActions
      playbackActions
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      session {
        id
        externalID
        name
        pic
        hiddenPic
        shortDescription
        description
        more
        tags
        hide
        live
        isFeatured
        isKeynote
        preroll
        startdate
        starttime
        enddate
        endtime
        duration
        presenters
        presenterIDs
        facilitatorIDs
        permitted
        related
        thread
        threadName
        hideThread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        useSessionViewer
        video
        interactive
        content
        videochat
        videochatServer
        videoChatAllowNonPresenters
        collateral
        collateralName
        actives {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const deleteSessionActivity = /* GraphQL */ `
  mutation DeleteSessionActivity(
    $input: DeleteSessionActivityInput!
    $condition: ModelSessionActivityConditionInput
  ) {
    deleteSessionActivity(input: $input, condition: $condition) {
      id
      userID
      sessionID
      createdAt
      updatedAt
      isFave
      visitActions
      mediaActions
      playbackActions
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      session {
        id
        externalID
        name
        pic
        hiddenPic
        shortDescription
        description
        more
        tags
        hide
        live
        isFeatured
        isKeynote
        preroll
        startdate
        starttime
        enddate
        endtime
        duration
        presenters
        presenterIDs
        facilitatorIDs
        permitted
        related
        thread
        threadName
        hideThread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        useSessionViewer
        video
        interactive
        content
        videochat
        videochatServer
        videoChatAllowNonPresenters
        collateral
        collateralName
        actives {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const createPoi = /* GraphQL */ `
  mutation CreatePoi(
    $input: CreatePOIInput!
    $condition: ModelPOIConditionInput
  ) {
    createPOI(input: $input, condition: $condition) {
      id
      name
      pic
      description
      pos
      thread
      space
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      inspace {
        id
        name
        pic
        description
        public
        thread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        pois {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const updatePoi = /* GraphQL */ `
  mutation UpdatePoi(
    $input: UpdatePOIInput!
    $condition: ModelPOIConditionInput
  ) {
    updatePOI(input: $input, condition: $condition) {
      id
      name
      pic
      description
      pos
      thread
      space
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      inspace {
        id
        name
        pic
        description
        public
        thread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        pois {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const deletePoi = /* GraphQL */ `
  mutation DeletePoi(
    $input: DeletePOIInput!
    $condition: ModelPOIConditionInput
  ) {
    deletePOI(input: $input, condition: $condition) {
      id
      name
      pic
      description
      pos
      thread
      space
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      inspace {
        id
        name
        pic
        description
        public
        thread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        pois {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const createPartner = /* GraphQL */ `
  mutation CreatePartner(
    $input: CreatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    createPartner(input: $input, condition: $condition) {
      id
      name
      pic
      headerpic
      headerlink
      shortDescription
      description
      more
      phone
      email
      tags
      hide
      priority
      staff
      related
      threadName
      thread
      hideThread
      gotoThread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      video
      slides
      downloadables
      actives {
        items {
          id
          userID
          partnerID
          createdAt
          updatedAt
          isFave
          mediaActions
          playbackActions
          visitActions
          linkActions
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const updatePartner = /* GraphQL */ `
  mutation UpdatePartner(
    $input: UpdatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    updatePartner(input: $input, condition: $condition) {
      id
      name
      pic
      headerpic
      headerlink
      shortDescription
      description
      more
      phone
      email
      tags
      hide
      priority
      staff
      related
      threadName
      thread
      hideThread
      gotoThread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      video
      slides
      downloadables
      actives {
        items {
          id
          userID
          partnerID
          createdAt
          updatedAt
          isFave
          mediaActions
          playbackActions
          visitActions
          linkActions
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const deletePartner = /* GraphQL */ `
  mutation DeletePartner(
    $input: DeletePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    deletePartner(input: $input, condition: $condition) {
      id
      name
      pic
      headerpic
      headerlink
      shortDescription
      description
      more
      phone
      email
      tags
      hide
      priority
      staff
      related
      threadName
      thread
      hideThread
      gotoThread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      video
      slides
      downloadables
      actives {
        items {
          id
          userID
          partnerID
          createdAt
          updatedAt
          isFave
          mediaActions
          playbackActions
          visitActions
          linkActions
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const createPartnerActivity = /* GraphQL */ `
  mutation CreatePartnerActivity(
    $input: CreatePartnerActivityInput!
    $condition: ModelPartnerActivityConditionInput
  ) {
    createPartnerActivity(input: $input, condition: $condition) {
      id
      userID
      partnerID
      createdAt
      updatedAt
      isFave
      mediaActions
      playbackActions
      visitActions
      linkActions
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      partner {
        id
        externalID
        name
        pic
        hiddenPic
        shortDescription
        description
        more
        tags
        hide
        live
        isFeatured
        isKeynote
        preroll
        startdate
        starttime
        enddate
        endtime
        duration
        presenters
        presenterIDs
        facilitatorIDs
        permitted
        related
        thread
        threadName
        hideThread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        useSessionViewer
        video
        interactive
        content
        videochat
        videochatServer
        videoChatAllowNonPresenters
        collateral
        collateralName
        actives {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const updatePartnerActivity = /* GraphQL */ `
  mutation UpdatePartnerActivity(
    $input: UpdatePartnerActivityInput!
    $condition: ModelPartnerActivityConditionInput
  ) {
    updatePartnerActivity(input: $input, condition: $condition) {
      id
      userID
      partnerID
      createdAt
      updatedAt
      isFave
      mediaActions
      playbackActions
      visitActions
      linkActions
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      partner {
        id
        externalID
        name
        pic
        hiddenPic
        shortDescription
        description
        more
        tags
        hide
        live
        isFeatured
        isKeynote
        preroll
        startdate
        starttime
        enddate
        endtime
        duration
        presenters
        presenterIDs
        facilitatorIDs
        permitted
        related
        thread
        threadName
        hideThread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        useSessionViewer
        video
        interactive
        content
        videochat
        videochatServer
        videoChatAllowNonPresenters
        collateral
        collateralName
        actives {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const deletePartnerActivity = /* GraphQL */ `
  mutation DeletePartnerActivity(
    $input: DeletePartnerActivityInput!
    $condition: ModelPartnerActivityConditionInput
  ) {
    deletePartnerActivity(input: $input, condition: $condition) {
      id
      userID
      partnerID
      createdAt
      updatedAt
      isFave
      mediaActions
      playbackActions
      visitActions
      linkActions
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      partner {
        id
        externalID
        name
        pic
        hiddenPic
        shortDescription
        description
        more
        tags
        hide
        live
        isFeatured
        isKeynote
        preroll
        startdate
        starttime
        enddate
        endtime
        duration
        presenters
        presenterIDs
        facilitatorIDs
        permitted
        related
        thread
        threadName
        hideThread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        useSessionViewer
        video
        interactive
        content
        videochat
        videochatServer
        videoChatAllowNonPresenters
        collateral
        collateralName
        actives {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      name
      pic
      shortDescription
      description
      more
      tags
      hide
      related
      priority
      curation
      isPublic
      validMembers
      downloadables
      thread
      gotoThread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      members {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      admins {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      owner
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      name
      pic
      shortDescription
      description
      more
      tags
      hide
      related
      priority
      curation
      isPublic
      validMembers
      downloadables
      thread
      gotoThread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      members {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      admins {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      owner
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      name
      pic
      shortDescription
      description
      more
      tags
      hide
      related
      priority
      curation
      isPublic
      validMembers
      downloadables
      thread
      gotoThread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      members {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      admins {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      owner
    }
  }
`;
export const createGroupMember = /* GraphQL */ `
  mutation CreateGroupMember(
    $input: CreateGroupMemberInput!
    $condition: ModelGroupMemberConditionInput
  ) {
    createGroupMember(input: $input, condition: $condition) {
      id
      userID
      groupID
      createdAt
      isFave
      approved
      visitActions
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      group {
        id
        name
        pic
        shortDescription
        description
        more
        tags
        hide
        related
        priority
        curation
        isPublic
        validMembers
        downloadables
        thread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        members {
          nextToken
        }
        admins {
          nextToken
        }
        owner
      }
    }
  }
`;
export const updateGroupMember = /* GraphQL */ `
  mutation UpdateGroupMember(
    $input: UpdateGroupMemberInput!
    $condition: ModelGroupMemberConditionInput
  ) {
    updateGroupMember(input: $input, condition: $condition) {
      id
      userID
      groupID
      createdAt
      isFave
      approved
      visitActions
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      group {
        id
        name
        pic
        shortDescription
        description
        more
        tags
        hide
        related
        priority
        curation
        isPublic
        validMembers
        downloadables
        thread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        members {
          nextToken
        }
        admins {
          nextToken
        }
        owner
      }
    }
  }
`;
export const deleteGroupMember = /* GraphQL */ `
  mutation DeleteGroupMember(
    $input: DeleteGroupMemberInput!
    $condition: ModelGroupMemberConditionInput
  ) {
    deleteGroupMember(input: $input, condition: $condition) {
      id
      userID
      groupID
      createdAt
      isFave
      approved
      visitActions
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      group {
        id
        name
        pic
        shortDescription
        description
        more
        tags
        hide
        related
        priority
        curation
        isPublic
        validMembers
        downloadables
        thread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        members {
          nextToken
        }
        admins {
          nextToken
        }
        owner
      }
    }
  }
`;
export const createThreadMember = /* GraphQL */ `
  mutation CreateThreadMember(
    $input: CreateThreadMemberInput!
    $condition: ModelThreadMemberConditionInput
  ) {
    createThreadMember(input: $input, condition: $condition) {
      id
      userID
      threadID
      lastRead
      viewedCount
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      thread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
    }
  }
`;
export const updateThreadMember = /* GraphQL */ `
  mutation UpdateThreadMember(
    $input: UpdateThreadMemberInput!
    $condition: ModelThreadMemberConditionInput
  ) {
    updateThreadMember(input: $input, condition: $condition) {
      id
      userID
      threadID
      lastRead
      viewedCount
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      thread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
    }
  }
`;
export const deleteThreadMember = /* GraphQL */ `
  mutation DeleteThreadMember(
    $input: DeleteThreadMemberInput!
    $condition: ModelThreadMemberConditionInput
  ) {
    deleteThreadMember(input: $input, condition: $condition) {
      id
      userID
      threadID
      lastRead
      viewedCount
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      thread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
    }
  }
`;
export const createThread = /* GraphQL */ `
  mutation CreateThread(
    $input: CreateThreadInput!
    $condition: ModelThreadConditionInput
  ) {
    createThread(input: $input, condition: $condition) {
      id
      name
      pic
      isPrivate
      positiveCuration
      disallowVideo
      type
      updatedAt
      comments {
        items {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        nextToken
      }
      users {
        items {
          id
          userID
          threadID
          lastRead
          viewedCount
        }
        nextToken
      }
      owner
    }
  }
`;
export const updateThread = /* GraphQL */ `
  mutation UpdateThread(
    $input: UpdateThreadInput!
    $condition: ModelThreadConditionInput
  ) {
    updateThread(input: $input, condition: $condition) {
      id
      name
      pic
      isPrivate
      positiveCuration
      disallowVideo
      type
      updatedAt
      comments {
        items {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        nextToken
      }
      users {
        items {
          id
          userID
          threadID
          lastRead
          viewedCount
        }
        nextToken
      }
      owner
    }
  }
`;
export const deleteThread = /* GraphQL */ `
  mutation DeleteThread(
    $input: DeleteThreadInput!
    $condition: ModelThreadConditionInput
  ) {
    deleteThread(input: $input, condition: $condition) {
      id
      name
      pic
      isPrivate
      positiveCuration
      disallowVideo
      type
      updatedAt
      comments {
        items {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        nextToken
      }
      users {
        items {
          id
          userID
          threadID
          lastRead
          viewedCount
        }
        nextToken
      }
      owner
    }
  }
`;
export const createReaction = /* GraphQL */ `
  mutation CreateReaction(
    $input: CreateReactionInput!
    $condition: ModelReactionConditionInput
  ) {
    createReaction(input: $input, condition: $condition) {
      id
      userID
      commentID
      like
      emoji
      reported
      updatedAt
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      comment {
        id
        thread
        inThread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        createdAt
        curation
        reporterID
        userID
        reactions {
          nextToken
        }
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        content
        owner
      }
      owner
    }
  }
`;
export const updateReaction = /* GraphQL */ `
  mutation UpdateReaction(
    $input: UpdateReactionInput!
    $condition: ModelReactionConditionInput
  ) {
    updateReaction(input: $input, condition: $condition) {
      id
      userID
      commentID
      like
      emoji
      reported
      updatedAt
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      comment {
        id
        thread
        inThread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        createdAt
        curation
        reporterID
        userID
        reactions {
          nextToken
        }
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        content
        owner
      }
      owner
    }
  }
`;
export const deleteReaction = /* GraphQL */ `
  mutation DeleteReaction(
    $input: DeleteReactionInput!
    $condition: ModelReactionConditionInput
  ) {
    deleteReaction(input: $input, condition: $condition) {
      id
      userID
      commentID
      like
      emoji
      reported
      updatedAt
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      comment {
        id
        thread
        inThread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        createdAt
        curation
        reporterID
        userID
        reactions {
          nextToken
        }
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        content
        owner
      }
      owner
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      thread
      inThread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      createdAt
      curation
      reporterID
      userID
      reactions {
        items {
          id
          userID
          commentID
          like
          emoji
          reported
          updatedAt
          owner
        }
        nextToken
      }
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      content
      owner
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      thread
      inThread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      createdAt
      curation
      reporterID
      userID
      reactions {
        items {
          id
          userID
          commentID
          like
          emoji
          reported
          updatedAt
          owner
        }
        nextToken
      }
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      content
      owner
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      thread
      inThread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      createdAt
      curation
      reporterID
      userID
      reactions {
        items {
          id
          userID
          commentID
          like
          emoji
          reported
          updatedAt
          owner
        }
        nextToken
      }
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      content
      owner
    }
  }
`;
