import React, { useState, useEffect } from "react";

import { Box, Button, DropButton, Text } from "grommet";

import Lottie from 'lottie-react-web';
import widgetAnim from '../assets/moreWidget_v02.json';

const MoreWidget = (props) => {
  const [isOpen, setIsOpen] = useState (false);
  const {size, choices} = props;

  useEffect( () => {


  }, []);

  const animOptions = { loop: false, autoplay: true, rendererSettings: { preserveAspectRatio: 'xMidYMid meet' },
    animationData: widgetAnim,
    };
  
  const listChoices = choices.map(item => (
      <Button key={item.label} plain onClick={() => {setIsOpen(false); item.onClick() }} label={item.label} />
  ));

  return (
    <DropButton plain onClick={() => { setIsOpen(!isOpen) }} open={isOpen}
          onClose={() => { setIsOpen(false) }}
          dropProps={{ align: { top: 'bottom', left: 'right' }, 
                    plain: true }}
          
          dropContent=<Box round='small' gap='small' pad='small' elevation='large' background='background-front' animation='slideDown'>{listChoices}</Box>
        >
          <Lottie options={animOptions} height={size} width={size} direction={isOpen ? 1 : -1} />
        </DropButton>
    
  )
};

export default MoreWidget;
