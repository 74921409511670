import React, { useState, useEffect } from 'react';

import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import { useRouteMatch, useHistory, useLocation } from "react-router-dom";

import { Box, Button, Grid, Heading, Menu, Text, TextInput, ResponsiveContext, Stack  } from 'grommet';
import { Chat, Edit } from 'grommet-icons';

import RoutedButton from './RoutedButton';
import MoreWidget from './MoreWidget';
import S3ImageBox from './S3ImageBox';
import { Activity, LVL } from '../Activity';

import GroupDetail from './VevGroupDetail';
import GroupEditor from './VevGroupEditor';
import EventParams from '../EventItems';
import PeopleList from './VevPeople';

import TagInput from './VevTagFilter';

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  
const GroupsList = (props) => {
    const [ groups, setGroups ] = useState([]);
    const [ favorites, setFavorites ] = useState({});
    const [ viewGroups, setViewGroups ] = useState([]);

    const [showPeople, setShowPeople] = useState(true);
    const [showGroups, setShowGroups] = useState(true);
    const [showFavesOnly, setShowFavesOnly] = useState(false);
    const [pageHeader, setPageHeader] = useState ("People & Groups");

    const [selectedTags, setSelectedTags] = useState([]);
    const [suggestions, setSuggestions] = useState (EventParams.Tags);
    const [filterString, setFilterString] = useState ("");
    const [nameFilterString, setNameFilterString] = useState("");

    const { canAdmin, userID, goToChatThread } = props;

    let { path, url } = useRouteMatch();
    let query = useQuery();
    let history = useHistory();

    const getGroupListAsync = async () => {
        try {
            const allGroups = await API.graphql(graphqlOperation(queries.listGroups, { limit: 1000 } ));
            const foundGroupList = allGroups.data.listGroups.items;
            //console.log (foundGroupList);
            setGroups (foundGroupList);

            // for each item in groups list, we must have a favorites item too

            // get group activity too, if it exists
            try {
                const activityQuery = await API.graphql(graphqlOperation(queries.listGroupMembershipsByUser, { userID:userID, limit: 1000 }  ));

                const foundActivity = activityQuery.data.listGroupMembershipsByUser.items;

                // we want to access fave or not like this: favorites[groupDetails.id]
                const currentFaves = foundGroupList.reduce((acc, cur) => 
                    ({ ...acc, [cur.id]: (isAFave (foundActivity, cur.id)) }), {});
                //console.log (currentFaves);
                setFavorites (currentFaves);
            } catch (err) {
                console.log ("Unable to get Activity: ", err);
            }
        } catch (err) {

        }
    }

    const isAFave = (actArray, id) => {
        let result = false;
        const matchActivity = actArray.find (act => act.groupID === id);
        if (matchActivity) {
            result = matchActivity.isFave; // null or undefined will be false?
        }
        return result;
    }

    const toggleFavorite = async (id) => {
        const newValue = !(favorites[id]);
        const newFaves = {
            ...favorites,
            [id]: newValue
        }
        setFavorites (newFaves);
        updateGroupFave (id, newValue);
    }

    const updateGroupFave = async (groupId, isFave) => {
        try {
            const activityQuery = await API.graphql(graphqlOperation(queries.listGroupMembershipsByUser, { userID:userID, limit: 1000 } ));

            const foundActivity = activityQuery.data.listGroupMembershipsByUser.items;
            if (foundActivity) {
                //console.log (foundActivity);
                const groupMatch = foundActivity.find (sess => sess.groupID === groupId);
            
                if (!groupMatch) {
                    const input = {isFave: isFave, userID: userID, groupID: groupId};
                    const updatedGroupActivity = await API.graphql(graphqlOperation(mutations.createGroupMember, {input: input}));
                    //console.log (updatedGroupMember);
                } else {
                    const input = {id: groupMatch.id, isFave: isFave};
                    const updatedGroupActivity = await API.graphql(graphqlOperation(mutations.updateGroupMember, {input: input}));
                    //console.log (updatedGroupActivity);
                }
            }
        } catch (err) {
          console.log ("Couldn't record group activity: " + err.message);
          console.log (err);
        }
      }

    const filterOnParams = (params) => {
        let newParams = new URLSearchParams(params);
        if (newParams.get("more") === 'AllPeople') {
            setShowPeople (true);
            setShowGroups (false);
            setShowFavesOnly (false);
            setPageHeader ("All People");
        } else if (newParams.get("more") === 'FavoritePeople') {
            setShowPeople (true);
            setShowGroups (false);
            setShowFavesOnly (true);
            setPageHeader ("Favorited People");
        } else if (newParams.get("more") === 'AllGroups') {
            setShowPeople (false);
            setShowGroups (true);
            setShowFavesOnly (false);
            setPageHeader ("Groups");
        }

        setFilterString (newParams.get('filter'));
        const querySuggestions = newParams.get('tags');
        setSelectedTags ((querySuggestions && querySuggestions.length > 3) ? querySuggestions.split(',') : []);
    };

    useEffect(() => {

        return history.listen((location) => {
            //console.log (location);
            filterOnParams (location.search);
            console.log(`You changed the page to: ${location.pathname}`)
        })
    
    },[history])

    useEffect( () => {
        //console.log ("Getting groups for: " + userID)
        getGroupListAsync ();
        filterOnParams (query);

    }, []);

    useEffect( () => {
        let groupsToSort = groups.filter(groupDetails => (canAdmin || !groupDetails.hide) && ( showFavesOnly === false || favorites[groupDetails.id] === true));
        
        if ((selectedTags && selectedTags.length > 0) || (filterString && filterString.length > 2)) {
            const taggedGroups = (selectedTags.length === 0) ? [...groupsToSort] :
            groupsToSort.filter (groupDetails => {
                    const tagArray = (groupDetails.tags === null) ? [] : groupDetails.tags.split(',');
                    return (tagArray.length === 0) ? false :
                    tagArray.some (s => selectedTags.indexOf(s) >= 0);
            });

            groupsToSort = [...taggedGroups];
        }
        
        // Now sort
        groupsToSort.sort (function(a,b){
            let result = (a.priority > b.priority ? -1 : (a.priority < b.priority ? 1 : 0));
            if (result !== 0) return result;
            result = (a.name < b.name ? -1 : (a.name > b.name ? 1 : 0));
            
            return result;
        })

        // edit the viewgroups
        setViewGroups (groupsToSort);

    }, [canAdmin, groups, favorites, showFavesOnly, selectedTags, filterString]);
    
    const onRemoveTag = tag => {
      const removeIndex = selectedTags.indexOf(tag);
      const newTags = [...selectedTags];
      if (removeIndex >= 0) {
        newTags.splice(removeIndex, 1);
      }
      setSelectedTags(newTags);
    };
  
    const onAddTag = tag => setSelectedTags([...selectedTags, tag]);
  
    const onFilterSuggestion = value => {
      setSuggestions(
        EventParams.Tags.filter(
          suggestion => suggestion.toLowerCase().indexOf(value.toLowerCase()) >= 0
        )
      );
      setFilterString (value); 
    }


    const listGroupBoxes = viewGroups.map(groupDetails => {
        const picUrl = groupDetails.pic; //encodeURI(groupDetails.pic);

        return (
            <Box 
                round="medium"
                elevation="medium"
                key={groupDetails.id}
                animation="slideUp"
                justify="start"
                align="start"
                direction="column"
                background="background-contrast"
            >
                <Stack height="small" fill='horizontal' anchor='top-right'>
                    <RoutedButton fill plain
                        path={`${url}/${encodeURIComponent(groupDetails.id)}`}
                        label=<S3ImageBox height="small" fill='horizontal' round={{size: "medium", corner: "top"}}
                                    src={picUrl} />
                    />
                    
                </Stack>

                <Box height='xxsmall' fill='horizontal' overflow='hidden' 
                    pad='small' wrap>
                        <RoutedButton plain path={`${url}/${encodeURIComponent(groupDetails.id)}`} >
                            <Text size='large' color='brand' truncate>{groupDetails.name}</Text>
                        </RoutedButton>

                </Box>
                <Box height='xsmall' overflow='hidden' justify='between'
                    pad={{ left: "small", right: "small", bottom:"medium"}}>
                    
                    <Text size="small">{groupDetails.shortDescription} </Text> 
                    
                </Box>
                <Box background="tab"
                    round={{ size:"medium", corner:'bottom' }} 
                    fill='horizontal' align='center' direction='row'
                    justify="between" pad={{ vertical: "xsmall", horizontal: 'small'}} >
                    <Button plain icon=<EventParams.Favorite on={favorites[groupDetails.id] ? 1 : 0} color='brand' /> onClick={() => {toggleFavorite (groupDetails.id)}} />
                    <Button plain icon=<Chat color='brand'/> onClick={() => 
                        {goToChatThread (groupDetails.thread); }} />
                    {canAdmin &&
                        <RoutedButton plain label=<Edit color='brand' />
                        path={`/groupEditor/${encodeURIComponent(groupDetails.id)}`} /> }

                </Box>

            </Box>
        )});



    const groupChoices = canAdmin ? 
    [
        { label: 'Overview', onClick: () => {setShowPeople (true); setShowGroups(true); setShowFavesOnly (false);} },
        { label: 'People', onClick: () => {setShowPeople (true); setShowGroups(false); setShowFavesOnly (false);} },
        { label: 'Groups', onClick: () => {setShowPeople (false); setShowGroups(true); setShowFavesOnly (false);} },
        { label: 'My Groups', onClick: () => {setShowPeople (false); setShowGroups(true); setShowFavesOnly (true);} },
        { label: 'New Group', disabled:(!canAdmin), onClick: () => {history.push ('groupEditor/new')} },
    ] : [
        { label: 'Overview', onClick: () => {setShowPeople (true); setShowGroups(true); setShowFavesOnly (false);} },
        { label: 'People', onClick: () => {setShowPeople (true); setShowGroups(false); setShowFavesOnly (false);} },
        { label: 'Groups', onClick: () => {setShowPeople (false); setShowGroups(true); setShowFavesOnly (false);} },
        { label: 'My Groups', onClick: () => {setShowPeople (false); setShowGroups(true); setShowFavesOnly (true);} },
    ]
    ;

    return (
        <ResponsiveContext.Consumer>
        {responsive => (
            <Box
            a11yTitle='Group List'
            fill
            direction='column'
            align='center'
            justify='between'
            pad={{ left: 'medium', right: 'small', vertical: 'xsmall' }}
            background={EventParams.GroupsBackdrop.length > 4 ? { image: EventParams.GroupsBackdrop } : undefined}
            {...props}
            >
                <Box fill='horizontal' align='center' direction='row' gap='small'>
                    <Heading color='brand' level='2'>{pageHeader}</Heading>
                    <MoreWidget size={32} choices={groupChoices} />
                </Box>
                
                <Box fill overflow='auto'>
                    <Box fill direction='row-responsive' gap='small'>
                        {showPeople &&
                        <Box overflow='hidden' background={showGroups ? 'background-contrast' : undefined} pad='xsmall'  direction='column'
                                height={responsive === 'small' ? '4000px' : undefined}
                                width={responsive === 'small' ? undefined : showGroups ? 'medium' : 'full'}
                                fill={responsive === 'small' ? 'horizontal' : undefined}

                                align='start'
                                elevation='medium' round='small'  gap='small' >
                            <Heading margin='xsmall' color='brand' level='3' >Attendees</Heading>
                            <Box background='white' round='large' direction="row" align="center"
                                    pad={{ horizontal: "xsmall" }}  >
                                <Box flex style={{ minWidth: "120px" }}>
                                <TextInput type="search" plain 
                                    placeholder="FILTER" value={nameFilterString} onChange={ ({ target: { value } }) => setNameFilterString(value) } />
                                </Box>
                            </Box>
                            <PeopleList userID={userID} filter={nameFilterString} mini={showGroups ? true : false} />
                        </Box> }
                        {showGroups &&
                        <Box fill='horizontal' align='start' direction='column' gap='small'
                                    margin={{ horizontal: "small" }}>
                            {/* <TagInput
                                placeholder="FILTER"
                                suggestions={suggestions}
                                value={selectedTags}
                                onRemove={onRemoveTag}
                                onAdd={onAddTag}
                                onChange={({ target: { value } }) => onFilterSuggestion(value)}
                            />*/}
                            <Box fill overflow='auto' justify='start'>
                                <Grid  margin={{ right: 'medium'}} 
                                    gap="medium" columns={responsive === 'small' ? '90vw' : "medium"} margin={{ right: "small" }} >
                                {listGroupBoxes}
                                </Grid>
                            </Box>
                        </Box> }
                    </Box>

                </Box>
            </Box>
        )}
        </ResponsiveContext.Consumer>
    
    );
}

export default GroupsList;
