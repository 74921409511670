import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import { useRouteMatch, useHistory, useLocation } from "react-router-dom";

import { Box, Button, Grid, Heading, Menu, Text, ResponsiveContext, Stack  } from 'grommet';
import { Alarm, CirclePlay, Edit } from 'grommet-icons';
import { Scrollbars } from 'react-custom-scrollbars';

import RoutedButton from './RoutedButton';
import MoreWidget from './MoreWidget';
import S3ImageBox from './S3ImageBox';
import { Activity, LVL, createCalendarLinkFromSession } from '../Activity';


import EventParams from '../EventItems';
import ScheduleList from './VevAgenda';

import TagInput from './VevTagFilter';

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  
const SessionsList = (props) => {
    const [ sessions, setSessions ] = useState([]);
    const [ favorites, setFavorites ] = useState({});
    const [ viewSessions, setViewSessions ] = useState([]);
    const [viewTimedSessions, setViewTimedSessions] = useState([]);
    const [viewUntimedSessions, setViewUntimedSessions] = useState([]);

    const [showSchedule, setShowSchedule] = useState(true);
    const [showFavesOnly, setShowFavesOnly] = useState(EventParams.SessionOverviewFavesOnly);
    const [pageHeader, setPageHeader] = useState ("Event Overview");
    const [agendaHeader, setAgendaHeader] = useState ("My Agenda");

    const [selectedTags, setSelectedTags] = useState([]);
    const [suggestions, setSuggestions] = useState (EventParams.Tags);
    const [filterString, setFilterString] = useState ("");
    const { canAdmin, userID, goToChatThread } = props;

    let { path, url } = useRouteMatch();
    let location = window.location.href;
    let query = useQuery();
    let history = useHistory();

    //console.log (location);

    function shorten (str, maxLen, separator = ' ') {
        if (str.length <= maxLen) return str;
        return str.substr(0, str.lastIndexOf(separator, maxLen)) + "...";
    }
        
    const getSessionListAsync = async () => {
        try {
            const allUsers = await API.graphql(graphqlOperation(queries.listSessions, { limit: 1000 } ));
            const foundSessionList = allUsers.data.listSessions.items;
            
            setSessions (foundSessionList);

            // for each item in sessions list, we must have a favorites item too

            // get session activity too, if it exists
            try {
                const activityQuery = await API.graphql(graphqlOperation(queries.listSessionActivityByUser, { userID:userID, limit: 1000 }  ));

                const foundActivity = activityQuery.data.listSessionActivityByUser.items;

                // we want to access fave or not like this: favorites[sessionDetails.id]
                const currentFaves = foundSessionList.reduce((acc, cur) => 
                    ({ ...acc, [cur.id]: (isAFave (foundActivity, cur.id)) }), {});
                //console.log (currentFaves);
                setFavorites (currentFaves);
            } catch (err) {
                console.log ("Unable to get Activity: ", err);
            }
        } catch (err) {

        }
    }

    const isAFave = (actArray, id) => {
        let result = false;
        const matchActivity = actArray.find (act => act.sessionID === id);
        if (matchActivity) {
            result = matchActivity.isFave; // null or undefined will be false?
        }
        return result;
    }

    const toggleFavorite = async (id) => {
        const newValue = !(favorites[id]);
        const newFaves = {
            ...favorites,
            [id]: newValue
        }
        setFavorites (newFaves);
        updateSessionFave (id, newValue);
    }

    const updateSessionFave = async (sessionId, isFave) => {
        try {
            const activityQuery = await API.graphql(graphqlOperation(queries.listSessionActivityByUser, { userID:userID, limit: 1000 } ));

            const foundActivity = activityQuery.data.listSessionActivityByUser.items;
            if (foundActivity) {
                //console.log (foundActivity);
                const sessionMatch = foundActivity.find (sess => sess.sessionID === sessionId);
            
                if (!sessionMatch) {
                    const input = {isFave: isFave, userID: userID, sessionID: sessionId};
                    const updatedSessionActivity = await API.graphql(graphqlOperation(mutations.createSessionActivity, {input: input}));
                    //console.log (updatedSessionActivity);
                } else {
                    const input = {id: sessionMatch.id, isFave: isFave};
                    const updatedSessionActivity = await API.graphql(graphqlOperation(mutations.updateSessionActivity, {input: input}));
                    //console.log (updatedSessionActivity);
                }
            }
        } catch (err) {
          console.log ("Couldn't record session activity: " + err.message);
          console.log (err);
        }
      }

      const filterOnParams = (params) => {
        let newParams = new URLSearchParams(params);
        if (newParams.get("tags") === 'Content') {
            setShowSchedule (false);
            setPageHeader ("Content");
            setAgendaHeader ("Agenda");
        } else if (newParams.get("tags") === 'Speakers') {
            setShowSchedule (false);
            setPageHeader ("Speakers");
        }

        setFilterString (newParams.get('filter'));
        const querySuggestions = newParams.get('tags');
        setSelectedTags ((querySuggestions && querySuggestions.length > 3) ? querySuggestions.split(',') : []);
    };

    useEffect(() => {

        return history.listen((location) => {
            filterOnParams (location.search);
            console.log(`You changed the page to: ${location.pathname}`)
        })
    
    },[history])

    useEffect( () => {
        //console.log ("Getting sessions for: " + userID)
        getSessionListAsync ();

        filterOnParams (query);
        if (query.get("more") === 'Favorites') {
            setShowFavesOnly (true);
            setShowSchedule (false);
        }

        if (query.get("tags") === 'Content') {
            setShowSchedule (false);
        }

        
    }, []);

    useEffect( () => {
        //console.log (filterString);
        //console.log ("Favorites only?", showFavesOnly);
        let sessionsToSort = sessions.filter(sessionDetails => 
                    (canAdmin || !sessionDetails.hide)
                    && ( showFavesOnly === false || favorites[sessionDetails.id] === true || sessionDetails.isKeynote) );
        
        if ((selectedTags && selectedTags.length > 0) || (filterString && filterString.length > 2)) {
            const taggedSessions = (selectedTags.length === 0) ? [...sessionsToSort] :
                sessionsToSort.filter (sessionDetails => {
                    const tagArray = (sessionDetails.tags === null) ? [] : sessionDetails.tags.split(',');
                    //console.log (sessionDetails)
                    //console.log (tagArray);
                    return (tagArray.length === 0) ? false :
                    tagArray.some (s => selectedTags.indexOf(s) >= 0);
            });

            sessionsToSort = [...taggedSessions];
        }
        
        // Now sort
        const today = moment().format ('YYYY-MM-DD');
        sessionsToSort.sort (function(a,b){
            // (x === y)? 0 : x? -1 : 1
            let result = (a.live === b.live ? 0 : (b.live ? 1 : -1));
            if (result !== 0) return result;    // abort if no match

            if (a.live) {   // it's both though
                // need to consider live plus no date as today
                const dateA = (a.startdate && a.startdate.length) ? a.startdate : today;
                const dateB = (b.startdate && b.startdate.length) ? b.startdate : today;
                result = (dateA < dateB ? -1 : (dateA > dateB ? 1 : 0));
                if (result !== 0) return result;    // abort if no match

                // need to account for different timezones!
                const timeA = moment (dateA + "T" + a.starttime);
                const timeB = moment (dateB + "T" + b.starttime);

                result = (timeA.isBefore (timeB) ? -1 : (timeB.isBefore (timeA) ? 1 : 0));
                if (result !== 0) return result;    // abort if no match
            }
            result = (a.name < b.name ? -1 : (a.name > b.name ? 1 : 0));
            return result;
        })

        // edit the viewsessions
        setViewSessions (sessionsToSort);
        //console.log (sessionsToSort);
        
        // already filtered hidden and favorite
        /*
        setViewUntimedSessions (!showSchedule ? sessionsToSort :
            sessionsToSort.filter (item => {
            return (!item.live);
        }));
        */ 
 
        setViewTimedSessions (sessionsToSort.filter (item => {
            return (item.live && (favorites[item.id] === true || item.isKeynote))
                //&& ((item.startdate && item.startdate.length) ? (item.startdate === today) : true)
                ;

        }));

       
    }, [canAdmin, sessions, favorites, showFavesOnly, showSchedule, selectedTags, filterString]);
    
    const onRemoveTag = tag => {
      const removeIndex = selectedTags.indexOf(tag);
      const newTags = [...selectedTags];
      if (removeIndex >= 0) {
        newTags.splice(removeIndex, 1);
      }
      setSelectedTags(newTags);
    };
  
    const onAddTag = tag => setSelectedTags([...selectedTags, tag]);
  
    const onFilterSuggestion = value => {
      setSuggestions(
        EventParams.Tags.filter(
          suggestion => suggestion.toLowerCase().indexOf(value.toLowerCase()) >= 0
        )
      );
      setFilterString (value); 
    }


    const listSessionBoxes = viewSessions.map(sessionDetails => {
        const picUrl = sessionDetails.pic; //encodeURI(sessionDetails.pic);
        const now = moment();
        const eventStart = (sessionDetails.startdate === null) ? moment (sessionDetails.starttime, "hh:mm:ssZ") :  moment (sessionDetails.startdate + "T" + sessionDetails.starttime);
        const eventTime = eventStart.format('LT, LL');
        
        const eventEnd = (sessionDetails.enddate === null) ? moment (sessionDetails.endtime, "hh:mm:ssZ") :  moment (sessionDetails.enddate + "T" + sessionDetails.endtime);
        const inProgress = now.isBetween (eventStart, eventEnd, undefined, '[]');

        return (
            <Box 
                round="medium"
                elevation="medium"
                key={sessionDetails.id}
                background="background-contrast"
                animation="slideUp"
                justify="start"
                align="start"
                direction="column"
            >
                <Stack height="small" fill='horizontal' anchor='top-right'>
                    <RoutedButton fill plain
                        path={`${url}/${encodeURIComponent(sessionDetails.id)}`}
                        label=<S3ImageBox height="small" fill='horizontal' round={{size: "medium", corner: "top"}}
                                    src={picUrl} />
                    />
                    {sessionDetails.live && inProgress && 
                        <Box pad='xsmall'>
                            <img src={EventParams.LiveIndicator} height="42" alt="LIVE" />
                        </Box> }
                </Stack>

                <Box height='74px' fill='horizontal' overflow='hidden'
                        pad='small' >
                    <RoutedButton plain path={`${url}/${encodeURIComponent(sessionDetails.id)}`} >
                        <Text size='large' truncate={sessionDetails.live} color='brand'>{shorten(sessionDetails.name, 40)}</Text>
                    </RoutedButton>

                    {sessionDetails.live && 
                        <Text size='medium' weight='bold' color='brand-contrast'>{eventTime}</Text>}
                </Box>
                <Box height='xsmall' overflow='hidden' justify='between'
                        pad={{ left: "small", right: "small", bottom:"medium"}}>
                    
                    <Text size="small">{sessionDetails.shortDescription} </Text> 
                    
                </Box>
                <Box background="tab"
                    round={{ size:"medium", corner:'bottom' }} 
                    fill='horizontal' align='center' direction='row'
                    justify="between" pad={{ vertical: "xsmall", horizontal: 'small'}} >
                    <Button plain icon=<EventParams.Favorite on={favorites[sessionDetails.id] ? 1 : 0} color={EventParams.FavoriteColor} /> onClick={() => {toggleFavorite (sessionDetails.id)}} />
                    {canAdmin && <RoutedButton plain
                        path={`${url}/${encodeURIComponent(sessionDetails.id)}?play=true`} size='small' icon={<CirclePlay  color='brand' />} /> }
                    <Menu plain disabled={sessionDetails.live !== true}
                      items={[
                          {label: 'Google', onClick: () => {createCalendarLinkFromSession (sessionDetails, 'google', `${location}/${encodeURIComponent(sessionDetails.id)}`); }},
                          {label: 'Office365', onClick: () => {createCalendarLinkFromSession (sessionDetails, 'office365', `${location}/${encodeURIComponent(sessionDetails.id)}`); }},
                          {label: 'Others (.ics)', onClick: () => {createCalendarLinkFromSession (sessionDetails, 'generic', `${location}/${encodeURIComponent(sessionDetails.id)}`); }},

                      ]} >
                      <Alarm color='brand' disabled={sessionDetails.live !== true} /> 
                    </Menu>
                    {canAdmin &&
                        <RoutedButton plain label=<Edit color='brand' />
                        path={`/sessionEditor/${encodeURIComponent(sessionDetails.id)}`} /> }

                </Box>

            </Box>
        )});



    const sessionChoices = canAdmin ? 
    [
        { label: 'Overview', onClick: () => {setShowFavesOnly (EventParams.SessionOverviewFavesOnly); setShowSchedule (true); } },
        { label: 'All Sessions', onClick: () => {setShowFavesOnly (false); setShowSchedule (false);} },
        { label: 'My Sessions', onClick: () => {setShowFavesOnly (true); setShowSchedule (false);} },
        { label: 'New Session', disabled:(!canAdmin), onClick: () => {history.push ('sessionEditor/new')} },
    ] : [
        { label: 'Overview', onClick: () => {setShowFavesOnly (EventParams.SessionOverviewFavesOnly); setShowSchedule (true);} },
        { label: 'All Sessions', onClick: () => {setShowFavesOnly (false); setShowSchedule (false);} },
        { label: 'My Sessions', onClick: () => {setShowFavesOnly (true); setShowSchedule (false);} },
    ]
    ;

    return (
            <ResponsiveContext.Consumer>
            {responsive => ( 

            <Box
                a11yTitle='Session List'
                fill
                tag='footer'
                direction='column'
                align='center'
                justify='between'
                pad={{ left: 'medium', right: 'small', vertical: 'xsmall' }}
        background={EventParams.SessionsBackdrop.length > 4 ? { image: EventParams.SessionsBackdrop } : undefined}
                {...props} >
                    <Box fill='horizontal' align='center' direction='row' gap='small'>
                <Heading color='brand' level='2'>{pageHeader}</Heading>
                        <MoreWidget size={32} choices={sessionChoices} />
                    </Box>
                    
                    <Box fill  overflow='auto'>
                        <Box fill direction='row-responsive' gap='small'>
                            {showSchedule &&
                            <Box 
                                    height={responsive === 'small' ? '4200px' : undefined}
                                    width={responsive === 'small' ? undefined : 'medium'}
                                    fill={responsive === 'small' ? 'horizontal' : undefined}
                                    overflow='hidden' background='background-front' pad='xsmall'  direction='column'
                                    elevation='medium' round='small'  gap='small' >
                        <Heading margin='xsmall' color='brand' level='3' >My Agenda</Heading>
                        <ScheduleList sessions={viewTimedSessions} />
                            </Box> }
                            <Box fill='horizontal' align='start' direction='column' gap='medium'
                                    margin={{ horizontal: "small" }}>
                                
                        {canAdmin && <TagInput
                                    placeholder="FILTER"
                                    suggestions={suggestions}
                                    value={selectedTags}
                                    onRemove={onRemoveTag}
                                    onAdd={onAddTag}
                                    onChange={({ target: { value } }) => onFilterSuggestion(value)}
                        /> }
                        <Box fill overflow='auto' justify='start'>
                            <Grid gap="medium" columns={responsive === 'small' ? '90vw' : "medium"} margin={{ right: "small" }} >
                                    {listSessionBoxes}
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                </Box>
                )}
                </ResponsiveContext.Consumer>
    );
}

export default SessionsList;