import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Box, Heading, Text  } from 'grommet';
import { useLocation } from "react-router-dom";
import Jitsi from 'react-jitsi';
import { Redirect, Link, Prompt } from "react-router-dom";
import { Spinning } from 'grommet-controls';

import EventParams from '../EventItems';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const chatConfig = {
  startWithAudioMuted: false,
  startWithVideoMuted: true
};

const interfaceConfig = {
  filmStripOnly: false,

  GENERATE_ROOMNAMES_ON_WELCOME_PAGE: false,
  DISPLAY_WELCOME_PAGE_CONTENT: false,
  DISABLE_VIDEO_BACKGROUND: true,
  DISABLE_FOCUS_INDICATOR: true,
  DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
  FILM_STRIP_MAX_HEIGHT: 128,
  RECENT_LIST_ENABLED: false,
  DISABLE_TRANSCRIPTION_SUBTITLES: true,
  MAXIMUM_ZOOMING_COEFFICIENT: 1,
  DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
  SHOW_BRAND_WATERMARK: false,
  MOBILE_APP_PROMO: false,
  BRAND_WATERMARK_LINK: EventParams.OrganizerURL,
  SHOW_JITSI_WATERMARK: false,
  LIVE_STREAMING_HELP_LINK: EventParams.HelpLink,
  SHOW_WATERMARK_FOR_GUESTS: false,
  HIDE_INVITE_MORE_HEADER: true,
  DEFAULT_LOGO_URL: EventParams.EventLogoSmall,
  DEFAULT_REMOTE_DISPLAY_NAME: 'Fellow Attendee',
  DEFAULT_WELCOME_PAGE_LOGO_URL: EventParams.EventLogo,
  APP_NAME: 'Vevomo Meet',
  NATIVE_APP_NAME: EventParams.Name,
  PROVIDER_NAME: EventParams.Organizer,
  HIDE_KICK_BUTTON_FOR_GUESTS: true,
  TOOLBAR_BUTTONS: [
    'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
    'fodeviceselection', 'hangup', 'recording', 'settings', 'raisehand',
    'videoquality', 'filmstrip', 'stats', 'shortcuts',
    'tileview', 'help', 'mute-everyone',
    'e2ee'
],
VIDEO_LAYOUT_FIT: 'both'
};

const VideoChat = (props) => {
  let query = useQuery();
  let chatID = query.get("chat");
  let pw = query.get("pw"); 
  
  const { userName, chatServer, chatRoom, isModerator } = props;
  const server = (chatServer && chatServer.length > 4) ? chatServer : EventParams.VideoConferenceServer;
  const room = ((chatRoom && chatRoom.length > 4) ? chatRoom : chatID).replace(/\W/g, '');

  const [onCall, setOnCall] = useState(true)
  const [jitsiAPI, setJitsiAPI] = useState ({});
  const [callToken, setCallToken] = useState(null);
  
  class loader extends React.Component {
    render () {
      return <div><br/><strong>Loading meeting... If your VPN is on, please turn it off then refresh your browser!</strong></div>
    }
  };
  
  const getVideoChatToken = async (domain, chatID, isModerator) => {
    const apiName = 'getjitsitoken';
    const path = '/chataccess';

    const attendeeClaims = {
      domain: domain,
      room: room
    };
    const moderatorClaims = {
      domain: domain,
      room: room,
      moderator: true
    };

    const myInit = {
      'responseType': 'text',
      headers: {
        
      }, 
      queryStringParameters: isModerator ? moderatorClaims : attendeeClaims
    };
    // https://docs.amplify.aws/lib/restapi/fetch/q/platform/js#accessing-query-parameters--body-in-lambda-proxy-function

    console.log ("Preparing credential request", myInit);
    const result = await API.get (apiName, path, myInit);
    setCallToken (result);
    console.log ("Getting credentials to access video chat: ", result);
  };

  useEffect( () => {
    // did we get to this link by a refresh or similar? Is it active?
    // get the token first...
    // EventParams.VideoConferenceServer
    getVideoChatToken (server, chatID, isModerator);
    //setCallToken ('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJqaXRzaSIsImlzcyI6IkJEQzNCIiwic3ViIjoieC52ZXZvbW8uY29tIiwicm9vbSI6IioifQ.nRFYtRKUCg4kDHgdY5Q2l1nWJhqcEk8NcpgqBVq5awU')
    console.log ("Starting chat " + chatID);
  }, []);
  
  const endListener = () => {
    console.log ("Call ended!");
    setOnCall (false);
  };

  const handleAPI = (JitsiMeetAPI) => {
    setJitsiAPI (JitsiMeetAPI);
    //JitsiMeetAPI.executeCommand('toggleVideo')
    //JitsiMeetAPI.addEventListener('participantLeft', listener);
    JitsiMeetAPI.addEventListener('videoConferenceLeft', endListener);
  }
  
  // domain={EventParams.VideoConferenceServer}
  // roomName={chatID}roomName={123456}jwt={callToken}

  return (
  
  <Box
    a11yTitle='Video Chat'
    fill
    pad='none'>
      {!onCall && <Redirect to='/' />}
      {/*Loader is passed to jitsi />*/}
      {callToken ?
      <>
      <Jitsi containerStyle={{ width: '100%', height: '100%' }}
          onAPILoad={handleAPI}
          roomName={room}
          loadingComponent={loader}
          jwt={server === 'meet.vevomo.com' ? undefined : callToken}
          displayName={userName}  
          domain={server}
          config={chatConfig}
          interfaceConfig={interfaceConfig}
          />
      <Prompt
        when={onCall}
        message='You are currently in a Video Chat, are you sure you want to leave?'
      /> </>
      :
      <>
        <Text>Authenticating to join video chat</Text> 
        <Spinning kind="three-bounce" />
        </>}
  </Box>

) };

// domain="v.vevomo.com"
export default VideoChat;