import React, { useContext, useState, useEffect, useRef } from 'react';

import { 
  Anchor,
  Avatar,
  Box,
  Button,
  CheckBox,
  Collapsible,
  DropButton,
  FormField,
  Image as GrImage,
  Layer,
  Menu,
  Nav,
  ResponsiveContext,
  Select,
  Text,
  TextInput,
  Grommet, 
} from 'grommet';
import {
  Chat,
  FormClose,
  Group,
  Menu as Burger,
  Rss,
  Search,
  ShareOption,
  VirtualMachine,
  User,
  UnorderedList,
} from 'grommet-icons';

import { RiFullscreenLine, RiFullscreenExitLine } from 'react-icons/ri';

// Event Specific 
import EventTheme from './eventTheme';
import EventParams from './EventItems';


import Amplify, { Auth, Storage } from 'aws-amplify';
import Aws_exports from './aws-exports';

import '@aws-amplify/ui/dist/style.css';
import 'typeface-roboto';

// General UI
import { BrowserRouter as Router, Switch, Redirect, Route, useHistory } from "react-router-dom";
import { ModalSwitch, ModalRoute, ModalLink } from 'react-router-modal-gallery';

import { useMeasure, useInterval } from 'react-use';

import randomColor from 'randomcolor';
import { SliderPicker, CirclePicker  } from 'react-color';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import 'react-toastify/dist/ReactToastify.min.css';
import { Dropzone } from "./components/Dropzone";
import usePrevious from './utils/use-previous'
import localstorage from 'local-storage';
import FullScreen from 'react-full-screen';

// Our components
import { Activity, LVL } from './Activity';
import RoutedButton from './components/RoutedButton';
import AppBar from './components/VevAppBar';
import AppFooter from './components/VevFooterBar';
import ChatView from './components/VevChatView';
import Dashboard from './components/VevDashboard';
import Welcome from './components/VevWelcomePage';
import VideoChat from './components/VevVideoChat';
import AdminAnalytics from './components/VevAnalyticsSample';

import SessionsList from './components/VevSessionsList';
import Session from './components/VevSession';
import SessionEditor from './components/VevSessionEditor';

import PartnersList from './components/VevPartnersList';
import PartnerDetail from './components/VevPartnerDetail';
import PartnerEditor from './components/VevPartnerEditor';

import GroupsList from './components/VevGroupsList';
import GroupDetail from './components/VevGroupDetail';
import GroupEditor from './components/VevGroupEditor';

import PersonDetail from './components/VevPeopleDetail';

import SpacesList from './components/VevSpacesList';
import PCScene from './components/PC_Scene';

//import useStateWithCallback from 'use-state-with-callback';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';
import * as subscriptions from './graphql/subscriptions';
import { store } from 'emoji-mart';

import MessageWidget from './components/MessageWidget';

Amplify.configure(Aws_exports);
Auth.configure ({ authenticationFlowType:'USER_PASSWORD_AUTH'}); // or 'USER_SRP_AUTH'
Storage.configure({ track: false, level: "private" }); // enables pinpoint of S3, sets default permissions

const App = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [haveNotifications, setHaveNotifications] = useState(false);
  const [ listOpen, setListOpen ] = useState (false);
  const [ sessionOverlay, setSessionOverlay ] = useState("");
  const prevOverlay = usePrevious (sessionOverlay);

  const [ref, {x, y, width, height}] = useMeasure();
  const [fullscreen, setFullscreen] = useState(false);

  const [searchTerm, setSearchTerm ] = useState("");
  const [searchSuggestions, setSearchSuggestions ] = useState([]);
  
  // Oddly we want to assume yes to avoid a trip to welcome
  const [isAuth, setIsAuth] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(true);
  const [canAdmin, setCanAdmin] = useState(false);

  const [firstVisit, setFirstVisit] = useState(false);
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [userID, setUserID] = useState("");
  const [userList, setUserList] = useState([]);

  const [userColor, setUserColor] = useState(randomColor ());
  const [skinColor, setSkinColor] = useState('#e6bc98');
  const [hairStyle, setHairStyle] = useState('Hair1');
  const [hairColor, setHairColor] = useState('#3b2219');
  
  const [showUserInList, setShowUserInList] = useState(true);
  const [allowInvitations, setAllowInvitations] = useState(true);
  const [isEventStaff, setIsEventStaff] = useState(false);
  const [confirmVPNOff, setConfirmVPNOff] = useState (false);

  const [editName, setEditName] = useState("");
  const [company, setCompany] = useState("");
  const [title, setTitle] = useState("");
  const [homePage, setHomePage] = useState("/");
  const [attendeeType, setAttendeeType] = useState("Attendee");

  const [userPos, setUserPos] = useState("{}");
  const [userAnim, setUserAnim] = useState("idle");
  const [userVersion, setUserVersion] = useState (0);
  const [userInitialSet, setUserInitialSet]  = useState(false);
  const [isDark, setIsDark] = useState(localstorage.get ("darkMode"));
  const [avatarUrl, updateAvatarUrl] = useState('')
  const [editProfile, setEditProfile] = useState(false);

  const [sceneActive, setSceneActive] = useState(0);
  const [space, setSpace] = useState ("");
  const prevSpace = usePrevious(space);
  const [lastSpace, setLastSpace] = useState("");
  const [POI, setPOI] = useState ("");
  const threeDRef = useRef();
  const [spaceListener, setSpaceListener] = useState(null);

  const [ thread, setThread ] = useState ("");

  // Unused because it returns a URL event when no file
  const  fetchUserAvatar = async () => {
    try {
      const key = 'avatars/' + userID;
      const imageData = await Storage.get (key, 
        {
          level: 'public',
        }
      )
      // this should always be a valid URL, which is not great
      //console.log (imageData);
      var img = new Image();
      img.onload = () => {
        updateAvatarUrl (imageData);
      } 
      img.onerror = () => {
        // generated an unavoidable console error too
        updateAvatarUrl ('');
      }
      img.src = imageData;
      
    } catch(err) {
      console.log('error: ', err)
      updateAvatarUrl ('');
    }
  }

  const quickUploadAvatar = async (files) => {
    //console.log (files);
    const file = files[0];

    if (!file) {
      return;   // cancelled
    }

    const contentType = file.type;
    const filenamekey = 'avatars/' + userID;

    console.log ("Starting file upload " + file + ", to " + filenamekey);
    const result = await Storage.put(filenamekey, file, 
      {
        level: 'public',
        metadata: { userid: userID }
      }
    );
    // If we succeeded, post to 
    toast.success ("Uploaded new profile!")

    Activity.log (LVL.user, "UploadedAvatar", "user", false);
    fetchUserAvatar ();
  }

  const addToGroupIfNeeded = async ( groupID, fave ) => {
    try {
      const activityQuery = await API.graphql(graphqlOperation(queries.listGroupMembershipsByUser, { userID:userID, limit: 1000 } ));

        const foundActivity = activityQuery.data.listGroupMembershipsByUser.items;
        //console.log (foundActivity);

        if (foundActivity) {
          const groupMatch = foundActivity.find (memb => memb.groupID === groupID);

          if (!groupMatch) {
            const input = {userID: userID, groupID: groupID, isFave: fave};
            const updatedGroupActivity = await API.graphql(graphqlOperation(mutations.createGroupMember, {input: input}));
            if (EventParams.JoinToasts) {
              toast.success ("Welcome to the " + updatedGroupActivity.data.createGroupMember.group.name + " Group!");
            }
          }
        }

    } catch (err) {
      console.log ("Couldn't join group: " + err.message);
    }
  }

  const addToSessionIfNeeded = async ( sessionID, fave ) => {
    try {
      const activityQuery = await API.graphql(graphqlOperation(queries.listSessionActivityByUser, { userID:userID, limit: 1000 } ));
      const foundActivity = activityQuery.data.listSessionActivityByUser.items;

      if (foundActivity) {
        const sessionMatch = foundActivity.find (sesh => sesh.sessionID === sessionID);

        if (!sessionMatch) {
          const input = {userID: userID, sessionID: sessionID, isFave: fave};
          const updatedSessionActivity = await API.graphql(graphqlOperation(mutations.createSessionActivity, {input: input}));
          if (EventParams.JoinToasts) {
            toast.success ("Enjoy the " + updatedSessionActivity.data.createSessionActivity.session.name + " Session!");
          }
        }
      }
    } catch (err) {
      console.log ("Couldn't join session: " + err.message);
    }
  }


  const addToThreadIfNeeded = async (joinThreadID) => {
    const currentThread = await API.graphql(graphqlOperation(queries.getThread, {id:joinThreadID} ));
    //console.log (currentThread);
    const threadQuery = await API.graphql(graphqlOperation(queries.listThreadMembershipByUser, { userID:userID, limit: 1000 }  ));
    const foundThreadMemberships = threadQuery.data.listThreadMembershipByUser.items;

    const targetThreadMembers = foundThreadMemberships.filter (obj => obj.threadID === joinThreadID);
    //console.log (targetThreadMembers);
    if (targetThreadMembers.length) {
      // Fix a bug I made originally - clean out multiples
      targetThreadMembers.slice(1).forEach(element => {
        API.graphql (graphqlOperation(mutations.deleteThreadMember, {input: {id: element.id}} ));
      });
    } else if (currentThread.data.getThread && currentThread.data.getThread.name) {
      console.log ("User is joining: " + currentThread.data.getThread.name);

      //console.log ("connecting");
      //console.log (joinThreadID);
      //console.log (userID);
      const memberDetails = { userID: userID, threadID: joinThreadID };
      const threadAdd = await API.graphql (graphqlOperation(mutations.createThreadMember, {input: memberDetails} ));
      if (threadAdd.data.createThreadMember) {
        Activity.log (LVL.user, "Added user to thread " + currentThread.data.getThread.name, "user", true);
        if (EventParams.JoinToasts) {
        	toast.success ("You have joined " + currentThread.data.getThread.name + ", enjoy the conversation!");
        }
      } else {
        Activity.log (LVL.netErr, "Failed to add user to thread " + joinThreadID, "user", true);
      }
    }
  }

  const goToChatThread = (threadID, forceOpen) => {
    setThread (threadID);
    setListOpen (false);
    if (forceOpen) {  
      console.log ("Opening thread! " + threadID);
      setShowSidebar(true);
    }
  }

  const inviteToChat = async (myID, userToTalkTo, theirName, myName) => {
    if (myID === userToTalkTo) return; 
    
    // first see if we already have a chat with this one person directly, if so, open it\
    // actually, the chat id will be me.you so maybe it will automagic
    const chatThreadID = userID + '.' + userToTalkTo;
    const memberDetails = {
      userID: myID,
      threadID: chatThreadID
    }
    // we'll favorite the chat so you can get there
    try {   
      const chatThread = await API.graphql(graphqlOperation(queries.getThread, {id:chatThreadID} ));
      if (chatThread && chatThread.data.getThread === null) {  // Let's make a thread!          
          const input = {
            id: chatThreadID,
            name: (myName + " to " + theirName + " chat").replace(/[^a-z0-9\s]/gi, ''),
            isPrivate: true,
          };
          const newThread = await API.graphql(graphqlOperation(mutations.createThread, { input }));

          // join it
          const threadAdd = await API.graphql (graphqlOperation(mutations.createThreadMember, {input: memberDetails} ));

      } else {
        const memberQuery = await API.graphql(graphqlOperation(queries.listThreadMembershipByUser, { userID:userID, threadID: {eq: chatThreadID} } ));
        if (memberQuery.data.listThreadMembershipByUser.items.length === 0) {
          const threadAdd = await API.graphql (graphqlOperation(mutations.createThreadMember, {input: memberDetails} ));
        }
      }
      toast.success ("Invited " + theirName + " to a conversation. Leave your first comment for them.");
      goToChatThread (chatThreadID, true);
      // TODO - got to chat

    } catch (err) {
      console.log ("Unable to get or create chat thread for you! Err: ", err);
      toast.warn ("Could not invite " + theirName + " to a conversation, sorry:  " + err.message);
    }
    
    const newNote = {
        userID: userToTalkTo,
        originUser: myID,
        body: myName + " has invited you to a conversation",
        type: 'Join Chat Thread',
        link: '#' + chatThreadID
    }
    const newNotification = await API.graphql(graphqlOperation(mutations.createNotification, {input: newNote} ));
    console.log ("Notification: ", newNotification);
};

  const leaveThreadIfNeeded = async (joinThreadID) => {
    const threadQuery = await API.graphql(graphqlOperation(queries.listThreadMembershipByUser, { userID:userID, limit: 1000 }  ));
    const foundThreadMemberships = threadQuery.data.listThreadMembershipByUser.items;

    const targetThreadMember = foundThreadMemberships.find (obj => obj.threadID === joinThreadID);
    //console.log (targetThreadMember);
    if (targetThreadMember) {
      toast.success ("You have left " + targetThreadMember.thread.name + ", come back when you want to rejoin!");
      await API.graphql (graphqlOperation(mutations.deleteThreadMember, {input: {id: targetThreadMember.id}} ));
      Activity.log (LVL.user, "Removed user from thread " + joinThreadID, "user", true);
    }
  }
  
  useEffect( () => {
    if (!space || space.length === 0) {
      // We're actually leaving the space, leave the thread too
      if (prevSpace && prevSpace.length > 0) {
        exitedSpace (prevSpace);
      }
      if (spaceListener !== null) {
        spaceListener.unsubscribe ();
        setSpaceListener (null);
      }
    } else {
      enteredSpace (space);

      // Let's get who else is here
      //console.log (userList);
      userList.forEach (item => {
        if (item.space === space && item.id !== userID && item.pos && item.pos.length > 2) {
          updateUserInSpace (item);
        }
      })

      // then listen for updates
      const onUpdateUserInSpaceListener = API.graphql (graphqlOperation(subscriptions.onUpdateUserInSpace, { space:space })).subscribe ({
        next: message => {
          const changedUser = message.value.data.onUpdateUserInSpace;
          
          // note that we don't have any valid state!

          // is it me? Then ignore it - you can't change me, and we don't want to cycle the data!
          if (changedUser && changedUser.id !== userID && changedUser.pos && changedUser.pos.length > 2) {   
            // TODO - did we get this out of order?
            updateUserInSpace (changedUser);            
  
          }  
        }
      } );
      setSpaceListener (onUpdateUserInSpaceListener);
    }

  }, [space]);

  const updateUserInSpace = async (user) => {
    //console.log ("Sending to Engine")
    threeDRef.current.sendUserDataToEngine (user);
  }

  useEffect( () => {
    if (POI.startsWith('!')) {
      // We're actually leaving the POI, leave the thread too
      exitedPOI (POI.slice(1));
    } else if (POI.length) {
      enteredPOI (POI);
    }
  }, [POI]);

  useEffect( () => {
    if (isDark) {
      localstorage.set ("darkMode");
    } else {
      localstorage.remove ("darkMode");
    }
  }, [isDark]);

  const enteredSpace = async (data) => {
    let threadToJoinID = data; // presumption that we can override
    // get the space info, so we can also join thread
    try {
      const newQuery = await API.graphql(graphqlOperation(queries.getSpace, {id:data}));
      if (newQuery.data.getSpace) {
        const spaceData = newQuery.data.getSpace;
        threadToJoinID = spaceData.thread;
        toast.info ("Welcome to " + spaceData.name); 
        // Let's see what the thread is (and add it if needed)
        //console.log (spaceData);
        if (spaceData.mainthread === null) {  // Let's make a thread!          
          const input = {
            id: threadToJoinID,
            name: (spaceData.name + " chat").replace(/[^a-z0-9\s]/gi, ''),
            pic: spaceData.pic,
            isPrivate: false
          };
          try {
            const newThread = await API.graphql(graphqlOperation(mutations.createThread, { input }));
          } catch (err) {
            console.log ("Unable to get create chat thread for space! Err: ", err);
          }
        }
        await addToThreadIfNeeded (threadToJoinID);
        
        // make this the current thread too
        setThread (threadToJoinID);
      } else {
        Activity.log (LVL.netErr, "Space not found in event database!", "3D", true);
      }
    } catch (err) {
      console.log ("Unable to get space details! Err: ", err);
      toast.warn ("Could not access the data for this space - check your network!");
    }
  }

  const exitedSpace = async (data) => {
    toast.info ("Thanks for visiting " + data + ", goodbye!"); 
    // get the space info, so we can also leave thread

    if (thread === data)
      setThread ('all-attendees');
  }

  const enteredPOI = async (data) => {
    let threadToJoinID = data; // presumption that we can override
    // get the space info, so we can also join thread
    try {
      const newQuery = await API.graphql(graphqlOperation(queries.getPoi, {id:data}));
      //console.log (newQuery);
      if (newQuery.data.getPOI) {
        const POI = newQuery.data.getPOI; // no idea why it's all caps here - GraphQL weirdness?
        let threadToJoinID = POI.thread;
        toast.info ("Welcome to " + POI.name);
        if (POI.mainthread === null) {  // Let's make a thread!          
          const input = { id: threadToJoinID, name: POI.name + " chat", pic: POI.pic, isPrivate: false, isFave: true, };
          try {
            const newThread = await API.graphql(graphqlOperation(mutations.createThread, { input }));
          } catch (err) {
            console.log ("Unable to get create chat thread for POI! Err: ", err);
          }
        }
        await addToThreadIfNeeded (threadToJoinID);
        
        // make this the current thread too
        setThread (threadToJoinID);
      } else {
        Activity.log (LVL.netErr, "POI not found in event database!", "3D", true);
      }
    } catch (err) {
      console.log ("Unable to get POI details! Err: ", err);
      //toast.warn ("Could not access the data for this POI - check your network!");
    }

  }

  const exitedPOI = async (data) => {
    let fallbackThread = "all-attendees";
    try {
      const newQuery = await API.graphql(graphqlOperation(queries.getPoi, {id:data}));
      //console.log (newQuery);
      if (newQuery.data.getPOI) {
        const POI = newQuery.data.getPOI; // no idea why it's all caps here - GraphQL weirdness?
        toast.info ("You left " + POI.name); 
        fallbackThread = POI.space;
      }
    } catch (err) {
      console.log ("Unable to get POI details when leaving. This is probably not a problem. Err: ", err);
    }

    if (thread === data)
      setThread (fallbackThread);
  }

  const signOut = async () => {
    try {
      await Auth.signOut ({ global: true });
      Activity.log (LVL.user, "Logged out", "user", true);
      toast.warn("You have signed out! Please sign in again when you wish to use the system.");
    } catch (err) {
      Activity.log (LVL.user, "Error signing out", "user", true);
      toast.error("Problem signing out, error: " + err.message);
    }
    setShowSidebar(false);
    setIsAuth (false);
    setCanAdmin (false);
  }
  
  const resendCode = async (userName) => {
    console.log ("Sending request for new code ");
    try {  
      await Auth.resendSignUp (userName);  
      toast.warn("New code request sent - please check your email for a confirmation code!");     
    } catch (err) {
        console.log ('Error requesting new code: ', err);
        toast.error("Confirmation code request was not sent, error: " + err.message);
        return err.message;
    }
    
    return "Requested"; // do not change - used as switch
  };

  const requestPasswordReset = async (signinName) => {
    console.log ("Requesting password Reset");
    try {
        await Auth.forgotPassword (signinName);   
        toast.warn("Password reset request sent - please check your email for a confirmation code! ");  
    } catch (err) {
        console.log ('Error requesting password reset: ', err);
        toast.error("Password reset request was not sent, error: " + err.message);
        return err.message;
    }
    
    return "Reset"; // do not change - used as switch
  };

  const confirmUser = async (signinName, confirmCode) => {
    console.log ("Confirming " + signinName);
    try {
        const user = await Auth.confirmSignUp(signinName, confirmCode);
        
        // Setting user id will trigger the side-effects
        if (user !== undefined) {
          console.log (user);
          Activity.log (LVL.user, "Confirmed user " + user.username, "signin", true);
          toast.success("Your membership has been confirmed!");
        }
        
    } catch (err) {
        console.log ('Error confirming user: ', err);
        toast.error("Could not confirm your membership, error: ", err.message);
        return err.message;
    }
    
    return "Confirmed!"; // do not change - used as switch
  };

  const submitPasswordChange = async (signinName, confirmCode, signinPW) => {
    console.log ("Confirming " + signinName);
    try {
        const user = await Auth.forgotPasswordSubmit (signinName, confirmCode, signinPW);
        
        // Setting user id will trigger the side-effects
        if (user !== undefined) {
          //console.log (user);
          Activity.log (LVL.user, "Reset password", "signin", true);
          toast.warn("Password successfully reset!");
        }
        
    } catch (err) {
        console.log ('Error resetting password: ', err);
        toast.error("Password reset failed, error: " + err.message);
        return err.message;
    }
    
    return "Confirmed!"; // do not change - used as switch
  };

  const signIn = async (signinName, signinPW, newPW, userAttributes) => {
    let attributes = {};
    //console.log (signinName);
    try {
        const user = await Auth.signIn(signinName, signinPW);
        //console.log ("Sign in result: ", user)
        // Setting user id will trigger the side-effects
        if (user !== undefined) {

          // Special case, happens with admin sign-ups
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            if (newPW && newPW.length >= 8) {
              const result = await Auth.completeNewPassword (user, newPW, userAttributes);
              Activity.log (LVL.user, "Signed in user and changed password " + user.username, "signin", true);

              // Let's use the attributes too
              setFirstName (userAttributes.given_name);
              setSecondName (userAttributes.family_name);
              setUserName (userAttributes.name);
              setEditName (userAttributes.name);

              console.log (result)
              setUserID (user.id);
            } else {
              Activity.log (LVL.user, "User needs to reset password " + user.username, "signin", true);
              console.log ('Need to set: ',  user.challengeParam.requiredAttributes);
              attributes = user.challengeParam.userAttributes;
              toast.warn ("Please change your password and check your name");
              return {err: 'NEW_PASSWORD_REQUIRED', attributes: attributes};
            }
          } else {
          Activity.log (LVL.user, "Signed in user " + user.username, "signin", true);
          // DOon't send toast - the actual log-in will trigger it
          //toast.success("You signed in.");
          setUserID (user.id);
        }
        }
        
    } catch (err) {
        console.log ('Error signing in: ', err);
        if (err.code === 'UserNotConfirmedException') {
          toast.warn("Please enter the confirmation code we sent you, or request a new one!");

        } else {
          toast.error("Sign-in failed, error: " + err.message);
        }
        // TODO - better toasts!
        
        return {err: err.message};
    }
    
    return {err: "Signed In"}; // do not change - used as switch
  };

  // Not used when flow has admin creation only
  const register = async (signinName, signinPW, nickname, emailVal) => {
    // Pre-validated in theory
    let needConfirm = false;
    try {
      const result = await Auth.signUp ({
        username: signinName, 
        password: signinPW,
        attributes: {
          email: emailVal,
          name: nickname
        }
      });
      
      //console.log (result);
      const user = result.user;
      needConfirm = !result.userConfirmed;

      if (needConfirm) {
        toast.warn("Please check your email: " + emailVal + " for a confirmation code!");
      }

      // Setting user id will trigger the side-effects
      if (!needConfirm && user !== undefined) {
        Activity.log (LVL.user, "Signed in user " + user.username, "startup", true);
        setUserID (user.id);
      }
      
    } catch (err) {
        console.log ('Error registering: ', err);
    }
    
    return needConfirm;
  };

  const addToSuggestions = (inList) => {
    // complex looking, but, map the newlist to names only, filter that by the previous list
    setSearchSuggestions ((prevList => [ ...prevList, ...(inList.map (item => item.name).filter (item => prevList.indexOf (item) < 0)) ]));
  };

  // Use the URL to decide on which group to filter too (LIVEDEMO)
  
  useEffect( () => {
    // for user updates only
    const updateUser = async () => {
      if (!userInitialSet || !isAuth) return;

      // If conflict resolution were enabled, we'd need to send _version with current version #
      const input = {
        id: userID,
        color: userColor,
        skincolor: skinColor,
        hairstyle: hairStyle,
        haircolor: hairColor,
        pos: userPos,

        // new options
        name: userName,
        firstName: firstName,
        secondName: secondName,
        showInList: showUserInList,
        allowInvitations: allowInvitations,
        isEventStaff: isEventStaff,
      };
      //console.log (input);
      if (space &&  space.length > 0) {
        input.space = space;
      }
      try {
        const tweakedUser = await API.graphql(graphqlOperation(mutations.updateUser, { input }));
        //console.log (tweakedUser);
        setUserVersion (tweakedUser.data.updateUser._version);
        //console.log ("Updated user");
        //console.log (tweakedUser.data.updateUser);

      } catch (err) {
        console.log ("Unable to set User: ", err);
        //console.log (input);
      }
    }
    
    console.log ("User parameters changed, updating!");
    updateUser ();
    setEditName (userName);

    // Dependencies - need anything we want to trigger on *and* anything we need to use
  }, [userName, firstName, secondName, space, userColor, skinColor, hairStyle, hairColor, userPos, userAnim, setUserVersion]);

  const updateProfile = async () => {
    const input = {
      id: userID,

      // new options
      name: editName,
      title: title,
      company: company,
      homePage: homePage,
      showInList: showUserInList,
      allowInvitations: allowInvitations,
      isEventStaff: isEventStaff,
    };
    try {
      const tweakedUser = await API.graphql(graphqlOperation(mutations.updateUser, { input }));
      setUserVersion (tweakedUser.data.updateUser._version);
    } catch (err) {
      console.log ("Unable to set User: ", err);
      }

    setUserName (editName);
  };
        
  useInterval( () => {
    // Check/refresh token
    Auth.currentSession()
      .then(data => console.log("Refreshed token: ", data))
      .catch(err => console.log("Error refreshing token: ", err));

  },
  600000
  );
          
  useEffect( () => {
    // main run once effect

    const getUserListAsync = async () => {
      try {
        const allUsers = await API.graphql(graphqlOperation(queries.listUsers, { limit: 1000}));
        const foundUserList = allUsers.data.listUsers.items;
        setUserList (foundUserList);
        addToSuggestions (foundUserList);
        //console.log (foundUserList);
        return foundUserList; 
      } catch (err) {
        console.log ("Cannot get list of users! ", err);
        return null;
      }
    }

    const getGroupListAsync = async () => {
      try {
        const allUsers = await API.graphql(graphqlOperation(queries.listGroups, { limit: 1000 } ));
        const foundGroupList = allUsers.data.listGroups.items;
        //console.log ("Groups");
        //console.log (foundGroupList);
        addToSuggestions (foundGroupList);
        return foundGroupList;
      } catch (err) {
        console.log ("Unable to get groups: ", err);
      }
    }

    const getCookies = async () => {
      
      const apiName = 'livedemocookieaccess';
      const path = '/access';
      const myInit = {
        headers: {
          
        }, 
      };
  
      const result = await API.get (apiName, path, myInit);
      console.log ("Getting credentials to access video: ", result);
    };

    const addNewUserToCognitoGroup = async (userName) => {
      const apiName = 'spacesenvJoinGroup';
      const path = '/joingroup';
      const myInit = {
        //'responseType': 'text',
        headers: {

        },
        body: {
          userPoolId: Aws_exports.aws_user_pools_id,
          userName: userName
        },
        "response": {}
      };

      const result = await API.post (apiName, path, myInit);
      console.log ("", result);
    };

    const getUserAsync = async () => {
      try {
        let user = await Auth.currentAuthenticatedUser();

        if (user !== undefined) {
          console.log ("Found authenticated user", user);
          setIsAuth (true);

          const { attributes } = user;

          const userGroups = user.signInUserSession.accessToken.payload["cognito:groups"];

          if (userGroups === undefined) {
            // Oh boy! We won't have permissions to do anything
            addNewUserToCognitoGroup (user.username);
            //toast.error("There is a serious problem with your authentication (no group found) - please contact us via the help menu below immediately!");
            toast.warn ("Welcome new user! We had to do some quick housekeeping - please sign in again!");
            signOut ();
            setTimeout(() => { window.location.reload() }, 1500);
            return {};

          } else if (userGroups.includes('staff') || userGroups.includes('admin') || userGroups.includes('editors') ) {
            setCanAdmin (true);
          }
      
          // Grab from attributes, but we'll override in a moment
          console.log ("Set Initial parameters");
          setUserName (attributes.name);

          // attributes may change by framework
          setFirstName (attributes.given_name);
          setSecondName (attributes.family_name);

          setUserID (attributes.sub);
      
          // will set other attributes from database
          return { name: attributes.name, id: attributes.sub, email: user.username};
        }
      } catch (err) {
        toast.error("Not signed in - please sign in to join the event!");
      }
      // either error in auth or bad user
      setIsAuth (false);
      // This could be very normal - 
      console.log ("User not signed in or authorized yet!")
      return {};

    }
    
    const getMigrateData = async (emailAddress) => {
      const apiName = 'spacesenvUserData';
      const path = '/userdata';
      const myInit = {
        headers: {},
        body: {
          userPoolId: Aws_exports.aws_user_pools_id,
          emailAddress: emailAddress
        },
        "response": {}
      };
      try {
        const result = await API.post (apiName, path, myInit);
        console.log ("Migration: ", result);
        return result;
      } catch (err) {
        console.log ("Failed to get migration data: ", err.message);
      }
    };

    const checkAndAddUser = async (userParams) => {
        try {
          const myUser = await API.graphql(graphqlOperation(queries.getUser, {id:userParams.id}));
          if (myUser.data.getUser) {
          console.log ("User " + userParams.name + " found in database...");
            const storedUserData = myUser.data.getUser;
            //console.log (storedUserData);
            Activity.log (LVL.user, userParams.name + " found in event database", "startup", true);
            
            // override user name for current displayname
            setUserName (storedUserData.name);

            setUserColor (storedUserData.color);
            if (storedUserData.skincolor)
              setSkinColor (storedUserData.skincolor);
            if (storedUserData.hairstyle)
              setHairStyle (storedUserData.hairstyle);
            if (storedUserData.haircolor)
              setHairColor (storedUserData.haircolor);
            setUserPos (storedUserData.pos);
            setUserVersion (storedUserData._version);
            setSceneActive (storedUserData.updatedAt);

            setFirstName (storedUserData.firstName ? storedUserData.firstName: storedUserData.name);
            setSecondName (storedUserData.secondName ? storedUserData.secondName: "");

            setTitle (storedUserData.title ? storedUserData.title : "");
            setCompany (storedUserData.company ? storedUserData.company : "");
            setHomePage (storedUserData.homePage ? storedUserData.homePage : "/");
            setAttendeeType (storedUserData.type ? storedUserData.type : "Attendee");

            setShowUserInList (storedUserData.showInList);
            setAllowInvitations (storedUserData.allowInvitations);
            setIsEventStaff (storedUserData.isEventStaff);

            Activity.setEventStaff (storedUserData.isEventStaff);

            const eventData = {
              userID: storedUserData.id,
              userName: storedUserData.name,
              title: storedUserData.title,
              company: storedUserData.company,
              type: storedUserData.type ? storedUserData.type : "Attendee",
              action: "signin",
            }
            Activity.track ("users", eventData);

            // we can't set space - have to enter, but we can mark where we were
            setLastSpace (storedUserData.space);
            
            const threadToBeIn = (storedUserData.currentThread) ? storedUserData.currentThread : "all-attendees";
            setThread (threadToBeIn);

            

            // TODO get all groups
            if (storedUserData.groups.length) {
              const groupList = storedUserData.groups.reduce ((total, item) => total + item.name + ", ")
              Activity.log (LVL.user, userParams.name + " is in these groups: " + groupList, "startup", true);
          
              //console.log ("Found groups!");
              // Look in migration now!
              //console.log (myUser.data.getUser.groups);
            }
          }
          else
          {
            Activity.log (LVL.netErr, "User not found in event database!", "startup", true);
        setFirstVisit (true);

          addNewUserToCognitoGroup (userParams.email);

        // Add myself as a user
        //console.log (userParams);
        Activity.log (LVL.user, "Trying to create user in event database for " + userParams.name, "startup", true);
        const input = {
          id: userParams.id,
          color: hairColor,
          hairstyle: hairStyle,
          skincolor: skinColor,
          haircolor: hairColor,
          animation: "idle",

          // stuff we hopefully had
          name: userParams.name ? userParams.name : 'Attendee', // lambda bug
          title: title,
          company: company,
          homePage: homePage,
          type: attendeeType,

          showInList: showUserInList,
          allowInvitations: allowInvitations,
          isEventStaff: isEventStaff,
        };

        Activity.setEventStaff (isEventStaff);
        
        const newUser = await API.graphql(graphqlOperation(mutations.createUser, { input }));
        const storedUser = newUser.data.createUser;
        if (storedUser) {
          Activity.log (LVL.user, "Created user in event database", "startup", true);
        }

        setUserInitialSet (true);
        return true; // first visit!
      }
      } catch (err) {
        console.log ("Unable to get User details!");
      }
      // leave animation at idle...
      setUserAnim ("idle");

      setUserInitialSet (true);
      return false;
    }

    const migrateUser = async (userName) => {
      // CAUTION! This may take a while and if it fails we're hung!
      console.log ("Getting migration data for: ", userName.email);
      const userMigrateData = await getMigrateData (userName.email);
      //console.log (userMigrateData);
      //console.log (userMigrateData.data);

      if (userMigrateData && userMigrateData.data && userMigrateData.data) {
        const foundData = userMigrateData.data;
        // Received data on this user - let's add them to groups and fave the right session
        toast.success ("Found your registration information, updating your preferences...");

        //console.log (foundData);

        // Hopefully we have Groups AttendeeType Specialty Title Organization
        const type = foundData.AttendeeType;
        let foundHomePage = "/";

        // Migrate into groups
        const groups = foundData.Groups.split(';');

        let foundShowInList = showUserInList;
        let foundAllowInvitations = allowInvitations;

        // keynotes
        if (type === 'Staff' || type === 'Vevomo') {
          setIsEventStaff (true);
          Activity.setEventStaff (true);
          foundShowInList = false;
          foundAllowInvitations = true;
          
        } else if (type === 'VIP' || type === 'Guest') {
          setIsEventStaff (false);
          Activity.setEventStaff (false);
          foundShowInList = false;
          foundAllowInvitations = false;
        }
        
        const eventData = {
          userID: userName.id,
          userName: userName.name,
          title: foundData.Title,
          company: foundData.Organization,
          type: type,
          action: "signin",
        }
        Activity.track ("users", eventData);

        // most everyone gets most everything this time!
        //addToSessionIfNeeded ("cda15c8c-9e6f-4b3b-bbb9-fc8b938bb372", true);

        if (type !== 'Exhibitor') {
          //addToSessionIfNeeded ("20eab8da-1233-4c6d-93ba-aa875b976397", true);
        }

        // breakouts
        if (groups.length === 0) {

    }

        groups.forEach (group => {
          console.log ("Join group: " + group);
          if (group.startsWith ("Neuro")) {
            
          } else if (group.startsWith ("Rheum")) {
            
          } else if (group.startsWith ("Intraf")) {
            
          }
        });



        // set title and org
        if (foundData.DisplayName) {
          setUserName (foundData.DisplayName);
    }

        setAttendeeType (type);
      setCompany (foundData.Organization);
      setTitle (foundData.Title);
      setHomePage (foundHomePage);
      setShowUserInList (foundShowInList);
      setAllowInvitations (foundAllowInvitations);

      const input = {
          id: userID,
          name: foundData.DisplayName,
          title: foundData.Title,
        company: foundData.Organization,
        type: type,
        homePage: foundHomePage,
        showInList: foundShowInList,
        allowInvitations: foundAllowInvitations,
        };
        try {
          const tweakedUser = await API.graphql(graphqlOperation(mutations.updateUser, { input }));
          setUserVersion (tweakedUser.data.updateUser._version);
        } catch (err) {
          console.log ("Unable to set User: ", err);
        }

      }
    }

    const putUserInGroups = async (user, groups) => {
      // for each group, see if user is in it
      //const checkGroups = groups.map

      // Always:
      addToGroupIfNeeded ("all-attendees", true);
      addToGroupIfNeeded ("event-concierge", true);

      addToThreadIfNeeded ("all-attendees");
      addToThreadIfNeeded ("event-concierge");
    }

    const startupUser = async () => {
      const user = await getUserAsync();
    // this will set userID which will re-trigger the useEffect process... so let's check
      
      if (userID === undefined || userID.length < 4) return;

      Activity.log (LVL.user, "User logged in as : " + user.name, "startup", true);
      toast.success("Successfully logged in!");

      getCookies ();

      const groups = await getGroupListAsync ();
      const allUsers = await getUserListAsync ();
      const isFirstVisit = await checkAndAddUser (user);

      if (isFirstVisit) {
        toast ("This is your message center - we'll keep you informed! Put your mouse pointer over a message to pause it.")
        migrateUser (user);
      };

      await putUserInGroups (user, groups);
      
      setTimeout (() => goToChatThread ('all-attendees', true), 2000);

      // Now let's listen for notifications
      const onNotificationListener = API.graphql (graphqlOperation(subscriptions.newNotification, { userID:userID })).subscribe ({
        next: message => {
          const notification = message.value.data.newNotification;
          if (notification && notification.type === "Join Chat Thread") {
            toast.success ("You've been invited to a conversation");
          }
          //console.log ("Notification for me: ", notification);
          setHaveNotifications (true);
        }
      } );

      if (canAdmin) {
        const onAdminNotificationListener = API.graphql (graphqlOperation(subscriptions.newNotification, { userID:'ADMIN' })).subscribe ({
          next: message => {
            const notification = message.value.data.newNotification;
            //console.log ("Notification for admins: ", notification);
            setHaveNotifications (true);
          }
        } );
      }

      //setUserInitialSet (true);

      // grab some images if we can
      fetchUserAvatar ();  // will set display
    }

    startupUser ();
    // DEBUG - print groups

    return () => {
      
    };

  }, [userID]);

  // old background
  // background={{ color: "lightgrey", image: "url(/app_bgnd.png)" }}
  return (
    <FullScreen onChange={isFull => setFullscreen(isFull)}>
      <ToastContainer limit={3} hideProgressBar autoClose={4000} newestOnTop position={toast.POSITION.BOTTOM_CENTER} className="myToast" style={{ width: "500px", borderRadius: '16px' }}/>
      <Grommet theme={EventTheme} themeMode={isDark ? 'dark' : 'light'} full background="background-back" >
        {/*This fullscreen box allows appbar and bottom bar to center up and fill*/}
        <Box align='center' direction='column' justify='stretch' fill>
          {/* AppBar is blank if not signed in*/
            (isAuth) ? (    
            <AppBar> 
              <Nav direction='row' gap='small' align='center'>
                <input type="file" id="quickSelectAvatar" hidden onChange={ (evt) => quickUploadAvatar(evt.target.files) } />
                <Menu 
                  dropProps={{ align: { top: "bottom", left: "left" }, elevation: "large" }}
                  dropBackground={{ color: "neutral-1", opacity: "weak" }}
                  items={[
                    { label: <Box alignSelf="center">Edit my profile</Box>,
                          onClick: () => { setEditProfile (!editProfile); }, },
                    { label: <Box alignSelf="center">Upload profile picture</Box>,
                          onClick: () => {document.getElementById('quickSelectAvatar').click();}, },
                    { label: <Box alignSelf="center">{ isDark ? 'Switch to light theme' : 'Switch to dark theme'}</Box>,
                          onClick: () => { setIsDark(!isDark) }, },
                    { label: <Box alignSelf="center">Sign out</Box>,
                          onClick: () => { signOut(); }, }
                  ]} >

                  {(avatarUrl && avatarUrl.length > 0) ? <Avatar size='medium' src={avatarUrl} border={{ size:"small", color:{userColor} }}/> : <User />}      
                </Menu>
                <Text size='medium' weight="bold" >{userName}</Text>
              </Nav>
              
              {editProfile && 
                <Layer position='left' animation='slide' modal={false} onClickOutside={() => setEditProfile(false)}>
                  <Box width='medium' pad='small' background='background-contrast' round='medium' elevation='large' gap='small'>
                    <Box direction='row' justify='between'>
                      <Text size='medium' weight='bold' color='brand' >Edit My Profile</Text>
                      <Box direction='row' align='center' gap='small'>
                          <Button label='Cancel' size='small' onClick={() => {setEditProfile(false)}} />
                          <Button label='Done' size='small' onClick={() => {updateProfile(); setEditProfile(false);}} />
                      </Box>
                    </Box>
                    <FormField label="Name" name="name" required>
                      <TextInput name="name" value={editName} onChange={(event) => setEditName(event.target.value)} />
                      <Text size='small'>this is the name people will see when you chat</Text>
                    </FormField>
                    <FormField label="Title" name="title" required>
                      <TextInput name="title" value={title} onChange={(event) => setTitle(event.target.value)} />
                    </FormField>
                    <FormField label="Company" name="company" required>
                      <TextInput name="company" value={company} onChange={(event) => setCompany(event.target.value)} />
                    </FormField>
                    <FormField label="Privacy" name="showInList" >
                      <CheckBox  checked={showUserInList} label="appear in the user list" onChange={(event) => setShowUserInList(event.target.checked)} />
                      <Text size='small'>so colleagues can find me in the list and friend me</Text>
                      <CheckBox  checked={allowInvitations} label="allow people to invite me to chat" onChange={(event) => setAllowInvitations(event.target.checked)} />
                    </FormField>                
                    {canAdmin && <FormField label="Staff" name="isEventStaff" >
                      <CheckBox  checked={isEventStaff} label="I am event staff (hide in analytics)" onChange={(event) => setIsEventStaff(event.target.checked)} />
                    </FormField>}

                    {EventParams.HasAvatars ?
                    <>
                      <FormField label="My Chat Color (+ avatar shirt)">
                        <SliderPicker  color={userColor}  onChange={newColor => setUserColor (newColor.hex) }/>
                      </FormField>
                      <FormField label="Avatar Skin Color">
                        <CirclePicker circleSize={32} color={skinColor} onChange={newColor => setSkinColor (newColor.hex) }
                          colors={['#ffd6c5', '#ffe2c9', '#ffcba3', '#d8905f', '#88513a', '#e7c1b2', '#e7cbb5', '#e8b894', '#c28155', '#7b4934', '#e4bdad', '#e6c8b0', '#e7b38d', '#be794a', '#733e26']} />
                      </FormField>
                      <FormField label="Avatar Hair Style">
                        <Select value={hairStyle} onChange={({ option }) => setHairStyle(option)} 
                          options={['Hair1', 'Hair2', 'Hair3', 'Hair4', 'Hair5', 'Hair6', 'Hair7', 'Hair8']} />
                      </FormField>
                      <FormField label="Avatar Hair Color">
                        <SliderPicker  color={hairColor} onChange={newColor => setHairColor (newColor.hex) }/>
                      </FormField> 
                    </>
                    : 
                    <FormField label="My Highlight Color">
                      <SliderPicker  color={userColor} onChange={newColor => setUserColor (newColor.hex) }/>
                    </FormField>
                    }
                  </Box>
              </Layer>}

              <ResponsiveContext.Consumer>
                {responsive =>
                  responsive === "small" ? (
                  <Menu icon=<Burger /> dropProps={{ align: { top: "bottom", left: "left" } }}
                    items={EventParams.Has3D ? [
                      { icon: (<RoutedButton plain path='/' label={EventParams.DashboardPageName} icon={<Rss/>} />) },
                      { icon: (<RoutedButton plain path='/sessions' label={EventParams.SessionsPageName} icon={<UnorderedList/>} />) },
                      { icon: (<RoutedButton plain path='/spaces' label={EventParams.SpacesPageName} icon={<VirtualMachine/>} />) },
                      { icon: (<RoutedButton plain path='/partners' label={EventParams.PartnersPageName} icon={<ShareOption/>} />) },
                      { icon: (<RoutedButton plain path='/groups' label={EventParams.GroupsPageName} icon={<Group/>} />) }
                      ] : [
                      { icon: (<RoutedButton plain path='/' label={EventParams.DashboardPageName} icon={<Rss/>} />) },
                      { icon: (<RoutedButton plain path='/sessions' label={EventParams.SessionsPageName} icon={<UnorderedList/>} />) },
                      { icon: (<RoutedButton plain path='/partners' label={EventParams.PartnersPageName} icon={<ShareOption/>} />) },
                      { icon: (<RoutedButton plain path='/groups' label={EventParams.GroupsPageName} icon={<Group/>} />) }
                    ]}
                  />
                  ) : (
                    <Nav direction="row">
                      <RoutedButton plain path='/' label={EventParams.DashboardPageName} icon={<Rss/>} />
                      <RoutedButton plain path='/sessions' label={EventParams.SessionsPageName} icon={<UnorderedList/>} />
                      {EventParams.Has3D &&
                      <RoutedButton plain path='/spaces' label={EventParams.SpacesPageName} icon={<VirtualMachine/>} /> }
                      {EventParams.HasExhibits &&
                        <RoutedButton plain path='/partners' label={EventParams.PartnersPageName} icon={<ShareOption/>} />}

                      {EventParams.HasPeopleGroups && 
                        <RoutedButton plain path='/groups' label={EventParams.GroupsPageName} icon={<Group/>} />}
                    </Nav>
                  )
                }
              </ResponsiveContext.Consumer>

              {/*<Box direction='row'>
                <ResponsiveContext.Consumer>
                  {responsive =>
                    responsive === "small" ? (
                      <Search />
                    ) : (
                      <Box background='white' round='large'>
                        <TextInput plain color='brand' size='small'
                          placeholder="SEARCH" 
                          value={searchTerm}
                          onChange={ event => setSearchTerm (event.target.value) }
                          onSelect={ event => setSearchTerm (event.suggestion) }
                          suggestions={searchSuggestions} />
                      </Box>
                    )}
                  </ResponsiveContext.Consumer>
              </Box>*/}
              
              <Box direction='row' gap='small'>
                <Button plain size='small'
                  label=<MessageWidget size={28} indicator={haveNotifications} interval={4.0}/>
                  onClick={() => setShowSidebar(!showSidebar)} />
                  
              </Box>
          </AppBar>
            ) : ( <AppBar><Redirect to='/welcome' /></AppBar> ) }

            
          {/* This box fills the whole space to hold side bar and (sort of) the 3D view*/}
          
          <Box fill style={{ zIndex: '0' }}
            direction='row' overflow={{ horizontal: 'hidden' }}>
                
            <Box flex align='center' justify='start' style={{ zIndex: '0'}}>
              {EventParams.DisplayVPNWarning && !confirmVPNOff &&
                <Box fill='horizontal' background='status-critical' align='center'>
                    <Box width='large'>
                    <Button onClick={() => setConfirmVPNOff (true)} label="Click here to confirm that you have switched off your VPN - or - if you do not have or use a VPN" />
                    </Box>
                </Box>
              }
              <ModalSwitch
                renderModal={({ open, redirectToBack }) => (
                  open && (<Layer margin='large' position='center' modal animation='fadeIn' onClickOutside={() => redirectToBack()}  onEsc={() => redirectToBack()}>
                    <ModalRoute 
                      defaultParentPath='/sessions'
                      path='/sessions/:id' >
                        <Session canAdmin={canAdmin} userID={userID} userName={userName} setThread={setThread} setShowSidebar={setShowSidebar} setListOpen={setListOpen} />
                      </ModalRoute>
                    <ModalRoute 
                      defaultParentPath='/partners'
                      path='/partners/:partnerId'>
                        <PartnerDetail canAdmin={canAdmin}  userID={userID} userName={userName} setThread={setThread} setShowSidebar={setShowSidebar} setListOpen={setListOpen}/>
                    </ModalRoute>
                    <ModalRoute 
                      defaultParentPath='/groups'
                      path='/groups/:groupId'>
                      <GroupDetail canAdmin={canAdmin} userID={userID} userName={userName} setThread={setThread} setShowSidebar={setShowSidebar} setListOpen={setListOpen}/>
                    </ModalRoute>
                    <ModalRoute 
                      defaultParentPath='/people'
                      path='/people/:personId'>
                      <PersonDetail canAdmin={canAdmin} userID={userID} userName={userName} goToChatThread={goToChatThread} inviteToChat={inviteToChat}/>
                    </ModalRoute>
  
                  </Layer>
                  )
                )}
              >
                    

                  <Route exact path='/'>
                    <Dashboard canAdmin={canAdmin} userID={userID} />
                  </Route>    
                
                  <Route path='/welcome'>
                    {(isAuth) ?  <Redirect to="/" /> : 
                    <Welcome isAuth={isAuth} setIsAuth={setIsAuth} signIn={signIn} register={register}
                      confirmUser={confirmUser} resendCode={resendCode}
                      requestPasswordReset={requestPasswordReset} submitPasswordChange={submitPasswordChange} /> }
                  </Route>
                  {userInitialSet && <>
                    <Route path='/admin'>
                      <AdminAnalytics canAdmin={canAdmin}  userID={userID} />
                    </Route>
                    <Route exact path="/sessions">
                      <SessionsList canAdmin={canAdmin} userID={userID} userName={userName} goToChatThread={goToChatThread} />
                    </Route>
                    <Route path="/sessionEditor/:sessionId">
                      <SessionEditor canAdmin={canAdmin} userID={userID} userName={userName} goToChatThread={goToChatThread} />
                    </Route>
                    <Route path="/sessions/:sessionId">
                      <Session canAdmin={canAdmin} userID={userID} userName={userName} goToChatThread={goToChatThread} />
                    </Route>

                    <Route path="/meet">
                      <VideoChat userName={userName}/>
                    </Route>

                    <Route exact path="/partners">
                      <PartnersList canAdmin={canAdmin}  userID={userID} userName={userName} goToChatThread={goToChatThread} />
                    </Route>
                    <Route path='/partners/:partnerId'>
                        <PartnerDetail canAdmin={canAdmin}  userID={userID} userName={userName} goToChatThread={goToChatThread}/>
                    </Route>
                    <Route path='/partnerEditor/:partnerId'>
                        <PartnerEditor canAdmin={canAdmin}  userID={userID} userName={userName} goToChatThread={goToChatThread}/>
                    </Route>

                    <Route exact path="/groups">
                      <GroupsList canAdmin={canAdmin}  userID={userID} userName={userName} goToChatThread={goToChatThread} />
                    </Route>
                    <Route path={'/groupEditor/:groupId'}>
                      <GroupEditor />
                    </Route>
                    <Route path={'/groups/:groupId'}>
                      <GroupDetail canAdmin={canAdmin} userID={userID} userName={userName} goToChatThread={goToChatThread}/>
                    </Route>   
                                       

                    <Route path='/peopleEditor/:personId'>
                      <PersonDetail canAdmin={canAdmin} userID={userID} userName={userName} goToChatThread={goToChatThread}/>
                    </Route>

                    <Route exact path="/spaces">
                      <SpacesList canAdmin={canAdmin} setFullscreen={setFullscreen} setUserAnim={setUserAnim}
                        userPos={userPos} setUserPos={setUserPos} sceneActive={sceneActive} setSceneActive={setSceneActive}
                        lastSpace={lastSpace} setSpace={setSpace} setPOI={setPOI} threeDRef={threeDRef} 
                        userName={userName} userID={userID} goToChatThread={goToChatThread} inviteToChat={inviteToChat}
                        userColor={userColor} skinColor={skinColor} hairStyle={hairStyle} hairColor={hairColor}
                        isAuth={isAuth} />
                    </Route>
                    <Route path={'/spaces/:spaceId'}>
                      <PCScene setUserAnim={setUserAnim} userPos={userPos} setUserPos={setUserPos}
                          lastSpace={lastSpace} setSpace={setSpace} setPOI={setPOI} ref={threeDRef}
                          sceneActive={sceneActive} setSceneActive={setSceneActive}
                          userName={userName} userID={userID} isAuth={isAuth} goToChatThread={goToChatThread} inviteToChat={inviteToChat}
                          userColor={userColor} skinColor={skinColor} hairStyle={hairStyle} hairColor={hairColor}
                          ref={threeDRef} />
                    </Route>
                  </>}
              </ModalSwitch>
            </Box>
            
            <ResponsiveContext.Consumer>
              {responsive => (
                  (!showSidebar || responsive !== "small") ? (
                    <Collapsible direction="horizontal" open={showSidebar}>
                      <Box flex width='medium'  align='center' style={{ zIndex: '0'}} >
                        <ChatView userID={userID} userName={userName} isAuth={isAuth} canAdmin={canAdmin} isDark={isDark}
                          threadID={thread} setThreadID={setThread} listOpen={listOpen} setListOpen={setListOpen} setHaveNotifications={setHaveNotifications} />
                      </Box>
                  </Collapsible> )
                  :
                  <Box fill animation='slideLeft' align='center' style={{ zIndex: '0'}} >
                      <ChatView userID={userID} userName={userName} isAuth={isAuth} canAdmin={canAdmin} isDark={isDark}
                      threadID={thread} setThreadID={setThread} listOpen={listOpen} setListOpen={setListOpen} setHaveNotifications={setHaveNotifications} />
                  </Box>
                )}
          </ResponsiveContext.Consumer>
          </Box>

          <AppFooter>
            <Box direction='row' gap='medium'>
              {!fullscreen && <Button plain icon=<RiFullscreenLine size={26}/> onClick={() => { setFullscreen (true); }} />}
              {fullscreen && <Button plain icon=<RiFullscreenExitLine size={26}/> onClick={() => { setFullscreen (false); }} />}
              <DropButton plain label="HELP"
                  dropAlign={{ bottom: "top", left: "left" }}
                  dropProps={{ plain: true }}
                  dropContent={
                  <Box width='medium' gap='small' pad='small' wrap background='brand' round='medium'>

                  {/*  <Switch>
                    <Route exact path='/'>
                          <Text>Here is where you will find useful messages and general information.</Text>
                    </Route>
                    <Route path='/welcome'>
                        <Text>To take part in the event, you'll need to register then sign in.</Text>
                    </Route>
                    <Route path="/schedule">
                        <Text>The Agenda will show you the whole event agenda.</Text>
                      </Route>
                      <Route path="/partners">
                          <Text>This is the exhibit area. Visit partners and see what they have to offer.</Text>
                    </Route>
                    <Route path="/sessions">
                        <Text>This is a list of all sessions in the event. Click on a session to see more information.</Text>
                    </Route>
                    <Route path="/groups">
                        <Text>Here you will find all the people and groups in the event. You may already be a member of some, and can ask to join others.</Text>
                    </Route>
                    <Route path="/spaces">
                        <GrImage src="/threeDhelp_brand.png" fill='horizontal' />
                      </Route>
                    </Switch> */}

                    <Anchor href={EventParams.HelpLink}  target="_blank">Contact us here if you are having trouble.</Anchor>
                      </Box>
                  }
                />
                {canAdmin && <RoutedButton plain path='/admin' label='ADMIN'/> }
                <Menu plain
                  dropProps={{ align: { bottom: "bottom", left: "left" }, elevation: "large" }}
                  dropBackground={{ color: "white", opacity: "weak" }}
                  items={[
                          { label: EventParams.Organizer + " does not share your personal information" }
                  ]} >
                  PRIVACY        
                </Menu>
            </Box>


            <Box direction='row' gap='medium'>
              <Button plain={true}><img src={EventParams.EventLogoSmall} height="18" alt="Logo"
                onClick={()=>{ 
                  if (EventParams.OrganizerURL.length > 4) { window.open(EventParams.OrganizerURL, "_blank"); }
                }} />
              </Button>
            </Box>
          </AppFooter>
        </Box>

      </Grommet>
  </FullScreen>
  );
};

export default App;
