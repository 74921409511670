import React, { useState, useEffect } from "react";
import { Storage } from 'aws-amplify';

import { Box } from "grommet";


const S3ImageBox = (props) => {
  const { src } = props;
  const [genURL, setGenURL] = useState("");
  const [positioning, setPositioning] = useState('center top');

  const getS3signedURL = async () => {
    if (src && src.length > 3) {
      try {
        const storedURL = await Storage.get (src, { level: 'public' });
        setGenURL (storedURL);
      
      } catch (err) {
        // there will be a message anyway, from browser
        console.log (err);
      }
    }
  };

  useEffect( () => {
    getS3signedURL ();
    if (props.positioning && props.positioning === 'center') {
      setPositioning ('center center');
    } else {
      setPositioning ('center top');
    }
  }, [src]);

  return (
    <Box {...props}
        background={{ color: 'brand', image:`url(${genURL})`, 
          position: `${positioning}` }}

    />
    
  )
};

export default S3ImageBox;
