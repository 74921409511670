import React, { useState, useEffect, useRef } from 'react';
import { Activity, LVL } from '../Activity'

import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';


import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';

import { Avatar, Box, Button, CheckBox, Drop, Form, FormField, Keyboard, List, Menu, ResponsiveContext, Tab, Tabs, Text } from 'grommet';
import TextInput from './WorkaroundTextInput';
import { Add, Alert, CircleInformation, Edit, FormNext, FormPrevious, Emoji, StatusWarningSmall, Send, Video } from 'grommet-icons';
import { Spinning } from 'grommet-controls';
import RoutedButton from './RoutedButton';
import { toast } from 'react-toastify';

import BaseInterweave, { InterweaveProps } from 'interweave';
import { UrlMatcher, EmailMatcher, HashtagMatcher } from 'interweave-autolink';
import { EmojiMatcher, useEmojiData } from 'interweave-emoji';
import { stripHexcode } from 'emojibase';

import { ModalLink } from 'react-router-modal-gallery';

import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'


import EventParams from '../EventItems';
import { set } from 'local-storage';

const maxChatItems = 250;

const globalMatchers: Matcher[] = [
    new EmailMatcher('email'),
    new UrlMatcher('url'),
    new HashtagMatcher('hashtag'),
    new EmojiMatcher('emoji', {
        enlargeThreshold: 3,
      convertEmoticon: true,
      convertShortcode: true,
      convertUnicode: true,
    }),
  ];

const emojiMatchers: Matcher[] = [
    new EmojiMatcher('emoji', {
        enlargeThreshold: 0,
      convertEmoticon: true,
      convertShortcode: true,
      convertUnicode: true,
    }),
];

function emojiPath(hexcode: string, { enlarged }: PathConfig) {
    //return `https://cdn.jsdelivr.net/gh/joypixels/emoji-assets@latest/png/${
    //    enlarged ? 64 : 32
    //}/${stripHexcode(hexcode).toLowerCase()}.png`;
    //return `https://cdn.jsdelivr.net/npm/jdrouet-apple-color-emoji@1.0.0/images/${stripHexcode(hexcode).toLowerCase()}.png`;
    return `https://cdn.jsdelivr.net/npm/emoji-datasource-apple@5.0.1/img/apple/64/${stripHexcode(hexcode).toLowerCase()}.png`;
}


function Interweave(props: InterweaveProps) {
    const [emojis, source] = useEmojiData();

    if (emojis.length === 0) {
        return null;
    }
    return <BaseInterweave {...props} newWindow={true} matchers={globalMatchers} emojiSize={20} emojiLargeSize={42} emojiSource={source} emojiPath={emojiPath} />;
}

function Emojiweave(props: InterweaveProps) {
    const [emojis, source] = useEmojiData();

    if (emojis.length === 0) {
        return null;
    }

    return <BaseInterweave {...props} newWindow={true} matchers={emojiMatchers} emojiSize={20} emojiSource={source} emojiPath={emojiPath} />;
}


    
const ThreadList = (props) => {
    const [ threadList, setThreadList ] = useState ([]);
    const { userID, isAuth, canAdmin, selectedThread, setThreadID, setListOpen } = props;

    useEffect( () => {
        //console.log ("starting thread list, refresh")
        getThreadListAsync ();
    }, []);

    useEffect( () => {
        //console.log ("user Auth, refresh thread list")
        getThreadListAsync ();
    }, [userID, selectedThread]);

    const getThreadListAsync = async () => {
        if (!userID || userID.length === 0) {
            return;
        }

        try {
            const threadQuery = await API.graphql(graphqlOperation(queries.listThreadMembershipByUser, { userID:userID, limit: 1000 }  ));
            //console.log (threadQuery);
            
            const foundThreadMemberships = threadQuery.data.listThreadMembershipByUser.items;
            //console.log (foundThreadMemberships);
            const foundThreadList = foundThreadMemberships.map (nextMember => { return nextMember.thread });
            setThreadList (foundThreadList);
            return foundThreadList;
        } catch (err) {
            console.log ("Unable to get Threads: ", err);
        }
    }
    
    const select = selected => {
        setThreadID (selected);
        setListOpen (false);
      };

    return (  
        <Scrollbars >
            { (threadList === undefined || threadList.length <= 0) ? <Spinning kind="three-bounce" /> :
            <List data={threadList} pad="xsmall" >
                {(item) => {
                        if (item) {
                    const threadName = item.name;
                    var d = new Date(item.updatedAt);
                    //console.log (item)
                    var today = new Date(); 
                    // if not today, use date
                    const lastPostTime = moment(d).format('LT');
                    const lastPostDate = ( today - d < (23 * 60 * 60 * 1000) ) ? "today" : moment(d).format('MMM DD');

                    return (
                    <Box
                        key={item.id}
                        direction="row"
                        gap="xxsmall"
                        align="center"
                        justify="between"
                        onClick={  () => select (item.id) }
                    >
                        <Box flex
                        key={item.id}
                        direction="row"
                        gap="small"
                        align="center"
                        justify="start"
                        overflow='hidden'
                        >
                            
                            <Avatar size="medium" src="/logo192.png" border={{ color: "brand", size: "small" }} />                    
                            
                                { (selectedThread.id === item.id) ? <Text size="small" textAlign='start' weight="bold" color='brand'>{threadName}</Text>
                                : <Text size="small" textAlign='start' weight="bold">{threadName}</Text>}
                        </Box>
                        <Box  width='xsmall' direction='column' justify='between' align='end' wrap={false} >
                            <Text size="xxsmall" >{lastPostTime}</Text>
                            <Text size="xxsmall" >{lastPostDate}</Text>
                        </Box>
                        
                    </Box> );
                    }
                }} 
            </List>
            }
        </Scrollbars>

    );
}

const ChatList = (props) => {
    const { userID, canAdmin, setComment, setEditComment, 
        reactions, toggleLike, positive,
        comments, commentsLoaded, setComments, isDark,
        showEmojiPicker, setShowEmojiPicker, setEditReaction } = props;
    const [ showReactionPicker, setShowReactionPicker ] = useState(false);

    const refs = (comments.reduce((acc, value) => {
        acc[value.id] = React.createRef();
        return acc;
        }, {}
    ));

    const scrollToShow = id => {
        //console.log ("getting ref to scroll item " + id + " into view from " + refs.length);
        const selectedItem = refs[id];
        //console.log (selectedItem);
        if (selectedItem.current) {
            selectedItem.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            } );
        }
    };

    useEffect( () => {
        // run when comments get loaded or updated
        if (commentsLoaded) {
            // scroll to last item
            const lastComment = comments.slice(-1)[0];
            //console.log (lastComment);
            if (lastComment) {
                scrollToShow (lastComment.id);
            }
        }  

    }, [comments, commentsLoaded]);

    const reportComment = async (reporter, commentID, thread) => {
        const commentDetails = {
            id: commentID,
            reporterID: reporter
        };
        try { 
            const editedComment = await API.graphql(graphqlOperation(mutations.updateComment, {input: commentDetails} ));
            if (editedComment.data.updateComment) {
                toast.warn ("Reported comment! Admins will be notified.");
                Activity.log (LVL.user, "Reported comment", true);
                const newNote = {
                    userID: 'ADMIN',
                    originUser: reporter,
                    body: "A comment was reported",
                    type: 'Review comment',
                    link: '#' + thread
                }
                const newNotification = await API.graphql(graphqlOperation(mutations.createNotification, {input: newNote} ));
                //console.log ("Notification: ", newNotification);
            }
        } catch (err) {
            Activity.log (LVL.netErr, "Could not report comment!", true);
        }
    };

    const curatePost = async (reporter, isGood, commentID) => {
        const commentDetails = {
            id: commentID,
            curation: isGood ? 'show' : 'hide',
            reporterID: ""
        };
        try { 
            const editedComment = await API.graphql(graphqlOperation(mutations.updateComment, {input: commentDetails} ));
            if (editedComment.data.updateComment) {
                toast.success ("Moderated comment to " + commentDetails.curation);
                Activity.log (LVL.user, "Curated comment", true);
             }
        } catch (err) {
            Activity.log (LVL.netErr, "Could not curate comment!", true);
        }
    };

    return (
        <Scrollbars >
            <List data={comments} step={maxChatItems} pad="xxsmall" border={{ color: 'background-front'}} >

            { (item) => {
                //console.log (item)
                const myPost = (item.user && item.user.id === userID);
                const userName = item.user ? item.user.name : "Commenter";
                const userLink = item.user ? item.user.id : "";
                const userColor = item.user && EventParams.UseChatColors ? item.user.color : (myPost ? EventParams.MeChatColor : EventParams.TheyChatColor);
                var d = new Date(item.createdAt);
                var today = new Date(); 
                // if not today, use date
                const postTime = ( today - d < (23 * 60 * 60 * 1000) ) ? moment(d).format('LT') : moment(d).format('MMM DD');

                const commentReactions = (item.reactions && item.reactions.items) ? item.reactions.items : [];
                const numLikes = (commentReactions) ? commentReactions.reduce (( acc, cur ) => acc + (cur.like ? 1 : 0), 0) : 0; 
                const emojis = (commentReactions) ? commentReactions.reduce (( acc, cur ) => acc + (cur.emoji ? cur.emoji : ""), "") : [];

                //console.log ("Reactions: ", item.reactions);
                const hasReactions = (numLikes > 0 || emojis.length > 0);
                //console.log (hasReactions);

                return (
                    <Box
                        elevation='small' direction="column" align="center" pad="xsmall" round="xsmall"
                        key={item.id}
                        ref={refs[item.id]}
                        wrap gap="xxsmall"  background ={userColor}
                        onMouseEnter={() => { setShowReactionPicker (item.id); }}
                        onMouseLeave={() => { setShowReactionPicker (""); }}
                        border={(!positive && item.curation === 'hide') || (positive && item.curation !== 'show') ? {color: 'red'} : undefined }
                    >
                        <Box fill direction="row" justify="between"  >
                            <ModalLink to={`/people/${encodeURIComponent(userLink)}`} >
                            <Text size="xxsmall" weight="bold" >{userName}</Text>
                            </ModalLink>
                            {item.reporterID && item.reporterID.length > 4 && <Alert size='small'/> }
                            <Text size="xxsmall" >{postTime}</Text>
                        </Box>
                        <Box fill style={{ "maxHeight": "192px" }} overflow='hidden' onClick={ () => scrollToShow (item.id) } >
                            <Interweave content={item.content} />
                        </Box>
                        {(hasReactions || (showReactionPicker === item.id)) && (
                        <Box fill='horizontal' direction='row' align='end' justify='between'>
                            <Box direction='row' gap='xsmall'>
                                <Button plain size='small' icon=<StatusWarningSmall size='small'/> onClick={() => { toggleLike (item.id) }} />
                                {hasReactions && numLikes > 0 && <Text size='small'>{numLikes}</Text>}
                            </Box>
                            <Box direction='row-reverse' gap='xsmall'>
                                {myPost && showReactionPicker === item.id && <Button plain size='small' icon=<Edit size='small'/> onClick={() => {setComment (item.content); setEditComment (item.id);}} />}
                                {showReactionPicker === item.id && 
                                    <Menu plain
                                        items={canAdmin ? [
                                            {label: 'Hide comment', onClick: () => {curatePost (userID, false, item.id)}},
                                            {label: 'Show comment', onClick: () => {curatePost (userID, true, item.id)}},
                                            {label: 'Report comment', onClick: () => {reportComment (userID, item.id, item.thread)}},
                                        ] : [
                                            {label: 'Report comment', onClick: () => {reportComment (userID, item.id, item.thread)}},
                                         ]} >
                                        <Alert size='small'/> 
                                    </Menu> }
                                <Button plain size='small' icon=<Emoji size='small'/> onClick={() => {setEditReaction (item.id); setShowEmojiPicker (!showEmojiPicker)}} />
                                {hasReactions && <Emojiweave content={emojis.slice(-20)} />}
                            </Box>
                                                     
                        </Box> )} 
                    </Box>
                    
                ) }
            }
        </List>
        
    </Scrollbars>    
    );
}

const NotificationList = (props) => {
    const { userID, userName, canAdmin, showThread,
        notifications, setNotifications } = props;
    
    const markRead = async (notificationID, isRead) => {
        const readNote = {
            id: notificationID,
            markedRead: isRead
        }
        const newNotification = await API.graphql(graphqlOperation(mutations.updateNotification, {input: readNote} ));
        console.log ("Notification: ", newNotification);
        const notIndex = notifications.findIndex (item => item.id === notificationID);

        setNotifications ([...notifications.slice(0, notIndex), newNotification.data.updateNotification, ...notifications.slice(notIndex + 1)]);
    }

    useEffect( () => {

    }, [notifications]);

    return (
        <Scrollbars >
            {!notifications && <Spinning kind="three-bounce" />}
            {notifications !== null && notifications.length === 0 && <Text>You have no notifications</Text>}
            <List data={notifications} step={maxChatItems} pad="xxsmall" border={{ color: 'background-front'}} >

            { (item) => {
                //console.log (item)
                const myNote = (item.originUser === userID);
                const userLink = item.user ? item.user.id : "";

                return (
                    <Box
                    elevation='small' direction="column" align="start" pad="xsmall" round="xsmall"
                    background='tab'
                    key={item.id}
                    wrap gap="xxsmall"
                    >
                        <Text size='small'>{item.body}</Text>
                        <Box fill direction="row" justify="between" align='end' >
                            <ModalLink to={`/people/${encodeURIComponent(userLink)}`} >
                                <Text size="xxsmall" weight="bold" >From</Text>
                            </ModalLink>
                            <Button size='small' label={item.type} onClick={() => {showThread (item.link.slice(1))}} />

                            <CheckBox size='small' checked={item.markedRead} onChange={(event) => markRead (item.id, event.target.checked)} label="Mark read"/>
                            
                            
                        </Box>
                    </Box>
                    
                ) }
            }
        </List>
        
    </Scrollbars>    
    );
}

const ChatView = (props) => {
    const [ comment, setComment ] = useState ("");
    const [ editComment, setEditComment] = useState("");
    const [ editReaction, setEditReaction ] = useState("");

    const [ currSelection, setCurrSelection] = useState({s:0, e:0});
    const [ comments, setComments ] = useState ([]);
    const [notifications, setNotifications] = useState([]);
    const [viewNotifications, setViewNotifications] = useState([]);

    const [ commentsLoaded, setCommentsLoaded ] = useState(false);
    const [ showThreadInfo, setShowThreadInfo ] = useState(false);
    const [ tabIndex, setTabIndex ] = useState(0);
    
    const [threadName, setThreadName] = useState("");
    const [isPositive, setIsPositive] = useState(false);
    const [disallow, setDisallow] = useState(false);
    
    const { userID, userName, isAuth, canAdmin, isDark, threadID, setThreadID, listOpen, setListOpen, setHaveNotifications } = props;
    const [ selectedThread, setSelectedThread ] = useState ({});

    const [ showEmojiPicker, setShowEmojiPicker] = useState (false);
    const emojiRef = useRef();
    
    const targetRef = useRef();

    var reactions = {};
    
    const sendComment = async (user, comment, commentID) => {
        if (selectedThread.id === undefined) return;

        if (comment){
            if (commentID.length > 0) {
                const commentDetails = {
                    id: commentID,
                    content: comment
                };
                try { 
                    const editedComment = await API.graphql(graphqlOperation(mutations.updateComment, {input: commentDetails} ));
                    if (editedComment.data.updateComment) {
                        Activity.log (LVL.user, "Edited comment in thread " + selectedThread.name + ": " + comment, "chat", true);
                    }
                } catch (err) {
                    Activity.log (LVL.netErr, "Could not edit comment in thread " + selectedThread.name + ": " + comment, "chat", true);
                }
            } else {
                const commentDetails = {
                    userID: user,
                    content: comment,
                    thread: selectedThread.id
                };
                //console.log (commentDetails); 
                try { 
                    const newComment = await API.graphql(graphqlOperation(mutations.createComment, {input: commentDetails} ));
                    if (newComment.data.createComment) {
                        Activity.log (LVL.user, "Added comment to thread " + selectedThread.name + ": " + comment, "chat", true);
                    }
                } catch (err) {
                    Activity.log (LVL.netErr, "Could not add comment to thread " + selectedThread.name + ": " + comment, "chat", true);
                }
            }

            // check we're 'in' this thread, else join it
            try {
                const memberQuery = await API.graphql(graphqlOperation(queries.listThreadMembershipByUser, { userID:userID, threadID: {eq: selectedThread.id} } ));
                //console.log (memberQuery);
                if (memberQuery.data.listThreadMembershipByUser.items.length === 0) {
                    const memberDetails = {
                        userID: userID,
                        threadID: selectedThread.id
                    }
                    const threadAdd = await API.graphql(graphqlOperation(mutations.createThreadMember, {input: memberDetails} ));
                    if (threadAdd.data.createThreadMember) {
                        Activity.log (LVL.user, "Added user to thread " + selectedThread.name, "chat", true);
                    }
                    else {
                        Activity.log (LVL.netErr, "Failed to add user to thread " + selectedThread.name, "chat", true);
                    }
                }
            } catch (err) {
                console.log ("Failed to see if user is member of thread: " + err.message);
            }
        }
    }

    const submitComment = () => {
        sendComment (userID, comment, editComment);
        setComment ("");
        setEditComment ("");
        
        // hacky attempt to lose focus
        document.activeElement.blur();
    }
 
    const toggleLike = async (itemID) => {
        try {
            //console.log (userID);
            const reactionQuery = await API.graphql(graphqlOperation(queries.listReactionsByComment, { commentID:itemID, userID: {eq: userID} } ));
            const myReaction = reactionQuery.data.listReactionsByComment.items;
            //console.log (reactionQuery);
            let didLike = false;

            if (myReaction && myReaction.length) {
                didLike = myReaction[0].like;
                
                const thisReac = reactions[itemID];
                if (thisReac) {
                    const toggledReaction = {id: thisReac.id, emojis: thisReac.emojis, likes: (didLike ? thisReac.likes - 1 : thisReac.likes + 1) };
                    
                } else {
                    console.log ("No reactions found!")
                }
                
                // update existing
                const input = { id: myReaction[0].id, like: !didLike };
                const reactionAdd = await API.graphql (graphqlOperation(mutations.updateReaction, {input: input} ));
                reactions[itemID] = reactionAdd;
            } else {
                const thisReac = reactions[itemID];
                if (thisReac) {
                    const toggledReaction = {id: thisReac.id, emojis: thisReac.emojis, likes: thisReac.likes + 1 };
                    reactions[itemID] = toggledReaction;
                } else {
                    console.log ("No reactions found!")
                }

                // create a new reaction
                const input = { userID: userID, commentID: itemID, like: true };
                const reactionAdd = await API.graphql (graphqlOperation(mutations.createReaction, {input: input} ));
                if (reactionAdd.data.createReaction) {
                    Activity.log (LVL.user, "User liked comment " + itemID, "user", true);
                    toast.success ("You liked that comment!");
                } else {
                    Activity.log (LVL.netErr, "Failed to store reaction to " + itemID, "user", true);
                }
            }
        } catch (err) {
            console.log ("Could not get reactions for this comment " + err.message);
            console.log (err);
        }
    }

    const updateReaction = async (itemID, emoji) => {
        try {
            //console.log (userID);
            const reactionQuery = await API.graphql(graphqlOperation(queries.listReactionsByComment, { commentID:itemID, userID: {eq: userID} } ));
            const myReaction = reactionQuery.data.listReactionsByComment.items;
            //console.log (reactionQuery);
    
            const thisReac = reactions[itemID];
            if (thisReac) {
                const toggledReaction = {id: thisReac.id, emojis: thisReac.emojis + emoji, likes: thisReac.likes };
                // order doesn't matter, we use ID
                reactions[itemID] = toggledReaction;
            } else {
                console.log ("No reactions found!")
            }
            
            if (myReaction && myReaction.length) {                
                // update existing
                const input = { id: myReaction[0].id, emoji: emoji };
                const reactionAdd = await API.graphql (graphqlOperation(mutations.updateReaction, {input: input} ));
            } else {
                // create a new reaction
                const input = { userID: userID, commentID: itemID, like: true };
                const reactionAdd = await API.graphql (graphqlOperation(mutations.createReaction, {input: input} ));
                if (reactionAdd.data.createReaction) {
                    Activity.log (LVL.user, "User liked comment " + itemID, "user", true);
                    toast.success ("You liked that comment!");
                } else {
                    Activity.log (LVL.netErr, "Failed to store reaction to " + itemID, "user", true);
                }
            }
        } catch (err) {
            console.log ("Could not get reactions for this comment " + err.message);
            console.log (err);
        }
    }

    //TODO - persuade thread to select before we get comments!
    useEffect( () => {
        // Getting reactions here triggers an overload when comments update
        //getReactions ().then (data => {
            //console.log (data);
        //    setReactions (data);   
        //});
    }, [comments]);

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const getReactions = async (newComments) => {
        for (const comm of newComments) {
            checkReactionsForComment (comm.id);
        }
    };

    const checkReactionsForComment = async (commentID) => {
        await delay(10);
        const reactionQuery = await API.graphql(graphqlOperation(queries.listReactionsByComment, { commentID:commentID, limit: 100 } ));
        const theReactions = reactionQuery.data.listReactionsByComment.items;

        const hasReactions = (theReactions && theReactions.length > 0);
        const numLikes = (hasReactions) ? theReactions.reduce (( acc, cur ) => acc + (cur.like ? 1 : 0), 0) : 0; 
        const emojis = (hasReactions) ? theReactions.reduce (( acc, cur ) => acc + (cur.emoji ? cur.emoji : ""), "") : "";
        const react = { likes: numLikes, emojis: emojis}
        //console.log ("reactions for comment: " + commentID + ", ", react)
        reactions[commentID] = react;
    }

    useEffect( () => {
        const getCurrentThread = async () => {
            console.log ("Thread: " + threadID)
            if (threadID === "") {
                // New user
                setListOpen (true);
                return;
            }
            // Access the current thread by ID else pick the default
            const pickThread = await API.graphql(graphqlOperation(queries.getThread, {id: threadID} ));

            if (pickThread.data.getThread) {
                Activity.log (LVL.user, "Returning to existing chat: " + pickThread.data.getThread.name, "chat", true);
                setSelectedThread (pickThread.data.getThread);
                console.log ("Thread: ", pickThread.data.getThread);
            } else {
                Activity.log (LVL.user, "Previous chat thread no longer exists", "chat", true);
                const defaultThread = await API.graphql(graphqlOperation(queries.getThread, {id:"all-attendees"} ));
                setSelectedThread (defaultThread.data.getThread);
                setThreadID (selectedThread.id);
            }
        }

        console.log ("Switching to thread: " + threadID);
        
        getCurrentThread (); // sets selectedThread from the ID

    }, [threadID] );
        
    const showThread = (newThread) => {
        setThreadID (newThread);
        //console.log ("Set thread ID: " + newThread);
        setListOpen (false);
        setTabIndex (0);
    }        

    const setThreadDetails = async () => {
                const input = {
                    id: selectedThread.id,
            name: threadName,
            positiveCuration: isPositive,
            disallowVideo: disallow
                };
        try {
                API.graphql(graphqlOperation(mutations.updateThread, { input })); 
             toast.success ("Updated thread details (leave to refresh)");
            } catch (err) {
                console.log ("Error setting chat thread details: " + err.message);
            }        

                };
    
    useEffect(  () => {
        if (!selectedThread || selectedThread.id === undefined) return;

        setThreadName(selectedThread.name);
        setIsPositive(selectedThread.positiveCuration);
        setDisallow(selectedThread.disallowVideo);

        const getComments = async () => {
            const allComments = await API.graphql(graphqlOperation(queries.listCommentsByThread, { thread:selectedThread.id, limit: 500 }  ));
            const newComments = allComments.data.listCommentsByThread.items;
            //const allComments = await API.graphql(graphqlOperation(queries.listComments, { filter: {thread: {eq: selectedThread.id}}, limit: 1000 }  ));
            //const newComments = allComments.data.listComments.items;
            //console.log (newComments);

            applyComments (newComments);
        }

        const applyComments = async (newComments) => {
            if (canAdmin) {
            setComments (newComments.slice (-maxChatItems));
            } else 
            if (selectedThread.positiveCuration) {
                const filteredComments = newComments.filter (cmt => (cmt.curation === 'show'));
                setComments (filteredComments.slice (-maxChatItems));
                
            } else {
                const filteredComments = newComments.filter (cmt => (cmt.curation !== 'hide'));
                setComments (filteredComments.slice (-maxChatItems));
            }
            setCommentsLoaded (true);
            // also get reactions, with some buffering
            getReactions (newComments);
        }

        const onCreateCommentListener = API.graphql (graphqlOperation(subscriptions.onCreateCommentInThread, { thread:selectedThread.id })).subscribe ({
            next: message => {
                const newComment = message.value.data.onCreateCommentInThread;
                //console.log ("Received in thread I'm watching: ", newComment);
                const shouldShow = ((newComment.curation === 'show') || (selectedThread && !selectedThread.positiveCuration && newComment.curation !== 'hide'));
                if (canAdmin || shouldShow || newComment.userID === userID) {
                    setComments (values => [...values, newComment]);
                    // new comments have no reactions, so ignore
                }
            }
        });

        const onUpdateCommentListener = API.graphql (graphqlOperation(subscriptions.onUpdateCommentInThread, { thread:selectedThread.id })).subscribe ({
            next: message => {
                const newComment = message.value.data.onUpdateCommentInThread;
                if (newComment.thread !==  selectedThread.id) {
                    console.log ("Update for another thread - ignored");
                } else {
                    console.log ("Received in thread I'm watching: ", newComment);

                    // If the database changes are made - removed field level permissions, this works
                    updateComment (newComment);
                }
            }
        });

        const updateComment = async (changedComment) => {
            //const commentQuery = await API.graphql(graphqlOperation(queries.getComment, { id:commentID }  ));
            //const changedComment = commentQuery.data.getComment;
            //console.log ("Received in thread I'm watching: ", changedComment);
            const shouldShow = ((changedComment.curation === 'show') || (selectedThread && !selectedThread.positiveCuration && changedComment.curation !== 'hide'));
            
            const doShow = (shouldShow || changedComment.userID === userID);
            if (canAdmin) {
                setComments (values => {
                    const commentIndex = values.findIndex (item => item.id === changedComment.id);
                    return [...values.slice(0, commentIndex), changedComment, ...values.slice(commentIndex + 1)];
                });
            } else {
                setComments (values => {
                    const commentIndex = values.findIndex (item => item.id === changedComment.id);
                    if (doShow) {
                        if (commentIndex === -1) {
                            return [...values, changedComment];
                        } else {
                            return [...values.slice(0, commentIndex), changedComment, ...values.slice(commentIndex + 1)];
                        }
                    } else {
                        return [...values.slice(0, commentIndex), ...values.slice(commentIndex + 1)]; 
                    }
                });
            } 
           // also check for updated reactions... for this one only!
           checkReactionsForComment (changedComment.id);
        };

        // reset the threadID when selectedThread has changed
        getComments ();
        
        const input = {
            id: userID,
            currentThread: selectedThread.id
          };
        API.graphql(graphqlOperation(mutations.updateUser, { input }));
        
        // TODO - mark that we read this at this time

       
        Activity.log (LVL.user, "Started looking at chat thread: " + selectedThread.name, "chat", true);

        return () => {
            onCreateCommentListener.unsubscribe ();
            onUpdateCommentListener.unsubscribe ();
            Activity.log (LVL.user, "Stopped looking at chat thread", "chat", true);
        };

    }, [selectedThread] );

    const onActive = nextIndex => setTabIndex (nextIndex);

    const getNotifications = async () => {
        try {
            const myNotifications = await API.graphql(graphqlOperation(queries.listNotificationsByUser, { userID: userID, limit: 200 }  ));
            if (myNotifications && myNotifications.data.listNotificationsByUser){
                //console.log ("Notications: ", myNotifications.data.listNotificationsByUser);
            
                if (canAdmin) {
                    const adminNotifications = await API.graphql(graphqlOperation(queries.listNotificationsByUser, { userID:'ADMIN', limit: 200 }  ));
                    const allNotifications = [...myNotifications.data.listNotificationsByUser.items, ...adminNotifications.data.listNotificationsByUser.items];

                    setNotifications (allNotifications);

                } else {
                    setNotifications (myNotifications.data.listNotificationsByUser.items);
                }
            }
        } catch (err) {
            console.log ("Unable to get Notifications: ", err);
        }
    };

    useEffect( () => {
        //console.log (filterString);
        let notificationsToSort = notifications; //.filter(sessionDetails => (canAdmin || !sessionDetails.hide) && ( showFavesOnly === false || favorites[sessionDetails.id] === true) );
        
        // Now sort
        notificationsToSort.sort (function(a,b){
            // (x === y)? 0 : x? -1 : 1
            return a.createdAt > b.createdAt ? -1 : 1;
        });

        setViewNotifications (notificationsToSort);

    }, [notifications]);

    useEffect( () => {
        if (tabIndex === 1) {
            setHaveNotifications (false);
            console.log ("Update Notifications");
            getNotifications ();
        }

    }, [tabIndex]);

    return (
        <>
        
        <Box
            fill background='background-front'
            pad={{ vertical: 'small' }} gap='medium' elevation='medium'
            >
            <Tabs activeIndex={tabIndex} onActive={onActive} flex>
                <Tab title=<Text size='small' weight='bold' color='brand'>MESSAGES</Text> >
                <Box
                    fill a11yTitle='Chat View' align='stretch'
                    justify='between' direction='column'
                    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
                    >
                    {!selectedThread ? 
                        <Text>Select a chat thread</Text>
                    :
                    <Box height="xxsmall" direction="row" align='start' justify="between" ref={targetRef}>
                        {listOpen && ( <Button plain icon=<FormNext color="brand"/> onClick={() => setListOpen(false)} /> )}
                        {!listOpen && ( <Button plain icon=<FormPrevious color="brand"/> onClick={() => setListOpen(true)} /> )}   
                        <Text size="medium"  weight="bold" color="brand" >{selectedThread.name}</Text>
                        <Box direction='row' gap='xsmall' pad='xsmall'>
                            {/*<Button plain icon=<EventParams.Favorite on={1} size='small' color='brand' />
                                onClick={() => { }} />*/}
                            <Button plain icon=<CircleInformation size='small' color="brand" />
                                onClick={ () => {setShowThreadInfo(!showThreadInfo); }} />
                        </Box>
                            
                        {showThreadInfo && (
                            <Drop
                                align={{ top: "bottom" }}
                                overflow="hidden"
                                target={targetRef.current}
                                onClickOutside={ () => setShowThreadInfo(false) }
                                >
                                <Box pad="large" gap='medium' background="background-contrast">
                                    <Text alignSelf="start" size="medium" weight="bold" >{selectedThread.name}</Text>
                                    <Text alignSelf="start" size="small"  >{selectedThread.description}</Text>
                                    {selectedThread.positiveCuration ?
                                        <Text size="small"  >This thread is moderated. A moderator must approve your comment for everyone to see it!</Text>
                                    :
                                        <Text size="small"  >A moderator may remove your post in this thread.</Text>  
                                    }
                                    {canAdmin && <>
                                        <FormField label="Thread Name" >
                                            <TextInput name="name" value={threadName} onChange={(event) => setThreadName(event.target.value)} />
                                        </FormField>
                                        <FormField label="Positive Curation" >
                                            <CheckBox  label='only show moderated comments' checked={isPositive} onChange={(event) => setIsPositive(event.target.checked)}/>
                                        </FormField>
                                        <FormField label="Disallow Video" >
                                            <CheckBox  label='do not allow video chat' checked={disallow} onChange={(event) => setDisallow(event.target.checked)}/>
                                        </FormField>
                                        <Button label='Update Details' onClick={() => {setThreadDetails ();}} />
                                    </>}
                                </Box>
                            </Drop>
                        ) }
                    </Box>
                    }
                    
                    {listOpen && ( <Box flex direction='column' justify="between" >
                        <Box flex overflow='hidden'>
                            <ThreadList
                                selectedThread={selectedThread}
                                userID={userID} isAuth={isAuth} canAdmin={canAdmin} 
                                setThreadID={setThreadID}
                                setListOpen={setListOpen}/>
                        </Box>
                        
                    </Box> )}

                    {!listOpen && ( <Box flex direction='column' justify="between" ref={emojiRef}>
                        <Box flex overflow='hidden'>
                            <ChatList  userID={userID} canAdmin={canAdmin}    
                                comments={comments} positive={selectedThread.positiveCuration} setComments={setComments}
                                reactions={reactions} toggleLike={toggleLike}
                                setComment={setComment} setEditComment={setEditComment} setEditReaction={setEditReaction}
                                commentsLoaded={commentsLoaded}
                                setCommentsLoaded={setCommentsLoaded} isDark={isDark}
                                showEmojiPicker={showEmojiPicker} setShowEmojiPicker={setShowEmojiPicker}
                                />
                        </Box>
                        
                        <Box direction='column' justify='center' round='small' gap='xsmall'>
                            <Keyboard onEnter={() => { submitComment(); }}>
                                <Box direction='row' align='center' border={{ color:EventParams.FlipHeaderFooterColors ? 'brand' : 'secondary' }}
                                    round='large' width='medium' pad={{ horizontal:'small', vertical: 'xxsmall' }}>
                                    <Button icon=<Emoji color='brand'/> onClick={() => {setShowEmojiPicker (!showEmojiPicker); setEditReaction (""); }} />
                                    <TextInput plain id="text-input-id" name="comment"
                                        value={comment}
                                        onChange={evt => {setComment(evt.target.value); 
                                                        setCurrSelection ({s:evt.target.value.length, e:evt.target.value.length}) }}
                                        onSelect={(evt) => {setCurrSelection ({s:evt.target.selectionStart, e:evt.target.selectionEnd}) }} />
                                    <Button plain icon=<Send color='brand'/> onClick={() => { submitComment(); }} />
                                </Box>
                            </Keyboard>
                            {showEmojiPicker && <Drop
                                plain
                                align={{ bottom: "bottom", right: 'left' }}
                                overflow="hidden"
                                target={emojiRef.current}
                                onClickOutside={ () => setShowEmojiPicker(false) }
                                >
                                <Picker set='apple' title="" theme={isDark ? 'dark' : 'light'}
                                    onSelect={(emoji) => {setShowEmojiPicker(false); setEditReaction (""); 
                                    (editReaction === "" ? setComment(comment + emoji.native) : updateReaction (editReaction, emoji.native) )}}
                                />
                            </Drop> }
                            {!selectedThread.disallowVideo && <RoutedButton primary size='small' icon=<Video /> label="START VIDEO CHAT" 
                                path={`/meet?chat=${encodeURIComponent(selectedThread.name)}&pw=${encodeURIComponent(selectedThread.id)}`} />}
                        </Box>
                        
                    </Box> )}
                </Box>

            </Tab>  
            <Tab title=<Text size='small' weight='bold' color='brand'>NOTIFICATIONS</Text> >
                <Box
                    fill a11yTitle='Notification View' align='stretch'
                    justify='between' direction='column'
                    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
                    >
                    <Box flex overflow='hidden'>
                        <NotificationList  userID={userID} userName={userName} canAdmin={canAdmin}  showThread={showThread}  
                            notifications={viewNotifications} setNotifications={setViewNotifications}
                            />
                        </Box> 
                         
                    </Box>
            </Tab>               
        </Tabs>
        </Box>
        </>
    );
};

export default  ChatView;
