
import React, { useEffect, useState, createRef } from 'react';
import { Box, Button, List, Menu, Text  } from 'grommet';
import { Alarm, FormDown, Video } from 'grommet-icons';
import moment from 'moment';
import randomColor from 'randomcolor';
import { Scrollbars } from 'react-custom-scrollbars';

import { Activity, LVL, createCalendarLinkFromSession } from '../Activity';
import RoutedButton from './RoutedButton';

const maxSessionItems = 500;

const ScheduleList = (props) => {
    const { sessions, mode } = props;
    
    useEffect( () => {
        

    }, [sessions]);

    return (
            <Scrollbars >
                <List data={sessions} step={maxSessionItems}  pad="xsmall" margin={{ right: "small" }} border={{ color: 'background-front'}} >

                { (item) => {
                    //console.log (item)
                    const now = moment();
                    const sessionTitle = item.name;
                    const sessionTime = (item.startdate === null) ? moment (item.starttime, "hh:mm:ssZ") :  moment (item.startdate + "T" + item.starttime);
                    const sessionEnd = (item.enddate === null) ? moment (item.endtime, "hh:mm:ssZ") :  moment (item.enddate + "T" + item.endtime);
                    const sessionContent = item.shortDescription;
                    const inProgress = now.isBetween (sessionTime, sessionEnd, undefined, '[]');

                    return (
                        <>
                        {(mode === 'centeredText') ?
                        <Box key={item.id} direction="column"
                            gap="xxsmall" align="center" justify='center' pad="xsmall"
                            round='small' elevation='small'
                            >
                                <Box direction='column' pad='xxsmall'>
                                    <Text size='small' textAlign='center'>{sessionTime.format('LT')} - {sessionEnd.format('LT')}</Text>
                                    <Text size='medium' color='focus' textAlign='center'>{sessionTitle}</Text>
                                </Box>
                            </Box>
                        :
                        <Box 
                        key={item.id}
                        direction="column"
                        gap="small"
                        align="start"
                        pad="small"
                        background="background-contrast" round='small' elevation='small'
                        >
                                
                            <Box direction='row' gap='small'>
                                <Text size='medium' weight='bold' color='brand'>{sessionTime.format('LT')}</Text>
                                {!inProgress && <Menu plain
                                    items={[
                                        {label: 'Google', onClick: () => {createCalendarLinkFromSession (item, 'google');}},
                                        {label: 'Office365', onClick: () => {createCalendarLinkFromSession (item, 'office365');}},
                                        {label: 'Others (.ics)', onClick: () => {createCalendarLinkFromSession (item, 'generic');}},

                                    ]} >
                                    <Alarm size='small' color='brand' /> 
                                </Menu> }
                            </Box>
                            <RoutedButton plain path={`sessions/${item.id}`}
                                label=<Text size='medium' weight='bold'>{sessionTitle}</Text> />
                            <Box flex>
                                <Text size="xxsmall" >{sessionContent}</Text>  
                            </Box>
                            {inProgress &&
                                <RoutedButton primary size='small' icon=<Video /> label="WATCH NOW" path={`sessions/${encodeURIComponent(item.id)}?play=true`} /> }
                        </Box>
                        } </>
                        
                    ) }
                }
                </List>
            </Scrollbars>
    );
}

export default ScheduleList;