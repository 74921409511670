import React, { useEffect, useState } from 'react';
import { Box, Button, Heading, Menu, Stack, Text  } from 'grommet';
import { Alarm, CircleInformation, Chat, Edit, Previous } from 'grommet-icons';

import { useParams, useLocation, useHistory, useRouteMatch } from "react-router-dom";

import { API, graphqlOperation, Storage } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import usePrevious from '../utils/use-previous'
import moment from 'moment';

import RoutedButton from './RoutedButton';

import { Activity, LVL } from '../Activity';

import EventParams from '../EventItems';
import S3ImageBox from './S3ImageBox';
import Scrollbars from 'react-custom-scrollbars';
import BaseInterweave, { InterweaveProps } from 'interweave';
import { UrlMatcher, EmailMatcher, HashtagMatcher } from 'interweave-autolink';
  
const globalMatchers: Matcher[] = [
  new EmailMatcher('email'),
  new UrlMatcher('url'),
  new HashtagMatcher('hashtag'),
];

function Interweave(props: InterweaveProps) {
  return <BaseInterweave {...props} newWindow={true} matchers={globalMatchers} />;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const Group = (props) => {
  const [groupDetails, setGroupDetails] = useState({});
  const [isFavorite, setIsFavorite] = useState(false);
  const [groupActivity, setGroupActivity] = useState(null);

  let { groupId } = useParams();
  let { url } = useRouteMatch();
  let query = useQuery();
  let history = useHistory();

  const { userID, canAdmin, changed, goToChatThread } = props;
  
  const downloadGroupMediaLink = async (key) => {
    const storedURL = await Storage.get (key, { level: 'public' });
    console.log (storedURL);
    console.log ("Get file at: " + storedURL);
    window.open (storedURL, "_blank");

  }

  const getGroupDetails = async () => {
    try {
      const foundGroup = await API.graphql(graphqlOperation(queries.getGroup, { id: groupId } ));
      //console.log (foundGroup);
      if (foundGroup.data.getGroup) {
        const currentGroup = foundGroup.data.getGroup;
        setGroupDetails (currentGroup);

          // get group activity too, if it exists
        try {
          const activityQuery = await API.graphql(graphqlOperation(queries.listGroupMembershipsByUser, { userID:userID, limit: 1000 } ));

          const foundActivity = activityQuery.data.listGroupMembershipsByUser.items;
          console.log (foundActivity);
          if (foundActivity) {
            const groupMatch = foundActivity.find (memb => memb.groupID === groupId);
            //console.log (groupMatch);
            setGroupActivity (groupMatch);
            // we have to set this to display, but will it cause a loop?
            if (groupMatch && groupMatch.isFave !== null) {
              setIsFavorite (groupMatch.isFave);
            } 
            
          }
        } catch (err) {
            console.log ("Unable to get Activity: ", err);
        }
      }
    } catch (err) {
      console.log ("Could not get group details");
    }
  };

  useEffect( () => {
    //console.log (groupActivity);
    //if (!groupActivity) || (isFavorite === true && groupActivity && (groupActivity.isFave === null || groupActivity.isFave === true))) {
      const input = { isFave: isFavorite };
      updateGroupActivity (input);
    //}
    if (changed) {
      changed ();
    }
  }, [isFavorite]);

  const updateGroupActivity = async ( actions ) => {
    if (!groupDetails || ! groupDetails.id) return;
    
    console.log ("Updating group, ", groupActivity);
    try {
      if (!groupActivity) {
        const input = {...actions, userID: userID, groupID: groupDetails.id};
        console.log (input);
        const updatedGroupActivity = await API.graphql(graphqlOperation(mutations.createGroupMember, {input: input}));
        console.log (updatedGroupActivity);
        setGroupActivity (updatedGroupActivity.data.createGroupMember);
      } else {
        const input = {id:groupActivity.id, ...actions};
        const updatedGroupActivity = await API.graphql(graphqlOperation(mutations.updateGroupMember, {input: input}));
        console.log (updatedGroupActivity);
        setGroupActivity (updatedGroupActivity.data.updateGroupMember);
      }
      
    } catch (err) {
      console.log ("Couldn't record group activity: " + err.message);
      console.log (err);
    }
  }

  useEffect( () => {
    // did we get to this link by a refresh or similar? Is it active?
  console.log (query);
  //if (query.get("play")) {
  //  setShowingVideo (true);
  //}
    getGroupDetails ();
    console.log ("Viewing Group " + groupId );
  }, []);
  
  // div error   https://github.com/zhulduz/video.js/commit/a7e44b702485275696eb5bbc0499bc092ec47862 
  // https://github.com/videojs/video.js/issues/4935

return (
  <Box
      a11yTitle={groupDetails.name + ' Group Details'}
      fill
      tag='footer'
      direction='column'
      align='start'
      gap='small'
      pad={{ left: 'medium', right: 'small', vertical: 'xsmall' }}
      {...props}
      >
      <Box fill='horizontal' align='center' direction='row' gap='small'>
          <Previous color='brand' onClick={() => history.goBack()} />
          <Heading level='3' color="brand" >{groupDetails.name}</Heading>
      </Box>
      <Box  fill='horizontal' direction='column' align='start' gap='small'>
        <Box width='large' direction='row' justify='between'>
          <Box direction='row' gap='medium'> 
            <Button plain icon=<EventParams.Favorite on={isFavorite ? 1 : 0} color='brand' /> onClick={() => {setIsFavorite (!isFavorite)}} />
            
            <Button plain icon=<Chat color='brand'/> onClick={() => 
              {goToChatThread (groupDetails.thread, true); }} />
          </Box>              

        </Box>
        
        <Box  direction='column' overflow='auto' gap='small'>
          <Box width="large" direction='column' gap='xsmall'>
            <Stack height="small" anchor='top-right'>
              <S3ImageBox height="medium"  round="medium"
                  src={groupDetails.pic} />
              {canAdmin && <Box margin = 'small' pad='xsmall' direction='row' background={{ color: 'white', opacity: 'medium' }} round>
                  <RoutedButton fill plain
                  path={`/groupEditor/${encodeURIComponent(groupDetails.id)}`}
                  label=<Edit color='brand'/>
                  />
              </Box>}
            </Stack>
          
            {groupDetails.collateral &&
              <Button label={'Download ' + groupDetails.collateralName} onClick={() => {downloadGroupMediaLink (groupDetails.collateral)}} />
            }
          </Box>
          <Box flex fill='horizontal' gap='medium'>
            <Text size='small'><Interweave content={groupDetails.description} /></Text>
            {(groupDetails.more && groupDetails.more.length > 0) &&
              <Button plain color='brand' label="More Details (link)" onClick={() => {window.open (groupDetails.more, "_blank");}} /> }
            
            {/*<Text size='medium' color='brand'>Members:</Text>*/}

          </Box>
        </Box>

      </Box>
              
  </Box>

) };

export default Group;