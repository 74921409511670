/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:ed63041f-c16e-4355-b018-a88578f50cd9",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_HISkcpQS4",
    "aws_user_pools_web_client_id": "6062scnl94043o0n06mo8preip",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://ocl4ieqrl5eoxeeq6zraxv62si.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "livedemocookieaccess",
            "endpoint": "https://iycztuxeg3.execute-api.us-west-2.amazonaws.com/spacesenv",
            "region": "us-west-2"
        },
        {
            "name": "getjitsitoken",
            "endpoint": "https://mqw6uivuaj.execute-api.us-west-2.amazonaws.com/spacesenv",
            "region": "us-west-2"
        },
        {
            "name": "signedUrl",
            "endpoint": "https://h0t93uzhc0.execute-api.us-west-2.amazonaws.com/spacesenv",
            "region": "us-west-2"
        },
        {
            "name": "spacesenvUserData",
            "endpoint": "https://89kqrkqvmd.execute-api.us-west-2.amazonaws.com/spacesenv",
            "region": "us-west-2"
        },
        {
            "name": "spacesenvJoinGroup",
            "endpoint": "https://281hc2748g.execute-api.us-west-2.amazonaws.com/spacesenv",
            "region": "us-west-2"
        }
    ],
    "aws_content_delivery_bucket": "spacesdemo-20200506151748-hostingbucket-spacesenv",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://dqkni3p3e89uu.cloudfront.net",
    "aws_user_files_s3_bucket": "spacesdemob5d50d42f6e34d4e91fcb9d4e78a5ab4140500-spacesenv",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_mobile_analytics_app_id": "28b786f67e844f8f9f2662c0d3cfc9d0",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
