/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      firstName
      secondName
      emailAddress
      description
      isPresenter
      isEventStaff
      type
      title
      company
      showInList
      blocked
      allowInvitations
      tags
      homePage
      space
      color
      skincolor
      hairstyle
      haircolor
      animation
      avoid3D
      pos
      lastOnline
      updatedAt
      createdAt
      currentThread
      comments {
        items {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        nextToken
      }
      reactions {
        items {
          id
          userID
          commentID
          like
          emoji
          reported
          updatedAt
          owner
        }
        nextToken
      }
      threads {
        items {
          id
          userID
          threadID
          lastRead
          viewedCount
        }
        nextToken
      }
      groups {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      friends
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      userID
      originUser
      body
      type
      link
      markedRead
      createdAt
      lastRead
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      owner
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        originUser
        body
        type
        link
        markedRead
        createdAt
        lastRead
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getSpace = /* GraphQL */ `
  query GetSpace($id: ID!) {
    getSpace(id: $id) {
      id
      name
      pic
      description
      public
      thread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      pois {
        items {
          id
          name
          pic
          description
          pos
          thread
          space
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const listSpaces = /* GraphQL */ `
  query ListSpaces(
    $filter: ModelSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        pic
        description
        public
        thread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        pois {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const getSession = /* GraphQL */ `
  query GetSession($id: ID!) {
    getSession(id: $id) {
      id
      externalID
      name
      pic
      hiddenPic
      shortDescription
      description
      more
      tags
      hide
      live
      isFeatured
      isKeynote
      preroll
      startdate
      starttime
      enddate
      endtime
      duration
      presenters
      presenterIDs
      facilitatorIDs
      permitted
      related
      thread
      threadName
      hideThread
      gotoThread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      useSessionViewer
      video
      interactive
      content
      videochat
      videochatServer
      videoChatAllowNonPresenters
      collateral
      collateralName
      actives {
        items {
          id
          userID
          sessionID
          createdAt
          updatedAt
          isFave
          visitActions
          mediaActions
          playbackActions
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        externalID
        name
        pic
        hiddenPic
        shortDescription
        description
        more
        tags
        hide
        live
        isFeatured
        isKeynote
        preroll
        startdate
        starttime
        enddate
        endtime
        duration
        presenters
        presenterIDs
        facilitatorIDs
        permitted
        related
        thread
        threadName
        hideThread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        useSessionViewer
        video
        interactive
        content
        videochat
        videochatServer
        videoChatAllowNonPresenters
        collateral
        collateralName
        actives {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const getSessionActivity = /* GraphQL */ `
  query GetSessionActivity($id: ID!) {
    getSessionActivity(id: $id) {
      id
      userID
      sessionID
      createdAt
      updatedAt
      isFave
      visitActions
      mediaActions
      playbackActions
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      session {
        id
        externalID
        name
        pic
        hiddenPic
        shortDescription
        description
        more
        tags
        hide
        live
        isFeatured
        isKeynote
        preroll
        startdate
        starttime
        enddate
        endtime
        duration
        presenters
        presenterIDs
        facilitatorIDs
        permitted
        related
        thread
        threadName
        hideThread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        useSessionViewer
        video
        interactive
        content
        videochat
        videochatServer
        videoChatAllowNonPresenters
        collateral
        collateralName
        actives {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const listSessionActivitys = /* GraphQL */ `
  query ListSessionActivitys(
    $filter: ModelSessionActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessionActivitys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        sessionID
        createdAt
        updatedAt
        isFave
        visitActions
        mediaActions
        playbackActions
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        session {
          id
          externalID
          name
          pic
          hiddenPic
          shortDescription
          description
          more
          tags
          hide
          live
          isFeatured
          isKeynote
          preroll
          startdate
          starttime
          enddate
          endtime
          duration
          presenters
          presenterIDs
          facilitatorIDs
          permitted
          related
          thread
          threadName
          hideThread
          gotoThread
          useSessionViewer
          video
          interactive
          content
          videochat
          videochatServer
          videoChatAllowNonPresenters
          collateral
          collateralName
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getPoi = /* GraphQL */ `
  query GetPoi($id: ID!) {
    getPOI(id: $id) {
      id
      name
      pic
      description
      pos
      thread
      space
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      inspace {
        id
        name
        pic
        description
        public
        thread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        pois {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const listPoIs = /* GraphQL */ `
  query ListPoIs(
    $filter: ModelPOIFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPOIs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        pic
        description
        pos
        thread
        space
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        inspace {
          id
          name
          pic
          description
          public
          thread
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getPartner = /* GraphQL */ `
  query GetPartner($id: ID!) {
    getPartner(id: $id) {
      id
      name
      pic
      headerpic
      headerlink
      shortDescription
      description
      more
      phone
      email
      tags
      hide
      priority
      staff
      related
      threadName
      thread
      hideThread
      gotoThread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      video
      slides
      downloadables
      actives {
        items {
          id
          userID
          partnerID
          createdAt
          updatedAt
          isFave
          mediaActions
          playbackActions
          visitActions
          linkActions
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const listPartners = /* GraphQL */ `
  query ListPartners(
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        pic
        headerpic
        headerlink
        shortDescription
        description
        more
        phone
        email
        tags
        hide
        priority
        staff
        related
        threadName
        thread
        hideThread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        video
        slides
        downloadables
        actives {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const getPartnerActivity = /* GraphQL */ `
  query GetPartnerActivity($id: ID!) {
    getPartnerActivity(id: $id) {
      id
      userID
      partnerID
      createdAt
      updatedAt
      isFave
      mediaActions
      playbackActions
      visitActions
      linkActions
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      partner {
        id
        externalID
        name
        pic
        hiddenPic
        shortDescription
        description
        more
        tags
        hide
        live
        isFeatured
        isKeynote
        preroll
        startdate
        starttime
        enddate
        endtime
        duration
        presenters
        presenterIDs
        facilitatorIDs
        permitted
        related
        thread
        threadName
        hideThread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        useSessionViewer
        video
        interactive
        content
        videochat
        videochatServer
        videoChatAllowNonPresenters
        collateral
        collateralName
        actives {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const listPartnerActivitys = /* GraphQL */ `
  query ListPartnerActivitys(
    $filter: ModelPartnerActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartnerActivitys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        partnerID
        createdAt
        updatedAt
        isFave
        mediaActions
        playbackActions
        visitActions
        linkActions
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        partner {
          id
          externalID
          name
          pic
          hiddenPic
          shortDescription
          description
          more
          tags
          hide
          live
          isFeatured
          isKeynote
          preroll
          startdate
          starttime
          enddate
          endtime
          duration
          presenters
          presenterIDs
          facilitatorIDs
          permitted
          related
          thread
          threadName
          hideThread
          gotoThread
          useSessionViewer
          video
          interactive
          content
          videochat
          videochatServer
          videoChatAllowNonPresenters
          collateral
          collateralName
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      pic
      shortDescription
      description
      more
      tags
      hide
      related
      priority
      curation
      isPublic
      validMembers
      downloadables
      thread
      gotoThread
      mainthread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      members {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      admins {
        items {
          id
          userID
          groupID
          createdAt
          isFave
          approved
          visitActions
        }
        nextToken
      }
      owner
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        pic
        shortDescription
        description
        more
        tags
        hide
        related
        priority
        curation
        isPublic
        validMembers
        downloadables
        thread
        gotoThread
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        members {
          nextToken
        }
        admins {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const getThreadMember = /* GraphQL */ `
  query GetThreadMember($id: ID!) {
    getThreadMember(id: $id) {
      id
      userID
      threadID
      lastRead
      viewedCount
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      thread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
    }
  }
`;
export const listThreadMembers = /* GraphQL */ `
  query ListThreadMembers(
    $filter: ModelThreadMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThreadMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        threadID
        lastRead
        viewedCount
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        thread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const getThread = /* GraphQL */ `
  query GetThread($id: ID!) {
    getThread(id: $id) {
      id
      name
      pic
      isPrivate
      positiveCuration
      disallowVideo
      type
      updatedAt
      comments {
        items {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        nextToken
      }
      users {
        items {
          id
          userID
          threadID
          lastRead
          viewedCount
        }
        nextToken
      }
      owner
    }
  }
`;
export const listThreads = /* GraphQL */ `
  query ListThreads(
    $filter: ModelThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThreads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const getReaction = /* GraphQL */ `
  query GetReaction($id: ID!) {
    getReaction(id: $id) {
      id
      userID
      commentID
      like
      emoji
      reported
      updatedAt
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      comment {
        id
        thread
        inThread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        createdAt
        curation
        reporterID
        userID
        reactions {
          nextToken
        }
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        content
        owner
      }
      owner
    }
  }
`;
export const listReactions = /* GraphQL */ `
  query ListReactions(
    $filter: ModelReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        commentID
        like
        emoji
        reported
        updatedAt
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        comment {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      thread
      inThread {
        id
        name
        pic
        isPrivate
        positiveCuration
        disallowVideo
        type
        updatedAt
        comments {
          nextToken
        }
        users {
          nextToken
        }
        owner
      }
      createdAt
      curation
      reporterID
      userID
      reactions {
        items {
          id
          userID
          commentID
          like
          emoji
          reported
          updatedAt
          owner
        }
        nextToken
      }
      user {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      content
      owner
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        thread
        inThread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        createdAt
        curation
        reporterID
        userID
        reactions {
          nextToken
        }
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        content
        owner
      }
      nextToken
    }
  }
`;
export const listUserBySpace = /* GraphQL */ `
  query ListUserBySpace(
    $space: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserBySpace(
      space: $space
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        firstName
        secondName
        emailAddress
        description
        isPresenter
        isEventStaff
        type
        title
        company
        showInList
        blocked
        allowInvitations
        tags
        homePage
        space
        color
        skincolor
        hairstyle
        haircolor
        animation
        avoid3D
        pos
        lastOnline
        updatedAt
        createdAt
        currentThread
        comments {
          nextToken
        }
        reactions {
          nextToken
        }
        threads {
          nextToken
        }
        groups {
          nextToken
        }
        friends
        owner
      }
      nextToken
    }
  }
`;
export const listNotificationsByUser = /* GraphQL */ `
  query ListNotificationsByUser(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationsByUser(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        originUser
        body
        type
        link
        markedRead
        createdAt
        lastRead
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const listSessionActivityByUser = /* GraphQL */ `
  query ListSessionActivityByUser(
    $userID: ID
    $sessionID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSessionActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessionActivityByUser(
      userID: $userID
      sessionID: $sessionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        sessionID
        createdAt
        updatedAt
        isFave
        visitActions
        mediaActions
        playbackActions
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        session {
          id
          externalID
          name
          pic
          hiddenPic
          shortDescription
          description
          more
          tags
          hide
          live
          isFeatured
          isKeynote
          preroll
          startdate
          starttime
          enddate
          endtime
          duration
          presenters
          presenterIDs
          facilitatorIDs
          permitted
          related
          thread
          threadName
          hideThread
          gotoThread
          useSessionViewer
          video
          interactive
          content
          videochat
          videochatServer
          videoChatAllowNonPresenters
          collateral
          collateralName
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const listSessionActivityBySession = /* GraphQL */ `
  query ListSessionActivityBySession(
    $sessionID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSessionActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessionActivityBySession(
      sessionID: $sessionID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        sessionID
        createdAt
        updatedAt
        isFave
        visitActions
        mediaActions
        playbackActions
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        session {
          id
          externalID
          name
          pic
          hiddenPic
          shortDescription
          description
          more
          tags
          hide
          live
          isFeatured
          isKeynote
          preroll
          startdate
          starttime
          enddate
          endtime
          duration
          presenters
          presenterIDs
          facilitatorIDs
          permitted
          related
          thread
          threadName
          hideThread
          gotoThread
          useSessionViewer
          video
          interactive
          content
          videochat
          videochatServer
          videoChatAllowNonPresenters
          collateral
          collateralName
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const listPoiBySpace = /* GraphQL */ `
  query ListPoiBySpace(
    $space: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPOIFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPOIBySpace(
      space: $space
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        pic
        description
        pos
        thread
        space
        mainthread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        inspace {
          id
          name
          pic
          description
          public
          thread
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const listPartnerActivityByUser = /* GraphQL */ `
  query ListPartnerActivityByUser(
    $userID: ID
    $partnerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPartnerActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartnerActivityByUser(
      userID: $userID
      partnerID: $partnerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        partnerID
        createdAt
        updatedAt
        isFave
        mediaActions
        playbackActions
        visitActions
        linkActions
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        partner {
          id
          externalID
          name
          pic
          hiddenPic
          shortDescription
          description
          more
          tags
          hide
          live
          isFeatured
          isKeynote
          preroll
          startdate
          starttime
          enddate
          endtime
          duration
          presenters
          presenterIDs
          facilitatorIDs
          permitted
          related
          thread
          threadName
          hideThread
          gotoThread
          useSessionViewer
          video
          interactive
          content
          videochat
          videochatServer
          videoChatAllowNonPresenters
          collateral
          collateralName
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const listPartnerActivityByPartner = /* GraphQL */ `
  query ListPartnerActivityByPartner(
    $partnerID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPartnerActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartnerActivityByPartner(
      partnerID: $partnerID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        partnerID
        createdAt
        updatedAt
        isFave
        mediaActions
        playbackActions
        visitActions
        linkActions
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        partner {
          id
          externalID
          name
          pic
          hiddenPic
          shortDescription
          description
          more
          tags
          hide
          live
          isFeatured
          isKeynote
          preroll
          startdate
          starttime
          enddate
          endtime
          duration
          presenters
          presenterIDs
          facilitatorIDs
          permitted
          related
          thread
          threadName
          hideThread
          gotoThread
          useSessionViewer
          video
          interactive
          content
          videochat
          videochatServer
          videoChatAllowNonPresenters
          collateral
          collateralName
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const listGroupMembershipsByUser = /* GraphQL */ `
  query ListGroupMembershipsByUser(
    $userID: ID
    $groupID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupMembershipsByUser(
      userID: $userID
      groupID: $groupID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        groupID
        createdAt
        isFave
        approved
        visitActions
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        group {
          id
          name
          pic
          shortDescription
          description
          more
          tags
          hide
          related
          priority
          curation
          isPublic
          validMembers
          downloadables
          thread
          gotoThread
          owner
        }
      }
      nextToken
    }
  }
`;
export const listGroupMembershipsByGroup = /* GraphQL */ `
  query ListGroupMembershipsByGroup(
    $groupID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupMembershipsByGroup(
      groupID: $groupID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        groupID
        createdAt
        isFave
        approved
        visitActions
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        group {
          id
          name
          pic
          shortDescription
          description
          more
          tags
          hide
          related
          priority
          curation
          isPublic
          validMembers
          downloadables
          thread
          gotoThread
          owner
        }
      }
      nextToken
    }
  }
`;
export const listThreadMembershipByUser = /* GraphQL */ `
  query ListThreadMembershipByUser(
    $userID: ID
    $threadID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelThreadMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThreadMembershipByUser(
      userID: $userID
      threadID: $threadID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        threadID
        lastRead
        viewedCount
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        thread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const listReactionsByUser = /* GraphQL */ `
  query ListReactionsByUser(
    $userID: ID
    $commentID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReactionsByUser(
      userID: $userID
      commentID: $commentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        commentID
        like
        emoji
        reported
        updatedAt
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        comment {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const listReactionsByComment = /* GraphQL */ `
  query ListReactionsByComment(
    $commentID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReactionsByComment(
      commentID: $commentID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        commentID
        like
        emoji
        reported
        updatedAt
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        comment {
          id
          thread
          createdAt
          curation
          reporterID
          userID
          content
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const listCommentsByUser = /* GraphQL */ `
  query ListCommentsByUser(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommentsByUser(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        thread
        inThread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        createdAt
        curation
        reporterID
        userID
        reactions {
          nextToken
        }
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        content
        owner
      }
      nextToken
    }
  }
`;
export const listCommentsByThread = /* GraphQL */ `
  query ListCommentsByThread(
    $thread: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommentsByThread(
      thread: $thread
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        thread
        inThread {
          id
          name
          pic
          isPrivate
          positiveCuration
          disallowVideo
          type
          updatedAt
          owner
        }
        createdAt
        curation
        reporterID
        userID
        reactions {
          nextToken
        }
        user {
          id
          name
          firstName
          secondName
          emailAddress
          description
          isPresenter
          isEventStaff
          type
          title
          company
          showInList
          blocked
          allowInvitations
          tags
          homePage
          space
          color
          skincolor
          hairstyle
          haircolor
          animation
          avoid3D
          pos
          lastOnline
          updatedAt
          createdAt
          currentThread
          friends
          owner
        }
        content
        owner
      }
      nextToken
    }
  }
`;
