
import React, { useEffect, useState, createRef } from 'react';
import { Box, Button, Grid, List, Menu, Text  } from 'grommet';
import { Alarm, Chat, CircleInformation, FormDown, Video } from 'grommet-icons';
import moment from 'moment';
import randomColor from 'randomcolor';
import { Scrollbars } from 'react-custom-scrollbars';

import RoutedButton from './RoutedButton';
import { ModalLink } from 'react-router-modal-gallery';

import S3ImageAvatar from './S3ImageAvatar';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

import { Activity, LVL } from '../Activity';
import EventParams from '../EventItems';

const PeopleList = (props) => {
    const [users, setUsers ] = useState ([]);
    const [sortedUsers, setSortedUsers] = useState([]);
    const [friends, setFriends] = useState([]);
    const [refs, setRefs] = useState([]);
    const { userID, filter, mini, showFavesOnly } = props;

    const scrollToShow = id => {
        //console.log ("getting ref to scroll item " + id + " into view from " + refs.length);
        const selectedItem = refs[id];
        //console.log (selectedItem);
        if (selectedItem.current) {
            selectedItem.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            } );
        }
    };

    useEffect( () => {
        //console.log ("Listing people for: ", userID);
        getUserListAsync ();
    }, []);

    useEffect( () => {
        // run when users get loaded or updated
        const filterLower = filter ? filter.toLowerCase() : "";

       const friendUsers = (showFavesOnly && friends.length > 0) ? users.filter(user => friends.includes (user.id)) : users;
        
        const filteredUsers = (filterLower.length) ? friendUsers.filter(user => user.name.toLowerCase().includes(filterLower)) : friendUsers;

        const viewUsers = filteredUsers.sort (function(a,b){
            const aName = a.name.toLowerCase();
            const bName = b.name.toLowerCase();
            let result = (aName < bName ? -1 : (aName > bName ? 1 : 0));
            
            return result;
        });

        setSortedUsers (viewUsers);

        setRefs (viewUsers.reduce((acc, value) => {
            acc[value.id] = createRef();
            return acc;
            }, {}
        ));        

    }, [users, filter, friends, showFavesOnly]);

    const getUserListAsync = async () => {
        try {
            const allUsers = await API.graphql(graphqlOperation(queries.listUsers, { limit: 5000 } ));
            const foundUserList = allUsers.data.listUsers.items;
            // filter users that are private and have not friended us
            const viewableUserList = foundUserList.filter (usr => {
                //console.log (usr);
                if (usr.showInList) { 
                    return true; 
                } else if (usr.friends) {
                    const friendList = JSON.parse(usr.friends);
                    //console.log (friendList);
                    if (friendList && Array.isArray(friendList)) {
                        if (friendList.includes (userID)) {
                            return true; 
                        }
                    }
                }
                return false;
            });
            setUsers (viewableUserList);

            // Also who are our friends?
            const myUser = await API.graphql(graphqlOperation(queries.getUser, {id:userID}));
            if (myUser.data.getUser) {
                const storedUserData = myUser.data.getUser;
                if (storedUserData.friends) {
                    const newFriendList = JSON.parse(storedUserData.friends);
                    
                    setFriends (Array.isArray(newFriendList) ? newFriendList : []); 
                } else {
                    setFriends ([]); 
                }
            }
        } catch (err) {

        }
    }

    const listUsers = sortedUsers.map ((item) => {
        const isAsFriend = friends.includes (item.id) ? 1 : 0;

        //let hasMeAsFriend = 0;
        //if (item.friends) {
        //    const friendList = JSON.parse(item.friends);
            //console.log (friendList);
        //    if (friendList && Array.isArray(friendList)) {
        //        hasMeAsFriend = friendList.includes (userID) ? 1 : 0;
        //    }
        //};

        return (
        <Box 
        key={item.id} ref={refs[item.id]}
        direction="row"  round='small'
        gap="medium" align="start" pad="xxsmall" gap="xsmall" >
            <ModalLink to={`people/${encodeURIComponent(item.id)}`}>
            <S3ImageAvatar size='xlarge' color={item.color} userID={item.id}/>
            </ModalLink>
            
            <Box direction='column' gap='xsmall'>
                <Text size='medium' weight='bold' >{item.name}</Text>
                <Box direction='row' gap='xsmall'>
                    <ModalLink to={`people/${encodeURIComponent(item.id)}`}>
                        <Button plain label=<CircleInformation size='small' color='brand' /> />
                    </ModalLink>
                    <Button plain icon=<EventParams.Favorite size='small' on={isAsFriend} color='brand' /> 
                        onClick={() => { toggleFavorite (userID, item.id) }} />
                </Box>
            </Box>
        </Box>
    )});
    
    const toggleFavorite = async (myID, theirID) => {
        //console.log ("toggle fave:", myID);
        const isFriend = friends.includes (theirID);
        if (isFriend) {
            const newFaves = friends.filter (item => item !== theirID);
            setFriends (newFaves);
            updateFaves (myID, newFaves);
        } else {
            const newFaves = [
                ...friends,
                theirID
            ]
            setFriends (newFaves);
            updateFaves (myID, newFaves);
        }
    }

    const updateFaves = async (myID, newFaves) => {
        //console.log ("Update faves", myID);
        const input = {
            id: myID,
            friends: JSON.stringify(newFaves)
        };

        //console.log (input);
        try {
            const tweakedUser = await API.graphql(graphqlOperation(mutations.updateUser, { input }));
            //console.log (tweakedUser);

        } catch (err) {
            console.log ("Unable to set User favorites: ", err);
            //console.log (input);
        }
    }

    const listUserBoxes = sortedUsers.map ((item) => {
        const isAsFriend = friends.includes (item.id) ? 1 : 0;

        return (
            <Box key={item.id} ref={refs[item.id]}
                round="medium"
                elevation="medium"
                background="background-front"
                animation="slideUp"
                justify="between"
                align="center"
                direction="column"
            >
                <Box flex direction="column" align="center" pad='medium' gap='xxsmall'>
                    <ModalLink to={`people/${encodeURIComponent(item.id)}`}>
                    	<S3ImageAvatar size='xxlarge' color={item.color} userID={item.id}/>
                    </ModalLink>
                    <Text size='large' color='brand' >{item.name}</Text>
                    <Text size='small' weight='bold' >{item.title}</Text>
                    <Text size='small'  weight='bold' >{item.company}</Text>
                </Box>
                
                <Box background="tab"
                    round={{ size:"medium", corner:'bottom' }} 
                    fill='horizontal' align='center' direction='row'
                    justify="between" pad={{ vertical: "xsmall", horizontal: 'small'}} >
                    <Button plain icon=<EventParams.Favorite on={isAsFriend} color='brand' /> 
                        onClick={() => { toggleFavorite (userID, item.id) }} />
                    <ModalLink to={`people/${encodeURIComponent(item.id)}`}>
                        <Button plain label=<CircleInformation size='small' color='brand' /> />
                    </ModalLink>
                </Box>
            </Box>

    )});

    return (
        <Scrollbars >
            <Grid gap="small" columns={mini ? 'small' : 'medium'}>
                {mini ? listUsers : listUserBoxes}
            </Grid>
            
        </Scrollbars>
    );
}

export default PeopleList;