import React, { useState, useEffect } from 'react';

import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import { useRouteMatch, useHistory, useLocation } from "react-router-dom";

import { Box, Button, Grid, Heading, Menu, Text, ResponsiveContext, Stack  } from 'grommet';
import { Alarm, CirclePlay, Edit } from 'grommet-icons';
import { Scrollbars } from 'react-custom-scrollbars';

import RoutedButton from './RoutedButton';
import MoreWidget from './MoreWidget';
import S3ImageBox from './S3ImageBox';
import { Activity, LVL, createCalendarLinkFromSession } from '../Activity';

import PartnerDetail from './VevPartnerDetail';
import PartnerEditor from './VevPartnerEditor';
import EventParams from '../EventItems';
import PeopleList from './VevPeople';

import TagInput from './VevTagFilter';

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  
function shorten (str, maxLen, separator = ' ') {
if (str.length <= maxLen) return str;
return str.substr(0, str.lastIndexOf(separator, maxLen)) + "...";
}

const PartnersList = (props) => {
    const [ partners, setPartners ] = useState([]);
    const [ favorites, setFavorites ] = useState({});
    const [ visited, setVisited ] = useState({});
    const [visitedCount, setVisitedCount] = useState(0);

    const [ viewPartners, setViewPartners ] = useState([]);

    const [showFavesOnly, setShowFavesOnly] = useState(false);

    const [selectedTags, setSelectedTags] = useState([]);
    const [suggestions, setSuggestions] = useState (EventParams.Tags);
    const [filterString, setFilterString] = useState ("");
    const { canAdmin, userID, userName, goToChatThread } = props;

    let { path, url } = useRouteMatch();
    let query = useQuery();
    let history = useHistory();

    const getPartnerListAsync = async () => {
        try {
            const allPartners = await API.graphql(graphqlOperation(queries.listPartners, { limit: 1000 } ));
            const foundPartnerList = allPartners.data.listPartners.items;
            
            setPartners (foundPartnerList);

            // for each item in Partner list, we must have a favorites item too

            // get Partner activity too, if it exists
            try {
                const activityQuery = await API.graphql(graphqlOperation(queries.listPartnerActivityByUser, { userID:userID, limit: 1000 }  ));

                const foundActivity = activityQuery.data.listPartnerActivityByUser.items;

                // we want to access fave or not like this: favorites[PartnerDetails.id]
                const currentFaves = foundPartnerList.reduce((acc, cur) => 
                    ({ ...acc, [cur.id]: (isAFave (foundActivity, cur.id)) }), {});
                
                //console.log (currentFaves);
                setFavorites (currentFaves);

                const numVisited = foundActivity.filter (act => act.visitActions && act.visitActions.length > 10).length;
                setVisitedCount (numVisited);

                const currentVisits = foundPartnerList.reduce((acc, cur) => 
                    ({ ...acc, [cur.id]: (hasVisited (foundActivity, cur.id)) }), {});
                
                //console.log (currentFaves);
                setVisited (currentVisits);


            } catch (err) {
                console.log ("Unable to get Activity: ", err);
            }
        } catch (err) {

        }
    }

    const isAFave = (actArray, id) => {
        let result = false;
        const matchActivity = actArray.find (act => act.partnerID === id);
        if (matchActivity) {
            result = matchActivity.isFave; // null or undefined will be false?
        }
        return result;
    }

    const hasVisited = (actArray, id) => {
        let result = false;
        const matchActivity = actArray.find (act => act.partnerID === id);
        if (matchActivity) {
            result = (matchActivity.visitActions && matchActivity.visitActions.length > 10); // null or undefined will be false?
        }
        return result;
    }

    const toggleFavorite = async (id, name) => {
        const newValue = !(favorites[id]);
        const newFaves = {
            ...favorites,
            [id]: newValue
        }
        setFavorites (newFaves);
        updatePartnerFave (id, name, newValue);
    }

    const updatePartnerFave = async (partnerId, partnerName, isFave) => {
        try {
            const activityQuery = await API.graphql(graphqlOperation(queries.listPartnerActivityByUser, { userID:userID, limit: 1000 } ));

            if (isFave) {
                const eventData = {
                    partner: { id: partnerId, name: partnerName },
                    userID: userID,
                    userName: userName,
                    item: 'favorite'
                }
                //console.log ("Event Entry: " + JSON.stringify (eventData));
                Activity.track ('partnerActivity', eventData, false);
            }

            const foundActivity = activityQuery.data.listPartnerActivityByUser.items;
            if (foundActivity) {
                //console.log (foundActivity);
                const partnerMatch = foundActivity.find (act => act.partnerID === partnerId);
            
                if (!partnerMatch) {
                    const input = {isFave: isFave, userID: userID, partnerID: partnerId};
                    const updatedPartnerActivity = await API.graphql(graphqlOperation(mutations.createPartnerActivity, {input: input}));
                    //console.log (updatedPartnerPartnerActivity);
                    
                } else {
                    const input = {id: partnerMatch.id, isFave: isFave};
                    const updatedPartnerActivity = await API.graphql(graphqlOperation(mutations.updatePartnerActivity, {input: input}));
                    //console.log (updatedPartnerActivity);
                }
            }
        } catch (err) {
          console.log ("Couldn't record partner activity: " + err.message);
          console.log (err);
        }
      }

    useEffect(() => {

        return history.listen((location) => {
            console.log(`You changed the page to: ${location.pathname}`)
        })
    
    },[history])

    useEffect( () => {
        console.log ("Getting Partners for: " + userID)
        getPartnerListAsync ();

        setFilterString (query.get('filter'));
        const querySuggestions = query.get('tags');
        setSelectedTags ((querySuggestions && querySuggestions.length > 3) ? querySuggestions.split(',') : []);
    }, []);

    useEffect( () => {
        let partnersToSort = partners.filter(partnerDetails => (canAdmin || !partnerDetails.hide) && (showFavesOnly === false || favorites[partnerDetails.id] === true));;
        
        if ((selectedTags && selectedTags.length > 0) || (filterString && filterString.length > 2)) {
            const taggedpartners = (selectedTags.length === 0) ? [...partnersToSort] :
            partnersToSort.filter (partnerDetails => {
                    const tagArray = (partnerDetails.tags === null) ? [] : partnerDetails.tags.split(',');
                    return (tagArray.length === 0) ? false :
                    tagArray.some (s => selectedTags.indexOf(s) >= 0);
            });

            partnersToSort = [...taggedpartners];
        }
        
        // Now sort
        partnersToSort.sort (function(a,b){ 
            let result = (a.priority > b.priority ? -1 : (a.priority < b.priority ? 1 : 0));
            if (result !== 0) return result;
            result = (a.name < b.name ? -1 : (a.name > b.name ? 1 : 0));
            
            return result;
        })

        // edit the viewpartners
        setViewPartners (partnersToSort);

    }, [canAdmin, partners, showFavesOnly, selectedTags, filterString]);
    
    const onRemoveTag = tag => {
      const removeIndex = selectedTags.indexOf(tag);
      const newTags = [...selectedTags];
      if (removeIndex >= 0) {
        newTags.splice(removeIndex, 1);
      }
      setSelectedTags(newTags);
    };
  
    const onAddTag = tag => setSelectedTags([...selectedTags, tag]);
  
    const onFilterSuggestion = value => {
      setSuggestions(
        EventParams.Tags.filter(
          suggestion => suggestion.toLowerCase().indexOf(value.toLowerCase()) >= 0
        )
      );
      setFilterString (value); 
    }

    const listPartnerTexts = viewPartners.map (partnerDetails => {
        const isVisited = visited[partnerDetails.id];
        const clippedName = partnerDetails.name.length > 24 ? (partnerDetails.name.substring(0,22) + '...') : partnerDetails.name;

        return (
            <Box height='xxsmall' fill='horizontal' pad={{ vertical: 'small'}}>
                <Box direction='row' gap='small' >
                    <Box border={{ color: 'brand', size: 'small' }} background={isVisited ? 'brand' :'background-front'}
                        round width='18px' height='18px'/>
                    <RoutedButton plain path={`${url}/${encodeURIComponent(partnerDetails.id)}`} >
                        <Text size='small' color='brand' truncate overflow='hidden'>{clippedName}</Text>
                    </RoutedButton>
                </Box>
            </Box>
        )});

    const listPartnerBoxes = (partnerSubList) => partnerSubList.map(partnerDetails => {
        const picUrl = partnerDetails.pic; //encodeURI(partnerDetails.pic);

        return (
            <Box 
                round="medium"
                elevation="medium"
                key={partnerDetails.id}
                background="background-contrast"
                animation="slideUp"
                justify="start"
                align="start"
                direction="column"
            >
                <Stack height="small" fill='horizontal' anchor='top-right'>
                    <RoutedButton fill plain
                        path={`${url}/${encodeURIComponent(partnerDetails.id)}`}
                        label=<S3ImageBox height="small" fill='horizontal' round={{size: "medium", corner: "top"}}
                            positioning='center' src={picUrl} />
                    />

                </Stack>

                <Box height='74px' fill='horizontal' overflow='hidden'
                    pad='small'>
                        <RoutedButton plain path={`${url}/${encodeURIComponent(partnerDetails.id)}`} >
                            <Text size='large' color='brand' truncate>{partnerDetails.name}</Text>
                        </RoutedButton>

                </Box>
                <Box height='xsmall' overflow='hidden' justify='between'
                    pad={{ left: "small", right: "small", bottom:"medium"}}>
                    
                    <Text size="small">{partnerDetails.shortDescription} </Text> 
                    
                </Box>
                <Box background="tab"
                    round={{ size:"medium", corner:'bottom' }} 
                    fill='horizontal' align='center' direction='row'
                    justify="between" pad={{ vertical: "xsmall", horizontal: 'small'}} >
                    <Button plain icon=<EventParams.Favorite on={favorites[partnerDetails.id] ? 1 : 0} color={EventParams.FavoriteColor} /> onClick={() => {toggleFavorite (partnerDetails.id, partnerDetails.name)}} />
                    <RoutedButton plain disabled={!partnerDetails.video || partnerDetails.video.length === 0}
                    path={`${url}/${encodeURIComponent(partnerDetails.id)}?play=true`} size='small' icon={<CirclePlay  color='brand' />} />
                    {canAdmin &&
                        <RoutedButton plain label=<Edit color='brand' />
                        path={`/partnerEditor/${encodeURIComponent(partnerDetails.id)}`} /> }

                </Box>

            </Box>
        )});



    const partnerChoices = canAdmin ? 
    [
        { label: 'Overview', onClick: () => {setShowFavesOnly (false);} },
        { label: 'My Favorites', onClick: () => {setShowFavesOnly (true);} },
        { label: 'New Partner', disabled:(!canAdmin), onClick: () => {history.push ('partnerEditor/new')} },
    ] : [
        { label: 'Overview', onClick: () => {setShowFavesOnly (false);} },
        { label: 'My Favorites', onClick: () => {setShowFavesOnly (true);} },
    ]
    ;

    return (
        <ResponsiveContext.Consumer>
        {responsive => ( 
            <Box
                a11yTitle='Partner List'
                fill
                tag='footer'
                direction='column'
                align='center'
                justify='between'
                pad={{ left: 'medium', right: 'small', vertical: 'xsmall' }}
                gap='medium'
            background={EventParams.PartnersBackdrop.length > 4 ? { image: EventParams.PartnersBackdrop } : undefined}
                {...props}
                >
                    <Box fill='horizontal' align='center' direction='row' gap='small'>
                <Heading color='brand' level='2'>Exhibit Hall</Heading>
                        <MoreWidget size={32} choices={partnerChoices} />
                    </Box>

            <Box fill overflow='auto'>
                <Box fill direction='row-responsive' gap='small'>
                    {EventParams.HasPunchCard && responsive !== 'small' &&
                    <Box direction='column' 
                            width='medium'
                            fill={responsive === 'small' ? 'horizontal' : undefined}
                            overflow='auto' background='background-front' pad='xsmall'
                            elevation='medium' round='small'  gap='xsmall' >
                        <Box round='medium' height='164px'
                        background={{ color: 'background-back', image: EventParams.PunchCardImage }} />
                        <Box round='medium' background='brand' align='center' pad='medium'>
                            <Text size='medium'>You have visited</Text>
                            <Text size='medium'>{`${visitedCount} of ${viewPartners.length}`}</Text>
                            <Text size='medium'>exhibitors</Text>
                        </Box>
                        <Box flex direction='column' pad='medium'>
                            <Text size='xsmall' textAlign='center' >{EventParams.PunchCardText}</Text>
                            {listPartnerTexts}
                        </Box>
                    </Box> }

                    <Box fill='horizontal' align='start' direction='column' gap='medium'
                                    margin={{ horizontal: "small" }}>

                        {canAdmin && <TagInput
                            placeholder="FILTER"
                            suggestions={suggestions}
                            value={selectedTags}
                            onRemove={onRemoveTag}
                            onAdd={onAddTag}
                            onChange={({ target: { value } }) => onFilterSuggestion(value)}
                        />}
                        <Box fill overflow='auto' justify='start' gap='small'>
                            <Heading margin='xsmall' color='brand' level='2' >Gold</Heading>

                            <Grid margin={{ right: "small" }} gap="medium"
                                columns={responsive === 'small' ? '90vw' : "medium"} >
                                {listPartnerBoxes (viewPartners.filter (partner => partner.priority >= 30 &&  partner.priority < 40))}
                            </Grid>
                            <Heading margin='xsmall' color='brand' level='2' >Silver</Heading>
                            <Grid margin={{ right: "small" }} gap="medium"
                                columns={responsive === 'small' ? '90vw' : "medium"} >
                                {listPartnerBoxes (viewPartners.filter (partner => partner.priority >= 20 &&  partner.priority < 30))}
                            </Grid>
                            <Heading margin='xsmall' color='brand' level='2' >Bronze</Heading>
                            <Grid margin={{ right: "small" }} gap="medium"
                                columns={responsive === 'small' ? '90vw' : "medium"} >
                                {listPartnerBoxes (viewPartners.filter (partner => partner.priority >= 10 &&  partner.priority < 20))}
                                </Grid>
                            </Box>
                        </Box>
                            
                </Box>
                        
                    </Box>
                </Box>
        )}
        </ResponsiveContext.Consumer>
    );
}

export default PartnersList;