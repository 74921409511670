import React from 'react';
import { Box } from 'grommet';
import EventParams from '../EventItems';

const AppBar = (props) => (
  <Box
    a11yTitle='Event header bar'
    fill='horizontal'
    tag='header'
    direction='row'
    align='center'
    justify='between'
    background={EventParams.FlipHeaderFooterColors ? 'secondary' : 'brand'}
    pad={{ left: 'medium', right: 'small', vertical: 'xsmall' }}
    elevation='medium'
    style={{ zIndex: '1' }}
    height='xxsmall'
    {...props}
  />
)

export default  AppBar;