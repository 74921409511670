import React, { useEffect, useState } from 'react';
import { Box, Button, Calendar, DropButton, Heading, Image, Form, FormField, Grid, Layer, Menu, Text, TextInput, TextArea, CheckBox  } from 'grommet';
import { Save, FormDown, Previous } from 'grommet-icons';

import { useParams, useLocation, useHistory, useRouteMatch } from "react-router-dom";
import {v4 as uuid} from 'uuid';

import { API, graphqlOperation, Storage } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';
import usePrevious from '../utils/use-previous'
import moment from 'moment';
import { toast } from 'react-toastify';

import RoutedButton from './RoutedButton';
import { Dropzone } from "./Dropzone";
import S3ImageBox from './S3ImageBox';
import { Scrollbars } from 'react-custom-scrollbars';
import MoreWidget from './MoreWidget';

import { Activity, LVL } from '../Activity';

import EventParams from '../EventItems';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


function makePrefix (str) {
  //console.log (str)
  var strLower = str.toLowerCase();
  var reduced = strLower.replace(/[\W_]/g, '');
  const chopped = reduced.slice(0,15);
  //console.log (chopped);
  return chopped;
}

const GroupEditor = (props) => {
  const [ID, setID] = useState("");
  const [name, setName] = useState("New Group");
  const [thread, setThread] = useState(null);
  const [pic, setPic] = useState("");
  const [picFiles, setPicFiles] = useState([]);

  const [shortDescription, setShortDescription] = useState("Group");
  const [description, setDescription] = useState("Group");
  const [more, setMore] = useState("");
  const [priority, setPriority] = useState(0);
  const [tags, setTags] = useState([]);
  const [hide, setHide] = useState(true);
  const [isPublic, setIsPublic] = useState(true);
  const [curation, setCuration] = useState("");

  const [members, setMembers] = useState([]);
  const [validMembers, setValidMembers] = useState([]);

  const [collateral, setCollateral] = useState("");
  const [collateralName, setCollateralName] = useState("");
  const [collateralFiles, setCollateralFiles] = useState([]);
  const [related, setRelated] = useState("");

  const [groupDelete, setGroupDelete] = useState(false);

  let { groupId } = useParams ();
  let { url } = useRouteMatch ();
  let query = useQuery();
  let history = useHistory ();

  const { changed } = props;
  
  const storeGroup = async () => {
    const groupShortName = makePrefix(name);
    //console.log ("Create thread for: " + groupShortName);
    const threadId = (ID !== null && ID.length > 3 && thread !== null && thread.length > 3) ?
      thread : groupShortName + '_' + uuid();

    const input = {
      ...(ID !== null && ID.length > 3) && {id: ID},
      name: name,
      pic: picFiles.length > 0 ? null : pic,
      shortDescription: shortDescription,
      description: description,
      more: more,
      priority: parseInt (priority, 10),
      isPublic: isPublic,
      curation: curation,
      validMembers: JSON.stringify (validMembers),

      tags: tags.join(','),
      hide: hide,

      thread: threadId,

      // do coverpic in a moment
      //collateral: collateralFiles.length > 0 ? null : collateral,
      //collateralName: collateralName,
      //related: related.split(','),

    };

    let storedGroupId = ID;
    //console.log (input);
    try {

      if (ID === null || ID.length < 3) {
        const storedGroup = await API.graphql(graphqlOperation(mutations.createGroup, { input }));
        console.log (storedGroup);
        if (storedGroup && storedGroup.data.createGroup) {
          storedGroupId = storedGroup.data.createGroup.id;
            setID (storedGroupId);
            history.push ('/groupEditor/' + storedGroupId);
            console.log (storedGroup.data.createGroup);
        }
  
      } else {
        const storedGroup = await API.graphql(graphqlOperation(mutations.updateGroup, { input } ));
      }
      
      // Set or check members


      // only deal with files if updating is working
      if (picFiles.length > 0) {
        // upload and grab new URL (and display it)
        uploadCoverPic (picFiles[0], storedGroupId );
      }

      if (collateralFiles) {
        // upload and grab new URL (and display it)
        uploadCollateral (collateralFiles[0], collateralName, storedGroupId);
      }

      // check if a thread exists, else make it
      try {
        const groupThread = await API.graphql(graphqlOperation(queries.getThread, {id: threadId }));
        //console.log (groupThread);
        if (groupThread.data.getThread === null) {
          const input = {
            id: threadId,
            name: (name + " chat").replace(/[^a-z0-9\s]/gi, ''),
            pic: pic,
            isPrivate: false, };
          const newThread = await API.graphql(graphqlOperation(mutations.createThread, { input }));
          console.log ("Created new thread for group");
        }

      } catch (err) {
        console.log ("Failed to find or create thread for group: " + name);
      }
      
      // If we succeeded
      toast.success ("Group edits stored!");
    
      if (changed) {
      changed(); // notify the list
      }
    } catch (err) {
      console.log ("Error storing group: " + err.message);
      toast.warning ("Failed to store group: " + err.message);
      // DEBUG
      console.log (input);
      console.log (err);
    }

  };

  const addPicFiles = newFiles => {
    setPicFiles(...picFiles, newFiles);
  };

  const deletePicFiles = remainingFiles => {
    setPicFiles(remainingFiles);
  };

  const uploadCoverPic = async (file, id) => {
    const filenamekey = id + "_coverPic"
    console.log ("Starting file upload " + file + ", to " + filenamekey);
    const result = await Storage.put(filenamekey, file, { level: 'public' } );

    console.log (result);
    if (result) {
      setPic (result.key);
      setPicFiles([]);
      const storedGroup = await API.graphql(graphqlOperation(mutations.updateGroup, { input: {id: id, pic: result.key} } ));
      toast.success ("Group cover picture uploaded!");
      if (changed) {
      changed ();
    }
    }
  };

  const addCollateralFiles = newFiles => {
    setCollateralFiles(...collateralFiles, newFiles);
  };

  const deleteCollateralFiles = remainingFiles => {
    setCollateralFiles(remainingFiles);
  };

  const uploadCollateral = async (file, name, id) => {
    if (!file) return;

    //console.log (file);
    const fileExt = file.path.substring(file.path.lastIndexOf('.')+1, file.path.length) || file.path;
    const filenamekey = id + '/' + makePrefix (name) + '.' + fileExt;
    console.log ("Starting file upload " + file + ", to " + filenamekey);
    const result = await Storage.put(filenamekey, file, { level: 'public' } );

    //console.log (result);
    if (result) {
      setCollateral (result.key);
      setCollateralFiles([]);
      const storedGroup = await API.graphql(graphqlOperation(mutations.updateGroup, { input: {id: id, collateral: result.key} } ));
      toast.success ("Group collateral uploaded!");
      if (changed) {
      //changed ();
      }    }
  };

  const deleteGroup = async () => {
    if (ID && ID.length > 3) {
      try {
        await API.graphql(graphqlOperation(mutations.deleteGroup, { input: {id: ID} }));
        createNewGroup ();
        if (changed) {
        changed ();
        }
      } catch (err) {
        console.log ("Error deleting group: " + err.message);
      }

    }

  }

  const getGroupDetails = async () => {
    try {
      const foundGroup = await API.graphql(graphqlOperation(queries.getGroup, { id: groupId } ));
      console.log (foundGroup);
      if (foundGroup.data.getGroup) {
        const groupData = foundGroup.data.getGroup;
        setID (groupData.id);
        setName (groupData.name);
        setThread (groupData.thread);
        setPic (groupData.pic);
        setDescription (groupData.description);
        setShortDescription (groupData.shortDescription);
        setMore (groupData.more ? groupData.more : "");
        setPriority (groupData.priority ? groupData.priority.toString() : '0')
        setTags ((groupData.tags && groupData.tags.length > 3) ? groupData.tags.split(','): []);
        setHide (groupData.hide);
        setIsPublic (groupData.isPublic);
        setValidMembers (groupData.validMembers);

        setCollateral (groupData.collateral);   
        setCollateralName (groupData.collateralName)     
        setRelated (groupData.related ? groupData.related.toString() : null);
        // for preview:
      }

      // TODO - get the member list too


    } catch (err) {
      console.log ("Could not get group details, error: " + err.message);
    }
  };

  const duplicateGroup = () => {
    // TODO - double check?
    history.push ("/groupEditor/copy"); // leave blank until we save
    setID (null);
    setName (name + " Copy");
  };

  const createNewGroup = () => {
    // TODO - double check?
    history.push ("/groupEditor/new"); 

    setID (null);
    setName ("New Group");
    setThread (null);

    setPic ("");
    setPicFiles ([]);
    setDescription ("");
    setShortDescription ("");
    setMore ("");
    setPriority ('0');
    setCuration ("")
    setTags ([]);
    setHide (true);
    setIsPublic(true);
    setValidMembers("");


    setCollateral (""); 
    setCollateralFiles([]);
    setCollateralName ("");   
    setRelated("");
  };

  useEffect( () => {
      // did we get to this link by a refresh or similar? Is it active?
      //console.log ("Mode = " + query);
      console.log (groupId);
      //console.log (url);

      if (groupId === 'new') {
        createNewGroup ();
      } else {
        getGroupDetails ();
      }
      

      console.log ("Editing Group " + groupId );
    }, []);
  
  useEffect( () => {
    console.log (tags);
  }, [tags]);

  const groupEditChoices = [
    { label: 'New Group', onClick: () => {createNewGroup ()} },
    { label: 'Duplicate Group', onClick: () => {duplicateGroup ()} },
    { label: 'Revert to saved', onClick: () => {getGroupDetails ()} },
    { label: 'Delete Group', onClick: () => {setGroupDelete (true)} },
];

return (
  <Box
  a11yTitle={'Edit ' + name + ' Group'}
  fill
  tag='footer'
  direction='column'
  align='start'
  gap='small'
  pad={{ left: 'medium', right: 'small', vertical: 'xsmall' }}
  {...props}
  >
    <Box direction="row" justify="between" fill='horizontal' >
      <Box fill='horizontal' align='center' direction='row' gap='small'>
        <Previous color='brand' onClick={() => history.goBack()} />
        <Heading level='3' color="brand" >EDITING - </Heading>
        <Heading level='3' color="brand" >{name}</Heading> 
        <MoreWidget size={32} choices={groupEditChoices} />
      </Box>
      <Box width='medium' margin='medium'>
        <Button primary label="Save Changes" icon=<Save /> onClick = {() => storeGroup() } />
      </Box>

    </Box>  

      {groupDelete && 
        <Layer position="center" onClickOutside={() => setGroupDelete(false)} onEsc={() => setGroupDelete(false)}>
          <Box pad="medium" gap="small" width="medium" round='medium'>
            <Heading level={3} margin="none" color='br'>Confirm </Heading>
            <Text>Are you sure you want to delete this group?</Text>
            <Text>It cannot be undone. Consider turning off visibility instead?</Text>
            <Box as="footer" gap="small" direction="row" align="center" justify="end"
                pad={{ top: "medium", bottom: "small" }} >
              <Button label="Cancel" onClick={() => setGroupDelete(false) } color="dark-3" />
              <Button label={<Text color="white"> <strong>Delete</strong> </Text>}
                onClick={() => {deleteGroup (); setGroupDelete(false); }}
                primary color="status-critical" />
            </Box>
          </Box>
        </Layer>
      }
 
    
  <Scrollbars>
   <Form onReset={() => createNewGroup() }>
        <Box direction='row-responsive' fill gap='medium'>

          <Box flex direction='column' width='medium' gap='small'>
            <FormField label="Group Name" name="name" required>
              <TextInput name="name" value={name} onChange={(event) => setName(event.target.value)} />
            </FormField>

            <FormField label="Short Description" name="shortDescription" required>
              <TextArea name="shortDescription" value={shortDescription} onChange={(event) => setShortDescription(event.target.value)} />
            </FormField>

            <FormField label="Description" name="description" required>
              <TextArea name="description" value={description} onChange={(event) => setDescription(event.target.value)} />
            </FormField>

            <FormField label="Link" name="more" >
              <TextInput name="more" placeholder="https://myeventinformation.com/thisgroup.html" value={more} onChange={(event) => setMore(event.target.value)} />
            </FormField>

            <FormField label="Public" name="hide" >
              <CheckBox  checked={isPublic} label="allow non-members to see details" onChange={(event) => setIsPublic(event.target.checked)} />
            </FormField>

            <FormField label="Priority" name="priority" >
              <Box direction='row' gap='small' wrap={false}> 
                <TextInput name="priority" placeholder="0" value={priority} onChange={(event) => setPriority(event.target.value)} />
                <Text size='small'>higher numbers will list first</Text>
              </Box>
            </FormField>

            <FormField label="Valid Members" name="validMembers" >
              <TextInput name="validMembers" placeholder="" value={validMembers} onChange={(event) => setValidMembers(event.target.value)} />
            </FormField>

          </Box>
        
          <Box flex direction='column' width='medium' gap='small'>

            <FormField label="Visibility" name="hide" >
              <CheckBox  checked={!hide} label="display group to attendees" onChange={(event) => setHide(!(event.target.checked))} />
            </FormField>
            <FormField label="Tags" name="tags">
              <Grid columns='small'>
                {EventParams.Tags.map (nextTag => (
                  <CheckBox label={nextTag} key={nextTag}
                    checked={tags.includes(nextTag)}
                    onChange={(event) => {event.target.checked ? setTags ([...tags, nextTag])
                                                              : setTags (tags.filter (a => a !== nextTag)) } } />
                ))}
              </Grid>
              {/*<Text>{tags.join(',')}</Text>*/}
            </FormField>
            <FormField label="Cover Picture" name="pic" required>
                <Dropzone height='xxsmall' accept="image/*" showPreview={true} onAddFiles={addPicFiles} onDeleteFiles={deletePicFiles} />
                <S3ImageBox  height='small' round="medium" src={pic} />
                <TextInput plain size='xsmall' name="pic" placeholder="greatpictures.com/mypic.jpg" value={pic} onChange={(event) => setPic(event.target.value)} />
            </FormField>
            
            <FormField label="Downloadable Media" name="collateral" required>
                <Dropzone height='xxsmall' showPreview={true} onAddFiles={addCollateralFiles} onDeleteFiles={deleteCollateralFiles} />
                <TextInput size='xsmall' name="collateral" value={collateral} onChange={(event) => setCollateral(event.target.value)} />
                <TextInput size='xsmall' placeholder="What do you want to call this file?" name="collateralName" value={collateralName} onChange={(event) => setCollateralName(event.target.value)} />
            </FormField>

          </Box>
        </Box>
 
    </Form>      
    </Scrollbars>

  </Box>

) };

export default GroupEditor;