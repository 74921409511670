import React, { Box } from 'react';
import { Blank, List } from 'grommet-icons';

import Lottie from 'lottie-react-web';
import loadingAnim from './assets/Vevomo_Loading_Animation-v03.json';


const Hearts = props => (
    <Blank {...props}>
        {props.on ? 
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
            <g>
                <path d="M2.8,11.7c-2-2-2-5.3,0-7.3c1-1,2.3-1.5,3.6-1.5c1.4,0,2.7,0.5,3.6,1.5l1.2,1.2c0.2,0.2,0.5,0.3,0.7,0.3
                    s0.5-0.1,0.7-0.3l1.2-1.2c1-1,2.3-1.5,3.6-1.5c1.4,0,2.7,0.5,3.6,1.5c2,2,2,5.3,0,7.3L12,20.9L2.8,11.7z"/>
                <path d="M17.5,3.9c1.1,0,2.1,0.4,2.9,1.2c1.6,1.6,1.6,4.2,0,5.9l-1.2,1.2L12,19.5l-7.3-7.3L3.5,11
                    c-1.6-1.6-1.6-4.2,0-5.9c0.8-0.8,1.8-1.2,2.9-1.2s2.1,0.4,2.9,1.2l1.2,1.2C11,6.7,11.5,6.9,12,6.9c0.5,0,1-0.2,1.4-0.6l1.2-1.2
                    C15.4,4.4,16.4,3.9,17.5,3.9 M17.5,1.9c-1.6,0-3.1,0.6-4.3,1.8c0,0,0,0,0,0L12,4.9l-1.2-1.2C9.6,2.5,8,1.9,6.5,1.9S3.3,2.5,2.1,3.7
                    c-2.4,2.4-2.4,6.3,0,8.7l1.2,1.2l8.7,8.7l8.7-8.7l1.2-1.2c2.4-2.4,2.4-6.3,0-8.7c0,0,0,0,0,0C20.7,2.5,19.1,1.9,17.5,1.9L17.5,1.9z
                    "/>
            </g>
        </svg> 
        : 
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
            <g>
                <path d="M17.5,3.9c1.1,0,2.1,0.4,2.9,1.2c1.6,1.6,1.6,4.2,0,5.9l-1.2,1.2L12,19.5l-7.3-7.3L3.5,11
                    c-1.6-1.6-1.6-4.2,0-5.9c0.8-0.8,1.8-1.2,2.9-1.2s2.1,0.4,2.9,1.2l1.2,1.2C11,6.7,11.5,6.9,12,6.9c0.5,0,1-0.2,1.4-0.6l1.2-1.2
                    C15.4,4.4,16.4,3.9,17.5,3.9 M17.5,1.9c-1.6,0-3.1,0.6-4.3,1.8c0,0,0,0,0,0L12,4.9l-1.2-1.2C9.6,2.5,8,1.9,6.5,1.9S3.3,2.5,2.1,3.7
                    c-2.4,2.4-2.4,6.3,0,8.7l1.2,1.2l8.7,8.7l8.7-8.7l1.2-1.2c2.4-2.4,2.4-6.3,0-8.7c0,0,0,0,0,0C20.7,2.5,19.1,1.9,17.5,1.9L17.5,1.9z
                    "/>
            </g>
        </svg>}
  </Blank>
);

const tags = [
    "Sales",
    "Partners",
    "Technology",
    "Finance",
    "Marketing",
    "Video"
];

const EventParams = {
    LoadAnimOptions: { loop: true, autoplay: true, rendererSettings: { preserveAspectRatio: 'xMidYMid meet' }, animationData: loadingAnim },

    LoadingBackdrop: "url(/hand-painted-background.jpg)",
    SignUpBackdrop: "url(/hand-painted-background.jpg)",
    MainBackdrop: "",
    Name: "Vevomo Live-demo 2020",
    AllowSignups: true,
    EventID: "vevomolivedemo",
    EventRegOpen: "2020-11-11T10:00-08:00",       // 2012-01-01T00:01:00-08:00
    EventSiteOpen: "2020-11-11T10:00-08:00",
    EventSiteClose: "2020-11-12T22:00-08:00",
    EventShowStart: "2020-11-12T09:00-08:00",
    EventShowEnd: "2020-11-12T16:00-08:00",
    keenProjectName: "livedemo2020",
    keenProject: '5f6fbb4eb3af9250cffc64a7',
    keenWriteKey: '7f336bdbdeca050d67e75e9cefbff8ee00d2c48dc7cad1042cde160e61867f455d1bdb780291bb009bd6b226024184723ba65a9bb13712d06678f44b3872482e93ac088bc146483970e6b84bc6f7520730f93ac28d61b4c21cdadf1fddd9c15e',
    keenReadKey: '9fcf2f31c1e6d61ac7fe939300f247cb7b3755db73fbb71d4c479570e4fe35a81b71f598abdc3d3f39ac55f4647ba3a9a5e0524c6e1924b3a796282c8ec6c5899cdb511f7ad42c3105caf5f52f8d9729953e317badbd476a1a3c097fce670e37',
    EventLogo: "Vevomo_Logo_White_Green_v01.png",
    EventLogoPre: "Vevomo_Logo_White_Green_v01.png",
    EventLogoSmall: "/logo_light.svg",
    LiveIndicator: "/liveIndicator.svg",
    InfoURL: "https://livedemo.vevomo.com",
    Organizer: "Vevomo",
    OrganizerURL: "https://vevomo.com",
    OrganizerLogo: "/Vevomo_Logo_White_Green_v01.png",
    TechsupportLink: 'mailto:info@vevomo.com?subject=Support for the Vevomo Live Demo',
    HelpLink: '',
    VideoConferenceServer: 'v.vevomo.com', // omrk1, uson1
    Has3D: true,
    HasAvatars: true,
    FlipHeaderFooterColors: false,
    DashboardPageName: "DASHBOARD",
    DashboardBackdrop:  "",
	WelcomeVideo: '',
    WelcomeVideoPoster: '',
    WelcomeImage: '',
    DisplayVPNWarning: false,
    SessionsPageName: "AGENDA",
    SessionOverviewFavesOnly: false,
    JoinToasts: true,
    SessionsBackdrop:  "",
    SessionBackdrop: "",
	SessionCarouselSpeed: 8000,
    SpacesPageName: "SPACES",
    SpacesBackdrop:  "",
    HasExhibits: true,
    HasPunchCard: false,
    PunchCardText: '',
	PunchCardImage: '',
	UseExperienceEngine: false,
    PartnersPageName: "PARTNERS",
    PartnersBackdrop:  "",
    HasPeopleGroups: true,
    GroupsPageName: "PEOPLE",
    GroupsBackdrop:  "",
    UsersPageName: "ATTENDEES",
    UsersBackdrop:  "",
    MaxChatParticipants: 50,
    HideReportedComments: false,
    Favorite: Hearts,
	FavoriteColor: 'brand',
    Tags: tags,
    UseChatColors: true,
    MeChatColor: '#aaaaaa',
    TheyChatColor: '#99dd99',
	DayHeaders: [
        "Welcome to the Vevomo Live Demo!"
    ],
    DayTexts: [
        "Try a Virtual Event in our framework!"
    ]

}


export default EventParams;