import React, { useEffect, useState } from 'react';
import { Box, Button, Calendar, DropButton, Heading, Image, Form, FormField, Grid, Layer, Menu, Text, TextInput, TextArea, CheckBox  } from 'grommet';
import { Save, FormDown, Previous } from 'grommet-icons';

import { useParams, useLocation, useHistory, useRouteMatch } from "react-router-dom";
import {v4 as uuid} from 'uuid';

import { API, graphqlOperation, Storage } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';
import usePrevious from '../utils/use-previous'
import moment from 'moment';
import { toast } from 'react-toastify';

import RoutedButton from './RoutedButton';
import { Dropzone } from "./Dropzone";
import S3ImageBox from './S3ImageBox';
import { Scrollbars } from 'react-custom-scrollbars';
import MoreWidget from './MoreWidget';

import { Activity, LVL } from '../Activity';

import EventParams from '../EventItems';
import PartnerDetail from './VevPartnerDetail';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


function makePrefix (str) {
  //console.log (str)
  var strLower = str.toLowerCase();
  var reduced = strLower.replace(/[\W_]/g, '');
  const chopped = reduced.slice(0,15);
  //console.log (chopped);
  return chopped;
}

const PartnerEditor = (props) => {
  const [ID, setID] = useState("");
  const [name, setName] = useState("New Partner");
  
  const [thread, setThread] = useState(null);
  const [threadName, setThreadName] = useState("");
  const [hideThread, setHideThread] = useState(false);
  const [gotoThread, setGotoThread] = useState(false);

  const [pic, setPic] = useState("");
  const [headerpic, setHeaderpic] = useState("");
  const [headerlink, setHeaderlink] = useState("");

  const [picFiles, setPicFiles] = useState([]);
  const [headerpicFiles, setHeaderpicFiles] = useState([]);

  const [shortDescription, setShortDescription] = useState("Partner");
  const [description, setDescription] = useState("Partner");
  const [more, setMore] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [priority, setPriority] = useState(0);
  const [tags, setTags] = useState([]);
  const [hide, setHide] = useState(true);

  const [video, setVideo] = useState("");
  const [slides, setSlides] = useState("");
  
  const [staff, setStaff] = useState("");

  const [dl1Name, setDl1Name] = useState ("");
  const [dl2Name, setDl2Name] = useState ("");
  const [dl3Name, setDl3Name] = useState ("");
  const [dl4Name, setDl4Name] = useState ("");
  const [dl5Name, setDl5Name] = useState ("");
  const [dl6Name, setDl6Name] = useState ("");

  const [dl1Event, setDl1Event] = useState ("download1");
  const [dl2Event, setDl2Event] = useState ("download2");
  const [dl3Event, setDl3Event] = useState ("download3");
  const [dl4Event, setDl4Event] = useState ("download4");
  const [dl5Event, setDl5Event] = useState ("download5");
  const [dl6Event, setDl6Event] = useState ("download6");

  const [dl1File, setDl1File] = useState (null);
  const [dl2File, setDl2File] = useState (null);
  const [dl3File, setDl3File] = useState (null);
  const [dl4File, setDl4File] = useState (null);
  const [dl5File, setDl5File] = useState (null);
  const [dl6File, setDl6File] = useState (null);

  const [downloadables, setDownloadables] = useState ({});

  const [related, setRelated] = useState([]);

  const [partnerDelete, setPartnerDelete] = useState(false);

  let { partnerId } = useParams ();
  let { url } = useRouteMatch ();
  let query = useQuery();
  let history = useHistory ();
  
  const [showingVideo, setShowingVideo] = useState (false);
  const prevVideo = usePrevious(showingVideo);

  const { changed } = props;
  
  const storePartner = async () => {
    const partnerShortName = makePrefix(name);
    //console.log ("Create thread for: " + partnerShortName);
    const threadId = (ID !== null && ID.length > 3 && thread !== null && thread.length > 3) ?
      thread : partnerShortName + '_' + uuid();

    const input = {
      ...(ID !== null && ID.length > 3) && {id: ID},
      name: name,
      pic: picFiles.length > 0 ? null : pic,
      headerpic: headerpicFiles.length > 0 ? null : headerpic,
      headerlink: headerlink,
      // do coverpic in a moment
      // do header pic in a moment
     
      shortDescription: shortDescription,
      description: description,
      more: more,
      phone: phone,
      email: email,

      priority: parseInt (priority, 10),

      tags: tags.join(','),
      hide: hide,

      staff: staff,

      thread: threadId,
      threadName: threadName,
      hideThread: hideThread,
      gotoThread: gotoThread,

      video: video,
      slides: slides,

       // this will get replaced if there are files going up
       downloadables: JSON.stringify (downloadables),
       
      //collateral: collateralFiles.length > 0 ? null : collateral,
      //collateralName: collateralName,
      
      related: related.join(','),

    };

    let storedPartnerId = ID;
    //console.log (input);
    try {

      if (ID === null || ID.length < 3) {
        const storedPartner = await API.graphql(graphqlOperation(mutations.createPartner, { input }));
        console.log (storedPartner);
        if (storedPartner && storedPartner.data.createPartner) {
          storedPartnerId = storedPartner.data.createPartner.id;
            setID (storedPartnerId);
            history.push ('/partnerEditor/' + storedPartnerId);
            console.log (storedPartner.data.createPartner);
        }
  
      } else {
        const storedPartner = await API.graphql(graphqlOperation(mutations.updatePartner, { input } ));
      }
      
      // only deal with files if updating is working
      if (picFiles.length > 0) {
        // upload and grab new URL (and display it)
        uploadCoverPic (picFiles[0], storedPartnerId );
      }

      if (headerpicFiles.length > 0) {
        // upload and grab new URL (and display it)
        uploadHeaderPic (headerpicFiles[0], storedPartnerId );
      }

      uploadDownloadables (storedPartnerId);

      // check if a thread exists, else make it
      try {
        const partnerThread = await API.graphql(graphqlOperation(queries.getThread, {id: threadId }));
        //console.log (partnerThread);
        if (partnerThread.data.getThread === null) {
          const input = { id: threadId, name: name + " chat", pic: pic, isPrivate: false, };
          const newThread = await API.graphql(graphqlOperation(mutations.createThread, { input }));
          console.log ("Created new thread for partner");
        }

      } catch (err) {
        console.log ("Failed to find or create thread for partner: " + name);
      }
      
      // If we succeeded
      toast.success ("Partner edits stored!");
    
      if (changed) {
      changed(); // notify the list
      }

    } catch (err) {
      console.log ("Error storing partner: " + err.message);
      toast.warning ("Failed to store partner: " + err.message);
      // DEBUG
      console.log (input);
      console.log (err);
    }

  };

  const addPicFiles = newFiles => {
    setPicFiles(...picFiles, newFiles);
  };

  const deletePicFiles = remainingFiles => {
    setPicFiles(remainingFiles);
  };

  const addHeaderpicFiles = newFiles => {
    setHeaderpicFiles(...picFiles, newFiles);
  };

  const deleteHeaderpicFiles = remainingFiles => {
    setHeaderpicFiles(remainingFiles);
  };

  const uploadCoverPic = async (file, id) => {
    const filenamekey = id + "_coverPic"
    console.log ("Starting file upload " + file + ", to " + filenamekey);
    const result = await Storage.put(filenamekey, file, { level: 'public' } );

    console.log (result);
    if (result) {
      setPic (result.key);
      setPicFiles([]);
      const storedPartner = await API.graphql(graphqlOperation(mutations.updatePartner, { input: {id: id, pic: result.key} } ));
      toast.success ("Partner cover picture uploaded!");
      if (changed) {
      changed ();
    }
    }
  };

  const uploadHeaderPic = async (file, id) => {
    const filenamekey = id + "_headerPic"
    console.log ("Starting file upload " + file + ", to " + filenamekey);
    const result = await Storage.put(filenamekey, file, { level: 'public' } );

    console.log (result);
    if (result) {
      setHeaderpic (result.key);
      setHeaderpicFiles([]);
      const storedPartner = await API.graphql(graphqlOperation(mutations.updatePartner, { input: {id: id, headerpic: result.key} } ));
      toast.success ("Partner header picture uploaded!");
      if (changed) {
        changed ();
      }
    }
  };

  const uploadDlFile = async (id, file) => {
    const fileName = file.name.substr(0, file.name.lastIndexOf('.'));
    const fileExt = file.path.substring(file.path.lastIndexOf('.')+1, file.path.length) || file.path;
    const filenamekey = id + '/' + makePrefix (fileName) + '.' + fileExt;
    console.log ("Starting file upload " + file + ", to " + filenamekey);
    
    
    // Can't tell if we need await in this?
    const result = await Storage.put(filenamekey, file, { level: 'public' } );
    return result;
  };

  const uploadDownloadables = async (id) => {
    // should have a set of objects with index and file
    var newDownloadables = downloadables;
    toast.warn ("Starting file uploads - please wait for completion!");

    if (dl1File) {
      const result = await uploadDlFile (id, dl1File);
      if (result) {
        console.log ("Uploaded file 1: ", result);
        newDownloadables[1] = {name: dl1Name, file: result.key, event: dl1Event};
        setDl1File (null);
      }
    } else {
      newDownloadables[1] = {name: dl1Name, file: downloadables[1] ? downloadables[1].file : null, event: dl1Event};
    }
    if (dl2File) {
      const result = await uploadDlFile (id, dl2File);
      if (result) {
        console.log ("Uploaded file 2: ", result);
        newDownloadables[2] = {name: dl2Name, file: result.key, event: dl2Event};
        setDl2File (null);
      }
    } else {
      newDownloadables[2] = {name: dl2Name, file: downloadables[2] ? downloadables[2].file : null, event: dl2Event};
    }

    if (dl3File) {
      const result = await uploadDlFile (id, dl3File);
    if (result) {
        console.log ("Uploaded file 3: ", result);
        newDownloadables[3] = {name: dl3Name, file: result.key, event: dl3Event};
        setDl1File (null);
      }
    } else {
      newDownloadables[3] = {name: dl3Name, file: downloadables[3] ? downloadables[3].file : null, event: dl3Event};
    }

    if (dl4File) {
      const result = await uploadDlFile (id, dl4File);
      if (result) {
        console.log ("Uploaded file 4: ", result);
        newDownloadables[4] = {name: dl4Name, file: result.key, event: dl4Event};
        setDl4File (null);
      }
    } else {
      newDownloadables[4] = {name: dl4Name, file: downloadables[4] ? downloadables[4].file : null, event: dl4Event};
    }
    if (dl5File) {
      const result = await uploadDlFile (id, dl5File);
      if (result) {
        console.log ("Uploaded file 5: ", result);
        newDownloadables[5] = {name: dl5Name, file: result.key, event: dl5Event};
        setDl1File (null);
    }
    } else {
      newDownloadables[5] = {name: dl5Name, file: downloadables[5] ? downloadables[5].file : null, event: dl5Event};
    }
    if (dl6File) {
      const result = await uploadDlFile (id, dl6File);
      if (result) {
        console.log ("Uploaded file 6: ", result);
        newDownloadables[6] = {name: dl6Name, file: result.key, event: dl6Event};
        setDl6File (null);
      }
    } else {
      newDownloadables[6] = {name: dl6Name, file: downloadables[6] ? downloadables[6].file : null, event:dl6Event};
    }
    

    console.log (newDownloadables);
    setDownloadables (newDownloadables);
    const downloadablesString = JSON.stringify (newDownloadables);

    const storedPartner = await API.graphql(graphqlOperation(mutations.updatePartner, { input: {id: id, downloadables: downloadablesString} } ));
    toast.success ("File uploads complete - carry on.");
  };

  const deletePartner = async () => {
    if (ID && ID.length > 3) {
      try {
        await API.graphql(graphqlOperation(mutations.deletePartner, { input: {id: ID} }));
        createNewPartner ();
        if (changed) {
        changed ();
        }
      } catch (err) {
        console.log ("Error deleting partner: " + err.message);
      }

    }

  }

  const getPartnerDetails = async () => {
    try {
      const foundPartner = await API.graphql(graphqlOperation(queries.getPartner, { id: partnerId } ));
      console.log (foundPartner);
      if (foundPartner.data.getPartner) {
        const partnerData = foundPartner.data.getPartner;
        setID (partnerData.id);
        setName (partnerData.name);
        setThread (partnerData.thread);
        setThreadName (partnerData.threadName);
        setHideThread (partnerData.hideThread);
        setGotoThread (partnerData.gotoThread);

        setPic (partnerData.pic);
        setHeaderpic (partnerData.headerpic);
        setHeaderlink (partnerData.headerlink);

        setShortDescription (partnerData.shortDescription);
        setDescription (partnerData.description);
        setMore (partnerData.more ? partnerData.more : "");
        setStaff (partnerData.staff ? partnerData.staff: ""); //.toString() : null);
        setPhone (partnerData.phone ? partnerData.phone : "");
        setEmail (partnerData.email ? partnerData.email : "");

        setPriority (partnerData.priority ? partnerData.priority.toString() : '0')
        setTags ((partnerData.tags && partnerData.tags.length > 3) ? partnerData.tags.split(','): []);
        setHide (partnerData.hide);

        setVideo (partnerData.video);
        setSlides (partnerData.slides);

        
        const downloadableData = partnerData.downloadables ? JSON.parse (partnerData.downloadables) : {};
        setDownloadables (downloadableData);
        setDl1Name (downloadableData[1] ? downloadableData[1].name : "");
        setDl2Name (downloadableData[2] ? downloadableData[2].name : "");
        setDl3Name (downloadableData[3] ? downloadableData[3].name : "");
        setDl4Name (downloadableData[4] ? downloadableData[4].name : "");
        setDl5Name (downloadableData[5] ? downloadableData[5].name : "");
        setDl6Name (downloadableData[6] ? downloadableData[6].name : "");

        setDl1Event (downloadableData[1] && downloadableData[1].event.length > 2 ? downloadableData[1].event : "download1");
        setDl2Event (downloadableData[2] && downloadableData[2].event.length > 2 ? downloadableData[2].event : "download2");
        setDl3Event (downloadableData[3] && downloadableData[3].event.length > 2 ? downloadableData[3].event : "download3");
        setDl4Event (downloadableData[4] && downloadableData[4].event.length > 2 ? downloadableData[4].event : "download4");
        setDl5Event (downloadableData[5] && downloadableData[5].event.length > 2 ? downloadableData[5].event : "download5");
        setDl6Event (downloadableData[6] && downloadableData[6].event.length > 2 ? downloadableData[6].event : "download6");

        setDl1File (null);
        setDl2File (null);
        setDl3File (null);
        setDl4File (null);
        setDl5File (null);
        setDl6File (null);
    
        setRelated ((partnerData.related && partnerData.related.length > 3) ? partnerData.related.split(',') : []);
        // for preview:
      }

    } catch (err) {
      console.log ("Could not get partner details, error: " + err.message);
    }
  };

  const duplicatePartner = () => {
    // TODO - double check?
    history.push ("/partnerEditor/copy"); // leave blank until we save
    setID (null);
    setName (name + " Copy");
  };

  const createNewPartner = () => {
    // TODO - double check?
    history.push ("/partnerEditor/new"); 

    setID (null);
    setName ("New Partner");
    setThread (null);
    setThreadName ("");
    setHideThread (false);
    setGotoThread (false);

    setPic ("");
    setPicFiles ([]);
    setHeaderpic ("");
    setHeaderpicFiles ([]);
    setHeaderlink ("");

    setShortDescription ("");
    setDescription ("");
    setMore ("");
    setStaff ("");
    setEmail ("");
    setPhone ("");

    setPriority ('0');
    setTags ([]);
    setHide (true);
    
    setVideo ("");
    setSlides ("");

    setDownloadables ({});
    setDl1Name ("");
    setDl2Name ("");
    setDl3Name ("");
    setDl4Name ("");
    setDl5Name ("");
    setDl6Name ("");

    setDl1Event ("download1");
    setDl2Event ("download2");
    setDl3Event ("download3");
    setDl4Event ("download4");
    setDl5Event ("download5");
    setDl6Event ("download6");

    setDl1File (null);
    setDl2File (null);
    setDl3File (null);
    setDl4File (null);
    setDl5File (null);
    setDl6File (null);

    setRelated([]);
  };

  useEffect( () => {
      // did we get to this link by a refresh or similar? Is it active?
      //console.log ("Mode = " + query);
      console.log (partnerId);
      //console.log (url);

      if (partnerId === 'new') {
        createNewPartner ();
      } else {
        getPartnerDetails ();
      }
      

      console.log ("Editing Partner " + partnerId );
    }, []);
  
  useEffect( () => {
    console.log (tags);
  }, [tags]);

  const partnerEditChoices = [
    { label: 'New Partner', onClick: () => {createNewPartner ()} },
    { label: 'Duplicate Partner', onClick: () => {duplicatePartner ()} },
    { label: 'Revert to saved', onClick: () => {getPartnerDetails ()} },
    { label: 'Delete Partner', onClick: () => {setPartnerDelete (true)} },
];

return (
  <Box
  a11yTitle={'Edit ' + name + ' Partner'}
  fill
  tag='footer'
  direction='column'
  align='start'
  gap='small'
  pad={{ left: 'medium', right: 'small', vertical: 'xsmall' }}
  {...props}
  >
    <Box direction="row" justify="between" fill='horizontal' >
      <Box fill='horizontal' align='center' direction='row' gap='small'>
        <Previous color='brand' onClick={() => history.goBack()} />
        <Heading level='3' color="brand" >EDITING - </Heading>
        <Heading level='3' color="brand" >{name}</Heading> 
        <MoreWidget size={32} choices={partnerEditChoices} />
      </Box>
      <Box width='medium' margin='medium'>
        <Button primary label="Save Changes" icon=<Save /> onClick = {() => storePartner() } />
      </Box>

    </Box>  

      {partnerDelete && 
        <Layer position="center" onClickOutside={() => setPartnerDelete(false)} onEsc={() => setPartnerDelete(false)}>
          <Box pad="medium" gap="small" width="medium" round='medium'>
            <Heading level={3} margin="none" color='br'>Confirm </Heading>
            <Text>Are you sure you want to delete this partner?</Text>
            <Text>It cannot be undone. Consider turning off visibility instead?</Text>
            <Box as="footer" gap="small" direction="row" align="center" justify="end"
                pad={{ top: "medium", bottom: "small" }} >
              <Button label="Cancel" onClick={() => setPartnerDelete(false) } color="dark-3" />
              <Button label={<Text color="white"> <strong>Delete</strong> </Text>}
                onClick={() => {deletePartner (); setPartnerDelete(false); }}
                primary color="status-critical" />
            </Box>
          </Box>
        </Layer>
      }
 
    
  <Scrollbars>
   <Form onReset={() => createNewPartner() }>
        <Box direction='row-responsive' fill gap='medium'>

          <Box flex direction='column' width='medium' gap='small'>
            <FormField label="Partner Name" name="name" required>
              <TextInput name="name" value={name} onChange={(event) => setName(event.target.value)} />
            </FormField>

            <FormField label="Short Description" name="shortDescription" required>
              <TextArea name="shortDescription" resize='vertical'
                value={shortDescription} onChange={(event) => setShortDescription(event.target.value)} />
            </FormField>

            <FormField label="Description" name="description" required>
              <TextArea name="description" resize='vertical'
                value={description} onChange={(event) => setDescription(event.target.value)} />
            </FormField>

            <FormField label="Link" name="more" >
            <TextInput name="more" placeholder="www.myeventinformation.com" value={more} onChange={(event) => setMore(event.target.value)} />
            </FormField>

            <FormField label="Header Pic Link" name="headerlink" >
            <TextInput name="headerlink" placeholder="https://myeventinformation.com/thisproduct.html" value={headerlink} onChange={(event) => setHeaderlink(event.target.value)} />
            </FormField>

            <FormField label="Priority" name="priority" >
              <Box direction='row' gap='small' wrap={false}> 
                <TextInput name="priority" placeholder="0" value={priority} onChange={(event) => setPriority(event.target.value)} />
                <Text size='small'>higher numbers will list first</Text>
              </Box>
            </FormField>

            <FormField label="Staff" name="staff" >
              <TextInput name="staff" placeholder="John Smith, Jane Doe" value={staff} onChange={(event) => setStaff(event.target.value)} />
            </FormField>

            <FormField label="Phone Number" name="phone" >
              <TextInput name="phone" placeholder="x (xxx) xxx-xxx" value={phone} onChange={(event) => setPhone(event.target.value)} />
            </FormField>

            <FormField label="Email Address" name="email" >
              <TextInput name="email" placeholder="Enter the contact email for this partner" value={email} onChange={(event) => setEmail(event.target.value)} />
            </FormField>

            <FormField label="Cover Picture" name="pic" required>
                <Dropzone height='xxsmall' accept="image/*" showPreview={true} onAddFiles={addPicFiles} onDeleteFiles={deletePicFiles} />
                <S3ImageBox  height='small' round="medium" src={pic} />
                <TextInput plain size='xsmall' name="pic" placeholder="greatpictures.com/mypic.jpg" value={pic} onChange={(event) => setPic(event.target.value)} />
            </FormField>
            <FormField label="Header Picture" name="headerpic" required>
                <Dropzone height='xxsmall' accept="image/*" showPreview={true} onAddFiles={addHeaderpicFiles} onDeleteFiles={deleteHeaderpicFiles} />
                <S3ImageBox  height='small' round="medium" src={headerpic} />
                <TextInput plain size='xsmall' name="headerpic" placeholder="greatpictures.com/mypic.jpg" value={headerpic} onChange={(event) => setHeaderpic(event.target.value)} />
            </FormField>

            <FormField label="Partner Gallery" name="slides">
              <Text>Contact your Event Project Manager to upload images and videos for this partner.</Text>
              <TextInput  size='small' name="slides" value={slides} onChange={(event) => setSlides(event.target.value)} />
              <Text size='small'>images and videos in this folder will play in a loop</Text>
            </FormField>

          </Box>
        
          <Box flex direction='column' width='medium' gap='small'>

            <FormField label="Visibility" name="hide" >
              <CheckBox  checked={!hide} label="display partner to attendees" onChange={(event) => setHide(!(event.target.checked))} />
            </FormField>
            
            <FormField label="Chat Thread Name" name="threadName" >
              <TextInput name="threadName" placeholder="Set the name of the chat thread" value={threadName} onChange={(event) => setThreadName(event.target.value)} />
              <Text size='small'>Will be used when new thread is created (only)</Text>
            </FormField>

            <FormField label="Thread Access" name="hideThread" >
              <CheckBox  checked={hideThread} label="hide the chat thread for this partner" onChange={(event) => setHideThread((event.target.checked))} />
            </FormField>
            <FormField label="Open Thread Automatically" name="gotoThread" >
              <CheckBox  checked={gotoThread} label="open the thread when this partner is viewed" onChange={(event) => setGotoThread((event.target.checked))} />
            </FormField>
            
            <FormField label="Tags" name="tags">
              <Grid columns='small'>
                {EventParams.Tags.map (nextTag => (
                  <CheckBox label={nextTag} checked={tags.includes(nextTag)}
                    onChange={(event) => {event.target.checked ? setTags ([...tags, nextTag])
                                                              : setTags (tags.filter (a => a !== nextTag)) } } />
                ))}
              </Grid>
              {/*<Text>{tags.join(',')}</Text>*/}
            </FormField>


            <FormField label="Partner Video" name="video">
              <Text>Contact your Event Project Manager to upload new videos for this partner.</Text>
              <TextInput  size='small' name="video" value={video} onChange={(event) => setVideo(event.target.value)} />
            </FormField>
            
            <FormField label="Downloadable Media 1 (Button text, event name)" name='downloadables'>
                <Box direction='row' pad='small' gap='small'>     
                  <TextInput value={dl1Name} onChange={(event) => setDl1Name(event.target.value)} />
                  <TextInput value={dl1Event} onChange={(event) => setDl1Event(event.target.value)} />
                </Box>
                <Dropzone multiple={false} height='xxsmall' onAddFiles={setFiles => setDl1File (setFiles[0])} />
            </FormField>
            <FormField label="Downloadable Media 2 (Button text, event name)" name='downloadables'>
              <Box direction='row' pad='small' gap='small'>     
                  <TextInput value={dl2Name} onChange={(event) => setDl2Name(event.target.value)} />
                  <TextInput value={dl2Event} onChange={(event) => setDl2Event(event.target.value)} />
                </Box>
                <Dropzone multiple={false} height='xxsmall' onAddFiles={setFiles => setDl2File (setFiles[0])} />
            </FormField>
            <FormField label="Downloadable Media 3 (Button text, event name)" name='downloadables'>
                <Box direction='row' pad='small' gap='small'>     
                  <TextInput value={dl3Name} onChange={(event) => setDl3Name(event.target.value)} />
                  <TextInput value={dl3Event} onChange={(event) => setDl3Event(event.target.value)} />
                </Box>
                <Dropzone multiple={false} height='xxsmall' onAddFiles={setFiles => setDl3File (setFiles[0])} />
            </FormField>
            <FormField label="Downloadable Media 4 (Button text, event name)" name='downloadables'>
                <Box direction='row' pad='small' gap='small'>     
                  <TextInput value={dl4Name} onChange={(event) => setDl4Name(event.target.value)} />
                  <TextInput value={dl4Event} onChange={(event) => setDl4Event(event.target.value)} />
                </Box>
                <Dropzone multiple={false} height='xxsmall' onAddFiles={setFiles => setDl4File (setFiles[0])} />
            </FormField>
            <FormField label="Downloadable Media 5 (Button text, event name)" name='downloadables'>
                <Box direction='row' pad='small' gap='small'>     
                  <TextInput value={dl5Name} onChange={(event) => setDl5Name(event.target.value)} />
                  <TextInput value={dl5Event} onChange={(event) => setDl5Event(event.target.value)} />
                </Box>
                <Dropzone multiple={false} height='xxsmall' onAddFiles={setFiles => setDl5File (setFiles[0])} />
            </FormField>
            <FormField label="Downloadable Media 6 (Button text, event name)" name='downloadables'>
                <Box direction='row' pad='small' gap='small'>     
                  <TextInput value={dl6Name} onChange={(event) => setDl6Name(event.target.value)} />
                  <TextInput value={dl6Event} onChange={(event) => setDl6Event(event.target.value)} />
                </Box>
                <Dropzone multiple={false} height='xxsmall' onAddFiles={setFiles => setDl6File (setFiles[0])} />
            </FormField>
          </Box>
        </Box>
 
    </Form>      
    </Scrollbars>

  </Box>

) };

export default PartnerEditor;