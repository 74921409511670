import React, { useEffect, useState } from 'react';
import { Box, Button, Calendar, DropButton, Heading, Image, Form, FormField, Grid, Layer, Menu, Text, TextInput, TextArea, CheckBox  } from 'grommet';
import { Save, FormDown, Previous } from 'grommet-icons';

import { useParams, useLocation, useHistory, useRouteMatch } from "react-router-dom";
import {v4 as uuid} from 'uuid';

import { API, graphqlOperation, Storage } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';
import usePrevious from '../utils/use-previous'
import moment from 'moment';
import { toast } from 'react-toastify';

import RoutedButton from './RoutedButton';
import { Dropzone } from "./Dropzone";
import S3ImageBox from './S3ImageBox';
import { Scrollbars } from 'react-custom-scrollbars';
import MoreWidget from './MoreWidget';

import { Activity, LVL } from '../Activity';

import EventParams from '../EventItems';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


function makePrefix (str) {
  //console.log (str)
  var strLower = str.toLowerCase();
  var reduced = strLower.replace(/[\W_]/g, '');
  const chopped = reduced.slice(0,15);
  //console.log (chopped);
  return chopped;
}

const SessionEditor = (props) => {
  const [ID, setID] = useState("");
  const [externalID, setExternalID] = useState("");
  const [name, setName] = useState("New Session");
  const [pic, setPic] = useState("");
  
  const [shortDescription, setShortDescription] = useState("Session");
  const [description, setDescription] = useState("Session");
  const [more, setMore] = useState("");
  const [tags, setTags] = useState([]);
  const [hide, setHide] = useState(true);
  
  const [live, setLive] = useState(false);
  const [keynote, setKeynote] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);

  const [preroll, setPreroll] = useState (0);
  const [timeoffset, setTimeoffset] = useState("");
  const [startdate, setStartdate] = useState("");
  const [starttime, setStarttime] = useState("09:00:00");
  const [startdateOpen, setStartdateOpen] = useState(false);

  const [enddate, setEnddate] = useState("");
  const [endtime, setEndtime] = useState("10:00:00");
  const [enddateOpen, setEnddateOpen] = useState(false);

  const [duration, setDuration] = useState("");

  const [presenters, setPresenters] = useState("");
  const [presenterIDs, setPresenterIDs] = useState([]);
  const [facilitatorIDs, setFacilitatorIDs] = useState([]);
  
  const [permitted, setPermitted] = useState([]);
  const [related, setRelated] = useState([]);

  const [thread, setThread] = useState(null);
  const [threadName, setThreadName] = useState("");
  const [hideThread, setHideThread] = useState(false);
  const [gotoThread, setGotoThread] = useState(false);
  // just use to make a new thread - don't set to session
  
  const [picFiles, setPicFiles] = useState([]);

  const [useSessionViewer, setUseSessionViewer] = useState(false);
  const [video, setVideo] = useState("");
  const [interactive, setInteractive] = useState("");
  const [content, setContent] = useState([]);
  const [videochat, setVideochat] = useState("");
  const [videochatServer, setVideochatServer] = useState("");
  const [allowNonPresenters, setAllowNonPresenters] = useState(false);

  const [collateral, setCollateral] = useState("");
  const [collateralName, setCollateralName] = useState("");
  const [collateralFiles, setCollateralFiles] = useState([]);
  
  const [sessionDelete, setSessionDelete] = useState(false);

  let { sessionId } = useParams ();
  let { url } = useRouteMatch ();
  let query = useQuery();
  let history = useHistory ();
  
  const [showingVideo, setShowingVideo] = useState (false);
  const prevVideo = usePrevious(showingVideo);

  const { changed } = props;
  
  const storeSession = async () => {
    const sessionShortName = makePrefix(name);
    //console.log ("Create thread for: " + sessionShortName);
    const threadId = (ID !== null && ID.length > 3 && thread !== null && thread.length > 3) ?
      thread : sessionShortName + '_' + uuid();

    const startDateToUse = (!startdate || startdate === "Invalid date") ? null : moment(startdate).format('YYYY-MM-DD');
    const endDateToUse = (!enddate || enddate === "Invalid date") ? null : moment(enddate).format('YYYY-MM-DD');
    const input = {
      ...(ID !== null && ID.length > 3) && {id: ID},
      name: name,
      pic: picFiles.length > 0 ? null : pic,
      
      shortDescription: shortDescription,
      description: description,
      more: more,
      tags: tags.join(','),
      hide: hide,

      live: live,
      ...(live === true) && {startdate: startDateToUse},
      ...(live === true) && {starttime: starttime + timeoffset},

      ...(live === true) && {enddate: endDateToUse},
      ...(live === true) && {endtime: endtime + timeoffset},

      isKeynote: keynote,
      isFeatured: isFeatured,

      preroll: preroll,
      duration: duration,
      presenters: presenters,
      presenterIDs: JSON.stringify (presenterIDs),
      facilitatorIDs: JSON.stringify (facilitatorIDs),

      permitted: JSON.stringify (permitted),
      related: JSON.stringify (related),

      thread: threadId,
      threadName: threadName,
      hideThread: hideThread,
      gotoThread: gotoThread,

      // do coverpic in a moment
      useSessionViewer: useSessionViewer,
      video: video,
      interactive: interactive,
      content: JSON.stringify (content),
      videochat: videochat,
      videochatServer: videochatServer,
      videoChatAllowNonPresenters: allowNonPresenters,

      collateral: collateralFiles.length > 0 ? null : collateral,
      collateralName: collateralName,
      //related: related.split(','),

    };

    let storedSessionId = ID;
    //console.log (input);
    try {

      if (ID === null || ID.length < 3) {
        const storedSession = await API.graphql(graphqlOperation(mutations.createSession, { input }));
        console.log (storedSession);
        if (storedSession && storedSession.data.createSession) {
          storedSessionId = storedSession.data.createSession.id;
            setID (storedSessionId);
            history.push ('/sessionEditor/' + storedSessionId);
            console.log (storedSession.data.createSession);
        }
  
      } else {
        const storedSession = await API.graphql(graphqlOperation(mutations.updateSession, { input } ));
      }
      
      // only deal with files if updating is working
      if (picFiles.length > 0) {
        // upload and grab new URL (and display it)
        uploadCoverPic (picFiles[0], storedSessionId );
      }

      if (collateralFiles) {
        // upload and grab new URL (and display it)
        uploadCollateral (collateralFiles[0], collateralName, storedSessionId);
      }

      // check if a thread exists, else make it
      try {
        const sessionThread = await API.graphql(graphqlOperation(queries.getThread, {id: threadId }));
        //console.log (sessionThread);
        if (sessionThread.data.getThread === null) {
          const newThreadName = threadName.length > 3 ? threadName : name + " chat";
          const input = {
            id: threadId,
            name: newThreadName.replace(/[^a-z0-9\s]/gi, ''),
            pic: pic,
            isPrivate: false, };
          const newThread = await API.graphql(graphqlOperation(mutations.createThread, { input }));
          console.log ("Created new thread for session");
        } 

      } catch (err) {
        console.log ("Failed to find or create thread for session: " + name);
      }
      
      // If we succeeded
      toast.success ("Session edits stored!");
    
      if (changed) {
      changed(); // notify the list
      }

    } catch (err) {
      console.log ("Error storing session: " + err.message);
      toast.warning ("Failed to store session: " + err.message);
      // DEBUG
      console.log (input);
      console.log (err);
    }

  };

  const addPicFiles = newFiles => {
    setPicFiles(...picFiles, newFiles);
  };

  const deletePicFiles = remainingFiles => {
    setPicFiles(remainingFiles);
  };

  const uploadCoverPic = async (file, id) => {
    const filenamekey = id + "_coverPic"
    console.log ("Starting file upload " + file + ", to " + filenamekey);
    const result = await Storage.put(filenamekey, file, { level: 'public' } );

    console.log (result);
    if (result) {
      setPic (result.key);
      setPicFiles([]);
      const storedSession = await API.graphql(graphqlOperation(mutations.updateSession, { input: {id: id, pic: result.key} } ));
      toast.success ("Session cover picture uploaded!");
      if (changed) {
        changed(); // notify the list
      }
    }
  };

  const addCollateralFiles = newFiles => {
    setCollateralFiles(...collateralFiles, newFiles);
  };

  const deleteCollateralFiles = remainingFiles => {
    setCollateralFiles(remainingFiles);
  };

  const uploadCollateral = async (file, name, id) => {
    if (!file) return;

    //console.log (file);
    const fileName = file.name.substr(0, file.name.lastIndexOf('.'));
    const fileExt = file.path.substring(file.path.lastIndexOf('.')+1, file.path.length) || file.path;
    const filenamekey = id + '/' + makePrefix (fileName) + '.' + fileExt;
    console.log ("Starting file upload " + file + ", to " + filenamekey);
    const result = await Storage.put(filenamekey, file, { level: 'public' } );

    //console.log (result);
    if (result) {
      setCollateral (result.key);
      setCollateralFiles([]);
      const storedSession = await API.graphql(graphqlOperation(mutations.updateSession, { input: {id: id, collateral: result.key} } ));
      toast.success ("Session collateral uploaded!");
      if (changed) {
        changed(); // notify the list
      }
    }
  };

  const deleteSession = async () => {
    if (ID && ID.length > 3) {
      try {
        await API.graphql(graphqlOperation(mutations.deleteSession, { input: {id: ID} }));
        createNewSession ();
        if (changed) {
          changed(); // notify the list
        }
      } catch (err) {
        console.log ("Error deleting session: " + err.message);
      }
    }
  }

  const getSessionDetails = async () => {
    try {
      const foundSession = await API.graphql(graphqlOperation(queries.getSession, { id: sessionId } ));
      console.log (foundSession);
      if (foundSession.data.getSession) {
        const sessionData = foundSession.data.getSession;
        setID (sessionData.id);
        setExternalID (sessionData.externalID);
        setName (sessionData.name);
        setPic (sessionData.pic);

        setShortDescription (sessionData.shortDescription);
        setDescription (sessionData.description);
        setMore (sessionData.more ? sessionData.more : "");
        setTags ((sessionData.tags && sessionData.tags.length > 3) ? sessionData.tags.split(','): []);
        setHide (sessionData.hide);
        setLive (sessionData.live);
        setKeynote (sessionData.isKeynote);
        setIsFeatured (sessionData.isFeatured ? true : false);

        const zonedStartDate = (sessionData.startdate === null) ? moment (sessionData.starttime, "hh:mm") : moment (sessionData.startdate + "T" + sessionData.starttime);
        console.log (zonedStartDate.toISOString());
        setStartdate (zonedStartDate.toISOString());
        setStarttime (zonedStartDate.format('kk:mm'));
        setTimeoffset (zonedStartDate.format('Z'));

        const zonedEndDate =  (sessionData.enddate === null) ? moment (sessionData.endtime, "hh:mm") : moment (sessionData.enddate + "T" + sessionData.endtime);
        setEnddate (zonedEndDate.toISOString());
        setEndtime (zonedEndDate.format('kk:mm'));

        setPreroll (sessionData.preroll ? sessionData.preroll : 0);
        setDuration (sessionData.duration);
        
        setPresenters (sessionData.presenters ? sessionData.presenters: ""); //.toString() : null);
        setPresenterIDs (sessionData.presenterIDs ? JSON.parse (sessionData.presenterIDs) : []);
        setFacilitatorIDs (sessionData.facilitatorIDs ? JSON.parse (sessionData.facilitatorIDs) : []);

        setPermitted (sessionData.permitted ? JSON.parse (sessionData.permitted) : []);
        setRelated (sessionData.reduced ? JSON.parse (sessionData.related) : []);
        
        setThread (sessionData.thread);
        setThreadName (sessionData.threadName ? sessionData.threadName : "");
        setHideThread (sessionData.hideThread);
        setGotoThread (sessionData.gotoThread);
        
        setUseSessionViewer (sessionData.useSessionViewer);
        setVideo (sessionData.video);
        setInteractive (sessionData.interactive);
        
        setContent(sessionData.content ? JSON.parse (sessionData.content) : []);

        setVideochat (sessionData.videochat ? sessionData.videochat : "");
        setVideochatServer (sessionData.videochatServer ? sessionData.videochatServer : "");
        setAllowNonPresenters ((sessionData.videoChatAllowNonPresenters && sessionData.videoChatAllowNonPresenters === true));
        
        setCollateral (sessionData.collateral);   
        setCollateralName (sessionData.collateralName);     
        
        // for preview:
      }

    } catch (err) {
      console.log ("Could not get session details, error: " + err.message);
    }
  };

  const duplicateSession = () => {
    // TODO - double check?
    history.push ("/sessionEditor/copy"); // leave blank until we save
    setID (null);
    setName (name + " Copy");
  };

  const createNewSession = () => {
    // TODO - double check?
    history.push ("/sessionEditor/new"); 

    setID (null);
    setExternalID ("");
    setName ("New Session");
    setPic ("");
    setPicFiles ([]);

    setShortDescription ("");
    setDescription ("");
    setMore ("");
    setTags ([]);
    setHide (true);
    
    setLive (false);
    setKeynote (false);
    setIsFeatured (false);

    setStartdate (null);
    setStarttime ("09:00:00");
    
    // Leave time offset
    setEnddate (null);
    setEndtime ("10:00:00");

    setPreroll (0);
    setDuration ("");

    setPresenters ("");
    setPresenterIDs ([]);
    setFacilitatorIDs ([]);

    setPermitted (['all-attendees'])
    setRelated([]);
    
    setThread (null);
    setThreadName ("New Session Thread");
    setHideThread (false);
    setGotoThread (false);
    
    setPicFiles([]);    
    
    setUseSessionViewer (false);
    setVideo ("");
    setInteractive ("");

    setContent ([]);
    setVideochat ("");
    setVideochatServer ("");
    setAllowNonPresenters (false);

    setCollateral (""); 
    setCollateralFiles([]);
    setCollateralName ("");   
    
  };

  useEffect( () => {
      // did we get to this link by a refresh or similar? Is it active?
      //console.log ("Mode = " + query);
      console.log (sessionId);
      //console.log (url);

      if (sessionId === 'new') {
        createNewSession ();
      } else {
        getSessionDetails ();
      }
      

      console.log ("Editing Session " + sessionId );
    }, []);
  
  useEffect( () => {
    console.log (tags);
  }, [tags]);

  const getSessionDateAndTime = () => {
    if (live === false) {
      return "Pre-recorded session";
    } else {
      const eventStart = (startdate === null) ? moment (starttime, "hh:mm:ss") :  moment (startdate + "T" + starttime);
      const eventEnd = (enddate === null) ? moment (endtime, "hh:mm:ss") : moment (enddate + "T" + endtime);
    // if dates were blank it should be today
      var eventDuration = moment.duration(eventEnd.diff(eventStart));
      var durationString = (duration) ? " (" + duration + ")" : " (" + eventDuration.asMinutes() + " minutes)";
      // .locale("en").humanize(true)
      
      return eventStart.format('LT, LL') + durationString;
    }
  }

  const sessionEditChoices = [
    { label: 'New Session', onClick: () => {createNewSession ()} },
    { label: 'Duplicate Session', onClick: () => {duplicateSession ()} },
    { label: 'Revert to saved', onClick: () => {getSessionDetails ()} },
    { label: 'Delete Session', onClick: () => {setSessionDelete (true)} },
];

return (
  <Box
  a11yTitle={'Edit ' + name + ' Session'}
  fill
  tag='footer'
  direction='column'
  align='start'
  gap='small'
  pad={{ left: 'medium', right: 'small', vertical: 'xsmall' }}
  {...props}
  >
    <Box direction="row" justify="between" fill='horizontal' >
      <Box fill='horizontal' align='center' direction='row' gap='small'>
        <Previous color='brand' onClick={() => history.goBack()} />
        <Heading level='3' color="brand" >EDITING - </Heading>
        <Heading level='3' color="brand" >{name}</Heading> 
        <MoreWidget size={32} choices={sessionEditChoices} />
      </Box>
      <Box width='medium' margin='medium'>
        <Button primary label="Save Changes" icon=<Save /> onClick = {() => storeSession() } />
      </Box>

    </Box>  

      {sessionDelete && 
        <Layer position="center" onClickOutside={() => setSessionDelete(false)} onEsc={() => setSessionDelete(false)}>
          <Box pad="medium" gap="small" width="medium" round='medium'>
            <Heading level={3} margin="none" color='br'>Confirm </Heading>
            <Text>Are you sure you want to delete this session?</Text>
            <Text>It cannot be undone. Consider turning off visibility instead?</Text>
            <Box as="footer" gap="small" direction="row" align="center" justify="end"
                pad={{ top: "medium", bottom: "small" }} >
              <Button label="Cancel" onClick={() => setSessionDelete(false) } color="dark-3" />
              <Button label={<Text color="white"> <strong>Delete</strong> </Text>}
                onClick={() => {deleteSession (); setSessionDelete(false); }}
                primary color="status-critical" />
            </Box>
          </Box>
        </Layer>
      }
 
    
  <Scrollbars>
   <Form onReset={() => createNewSession() }>
        <Box direction='row-responsive' fill gap='medium'>

          <Box flex direction='column' width='medium' gap='small'>
            <FormField label="Session Name" name="name" required>
              <TextInput name="name" value={name} onChange={(event) => setName(event.target.value)} />
            </FormField>

            <FormField label="ShortDescription" name="shortDescription" required>
              <TextArea name="shortDescription" value={shortDescription} onChange={(event) => setShortDescription(event.target.value)} />
            </FormField>

            <FormField label="Description" name="description" required>
              <TextArea name="description" value={description} onChange={(event) => setDescription(event.target.value)} />
            </FormField>

            <FormField label="Link" name="more" >
            <TextInput name="more" placeholder="https://myeventinformation.com/thissession.html" value={more} onChange={(event) => setMore(event.target.value)} />
            </FormField>
              
            <FormField label="Keynote Session" name="live" >
              <CheckBox  label='this session is a keynote, encourage attendees to attend' checked={keynote} onChange={(event) => setKeynote(event.target.checked)}/>
            </FormField>           
            
            <FormField label="Live Session" name="live" >
              <CheckBox  label='show in agenda, and show session times' checked={live} onChange={(event) => setLive(event.target.checked)}/>
            </FormField>

            <FormField label="Featured Session" name="featured" >
              <CheckBox  label='show with featured sessions' checked={isFeatured} onChange={(event) => setIsFeatured(event.target.checked)}/>
            </FormField>

            <FormField label="Start Date" name="startdate" >
              <Box direction='row' justify="between">
                <DropButton
                  open={startdateOpen}
                  onClose={() => setStartdateOpen(false)}
                  onOpen={() => setStartdateOpen(true)}
                  dropContent={<Calendar date={startdate} onSelect={(datetime) => {
                    setStartdate(datetime);
                    console.log (datetime);
                    setStartdateOpen (false); }}  />}
                >
                  <Box direction="row" gap="medium" align="center">
                    <Text size='small' weight='bold'>{startdate ? moment(startdate).format("dddd, MMMM Do YYYY") : 'Select date'}</Text>
                    <FormDown color="brand" />
                    
                  </Box>
                </DropButton>
                <Button label='no date' onClick={() => setStartdate(null)} />
              </Box>
            </FormField>
            <FormField label="Time Zone (±hh:mm)" name="timezone">
              <Box direction='row' justify="between">
                <TextInput name="timezone" width='small' value={timeoffset} onChange={(event) => setTimeoffset(event.target.value)} />
                <Menu dropProps={{ align: { top: "bottom", left: "left" }, elevation: "large" }}
                  label="zones" items={[
                    { label: "Eastern Standard Time", onClick: () => {setTimeoffset("-05:00")} },
                    { label: "Eastern Daylight Time", onClick: () => {setTimeoffset("-04:00")} },
                    { label: "Central Standard Time", onClick: () => {setTimeoffset("-06:00")} },
                    { label: "Central Daylight Time", onClick: () => {setTimeoffset("-05:00")} },
                    { label: "Mountain Standard Time", onClick: () => {setTimeoffset("-07:00")} },
                    { label: "Mountian Daylight Time", onClick: () => {setTimeoffset("-07:00")} },
                    { label: "Pacific Standard Time", onClick: () => {setTimeoffset("-08:00")} },
                    { label: "Pacific Daylight Time", onClick: () => {setTimeoffset("-07:00")} },
                    { label: "Greenwich Mean Time", onClick: () => {setTimeoffset("+00:00")} },
                    { label: "Central European Standard Time", onClick: () => {setTimeoffset("+02:00")} },
                    { label: "Central European Time", onClick: () => {setTimeoffset("+01:00")} },
                    { label: "East European Standard Time", onClick: () => {setTimeoffset("+03:00")} },
                    { label: "East European Time", onClick: () => {setTimeoffset("+02:00")} } ]}
                />
              </Box>
            </FormField>              
            <FormField label="Start Time (hh:mm:ss, 24h)" name="starttime">
              <TextInput name="starttime" value={starttime} onChange={(event) => setStarttime(event.target.value)} />
            </FormField>

            <FormField label="End Date" name="enddate" >
              <Box direction='row' justify="between">
                <DropButton
                  open={enddateOpen}
                  onClose={() => setEnddateOpen(false)}
                  onOpen={() => setEnddateOpen(true)}
                  dropContent={<Calendar date={enddate} onSelect={(datetime) => {
                    setEnddate(datetime);
                    console.log (datetime);
                    setEnddateOpen (false); }}
                    />}
                >
                  <Box direction="row" gap="medium" align="center" >
                    <Text size='small' weight='bold'>{enddate ? moment(enddate).format("dddd, MMMM Do YYYY") : 'Select date'}</Text>
                    <FormDown color="brand" />
                    
                  </Box>
                </DropButton>
                <Button label='no date' onClick={() => setEnddate(null)} />
              </Box>
            </FormField>
            <FormField label="End Time (hh:mm:ss, 24h)" name="endtime">
              <TextInput name="endtime" value={endtime} onChange={(event) => setEndtime(event.target.value)} />
            </FormField>
            
            <FormField label="Pre-roll (in seconds)" name="preroll">
              <TextInput name="preroll" value={preroll} placeholder='60' onChange={(event) => setPreroll(Number(event.target.value))} />
            </FormField>

            <FormField label="Presenters" name="presenters">
              <TextArea name="presenters" value={presenters} onChange={(event) => setPresenters(event.target.value)} />
              <Text size='small'>this text is in addition to a list of presenters generated from IDs</Text>
              <TextArea name="presenterIDs" placeholder="abcd,1234,efgh"
                value={presenterIDs.join(',')}
                onChange={(event) => setPresenterIDs(event.target.value.split(','))} />
              <Text size='small'>For now: comma separated list of presenter IDs</Text>
            
              <TextArea name="facilitatorIDs" placeholder="abcd,1234,efgh"
                value={facilitatorIDs.join(',')}
                onChange={(event) => setFacilitatorIDs(event.target.value.split(','))} />
              <Text size='small'>For now: comma separated list of faciliator IDs</Text>
              <Text size='small'>Facilitators can access and moderate like presenters but are not listed</Text>
            </FormField> 
            
            <FormField label="Downloadable Media" name="collateral" required>
                <Dropzone height='xxsmall' showPreview={true} onAddFiles={addCollateralFiles} onDeleteFiles={deleteCollateralFiles} />
                <TextInput size='xsmall' name="collateral" value={collateral} onChange={(event) => setCollateral(event.target.value)} />
                <TextInput size='xsmall' placeholder="What do you want to call this file?" name="collateralName" value={collateralName} onChange={(event) => setCollateralName(event.target.value)} />
            </FormField>
   
          </Box>
        
          <Box flex direction='column' width='medium' gap='small'>

            <FormField label="Visibility" name="hide" >
              <CheckBox  checked={!hide} label="display session to attendees" onChange={(event) => setHide(!(event.target.checked))} />
            </FormField>

            <FormField label="Chat Thread Name" name="threadName" >
              <TextInput name="threadName" placeholder="Set the name of the chat thread" value={threadName} onChange={(event) => setThreadName(event.target.value)} />
              <Text size='small'>Will be used when new thread is created (only)</Text>
            </FormField>

            <FormField label="Thread Access" name="hideThread" >
              <CheckBox  checked={hideThread} label="hide the chat thread for this session" onChange={(event) => setHideThread((event.target.checked))} />
            </FormField>
            <FormField label="Open Thread Automatically" name="gotoThread" >
              <CheckBox  checked={gotoThread} label="open the thread when this session is viewed" onChange={(event) => setGotoThread((event.target.checked))} />
            </FormField>

            <FormField label="Tags" name="tags">
              <Grid columns='small'>
                {EventParams.Tags.map (nextTag => (
                  <CheckBox label={nextTag} checked={tags.includes(nextTag)}
                    onChange={(event) => {event.target.checked ? setTags ([...tags, nextTag])
                                                              : setTags (tags.filter (a => a !== nextTag)) } } />
                ))}
              </Grid>
              {/*<Text>{tags.join(',')}</Text>*/}
            </FormField>
            <FormField label="Cover Picture" name="pic" required>
                <Dropzone height='xxsmall' accept="image/*" showPreview={true} onAddFiles={addPicFiles} onDeleteFiles={deletePicFiles} />
                <S3ImageBox  height='small' round="medium" src={pic} />
                <TextInput plain size='xsmall' name="pic" placeholder="greatpictures.com/mypic.jpg" value={pic} onChange={(event) => setPic(event.target.value)} />
            </FormField>

            <FormField label="Use the Session Viewer" name="useSessionViewer" >
              <CheckBox  label='use multiple resizable windows for content (instead of filling space)' checked={useSessionViewer} onChange={(event) => setUseSessionViewer(event.target.checked)}/>
            </FormField>
            
            <FormField label="Session Video" name="video">
              <Text>Contact your Event Project Manager to upload new videos for this session.</Text>
              <TextArea size='xsmall' name="video" value={video} onChange={(event) => setVideo(event.target.value)} />
            </FormField>

            <FormField label="Video Chat - Room name" name="videochat">
              <TextInput name="videochat" value={videochat} 
                onChange={(event) => setVideochat(event.target.value)} />
              <Text size='small'>If non-presenters are allowed, everyone gets the chat.</Text>
              <CheckBox  checked={allowNonPresenters} label="allow non-presenters into chat"
                onChange={(event) => setAllowNonPresenters(event.target.checked)} />
              <Text size='small'>If non-presenters are not allowed, presenters get a chat, and attendees get the other elements - presumably a live stream</Text>
            </FormField>
            <FormField label="Video Chat - Server" name="videochatServer">
              <TextInput name="videochatserver" value={videochatServer} 
                onChange={(event) => setVideochatServer(event.target.value)} />
              <Text size='small'>Leave blank to use the default event server (need to set for recordings or streaming)</Text>
            </FormField>
            
            <FormField label="Interactive Content" name="interactive" >
              <TextInput name="interactive" placeholder="https://PollEv.com/username123" value={interactive} onChange={(event) => setInteractive(event.target.value)} />
              <Text size='small'>Interactive content displays alongside other content</Text>
            </FormField>

            <FormField label="Session Content" name="content">
              <TextArea size='xsmall' name="content" 
                value={content.join(',')}
                onChange={(event) => setContent(event.target.value.split(','))} />
              <Text size='small'>A folder or list of images or videos to be shown in a carousel. Your Event Project Director will process and upload the elements.</Text>
            </FormField>

            
          </Box>
        </Box>
 
    </Form>      
    </Scrollbars>

  </Box>

) };

export default SessionEditor;