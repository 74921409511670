import React, { useState, useEffect } from "react";
import { useInterval } from 'react-use';

import Lottie from 'lottie-react-web';
import widgetAnim from '../assets/newMessage_v01.json';

const MessageWidget = (props) => {
  const {size, indicator, interval} = props;
  const [runAnim, setRunAnim] = useState (-1);

  useEffect( () => {


  }, []);

  useInterval(
    () => {
        if (indicator && runAnim === -1) {
            setRunAnim (1); // toggle
            //console.log ("toggle!");
        } else {
            setRunAnim (-1);
        }
    },
    indicator ? (interval * 1000) : null
  );

  const animOptions = { loop: false, autoplay: true, rendererSettings: { preserveAspectRatio: 'xMidYMid meet' },
    animationData: widgetAnim,
    };
  
  return (
    <Lottie options={animOptions} height={size} width={size} direction={runAnim} />
    
  )
};

export default MessageWidget;
