import React, { useState } from 'react';
import { Box, Heading, Text, TextInput, ResponsiveContext  } from 'grommet';
import PeopleList from './VevPeople';

const Dashboard = (props) => {
  const [filterString, setFilterString] = useState ("");
  const {userID} = props;
  
  return (
    <ResponsiveContext.Consumer>
      {responsive => (
      <Box
        a11yTitle='Dashboard'
        direction='column'
        justify='start'
        pad={{ left: 'medium', right: 'small', vertical: 'xsmall' }}
        gap='medium'
        fill={responsive === 'small' ? 'horizontal' : 'vertical'}
        height={responsive === 'small' ? '800px' : undefined}
        {...props}
        >
            <Box fill='horizontal' align='start' pad='xxsmall'>
                <Heading color='brand' level='2'>Welcome!</Heading>
            </Box>
            <Box  direction='row-responsive' gap='medium' >
              <Box width='large' height='medium' animation="slideUp" >
                <Box round='medium' fill
                  background={{ image: "url(/Welcome-Image-Play.jpg)" }} />
              </Box>
              <Box direction='column' overflow='auto' justify='center' width='large' height='medium' gap='medium' animation="slideUp" >
                <Heading color='brand' level='3'>Today at Vevomo LiveDemo 2020</Heading>
                <Text size='small'>Vevomo is a digital event service designed by live event and production professionals. Using our proprietary framework, we create a custom digital-first event that is an experience (not just a website or platform) for your attendees and your brand. We focus on creating a community for your event to flourish, not just flat streams and dull chat rooms.</Text>
                <Text size='small'>Our service is turnkey, either with opportunities for your staff to provide design elements, or a complete, ‘ready to meet’ solution. If you’re an event planner or producer, you can help drive the client process, or we offer complete experience strategy and content development as part of our ‘a la carte’ approach.</Text>
              </Box>
            </Box>
            
            <Box
              flex={responsive === 'small' ? undefined : true}
              fill={responsive === 'small' ? 'horizontal' : undefined}
              height={responsive === 'small' ? '80%' : undefined}
              overflow='hidden' background='background-contrast' pad='xxsmall'
              elevation='medium' round='small'  gap='small' pad='medium' align='start'>
              <Heading margin='xsmall' color='brand' level='3' >Attendees</Heading>
              <Box background='white' round='large' direction="row" align="center"
                  pad={{ horizontal: "xsmall" }}  >
                <Box flex style={{ minWidth: "120px" }}>
                  <TextInput type="search" plain 
                    placeholder="FILTER" value={filterString} onChange={ ({ target: { value } }) => setFilterString(value) } />
                </Box>
              </Box>
              <PeopleList userID={userID} mini={true} filter={filterString} />
            </Box>
        </Box>
      )}
  </ResponsiveContext.Consumer>
)};

export default Dashboard;