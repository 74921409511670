import {
  grommet,
  Box,
  Footer,
  Heading,
  Grommet,
  Paragraph,
  Text
} from "grommet";
import { deepMerge } from "grommet/utils";
import { css } from 'styled-components';

const EventTheme = deepMerge(grommet, {
    defaultMode: "light",
    global: {
        size: {
          "xxsmall": "48px",
          "xsmall": "96px",
          "small": "192px",
          "medium": "384px",
          "large": "768px",
          "xlarge": "1152px",
          "xxlarge": "1536px",
          "full": "100%"
        },

        colors: {
            brand: '#007FFF',
            secondary: '#E6E6E6',

            focus: '#999999',
            
            "tab": {
              dark: "#101030",
              light: "#E5F2FF"
            },

            "background-back": {
              dark: "#1A1F2B",
              light: "#F7F7F7"
            },
            "background-front": {
              dark: "#222938",
              light: "#FFFFFF"
            },
            "background-contrast": {
              dark: "#FFFFFF08",
              light: "#11111108"
            }

        },

        elevation: {
          dark: {
            none: 'none',
            // You can override the values for box-shadow here.
            xsmall: '0px 2px 2px rgba(0, 0, 0, 0.7)',
            small: '0px 4px 4px rgba(0, 0, 0, 0.7)',
            medium: '0px 6px 8px rgba(0, 0, 0, 0.7)',
            large: '0px 8px 16px rgba(0, 0, 0, 0.7)',
            xlarge: '0px 12px 24px rgba(0, 0, 0, 0.7)',
          },
        },

        font: {
            family: 'Roboto',
            size: '14px',
            height: '16px',
        },
        
    },
    heading: {
      weight: 500,
    },

    formField: {
      label: {
        color: 'brand',
        size: 'xsmall',
        margin: { vertical: '0', bottom: 'small', horizontal: '0'},
        weight: 600,
      },
      border: false,
      margin: '0',
    },

    textInput: {
      extend: {
        padding: '6px'
      }
    },

    avatar: {
      size: {
        medium: '38px',
        large: '48px',
        xlarge: '56px',
        xxlarge: '108px',
        small: '28px',
      }
    },
    
    icon: {
      size: {
        large: '32px',
        medium: '24px',
        small: '18px',
      }
    },

    tab: {
      active: {
        color: 'brand',
      },
      color: 'brand',
      border: {
        side: 'bottom',
        size: 'small',
        color: {
          dark: 'background-front',
          light: 'background-front',
        },
        active: {
          color: {
            dark: 'brand',
            light: 'brand',
          },
        },
      },
      extend: `font-size: 6px`,
    },

    tabs: {
      gap: 'medium',
    },

});
    
export default EventTheme;
  